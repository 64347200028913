import SelectEditData from "./editForm/paragraph.edit.data";
// import SelectEditValidation from './editForm/Select.edit.validation';

export default function (...extend) {
  return {
    components: [
      {
        components: [
          {
            label: "Configuration",
            key: "data",
            components: SelectEditData,
          },
        ],
        key: "tabs",
        type: "tabs",
        input: false,
        tableView: false,
      },
    ],
  };
}
