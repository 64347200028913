import {
  Injectable,
  Output,
  EventEmitter,
  SecurityContext,
} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject, throwError, of, ReplaySubject } from "rxjs";
import { map, catchError, filter } from "rxjs/operators";
import { environment } from "./../../environments/environment";
import { UserService } from "../user.service";
import { HelperServiceService } from "../helper-service.service";

@Injectable({
  providedIn: "root",
})
export class IntegrityService {
  private baseUrl: string;
  private swaggerUrl: string;
  private activeUser: {};
  private systemProperties: {};
  private integrityCheckSearchEndPoint: string;
  private integrityCompanyDetailsEndPoint: string;
  private integrityChekRequestEndPoint: string;
  private integrityEndpoints: {};
  private companyUnderReview: any;
  private companyDetailsKvkNumber: string;
  private selectedCompanyForFlag: any; // it will hold an object with KVK and name for the company that is in process of flaging
  private selectedInformationRequest: any; // it will hold the id of the information request for which we are granting identity reveal and/or answering questions
  private scrollToId: string; // the id of (any) DOM element to which we need to scroll
  private scrollToKey: string; // the key of (any) DOM element to which we need to scroll
  private isScrolled: boolean; // if the scroll has already happened, it will be reset on new action
  private activeUserEvent = new Subject<any>();
  private companyDetails = new Subject<any>();
  private activeIC = new Subject<any>();
  private activeIR = new Subject<any>();
  private flagC = new Subject<any>();
  private searchHistory = new Subject<any>();
  private requestInfo = new Subject<any>();
  private scrollToCompany: ReplaySubject<string> = new ReplaySubject(1);
  private postInfo = new Subject<any>();
  private logActivity = new Subject<any>();
  private integrityFlags = new Subject<any>();
  private integrityClose = new Subject<any>();
  private notifications = new Subject<any>();
  private icReopen = new Subject<any>();
  private postIC = new Subject<any>();
  private hideOrangeDot = new Subject<any>();
  private loggedActivities = new Subject<any>();
  private updateUserInfo = new Subject<any>();
  private kvkStatus = new Subject<any>();
  private partialRecovery = new Subject<any>();
  private loadPastRequests = new Subject<any>();
  private markCloseNotification = new Subject<any>();
  private scrollToKeyStatusChange = new Subject<boolean>();
  private fetchKnownEmailAddresses = new Subject<any>();
  private grantIdentityConsent = new Subject<any>();
  private denyIdentityConsent = new Subject<any>();
  public activeUserStatus = this.activeUserEvent.asObservable();
  public currentCompanyDetails = this.companyDetails.asObservable();
  public activeIntegrityChecks = this.activeIC.asObservable();
  public activeIntegrityRequests = this.activeIR.asObservable();
  public flagedCompany = this.flagC.asObservable();
  public searchHistoryResults = this.searchHistory.asObservable();
  public requestInfoStatus = this.requestInfo.asObservable();
  public scrollToCurrentCompany = this.scrollToCompany.asObservable();
  public postInfoStatus = this.postInfo.asObservable();
  public logActivityStatus = this.logActivity.asObservable();
  public integrityFlagsStatus = this.integrityFlags.asObservable();
  public integrityCloseStatus = this.integrityClose.asObservable();
  public notificationsList = this.notifications.asObservable();
  public icReopenStatus = this.icReopen.asObservable();
  public postIntegrityCheckStatus = this.postIC.asObservable();
  public hideOrangeDotStatus = this.hideOrangeDot.asObservable();
  public loggedActivitiesStatus = this.loggedActivities.asObservable();
  public updateUserInfoStatus = this.updateUserInfo.asObservable();
  public kvkStatusChange = this.kvkStatus.asObservable();
  public partialRecoveryStatus = this.partialRecovery.asObservable();
  public loadPastRequestsStatus = this.loadPastRequests.asObservable();
  public markCloseNotificationStatus =
    this.markCloseNotification.asObservable();
  public detectScrollToKeyStatusChange =
    this.scrollToKeyStatusChange.asObservable(); // currently no subscribers
  public fetchKnownEmailAddressesStatus =
    this.fetchKnownEmailAddresses.asObservable();
  public grantIdentityConsentStatus = this.grantIdentityConsent.asObservable();
  public denyIdentityConsentStatus = this.denyIdentityConsent.asObservable();

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private helper: HelperServiceService,
  ) {
    this.baseUrl = environment.APIEndpoint;
    this.swaggerUrl =
      "https://virtserver.swaggerhub.com/SingleTruth/singletruth/1.0.4/";
    this.integrityEndpoints = {
      activeIntegrityRequests: "api/integrity/requests", // all Requests for information sent to me
      activeIntegrityChecks: "api/integrity/checks", // returns all Integrity Checks made by me, options: open/closed
      markSeenActiveIntegrityChecks: "api/integrity/checks/{kvk}/seen", // markes integrity checks (and requests) as seen
      searchCompany: "api/kvk/companies?query=",
      postIntegrityCheck: "api/integrity/checks",
      requestInformation: "api/integrity/checks/{kvk}/request",
      requestSearchHistory: "api/integrity/checks/history",
      companyDetails: "api/kvk/companies/{kvk}",
      postIntegrityFlag: "api/integrity/flags",
      postAnswersToInformation: "api/integrity/requests/{kvk}/answer",
      rejectPostAnswersToInformation: "api/integrity/requests/{kvk}/reject",
      integrityFlags: "api/integrity/flags",
      integrityClose: "api/integrity/checks/{kvk}/close",
      getNotifications: "api/integrity/notifications",
      markSeenNotifications: "api/integrity/notifications/{kvk}/seen",
      reopenNotification: "api/integrity/checks/{kvk}/reopen",
      markRequestSeen: "api/integrity/requests/{kvk}/seen",
      logActivity: "api/integrity/checks/{kvk}/activities",
      getSystemProperties: "api/system/properties",
      integrityCheckRecover: "api/integrity/checks/{kvk}/recover",
      flagCompanyRecover: "api/integrity/flags/{kvk}/recover",
      loadPastRequests: "api/integrity/checks/{kvk}/requests/previous",
      markClosedNotification: "api/integrity/notifications/{kvk}/close",
      grantIdentityApproval:
        "api​/integrity​/requests​/{kvk}​/identity​/approve",
    };
    this.selectedInformationRequest = null;

    // handle user status
    this.userService.activeUserStatus.subscribe(
      (activeUser) => {
        if (typeof activeUser === undefined) {
          console.log("ERROR FETCHING ACTIVE USER FROM BACKEND");
          this.activeUser = 0;
          this.activeUserEvent.next(false);
          return;
        }
        this.activeUser = activeUser;
        this.activeUserEvent.next(activeUser);
      },
      (error) => {
        console.log(error);
        this.activeUser = 0;
        this.activeUserEvent.next(false);
      },
    );

    // handle update user info status
    this.userService.updateUserInfoStatus.subscribe(
      (status) => {
        // simply re-emit the status
        this.updateUserInfo.next(status);
      },
      (error) => {
        console.log(error);
        this.updateUserInfo.next(null);
      },
    );
  }

  buildEndPoint(endpoint, query = "", demoUrl = true) {
    return (
      (demoUrl ? this.baseUrl : this.swaggerUrl) +
      this.integrityEndpoints[endpoint]
        .replace("{key}", this.getCurrentCompanyKvkNumber())
        .replace("{kvk}", this.companyDetailsKvkNumber) +
      query
    );
  }

  /**
   * call the BE to validate the user
   */
  validateUser() {
    this.userService.validateUser();
  }

  /**
   * Determines where the next scrollToElementWithId event will scroll to
   * @param id string, id of DOM element
   */
  setScrollToId(id: string) {
    this.scrollToId = id;
  }

  /**
   * Retrieves the id of DOM element where the next scrollToElementWithId event will scroll to
   */
  getScrollToId() {
    return this.scrollToId;
  }

  /**
   * Determines where the next scrollToElementWithId event will scroll to
   * @param id string, id of DOM element
   */
  setScrollToKey(key: string) {
    this.scrollToKey = key;
    this.scrollToKeyStatusChange.next(true);
  }

  /**
   * Retrieves the id of DOM element where the next scrollToElementWithId event will scroll to
   */
  getScrollToKey() {
    return this.scrollToKey;
  }

  /**
   * get integrity check
   */
  getIntegrityCheckResults(query) {
    // console.log('calling: ', this.buildEndPoint('searchCompany', query));
    // return this.http.get(this.buildEndPoint('searchCompany', query));
    return this.userService.getEndPoint(
      this.buildEndPoint("searchCompany", query),
    );
  }

  /**
   * set the status of isScrolled
   */
  setIsScrolled(status: boolean) {
    this.isScrolled = status;
  }

  /**
   * get the current status of isScrolled
   */
  getIsScrolled() {
    return this.isScrolled;
  }

  /**
   * post integrity check
   */
  postIntegrityCheck(hideMessage = false) {
    // if hide message is set to true update the user preferences:
    this.updateUserPreference("showIntegrityCheckRecordConfirmDialog", false);

    // make  the integrity check
    console.log(
      "posting integrity check on",
      this.getCurrentCompanyKvkNumber(),
    );
    this.userService
      .postEndPoint(this.buildEndPoint("postIntegrityCheck"), {
        kvkNumber: this.getCurrentCompanyKvkNumber(),
      })
      .subscribe(
        (status) => {
          this.postIC.next(status);
        },
        (error) => {
          console.log(
            "The following error occured while posting integrity check",
            error,
          );
        },
      );
  }

  /**
   * @param companyDetails string
   */
  setCurrentCompany(companyDetails) {
    this.companyUnderReview = companyDetails;
  }

  /**
   * checks if there is companyUnderReview is set and valid
   */
  isValidCurrentCompany() {
    if (
      typeof this.companyUnderReview === "undefined" ||
      this.companyUnderReview === null
    ) {
      return false;
    }
    return true;
  }

  /**
   * clear current company
   */
  clearCurrentCompany() {
    this.companyUnderReview = null;
  }

  /**
   * get all available data for the current company
   */
  getCurrentCompany() {
    return this.companyUnderReview;
  }

  /**
   * get the KVK number of the current company
   */
  getCurrentCompanyKvkNumber() {
    if (!this.isValidCurrentCompany()) {
      return "";
    }
    return this.companyUnderReview.kvkNumber;
  }

  /**
   * get the name of the current company
   */
  getCurrentCompanyName() {
    if (!this.isValidCurrentCompany()) {
      return "";
    }
    return this.companyUnderReview.companyName;
  }

  /**
   * set the integrity check key for the current company
   * @param key string, the key for the current company
   */
  setCurrentCompanyDetails(details) {
    if (!this.isValidCurrentCompany()) {
      return "";
    }
    this.companyUnderReview.details = details;
    console.log("AFTER SET: ", this.companyUnderReview.details);
    return true;
  }

  /**
   * get the integrity check key for the current company
   */
  getCurrentCompanyKey() {
    if (
      !this.isValidCurrentCompany() ||
      (this.isValidCurrentCompany() &&
        typeof this.companyUnderReview.details === "undefined")
    ) {
      return false;
    }
    return this.companyUnderReview.details.key;
  }

  /**
   * get the integrity check key for the current company
   */
  getCurrentCompanyRecord() {
    if (
      !this.isValidCurrentCompany() ||
      (this.isValidCurrentCompany() &&
        typeof this.companyUnderReview.details === "undefined")
    ) {
      return "";
    }
    return this.companyUnderReview.details.record;
  }

  /**
   * get the integrity check key for the current company
   */
  getCurrentCompanyIsNew() {
    if (
      !this.isValidCurrentCompany() ||
      (this.isValidCurrentCompany() &&
        typeof this.companyUnderReview.details === "undefined")
    ) {
      return "";
    }
    return this.companyUnderReview.details.isNew;
  }

  /**
   * make a GET request to retrieve current company details (by KVK number)
   * @param kvkNumber string
   */
  getCompanyDetails(kvkNumber) {
    this.companyDetailsKvkNumber = kvkNumber;

    this.userService
      .getEndPoint(this.buildEndPoint("companyDetails"))
      .subscribe((companyDetails) => {
        if (this.baseUrl.indexOf("swagger") !== -1) {
          companyDetails = this.helper.sanitize(companyDetails["record"]);
        }
        this.companyDetails.next(companyDetails);
      });
  }

  /**
   * get active or closed integrity checks
   * @param status string, open or closed
   */
  getIntegrityChecks(status) {
    this.userService
      .getEndPoint(
        this.buildEndPoint("activeIntegrityChecks", "?status=" + status),
      )
      .subscribe((activeCheckResults) => {
        if (activeCheckResults === undefined) {
          console.log(
            "ERROR FETCHING ACTIVE INTEGRITY CHECK RESULTS FROM BACKEND",
          );
          return;
        }
        // console.log('FROM BE SERVER', activeCheckResults);
        // if (this.baseUrl.indexOf('swagger') !== -1) {
        activeCheckResults = this.helper.sanitize(activeCheckResults["checks"]);
        // }
        this.activeIC.next({ checks: activeCheckResults, status: status });
      });
  }

  /**
   * GET all integrity requests initiated by me
   */
  getIntegrityRequests() {
    this.userService
      .getEndPoint(
        this.buildEndPoint("activeIntegrityRequests", "?status=open"),
      )
      .subscribe((activeRequestsResults) => {
        if (activeRequestsResults === undefined) {
          console.log(
            "ERROR FETCHING ACTIVE INTEGRITY REQUESTS RESULTS FROM BACKEND",
          );
          return;
        }
        // if (this.baseUrl.indexOf('swagger') !== -1) {
        activeRequestsResults = this.helper.sanitize(
          activeRequestsResults["requests"],
        );
        // }
        this.activeIR.next(activeRequestsResults);
      });
  }

  /**
   * set the company that is in process of flaging
   * @param company object
   */
  setSelectedCompanyForFlag(company: {}) {
    if (
      typeof company["kvkNumber"] !== "undefined" &&
      company["kvkNumber"] !== ""
    ) {
      this.selectedCompanyForFlag = this.helper.sanitize(company);
      return true;
    }
    return false;
  }

  /**
   * set the service dates for the company that is in process of flaging
   * @param dates object
   */
  setDatesForSelectedCompanyForFlag(dates: {}) {
    if (
      typeof this.selectedCompanyForFlag !== "undefined" &&
      this.selectedCompanyForFlag !== null
    ) {
      this.selectedCompanyForFlag.startDate = dates["startDate"];
      this.selectedCompanyForFlag.endDate = dates["endDate"];
      return true;
    }
    return false;
  }

  /**
   * get the company that is in process of flaging
   */
  getSelectedCompanyForFlag() {
    return this.selectedCompanyForFlag;
  }

  /**
   * remove the company selected for flaging
   */
  clearSelectedCompanyForFlag() {
    this.selectedCompanyForFlag = null;
  }

  /**
   * it will submit the questions to the "First Trust Company" initiated after integrity search/check
   */
  requestIntegrityCheck(questions, integrityCheckKey, revealIdentity = false) {
    if (typeof integrityCheckKey === "undefined" || integrityCheckKey === "") {
      alert("Unexpected error!");
      console.log("Invalid check key has been passed!");
    }
    this.companyDetailsKvkNumber = integrityCheckKey;
    if (!Array.isArray(questions)) {
      return false;
    }
    const prepped_questions = [];
    const prepped_emails = [];
    let iterator = 0;
    questions.forEach((element) => {
      if (
        (element.name === "additional_email" ||
          element.name.indexOf("integrity_risk_mail") !== -1) &&
        element.value !== ""
      ) {
        prepped_emails.push(
          this.helper.sanitize(element.value, SecurityContext.HTML),
        );
      }
      if (
        (element.name === "additional_info" ||
          element.name.indexOf("integrity_risk_question") !== -1) &&
        element.value !== ""
      ) {
        prepped_questions.push({
          questionOrder: (iterator += 1),
          questionText: this.helper.sanitize(
            element.value,
            SecurityContext.HTML,
          ),
          questionType: "text",
        });
      }
    });
    console.log("I WILL SUBMIT THE FOLLOWING emails: ", prepped_emails);
    console.log("I WILL SUBMIT THE FOLLOWING QUESTIONS: ", prepped_questions);
    const submit = {
      questions: prepped_questions,
      requestForIdentity: revealIdentity,
    };
    if (prepped_emails !== undefined && prepped_emails.length > 0) {
      submit["emails"] = prepped_emails;
    }

    // this.http.post(this.baseUrl + this.integrityChekRequestEndPoint.replace('{key}', this.getCurrentCompanyKvkNumber()),
    this.userService
      .postEndPoint(this.buildEndPoint("requestInformation"), submit)
      // .pipe(
      //   catchError(err => {
      //     console.error(err);
      //     this.requestInfo.next('error!');
      //     return of([]);
      //     }),
      //   filter(response => response['code'] === 200),
      //   )
      // map(data => { alert(); this.requestInfo.next( { status: 'success', data: data } ); } ),
      // catchError( (data) => {
      //   alert();
      //   this.requestInfo.next(
      //       { status: 'error', data: (data.status + ' ' + data.statusText + ' - ' + data.url) }
      //   );
      //   return throwError('Something bad happened; please try again later.');
      // })
      .subscribe(
        (status) => {
          console.log("CONSOLING request information, status", status);
          this.requestInfo.next(status);
        },
        (error) => {
          this.requestInfo.next(null);
        },
      );
  }

  /**
   * send the currently selectedCompanyForFlag to backend for flaging
   */
  flagCompany() {
    // console.log('trying to flag a company!', this.buildEndPoint('activeIntegrityRequests'));
    console.log(this.getSelectedCompanyForFlag());
    console.log("From service, Before sending to BE:", {
      kvkNumber: this.selectedCompanyForFlag.kvkNumber,
      serviceStartDate: this.selectedCompanyForFlag.startDate,
      serviceEndDate: this.selectedCompanyForFlag.endDate,
    });
    this.userService
      .postEndPoint(this.buildEndPoint("postIntegrityFlag"), {
        kvkNumber: this.selectedCompanyForFlag.kvkNumber,
        serviceStartDate: this.selectedCompanyForFlag.startDate,
        serviceEndDate: this.selectedCompanyForFlag.endDate,
      })
      .subscribe(
        (response) => {
          console.log("returned from flaging: ", response);
          if (response === undefined || response === null) {
            console.log("ERROR WHILE FLAGGING THE COMPANY!");
            this.flagC.next({ status: false });
            return;
          }
          // clear the company set for flaging in any case:
          this.clearSelectedCompanyForFlag();
          this.setScrollToKey(response["key"]);
          this.flagC.next({
            status: true,
            response: this.helper.sanitize(response),
          });
        },
        (error) => {
          console.log("ERROR WHILE FLAGGING THE COMPANY!");
          this.flagC.next({ status: false });
        },
      );
  }

  /**
   * it will load the search history results from backend
   */
  loadSearchHistoryResults() {
    this.userService
      .getEndPoint(this.buildEndPoint("requestSearchHistory"))
      .subscribe((result) => {
        // this.companyDetails.next(status);
        console.log("status of request integrity check: ", result);
        this.searchHistory.next(this.helper.sanitize(result));
      });
  }

  /**
   * send a Scroll to Current Company event
   */
  scrollToSelectedCompany() {
    // this.scrollToCompany.next(this.getCurrentCompanyKvkNumber());
    this.scrollToCompany.next(this.getCurrentCompanyKey());
  }

  /**
   * Submits answers to a request for information
   */
  submitAnswersToRequestedInfo(answers, informationRequestKey) {
    if (typeof answers !== "object") {
      console.log("NOT AN OBJECT, returning", typeof answers);
      return false;
    }
    if (
      typeof informationRequestKey === "undefined" &&
      informationRequestKey === ""
    ) {
      console.log("not a key, returning", informationRequestKey);
      return false;
    }
    this.companyDetailsKvkNumber = informationRequestKey;
    console.log(this.companyDetailsKvkNumber);

    this.userService
      .postEndPoint(
        this.buildEndPoint("postAnswersToInformation"),
        this.helper.sanitize(answers),
        { responseType: "text" },
      )
      .subscribe((status) => {
        console.log("returned from posting answers to questions: ", status);
        this.clearSelectedCompanyForFlag();
        this.postInfo.next("sent");
      });
  }

  /**
   * Submits answers to a request for information
   */
  rejectRequestedInfo(informationRequestKey) {
    if (
      typeof informationRequestKey === "undefined" &&
      informationRequestKey === ""
    ) {
      console.log("not a key, returning", informationRequestKey);
      return false;
    }
    this.companyDetailsKvkNumber = informationRequestKey;
    console.log(this.companyDetailsKvkNumber);

    this.userService
      .postEndPoint(
        this.buildEndPoint("rejectPostAnswersToInformation"),
        {},
        { responseType: "text" },
      )
      .subscribe(
        (status) => {
          if (status === undefined) {
            console.log("ERROR WHILE REJECTING THE REQUEST");
            return;
          }
          console.log("returned from rejecting to answer questions: ", status);
          this.clearSelectedCompanyForFlag();
          this.postInfo.next("rejected");
        },
        (error) => {
          console.log(
            "An error occured while trying to reject a request for information",
            error,
          );
          this.postInfo.next("error!");
        },
      );
  }

  /**
   * Logs activity made outside the system
   */
  logIntegrityCheck(logInfo, logIntegrityCheckKey) {
    if (typeof logInfo === "undefined" && logInfo === "") {
      console.log("nothing to log!", logInfo);
      return false;
    }
    this.companyDetailsKvkNumber = logIntegrityCheckKey;

    this.userService
      .postEndPoint(this.buildEndPoint("logActivity"), logInfo)
      .subscribe(
        (status) => {
          // this.clearSelectedCompanyForFlag();
          this.logActivity.next("The activity was successfully logged");
        },
        (error) => {
          console.log(
            "Following error occured while logging information:",
            error,
          );
          this.logActivity.next("Unexpected error while logging the activity");
        },
      );
  }

  /**
   * get companies flagged by the current user
   */
  getMyFlaggedCompanies() {
    this.userService
      .getEndPoint(this.buildEndPoint("integrityFlags"))
      .subscribe((integrityFlags) => {
        if (integrityFlags === undefined) {
          console.log(
            "ERROR FETCHING ACTIVE INTEGRITY CHECK RESULTS FROM BACKEND",
          );
          return;
        }
        integrityFlags = this.helper.sanitize(integrityFlags["flags"]);
        this.integrityFlags.next(integrityFlags);
      });
  }

  /**
   * get companies flagged by the current user
   * @param integrityKey: string, the key of the integrity check we are about to close
   */
  endIntegrityCheck(integrityKey) {
    if (typeof integrityKey === "undefined" || integrityKey === null) {
      console.log("ERROR FETCHING ACTIVE INTEGRITY CHECK RESULTS FROM BACKEND");
      return;
    }
    this.companyDetailsKvkNumber = integrityKey;
    this.userService
      .postEndPoint(
        this.buildEndPoint("integrityClose"),
        {},
        { responseType: "text" },
      )
      .subscribe(
        (integrityClose) => {
          if (integrityClose === undefined) {
            console.log(
              "ERROR FETCHING ACTIVE INTEGRITY CHECK RESULTS FROM BACKEND",
            );
            this.integrityClose.next(null);
            return;
          }
          // integrityClose = this.helper.sanitize(integrityClose['flags']);
          // BE doesn't return anything except for status 200
          this.integrityClose.next(true);
        },
        (error) => {
          console.log(
            "Following error occured while ending integrity check:",
            error,
          );
          this.integrityClose.next(null);
        },
      );
  }

  /**
   * get notifications (new and old) for the user
   */
  getNotifications() {
    this.userService
      .getEndPoint(this.buildEndPoint("getNotifications", "?status=open,seen"))
      .subscribe(
        (notifications) => {
          if (notifications === undefined) {
            console.log("ERROR FETCHING NOTIFICATIONS FROM BACKEND");
            this.notifications.next(null);
            return;
          }
          this.notifications.next(
            this.helper.sanitize(notifications["notifications"]),
          );
        },
        (error) => {
          // console.log('Following error occured while retrieving notifications:', error);
          this.notifications.next(null);
        },
      );
  }

  /**
   * Mark the list of notifications as seen
   * @param notifications any[], array of unseen notifications
   */
  markNotificationsAsSeen(notifications: any[]) {
    if (typeof notifications === "undefined" || notifications === null) {
      console.log("CANNOT MARK EMPTY ARRAY OF NOTIFICATIONS");
      return;
    }
    console.log("marking a notification", notifications);
    notifications.forEach((notification) => {
      this.companyDetailsKvkNumber = notification.Key;
      this.userService
        .postEndPoint(
          this.buildEndPoint("markSeenNotifications"),
          {},
          { responseType: "text" },
        )
        .subscribe(
          (status) => {
            if (status === undefined) {
              console.log("ERROR MARKING NOTIFICATIONS AS SEEN");
              return;
            }
          },
          (error) => {
            console.log(
              "The following error occured when marking a notification",
              error,
            );
          },
        );
    });
  }

  /**
   * Mark the notification as closed
   * @param notificationKey string, key of the notification we are about to mark as closed
   */
  markNotificationAsClosed(notificationKey) {
    if (typeof notificationKey === "undefined" || notificationKey === null) {
      console.log("CANNOT MARK EMPTY NOTIFICATION");
      return;
    }
    console.log("marking a notification as closed", notificationKey);
    this.companyDetailsKvkNumber = notificationKey;
    this.userService
      .postEndPoint(
        this.buildEndPoint("markClosedNotification"),
        {},
        { responseType: "text" },
      )
      .subscribe(
        (status) => {
          if (status === undefined) {
            console.log("ERROR MARKING NOTIFICATIONS AS CLOSED");
            // do nothing
            return;
          }
          // don't show this notification anymore
          console.log("marked as closed successfully");
          this.markCloseNotification.next(notificationKey);
        },
        (error) => {
          console.log(
            "The following error occured when marking a notification as closed",
            error,
          );
          // do nothing
        },
      );
  }

  /**
   * reopen closed integrity check
   */
  reopenIntegrityCheck(key, kvk) {
    if (typeof key === "undefined" || key === null) {
      console.log("CANNOT REOPEN EMPTY INTEGRITY CHECK KEY");
      return;
    }
    this.icReopen.next({
      status: "display",
      message:
        "Please stand by while we re-open the integrity check for you ...",
    });
    this.companyDetailsKvkNumber = key;
    this.setCurrentCompany({ kvkNumber: kvk });
    this.userService
      .postEndPoint(
        this.buildEndPoint("reopenNotification"),
        {},
        { responseType: "text" },
      )
      .subscribe(
        (status) => {
          if (status === undefined) {
            console.log("ERROR REOPENNING INTEGRITY CHECK");
            return;
          }
          // emit reopen status
          this.icReopen.next({
            status: "refresh",
            message: "The integrity check has been successfully re-opened",
          });
        },
        (error) => {
          console.log(
            "The following error occured when reopenning integrity check",
            error,
          );
          this.icReopen.next({
            status: "refresh",
            message: JSON.parse(error.error).message,
          });
        },
      );
  }

  /**
   * mark the request for information as seen by the receiving trust company
   */
  markRequestForInformationSeen(key) {
    if (typeof key !== "string" || key === null) {
      console.log("We could not mark this request as seen");
      return;
    }
    this.companyDetailsKvkNumber = key;
    this.userService
      .postEndPoint(
        this.buildEndPoint("markRequestSeen"),
        {},
        { responseType: "text" },
      )
      .subscribe(
        (status) => {
          if (status === undefined) {
            console.log("We could not mark this request as seen");
            return;
          }
          // success - remove the dot on the FE side
          this.hideOrangeDot.next("success");
        },
        (error) => {
          console.log(
            "The following error occured when marking a request as seen",
            error,
          );
        },
      );
  }

  /**
   * mark the request for information as seen by the receiving trust company
   */
  markRequestForInformationSeenSendingSide(key) {
    if (typeof key !== "string" || key === null) {
      console.log("We could not mark this request as seen");
      return;
    }
    this.companyDetailsKvkNumber = key;
    this.userService
      .postEndPoint(
        this.buildEndPoint("markSeenActiveIntegrityChecks"),
        {},
        { responseType: "text" },
      )
      .subscribe(
        (status) => {
          if (status === undefined) {
            console.log("We could not mark this request as seen");
            return;
          }
          // success - remove the dot on the FE side
          this.hideOrangeDot.next("success");
        },
        (error) => {
          console.log(
            "The following error occured when marking a request as seen",
            error,
          );
        },
      );
  }

  /**
   * retrieves user preference
   * @param preference - the title of the preference that we are retrieving.
   * @return null if no preference is found, the stored value of the preference otherwise
   */
  getUserPreference(preference) {
    return this.userService.getUserPreference(preference);
  }

  /**
   * retrieves user information
   * @return null if no user is found, the stored user object otherwise
   */
  getUserInfo() {
    return this.userService.getUserInfo();
  }

  /**
   * set user preference
   * @param preference - the title of the preference that we are updating
   * @param value - the new value of the preference
   */
  updateUserPreference(preference, value) {
    this.userService.updateUserPreferences({ [preference]: value });
  }

  /**
   * set user preference
   * @param preference - the title of the preference that we are updating
   * @param value - the new value of the preference
   */
  updateUser(params) {
    this.userService.updateUser(params);
  }

  /**
   * Load Logged activities for integrity check
   */
  loadLoggedActivities(key, targetGroup, elementTarget) {
    if (typeof key !== "string" || key === null) {
      console.log("We could not mark this request as seen");
      return;
    }
    this.companyDetailsKvkNumber = key;
    this.userService.getEndPoint(this.buildEndPoint("logActivity")).subscribe(
      (status) => {
        if (status === undefined) {
          console.log(
            "We could retrieve the activities for this integrity check",
          );
          this.loggedActivities.next({
            status: null,
            key: key,
            targetGroup: targetGroup,
            eventTarget: elementTarget,
          });
          return;
        }
        this.loggedActivities.next({
          status: this.helper.sanitize(status),
          key: key,
          targetGroup: targetGroup,
          eventTarget: elementTarget,
        });
      },
      (error) => {
        console.log(
          "The following error occured when retrieving the activities",
          error,
        );
        this.loggedActivities.next({
          status: null,
          key: key,
          targetGroup: targetGroup,
          eventTarget: elementTarget,
        });
      },
    );
  }

  /**
   * Load Logged activities for integrity check
   */
  loadRequestsHistory(key, targetGroup, elementTarget) {
    console.log("targetGroup", targetGroup);
    if (typeof key !== "string" || key === null) {
      console.log("We could not mark this request as seen");
      return;
    }
    this.companyDetailsKvkNumber = key;
    this.userService
      .getEndPoint(this.buildEndPoint("loadPastRequests", ""))
      .subscribe(
        (status) => {
          if (status === undefined) {
            console.log(
              "We could retrieve the activities for this integrity check",
            );
            this.loadPastRequests.next({
              status: null,
              key: key,
              targetGroup: targetGroup,
              eventTarget: elementTarget,
            });
            return;
          }
          this.loadPastRequests.next({
            status: this.helper.sanitize(status),
            key: key,
            targetGroup: targetGroup,
            eventTarget: elementTarget,
          });
        },
        (error) => {
          console.log(
            "The following error occured when retrieving the activities",
            error,
          );
          this.loadPastRequests.next({
            status: null,
            key: key,
            targetGroup: targetGroup,
            eventTarget: elementTarget,
          });
        },
      );
  }

  /**
   * load system properties like enable/disable log activities
   * TODO: when the system grows enough this should be moved to a separate service
   */
  loadSystemProperties() {
    this.userService
      .getEndPoint(this.buildEndPoint("getSystemProperties"))
      .subscribe(
        (status) => {
          if (status === undefined) {
            console.log(
              "We could retrieve the system properties. Advanced features will be disabled!",
            );
            return;
          }
          this.systemProperties = status;
        },
        (error) => {
          console.log(
            "The following error occured when retrieving the activities",
            error,
            "Advanced features will be disabled!",
          );
        },
      );
  }

  /**
   * get system property value
   */
  getSystemProperty(property) {
    if (
      this.systemProperties === undefined ||
      this.systemProperties[property] === undefined
    ) {
      return false;
    }
    return this.systemProperties[property];
  }

  /**
   * set status of KVK service
   */
  setKvkStatusFailed(status: boolean, trigger: string) {
    this.kvkStatus.next({ status: status, trigger: trigger });
  }

  /**
   * reset status of KVK service
   */
  resetKvkStatusFailed() {
    // trigger only headerSearch, companySearch is reset by default
    this.kvkStatus.next({ status: false, trigger: "headerSearch" });
  }

  /**
   * initiate recovery for partial integrity Check
   */
  initiatePartialRecovery(key, target) {
    console.log("key", key, "target", target);
    // first test the unable to recover scenario:
    // this.partialRecovery.next({'result': {
    //   'key': 'string',
    //   'record': {
    //     'noOfFlags': 2,
    //     'createdOn': '2019-01-24T13:33:55.046Z',
    //     'serviceStartDate': '2019-01-24T13:33:55.046Z',
    //     'serviceEndDate': '2019-01-24',
    //     'status': 'open',
    //     'company': {
    //       'partial': false,
    //       'kvkNumber': '90001745',
    //       'businessName': 'Free Movelax',
    //       'legalForm': 'Maatschap',
    //       'businessActivities': [
    //         {
    //           'sbiCode': 777,
    //           'sbiCodeDescription': 'Houden van melkvee',
    //           'isMainSbi': true
    //         }
    //       ],
    //       'registrationDate': 20090626,
    //       'address': {
    //         'street': 'Burg G van Andellaan',
    //         'houseNumber': 11,
    //         'houseNumberAddition': 'string',
    //         'postalCode': '3214TA',
    //         'city': 'Zuidland',
    //         'country': 'Nederland'
    //       },
    //       'website': 'www.somecompany.nl'
    //     }
    //   },
    //   'requests': [
    //     {
    //       'key': 'string',
    //       'record': {
    //         'status': 'open',
    //         'createdOn': '2019-01-24T13:33:55.046Z',
    //         'questions': [
    //           {
    //             'questionOrder': 1,
    //             'questionText': 'What was the nature of the integrity risk?',
    //             'questionType': 'text',
    //             'key': 'QST12312312',
    //             'answer': {
    //               'key': 'ANS123891739812',
    //               'record': 'This is the answer of a particular question'
    //             }
    //           }
    //         ]
    //       }
    //     }
    //   ]
    // }, 'key': key, 'target': target});
    // return;
    if (typeof key !== "string" || key === null) {
      console.log("We could not mark this request as seen");
      return;
    }
    this.companyDetailsKvkNumber = key;
    this.userService
      .postEndPoint(
        this.buildEndPoint(
          target === "Flagged Companies"
            ? "flagCompanyRecover"
            : "integrityCheckRecover",
        ),
        {},
      )
      .subscribe(
        (result) => {
          if (status === undefined) {
            console.log("We could recover the integirty check");
            this.partialRecovery.next({
              result: null,
              key: key,
              target: target,
            });
            return;
          }
          console.log(
            "executed recover: ",
            target === "Flagged Companies"
              ? "flagCompanyRecover"
              : "integrityCheckRecover",
          );
          console.log("RETURNED, FROM RECOVER: ", result);
          // success - update the IC data the FE side
          this.partialRecovery.next({
            result: this.helper.sanitize(result),
            key: key,
            target: target,
          });
        },
        (error) => {
          if (error.status === 503) {
            this.partialRecovery.next({
              result: 503,
              key: key,
              target: target,
            });
          } else {
            console.log(
              "The following error occured when requesting a recovery for integrity check",
              error,
            );
            this.partialRecovery.next({
              result: null,
              key: key,
              target: target,
            });
          }
        },
      );
  }

  // hide notifications menu
  HideHotifications(event) {
    if ($(event.target).closest(".notification-wrapper").length > 0) {
      return;
    }
    // if ($('.notification-wrapper .all-notifications-outer').css('display') === 'block') {
    $(".notification-wrapper .all-notifications-outer").stop().fadeOut(60);
    // }
  }

  // hide notifications menu
  HideMainNav(event) {
    if (
      $(event.target).closest(".nav").length > 0 ||
      $(event.target).closest(".navbar-toggler").length > 0
    ) {
      return;
    }
    $(".nav").addClass("collapsed");
  }

  fetchKnownEmailAddressesForExternalTrust(
    trcKey: string,
    key: string,
    companyName: string,
  ) {
    // key is used for scrollTo purposes while
    // trcKey is used to fetch the email addresses
    if (trcKey === "") {
      console.log(
        "We could not retrieve known email addresses due to lack of valid parameters",
      );
      return;
    }
    this.companyDetailsKvkNumber = trcKey;
    this.userService
      .getEndPoint(`api/integrity/checks/${key}/request/${trcKey}/emails`)
      .subscribe(
        (result) => {
          if (status === undefined) {
            console.log("We could not retrieve known email addresses");
            this.fetchKnownEmailAddresses.next({
              result: null,
              key: key,
              companyName: companyName,
            });
            return;
          }
          // success - update the IC data the FE side
          this.fetchKnownEmailAddresses.next({
            result: this.helper.sanitize(result),
            key: key,
            companyName: companyName,
          });
        },
        (error) => {
          console.log(
            "An error ocurred while retrieving known email addresses",
            error,
          );
          this.fetchKnownEmailAddresses.next({
            result: null,
            key: key,
            companyName: companyName,
          });
        },
      );
  }

  /**
   * set the current information request
   * @param key: string the key of the current information request
   */
  public setInformationRequestData(data) {
    this.selectedInformationRequest = data;
  }

  /**
   * get current information request key
   */
  public getInformationRequestKey() {
    if (
      this.selectedInformationRequest &&
      this.selectedInformationRequest.key
    ) {
      return this.selectedInformationRequest.key;
    }
    return null;
  }

  /**
   * get current information request companyName
   */
  public getInformationRequestCompanyName() {
    if (
      this.selectedInformationRequest &&
      this.selectedInformationRequest.company
    ) {
      return this.selectedInformationRequest.company;
    }
    return null;
  }

  /**
   * reset current information request key
   */
  public resetInformationRequestKey() {
    this.selectedInformationRequest = null;
  }

  public initiateGrantIdentityConsent(key: string) {
    // testing purposes only: manually return result
    // this.grantIdentityConsent.next({'result': {
    //       "record": {
    //         "status": "open",
    //         "createdOn": "2019-09-26T08:34:04.550Z",
    //         "finishedOn": "2019-09-26T08:34:04.551Z",
    //         "type": "internal",
    //         "questions": [
    //           {
    //             "questionOrder": 1,
    //             "questionText": "What is the nature of the integrity risk?",
    //             "questionType": "text",
    //             "key": "QST12312312",
    //             "answer": {
    //               "key": "ANS123891739812",
    //               "record": "This is the answer of a particular question"
    //             }
    //           }
    //         ],
    //         "company": {
    //           "partial": false,
    //           "businessName": "Finos",
    //           "kvkNumber": "12345678"
    //         },
    //         "identity": {
    //           "status": "IDENTITY_REQUESTED",
    //           "organization": "org1"
    //         }
    //       }
    // }
    // , 'key': key});
    // return;
    // key is used for scrollTo purposes while
    if (key === "") {
      console.log(
        "We could not grant a consent due to insufficient parameters",
      );
      this.grantIdentityConsent.next({
        result: false,
        reason: "We could not grant a consent due to insufficient parameters",
      });
      return;
    }
    this.userService
      .postEndPoint(`api/integrity/requests/${key}/identity/approve`, {})
      .subscribe(
        (result) => {
          // success - proceed with answering questions
          this.grantIdentityConsent.next({
            result: this.helper.sanitize(result),
            key: key,
          });
        },
        (error) => {
          this.grantIdentityConsent.next({
            result: false,
            reason: "An error ocurred while granting identity consent",
          });
          console.log(
            "An error ocurred while granting identity consent",
            error,
          );
        },
      );
  }

  public initiateDenyIdentityConsent(key: string) {
    if (key === "") {
      console.log("We could not deny a consent due to insufficient parameters");
      this.denyIdentityConsent.next({
        result: false,
        reason: "We could not deny a consent due to insufficient parameters",
      });
      return;
    }
    this.userService
      .postEndPoint(`api/integrity/requests/${key}/identity/reject`, {})
      .subscribe(
        (result) => {
          // success - proceed with answering questions
          this.denyIdentityConsent.next({
            result: this.helper.sanitize(result),
            key: key,
          });
        },
        (error) => {
          this.denyIdentityConsent.next({
            result: false,
            reason: "An error ocurred while denying identity consent",
          });
          console.log("An error ocurred while denying identity consent", error);
        },
      );
  }
}
