import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-tooltip",
  templateUrl: "./tooltip-component.component.html",
  styleUrls: ["./tooltip-component.component.scss"],
})
export class TooltipComponentComponent implements OnInit {
  @Input() icon = "/assets/images/icon-information-prepared-teal.svg";
  @Input() iconWidth = 20;
  @Input() iconHeight = 20;
  @Input() tooltipText: string;
  @Input() classWrapper = " ";
  @Output() onClick?: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
