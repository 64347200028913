import CheckboxEditData from "./editForm/checkbox.edit.data";
import CheckboxEditDisplay from "./editForm/checkbox.edit.display";
import RadioEditValidation from "./editForm/checkbox.edit.validation";

export default function (...extend) {
  return {
    components: [
      {
        components: [
          {
            label: "Configuration",
            key: "data",
            components: CheckboxEditData,
          },
          // {
          //   label: "Display",
          //   key: "display",
          //   components: CheckboxEditDisplay
          // },
        ],
        key: "tabs",
        type: "tabs",
        input: false,
        tableView: false,
      },
    ],
  };
}
