<div
  [ngClass]="element.class ? element.class : ''"
  class="element-wrapper kompany-input mt-2"
>
  <div class="pt-2">
    <!-- <p class="d-inline"> {{'kompanySearch.content' | translate}} </p> -->
    <a
      [attr.clickmethod]="'changeToManualCreation'"
      (click)="onEmit(elementService.OnButtonClick($event))"
      *ngIf="
        !element.params.basicFieldsProcess && !element.params.reviewProcess
      "
      class="local-manual-link d-block"
      >{{ "kompanySearch.content2" | translate }}</a
    >
  </div>
  <div class="element-wrapper reduced-margin-top input-transparent">
    <span class="input-label font-weight-bold d-flex">
      {{ "evidenceFields.CountryOfRegistration" | translate }}
      <sup class="sup-required sup-required-alternate">*</sup>
      <span *ngIf="element.params.additonalIcon" class="ml-auto"></span>
    </span>
    <span class="select-wrapper">
      <select
        style="background-color: #ffffff"
        class="select-input"
        id="CountryOfRegistration"
        (change)="selectCountryKompany($event)"
        [disabled]="element.params.readOnly || !element.params.processStart"
        [attr.changemethod]="'selectCountryKompany'"
        [attr.isRequired]="element.params.required"
        [value]="
          element.params.selectedCountryValue
            ? element.params.selectedCountryValue
            : -1
        "
      >
        <option
          [selected]="
            element.params.selectedCountryValue === option.country_code
          "
          value="{{ option.country_code }}"
          *ngFor="let option of element.params.countryList"
        >
          {{ option.country_name }}
        </option>
      </select>
    </span>
    <div
      *ngIf="element.params.additionalInfo"
      class="text-right font-italic mt-2"
    >
      {{ element.params.additionalInfo }}
    </div>
    <div
      class="error-field"
      [attr.validatorMessage]="element.params.validatorMessage"
      [attr.validatorMessageField]="element.params.validatorMessage"
    ></div>
  </div>
  <div
    *ngIf="element.params.processStart"
    [ngClass]="{
      'search-blurred':
        !element.params.reviewProcess && !element.params.dontBlur
    }"
    class="kompany-search-input search-blurred"
  >
    <div class="basic-search-container">
      <div class="search-toggle mt-3 pt-2 text-left">
        <div class="font-weight-bold mb-3 color-black">
          {{ "kompanySearch.title" | translate }}
        </div>
        <div class="radio-btn-wrapper">
          <input
            class="custom-radio-button mt-0"
            [attr.changeMethod]="'hideGeneralError'"
            (change)="onEmit(elementService.OnChangeSelect($event))"
            checked
            id="kompanySearchName"
            name="kompanySearchType"
            type="radio"
          />
          <label
            class="custom-radio-label mb-3 mt-0 my-md-0"
            for="kompanySearchName"
            >{{ "kompanySearch.label2" | translate }}</label
          >
          <input
            class="custom-radio-button"
            [attr.changeMethod]="'hideGeneralError'"
            (change)="onEmit(elementService.OnChangeSelect($event))"
            id="kompanySearchID"
            name="kompanySearchType"
            type="radio"
          />
          <label
            class="custom-radio-label mb-3 my-md-0"
            for="kompanySearchID"
            >{{ "kompanySearch.label3" | translate }}</label
          >
          <input
            class="custom-radio-button"
            [attr.changeMethod]="'hideGeneralError'"
            (change)="onEmit(elementService.OnChangeSelect($event))"
            id="kompanySearchLei"
            name="kompanySearchType"
            type="radio"
          />
          <label class="custom-radio-label my-md-0" for="kompanySearchLei">{{
            "kompanySearch.label4" | translate
          }}</label>
        </div>
      </div>
      <div class="d-flex kompany-main-input-wrapper">
        <input
          type="text"
          class="text-input text-company-input"
          id="kompanyInput"
          name="kompanyInputN"
          value="{{ element.params.content }}"
          (blur)="onEmit(elementService.OnInputBlur($event))"
          (keydown.enter)="kompanySearch($event)"
        />
        <button
          (click)="kompanySearch($event)"
          (click)="onEmit(elementService.OnButtonClick($event))"
          class="form-button form-button-1 mr-0 company-input-search-inner company-input-search"
        >
          {{ "common.search" | translate }}
        </button>
      </div>
    </div>
    <a
      [attr.clickMethod]="'showOptionalParameters'"
      (click)="showOptionalParameters($event)"
      class="show-optional-parameters-button more-details-button mt-4 text-left"
    >
      <img src="/assets/images/ico_green.svg" />
      <span>{{ "kompanySearch.label5" | translate }}</span>
    </a>
    <a
      [attr.clickMethod]="'hideOptionalParameters'"
      (click)="hideOptionalParameters($event)"
      class="hide-optional-parameters-button more-details-button mt-4 text-left d-none"
    >
      <img src="/assets/images/ico_green.svg" />
      <span>{{ "kompanySearch.label6" | translate }}</span>
    </a>
    <div class="optional-parameters d-none text-left">
      <div class="font-weight-bold mb-3 mt-4 color-black">
        {{ "kompanySearch.label7" | translate }}
      </div>
      <span style="line-height: 1" class="input-label font-weight-bold">
        {{ "evidenceFields.RegisteredAddress" | translate }}</span
      >
      <input
        type="text"
        class="text-input text-company-input"
        id="kompanyInputAddress"
        name="kompanyInputddressN"
        value="{{ element.params.content }}"
        (blur)="onEmit(elementService.OnInputBlur($event))"
      />
      <span class="input-label font-weight-bold mt-3">
        {{ "evidenceFields.PhoneNumber" | translate }}
      </span>
      <input
        type="text"
        class="text-input text-company-input"
        id="kompanyInputPhone"
        name="kompanyInputPhoneN"
        value="{{ element.params.content }}"
        (blur)="onEmit(elementService.OnInputBlur($event))"
      />
      <span class="input-label font-weight-bold mt-3">
        {{ "evidenceFields.TaxIDNumber" | translate }}</span
      >
      <input
        type="text"
        class="text-input text-company-input"
        id="kompanyInputVat"
        name="kompanyInputVatN"
        value="{{ element.params.content }}"
        (blur)="onEmit(elementService.OnInputBlur($event))"
      />
    </div>
    <div class="d-flex justify-content-end">
      <button
        [attr.clickMethod]="'kompanySearch'"
        (click)="kompanySearch($event)"
        class="form-button form-button-1 mr-0 mt-4 company-input-search company-input-search-outer d-none"
      >
        {{ "common.search" | translate }}
      </button>
    </div>
    <div class="button-error-field kompany-search-error-field">
      {{ "kompanySearch.validation" | translate }}
    </div>
    <div
      *ngIf="element.params.loadingMode"
      class="d-flex-low kompany-loading d-flex-low flex-column"
    >
      <img
        src="/assets/images/spinner-green.svg"
        width="100px"
        height="100px"
        class="my-auto mx-auto"
      />
    </div>
  </div>
  <div *ngIf="element.params.fetchedCompanyList" class="kompany-search-results">
    <div
      class="font-weight-bold input-label mb-3 text-left kompany-search-title d-flex align-items-center"
    >
      <img
        src="assets/images/icon-triangle-red.svg"
        class="mr-2"
        width="16px"
        height="14px"
      />
      <span>{{ "kompanySearch.label9" | translate }} </span>
    </div>
    <div class="kompany-search-results-content">
      <div
        class="font-weight-bold kompany-list-header d-flex justify-content-between"
      >
        <div>{{ "kompanySearch.label10" | translate }}</div>
        <div>{{ "kompanySearch.label11" | translate }}</div>
      </div>
      <div class="search-results-rows">
        <div
          (click)="selectKompanyFromList($event)"
          [attr.clickmethod]="'selectKompanyFromList'"
          [attr.idNumber]="company.id"
          class="d-flex justify-content-between kompany-list-member"
          *ngFor="let company of element.params.fetchedCompanyList"
        >
          <div class="kompany-list-member-name">{{ company.name }}</div>
          <div>{{ company.registrationNumber }}</div>
        </div>
      </div>
    </div>
    <div class="kompany-confirmation-dialogue d-none mt-3">
      <div class="mb-0 text-left">
        {{ "kompanySearch.content3" | translate }}
        <span class="kompany-confirmation-dialogue-name"></span>.
        {{ "kompanySearch.content4" | translate }}
      </div>
      <div class="d-flex justify-content-end">
        <button
          class="form-button form-button-link-2 pl-2 mt-0"
          [attr.clickmethod]="'selectKompanyFromListCancel'"
          (click)="selectKompanyFromListCancel($event)"
        >
          {{ "common.cancel" | translate }}
        </button>
        <button
          class="form-button form-button-link-2 pl-0 mr-0 font-weight-bold mt-0"
          [attr.clickmethod]="'selectKompanyFromListConfirm'"
          (click)="selectKompanyFromListConfirm($event)"
        >
          {{ "common.continue" | translate }}
        </button>
      </div>
    </div>
    <div class="d-flex ml-auto">
      <button
        (click)="searchKompanyAgain($event)"
        [attr.clickmethod]="'searchKompanyAgain'"
        class="form-button form-button-link-2 pl-0 mt-3 search-kompany-again"
      >
        {{ "kompanySearch.button2" | translate }}
      </button>
    </div>
    <div
      *ngIf="element.params.loadingMode"
      class="d-flex-low kompany-loading d-flex-low flex-column"
    >
      <img
        src="/assets/images/spinner-green.svg"
        width="100px"
        height="100px"
        class="my-auto mx-auto"
      />
    </div>
  </div>
  <div
    class="d-flex flex-column justify-content-center align-items-center mt-4"
    *ngIf="element.params.kompanyCallError"
  >
    <p class="px-3 mb-3 text-center color-black" style="line-height: 1.2">
      {{ element.params.errorMessage }}
    </p>
    <div class="d-flex">
      <button
        *ngIf="
          !element.params.basicFieldsProcess && !element.params.reviewProcess
        "
        class="form-button form-button-link-2 mr-1"
        [attr.clickmethod]="'changeToManualCreation'"
        (click)="onEmit(elementService.OnButtonClick($event))"
      >
        {{ "kompanySearch.label8" | translate }}
      </button>
      <!-- <button
      *ngIf="element.params.basicFieldsProcess"
      class="form-button form-button-link-2 mr-1"
      [attr.clickmethod]="'changeToManualCreation'"
      (click)="cancelBasicFieldsProcess()">
        Input manually
      </button> -->
      <button
        class="form-button form-button-link-2 mr-1"
        [attr.clickmethod]="'searchKompanyAgain'"
        (click)="searchKompanyAgain($event)"
      >
        {{ "kompanySearch.button" | translate }}
      </button>
    </div>
  </div>
</div>
