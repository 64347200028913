import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RiskAssessmentService } from 'src/app/id-verification/pages/risk-assessment/risk-assessment.service';

@Component({
  selector: 'app-risk-indicator-override',
  templateUrl: './risk-indicator-override.component.html',
  styleUrls: ['./risk-indicator-override.component.scss']
})
export class RiskIndicatorOverrideComponent implements OnInit {
  @Input() data;
  @Output() closeLightbox : EventEmitter<any> = new EventEmitter();
  indicatorValue : boolean = true;
  comment : string = '';
  characterCount : number = 0; 

  constructor(private riskAssessmentService: RiskAssessmentService) { }

  ngOnInit(): void {
  }

  close(){
    this.closeLightbox.emit();
  }
  updateRiskIndicator()
  {
    let payload = {answers: [
      {
      value: this.data.isAnswered ? !this.data.value : this.indicatorValue,
      questionId: this.data.questionId,
      comment : this.comment,
    }]}


    this.close();
    this.riskAssessmentService.loadRiskAssessmentLightbox();

    this.riskAssessmentService.overrideRiskIndicator(this.data.RRkey, payload);
  }
  countCharacters(event)
  {
    this.characterCount = event.target.textLength;
  }
}
