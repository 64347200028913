// Get the HTMLComponent from the components listing.
const HTMLComponent = Formio.Components.components.htmlelement;
import headerComponentForm from "./header.form";

/**
 * Create a Header compoennt and extend from the HTMLComponent.
 */
class HeaderExtendedComponent extends HTMLComponent {
  /**
   * Define the default schema to change the type and tag and label.
   */
  static schema(...extend) {
    return super.schema(
      {
        type: "HeaderExtendedComponent",
        label: "",
        any_other_fields: "",
      },
      ...extend,
    );
  }

  static get builderInfo() {
    return {
      title: "Header",
      group: "layout",
      icon: "header",
      weight: 2,
      schema: this.schema(),
    };
  }

  render() {
    const submission = _.get(this.root, "submission", {});
    this.tpl = `
        <h2 class="questionare-title ${this.component.HeaderPosition} ${this.component.HeaderSize}">${this.component.label}</h2>`;
    // Note the use of the 'ref' tag, which is used later to find
    // parts of your rendered element.
    // If you need to render the superclass,
    // you can do that with
    // tpl+=super.render(element);
    // This wraps your template to give it the standard label and bulider decorations
    return Formio.Components.components.component.prototype.render.call(
      this,
      this.tpl,
    );
  }
}

HeaderExtendedComponent.editForm = headerComponentForm;

Formio.Components.addComponent(
  "HeaderExtendedComponent",
  HeaderExtendedComponent,
);
export default HeaderExtendedComponent;
