import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
  AfterViewInit,
  OnChanges,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ElementsService } from "../../elements.service";

@Component({
  selector: "app-related-party",
  templateUrl: "./related-party.component.html",
  styleUrls: ["./related-party.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class RelatedPartyComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() element;
  @Input() index;
  @Input() mainTitle;
  // this information about allowed profile type comes from a local FE JSON and artificial FE steps not connectd to the BE
  @Input() allowedProfileType;
  @Input() allowedRelatedPartyType;
  @Input() alternateCancelText;
  @Input() hideCancelButton;
  @Output() contentReturn = new EventEmitter<any>();
  selectedProfile: any = {};
  noSelectedTypeError = false;
  // this information about allowed profile type comes from the BE
  notAllowedLegalTypes;
  legalRPOptionAllowed;
  naturalRPOptionAllowed;
  relatedPartyRoles;
  hideRelatedPartyRoles;

  constructor(
    public elementService: ElementsService,
    public translate: TranslateService,
  ) {}
  ngOnInit(): void {
    if (
      this.element &&
      this.element["params"] &&
      this.element["params"].fieldTypes &&
      !this.element["params"].fieldTypesDV
    ) {
      let lastTitle;
      lastTitle = {};
      this.element["params"].fieldTypes.forEach((fieldType) => {
        if (fieldType.type === "title") {
          lastTitle = fieldType;
          lastTitle.showTitle = false;
        } else if (fieldType.value) {
          lastTitle.showTitle = true;
        }
      });
    }
    this.notAllowedLegalTypes = this.element.params.notAllowedLegalTypes;
    this.legalRPOptionAllowed =
      !this.notAllowedLegalTypes?.notAllowedRelatedRequest?.includes(
        "LegalPerson",
      );
    this.naturalRPOptionAllowed =
      !this.notAllowedLegalTypes?.notAllowedRelatedRequest?.includes(
        "NaturalPerson",
      );
    // The index of the related party
    this.index += 1;
  }

  ngAfterViewInit(): void {
    if (this.allowedProfileType) {
      if (this.allowedProfileType === "LegalPerson") {
        this.naturalRPOptionAllowed = false;
      }
      if (this.allowedProfileType === "NaturalPerson") {
        this.legalRPOptionAllowed = false;
      }
    }
    if (this.legalRPOptionAllowed && !this.naturalRPOptionAllowed) {
      // legal person should be automaticall selected
      this.onSelectType(undefined, "legal");
    }
    if (!this.legalRPOptionAllowed && this.naturalRPOptionAllowed) {
      // natural person should be automaticall selected
      this.onSelectType(undefined, "natural");
    }
    this.relatedPartyRoles = this.element.params.relatedPartyRoles;

    if (this.allowedRelatedPartyType) {
      // this means that there is only one RP option available and we should hide the list
      if (this.allowedRelatedPartyType.length === 1) {
        this.hideRelatedPartyRoles = true;
      }
      this.relatedPartyRoles = this.allowedRelatedPartyType;
    }
  }

  ngOnChanges() {
    if (this.allowedProfileType) {
      if (this.allowedProfileType === "LegalPerson") {
        this.naturalRPOptionAllowed = false;
      }
      if (this.allowedProfileType === "NaturalPerson") {
        this.legalRPOptionAllowed = false;
      }
      if (this.legalRPOptionAllowed && !this.naturalRPOptionAllowed) {
        // legal person should be automaticall selected
        this.onSelectType(undefined, "legal");
      }
      if (!this.legalRPOptionAllowed && this.naturalRPOptionAllowed) {
        // natural person should be automaticall selected
        this.onSelectType(undefined, "natural");
      }
    }
    if (this.allowedRelatedPartyType) {
      // this means that there is only one RP option available and we should hide the list
      if (this.allowedRelatedPartyType.length === 1) {
        this.hideRelatedPartyRoles = true;
      }
      this.relatedPartyRoles = this.allowedRelatedPartyType;
    }
  }

  onSelectType(event, type) {
    event?.preventDefault();
    this.selectedProfile.type = type;
    this.noSelectedTypeError = false;
  }

  OnChangeSelect(event) {
    if ($("#rp_profileLegalEntityType").val() === "emptySelect") {
      $("#rp_profileLegalEntityType")
        .closest(".element-wrapper")
        .addClass("error");
    } else {
      $("#rp_profileLegalEntityType")
        .closest(".element-wrapper")
        .removeClass("error");
      this.selectedProfile.legalType = $("#prp_rofileLegalEntityType").val();
    }
  }

  OnChangeSelectLegalRole(event) {
    if ($("#rp_LegalRole").val() === "emptySelect") {
      $("#rp_LegalRole").closest(".element-wrapper").addClass("error");
    } else {
      $("#rp_LegalRole").closest(".element-wrapper").removeClass("error");
      this.selectedProfile.legalRole = $("#prp_rofileLegalEntityType").val();
    }
  }

  OnChangeSelectNaturalRole(event) {
    if ($("#rp_naturalRole").val() === "emptySelect") {
      $("#rp_naturalRole").closest(".element-wrapper").addClass("error");
    } else {
      $("#rp_naturalRole").closest(".element-wrapper").removeClass("error");
      this.selectedProfile.naturalRole = $("#prp_rofileLegalEntityType").val();
    }
  }

  processProfileInfo($event) {
    if (!this.selectedProfile.type) {
      this.noSelectedTypeError = true;
      return;
    }
    let error = false;
    switch (this.selectedProfile.type) {
      case "legal":
        if (
          !$("#rp_profileLegalName").val() ||
          ($("#rp_profileLegalName").val() &&
            $("#rp_profileLegalName").val().toString().trim() === "")
        ) {
          error = true;
          $("#rp_profileLegalName")
            .closest(".element-wrapper")
            .addClass("error");
        } else {
          $("#rp_profileLegalName")
            .closest(".element-wrapper")
            .removeClass("error");
          this.selectedProfile.legalName = $("#rp_profileLegalName").val();
        }
        if ($("#rp_profileLegalEntityType").val() === "emptySelect") {
          error = true;
          $("#rp_profileLegalEntityType")
            .closest(".element-wrapper")
            .addClass("error");
        } else {
          $("#rp_profileLegalEntityType")
            .closest(".element-wrapper")
            .removeClass("error");
          this.selectedProfile.legalType = $(
            "#rp_profileLegalEntityType",
          ).val();
        }
        if ($("#rp_LegalRole").val() === "emptySelect") {
          error = true;
          $("#rp_LegalRole").closest(".element-wrapper").addClass("error");
        } else {
          $("#rp_LegalRole").closest(".element-wrapper").removeClass("error");
          this.selectedProfile.legalRole = $("#rp_LegalRole").val();
        }
        break;

      case "natural":
        if (
          !$("#rp_profileNaturalFirstName").val() ||
          ($("#rp_profileNaturalFirstName").val() &&
            $("#rp_profileNaturalFirstName").val().toString().trim() === "")
        ) {
          error = true;
          $("#rp_profileNaturalFirstName")
            .closest(".element-wrapper")
            .addClass("error");
        } else {
          $("#rp_profileNaturalFirstName")
            .closest(".element-wrapper")
            .removeClass("error");
          this.selectedProfile.naturalFirstName = $(
            "#rp_profileNaturalFirstName",
          ).val();
        }
        if (
          !$("#rp_profileNaturalLastName").val() ||
          ($("#rp_profileNaturalLastName").val() &&
            $("#rp_profileNaturalLastName").val().toString().trim() === "")
        ) {
          error = true;
          $("#rp_profileNaturalLastName")
            .closest(".element-wrapper")
            .addClass("error");
        } else {
          $("#rp_profileNaturalLastName")
            .closest(".element-wrapper")
            .removeClass("error");
          this.selectedProfile.naturalLastName = $(
            "#rp_profileNaturalLastName",
          ).val();
        }
        if ($("#rp_naturalRole").val() === "emptySelect") {
          error = true;
          $("#rp_naturalRole").closest(".element-wrapper").addClass("error");
        } else {
          $("#rp_naturalRole").closest(".element-wrapper").removeClass("error");
          this.selectedProfile.naturalRole = $("#rp_naturalRole").val();
        }
        if ($("#rp_profileNaturalMinor").prop("checked")) {
          this.selectedProfile.isMinor = true;
        }
        break;
    }
    if (!error) {
      this.onEmit({ status: "add", rpProfile: this.selectedProfile });
    }
  }

  cancelRP($event) {
    this.onEmit({ status: "cancel" });
  }

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
  onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }
}
