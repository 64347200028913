<header
  class="d-flex flex-column {{ headerClasses['backgroundClass'] }} {{
    headerClasses['sectionClass']
  }}"
>
  <div class="header-top d-flex justify-content-center">
    <img class="finos-logo-img" src="assets/images/meshId_logo.svg" />
  </div>

  <div class="d-flex flex-column flex-fill justify-content-center">
    <div class="unauth-message text-center mx-auto">
      We have not been able to grant you access at this time. <br />Please
      <a href="mailto:support@singletruth.io">contact support</a> to resolve
      this issue.
    </div>
    <br />
    <button (click)="logout()" class="form-button form-button-1">Logout</button>
  </div>
</header>
