<div
  class="d-flex flex-row align-items-start mt-2 profile-status"
  *ngIf="evidenceTypes?.length > 0"
>
  <div class="description">
    {{ "appSpecific.thisProfileHas" | translate }}
    <span
      *ngFor="
        let item of statuses | keyvalue;
        let lastEvidence = last;
        let firstEvidence = first
      "
    >
      <span *ngIf="lastEvidence && !firstEvidence">
        {{ "common.and" | translate }}
      </span>
      <span *ngIf="!lastEvidence && !firstEvidence">, </span>
      <span
        class="{{ statusClass[item.key] ? statusClass[item.key] : '' }} status"
      >
        {{ item.value }}
        {{
          helperService.translateEvidenceStatus(item.key).toLowerCase()
        }}</span
      >
      <span *ngIf="lastEvidence">
        <span *ngIf="items; else item"> {{ "common.items" | translate }}</span>
        <ng-template #item> {{ "common.item" | translate }}</ng-template>
      </span>
    </span>
  </div>
</div>
