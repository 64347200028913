<div
  *ngIf="
    element.evidenceTypes &&
    (!element.investorSide || element.investorSide === false)
  "
  class="document-entry-list"
>
  <app-evidence-view-box
    [evidence]="evidence"
    [element]="element"
    *ngFor="let evidence of element.evidenceTypes"
  ></app-evidence-view-box>
</div>
