// cdd-process-control-screen.module.ts
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '../ui/ui.module';
import { NewCddProcessControlScreenComponent } from './pages/share-request/new-cdd-process-control-screen/new-cdd-process-control-screen.component';
import { ContractShareDialogComponent } from './pages/share-request/new-cdd-process-control-screen/contract-share-dialog/contract-share-dialog.component';
import { DocumentShareDialogComponent } from './pages/share-request/new-cdd-process-control-screen/document-share-dialog/document-share-dialog.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../app.module';
import { HttpClient } from '@angular/common/http';
import { GenerateVerificationLinkShareDialogComponent } from './pages/share-request/new-cdd-process-control-screen/generate-verification-link-share-dialog/generate-verification-link-share-dialog.component';
import { VerifyYourselfOrShareDialogComponent } from './pages/share-request/new-cdd-process-control-screen/verify-yourself-or-share-dialog/verify-yourself-or-share-dialog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddressShareDialogComponent } from './pages/share-request/new-cdd-process-control-screen/address-share-dialog/address-share-dialog.component';

@NgModule({
  declarations: [    NewCddProcessControlScreenComponent, ContractShareDialogComponent, DocumentShareDialogComponent, GenerateVerificationLinkShareDialogComponent, VerifyYourselfOrShareDialogComponent, AddressShareDialogComponent ],
  imports: [CommonModule, UiModule, BrowserAnimationsModule,
    TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient],
    },
  }),],
  exports: [NewCddProcessControlScreenComponent, ContractShareDialogComponent,DocumentShareDialogComponent],
  providers: [],
})
export class CddProcessControlScreenModule {}