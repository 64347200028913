<!-- <app-layout>
  <div class="jumbotron">
    <h1>Angular CLI v6 + Bootstrap & FontAwesome</h1>
    <p>
      In this tutorial you learn how to use Angular CLI v6 with Bootstrap and FontAwesome!
    </p>
  </div>
</app-layout> -->
<router-outlet></router-outlet>
<style>
  .tox-tinymce-aux {
    z-index: 99999999999 !important;
  }
</style>
