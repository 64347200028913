<div
  class="px-4 py-4 dv-review-step-one d-flex flex-column d-none mb-0 position-relative form-container-side"
>
  <div>
    <h4
      *ngIf="!isContract"
      class="verify-heading my-3"
      [innerHTML]="
        'review.digitalVerifications.title'
          | translate: { entity: entityName ? entityName : 'entity' }
      "
    ></h4>
    <p
      class="pr-2"
      *ngIf="!isContract && typeOfInvestor === 'LegalPerson'"
      [innerHTML]="
        'review.digitalVerifications.content6'
          | translate: { entity: entityName ? entityName : 'entity' }
      "
    ></p>
    <p
      class="pr-2"
      *ngIf="typeOfInvestor !== 'LegalPerson' && !isContract"
      [innerHTML]="
        'review.digitalVerifications.content'
          | translate: { entity: entityName ? entityName : 'entity' }
      "
    ></p>

    <p *ngIf="typeOfInvestor !== 'LegalPerson' && !isContract" class="mt-4">
      {{ "review.digitalVerifications.content1" | translate }}
    </p>
    <p
      *ngIf="typeOfInvestor === 'LegalPerson'"
      [innerHTML]="
        'review.digitalVerifications.content2'
          | translate: { entityName: entityName ? entityName : 'entity' }
      "
      class="mt-4"
    ></p>
    <button
      *ngIf="!isContract"
      [attr.clickmethod]="'htpmSubmitEvidenceFields'"
      [attr.data-evidenceKey]="
        isIdentityGoRP || isContract
          ? element.params.digitalEvidenceTypes[0]?.originalEvidenceKey
          : element.params.evidenceKey
      "
      [attr.data-dvType]="
        element.params.digitalEvidenceTypes[0]?.originalEvidenceKey
      "
      [attr.data-verificationType]="
        isIdentityGo
          ? 'IdentityInstantAI'
          : isContract
          ? 'Contract'
          : 'Identity'
      "
      [attr.data-originalKey]="
        element.params.digitalEvidenceTypes[0]?.originalEvidenceKey
      "
      [attr.data-requestKey]="element.params.requestKey"
      [attr.data-confirmPerson]="true"
      (click)="
        true
          ? onEmit(
              elementService.OnButtonClick($event, element.params.evidenceTypes)
            )
          : initiateContract()
      "
      id="dvGenerateLink"
      type="submit"
      class="form-button form-button-1 my-3"
    >
      {{ "common.continue" | translate }}
    </button>
  </div>
  <div class="dv-review-step-loading d-none flex-column">
    <!-- <img src="/assets/images/spinner-green.svg" width="65px" height="65px" class="mb-4 mx-auto"> -->
    <p class="mb-1">{{ "review.digitalVerifications.content4" | translate }}</p>
    <p>
      <strong> {{ "review.digitalVerifications.content5" | translate }}</strong>
    </p>
    <div class="digital-evidence-labels-container">
      <div
        class="digital-evidence-label digital-evidence-label-sharing"
        [attr.evidenceKey]="digitalEvidence.key"
        [ngClass]="{ 'd-none': digitalEvidence.verificationKey }"
        *ngFor="let digitalEvidence of digitalEvidenceTypes"
      >
        <div>
          {{
            this.helperService.translateVerificationType(
              digitalEvidence.verificationType
            )
          }}
        </div>
        <img
          class="evidence-label-check d-none"
          src="assets/images/check.svg"
        />
        <img
          class="evidence-label-spinner d-none"
          src="assets/images/spinner-green.svg"
        />
      </div>
    </div>
  </div>
</div>
