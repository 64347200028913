import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HelperServiceService } from "src/app/helper-service.service";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { ElementsService } from "../elements.service";
import {statusClass,} from "../../../../../id-verification/id-verification-const"

@Component({
  selector: "app-profiles-status-information",
  templateUrl: "./profiles-status-information.component.html",
  styleUrls: ["./profiles-status-information.component.scss"],
})
export class ProfilesStatusInformationComponent implements OnInit {
  public statusClass = statusClass;
  @Input() profileRecord: any[];
  evidenceTypes: any[] = [];
  statuses = {};
  items: boolean;

  constructor(
    public elementService: ElementsService,
    public translate: TranslateService,
    private helperService: HelperServiceService,
    private idVerificationService: IdVerificationService,
  ) {}

  ngOnInit(): void {
    this.generateProfileStatusesInformationObject();

    this.items = this.evidenceTypes?.length > 1;
  }

  generateProfileStatusesInformationObject(): void {

    this.evidenceTypes = this.idVerificationService.generateAllEvidenceForProfile(this.profileRecord)
    this.statuses = this.idVerificationService.generateProfileStatusInformationObject(this.evidenceTypes)
  }
}
