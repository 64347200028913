<!-- no error state -->
<header
  *ngIf="!errorState"
  class="d-flex flex-column p-2 p-sm-3 {{ headerClasses['backgroundClass'] }} {{
    headerClasses['sectionClass']
  }}"
  [ngStyle]="{ 'background-color': projectColor }"
>
  <div class="header-top d-flex justify-content-between mx-auto py-3">
    <div class="navbar mx-auto p-0">
      <a [routerLink]="['/']" class="navbar-brand mr-0"></a>
    </div>
  </div>
</header>

<app-lightbox
  *ngIf="displayModal && !errorState"
  [formType]="formType"
  [formTitle]="formTitle"
  [formClass]="formClass"
  [formSubTitle]="formSubTitle"
  [lightboxClass]="lightboxClass"
  [btnOkText]="btnOkText"
  [btnCancelText]="btnCancelText"
  [displayModal]="displayModal"
  [displayLoader]="displayLoader"
  [contentDOM]="lightboxContent"
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
></app-lightbox>
