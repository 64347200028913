<div class="custom-dialog risk-indicator-override">
    <a class="close-button" (click)="close()"><img src="assets/images/close_icon_green.svg"></a>
    <div class="content">
        <div *ngIf="!data.isAnswered" class="d-flex align-items-center justify-content-center">
            <h2 *ngIf="data.riskIndicator" class="risk-idicator">{{data.riskIndicator}} <span *ngIf="data.riskPoints"> ({{data.riskPoints}} points)</span> </h2>
            <app-toggle-button
            [toggleChecked]="indicatorValue"
            (toggle)="indicatorValue = !indicatorValue"
            toggleId="riskIndicatorValue">
          </app-toggle-button>
        </div> 
        <div *ngIf="data.isAnswered" class="d-flex align-items-center justify-content-center">
            <h2 *ngIf="data.riskIndicator" class="risk-idicator">You are about to change the {{data.riskIndicator}} indicator <span *ngIf="data.riskPoints">(worth {{data.riskPoints}} points)</span> to 
                <span [ngClass]="!data.value? 'red' : 'green'">{{!data.value ? "risk" : "no risk"}}</span> </h2>
        </div> 
        <textarea
        maxlength="400"
        class="text-input-div"
        name="comment"
        rows="8"
        (keyup)="countCharacters($event)"
        [(ngModel)] ="comment">
        </textarea>
        <div id="the_count" [ngClass]="{'red' : characterCount > 350}">
            <span id="current">{{characterCount}}</span>
            <span id="maximum">/ 400</span>
        </div>
        <div class="buttons">
            <div class="form-button form-button-6" (click)="close()"> Go back</div>
            <div class="form-button form-button-1" (click)="updateRiskIndicator()"> Update indicator</div>
        </div>
    </div>
</div>
