import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-toggle-button",
  templateUrl: "./toggle-button.component.html",
  styleUrls: ["./toggle-button.component.scss"],
})
export class ToggleButtonComponent implements OnInit {
  @Input() toggleChecked: boolean;
  @Input() toggleId: string | number;
  @Input() toggleDisabled = false;
  @Input() toggleTooltip = "";
  @Output() toggle: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
