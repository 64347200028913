import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HelperServiceService } from "../helper-service.service";
import { TrustsService } from "./trusts.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-trusts",
  templateUrl: "./trusts.component.html",
  styleUrls: ["./trusts.component.scss"],
})
export class TrustsComponent implements OnInit, OnDestroy {
  logo: string;
  projectColor: string;
  projectTextColorClass: string;
  h1: string;
  message: string;
  cryptoKey: string;
  headerInfo: {};
  headerClasses: {};
  iframeSrc;
  consent: boolean; // can be: false - no consent, true - consent
  project: string;

  // subscriptions
  requestDataStatus: Subscription;
  noIntegrityRiskStatus: Subscription;
  submitAnswersStatus: Subscription;
  denyRequestStatus: Subscription;

  // lightbox parameters:
  displayModal: boolean;
  displayLoader: boolean;
  formType: string;
  formTitle: string;
  formSubTitle: string;
  lightboxContent: any[];
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;
  btnOkEnabled: boolean;
  btnCancelEnabled: boolean;
  btnContentElements: string;
  refreshType: string;

  // request data
  private requestData: {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private trustsService: TrustsService,
    private helper: HelperServiceService,
  ) {}

  ngOnInit() {
    // init modal lightbox
    this.displayModal = false;
    this.formType = "Request for information";
    this.formTitle = ""; // 'Financial Holdings B.V';
    this.displayLoader = false;
    // init header
    this.logo = "assets/images/meshId_logo.svg";
    this.projectColor = "#00889c";
    this.h1 =
      "The new standard in integrity check & identity verification for Trust Companies like yours.";
    this.message =
      "Marketing copy goes here, Marketing copy goes here, Marketing copy goes here, Marketing copy goes here, Marketing copy goes here, Marketing copy goes here, Marketing copy goes here, Marketing copy goes here, ";
    this.consent = false;
    this.project = "";
    this.headerInfo = {
      headerType: "full", // 'inline',
      headerTitle: "Integrity Check",
      activeNavLink: "integrityCheck",
    };
    this.headerClasses = {
      backgroundClass: "tealish-gradient-bg",
      sectionClass: "ic_height_full_page", // 'ic_height_auto',
    };

    this.cryptoKey = this.activatedRoute.snapshot.params.cryptoKey;
    this.OnInitModal();
    // this.OnIsThereIntegrityRisk();
    this.requestDataStatus = this.trustsService
      .getRequestData(this.cryptoKey)
      .subscribe((response) => {
        console.log(response);
        if (response === null) {
          return;
        }
        this.requestData = response;
        this.OnIsThereIntegrityRisk();
      });

    /**
     * subscribe to no integrity risk status
     */
    this.noIntegrityRiskStatus =
      this.trustsService.noIntegrityRiskStatus.subscribe((response) => {
        console.log(response);
        this.displayLoader = false;
        if (response === null) {
          // error occured while marking the request as no integrity risk
          this.displayMessageDialog(
            "Error occured while marking the request as no integrity risk",
          );
          return;
        }
        // marked as integrity risk
        this.displayMessageDialog(
          "You have successfully submitted the request with No risk",
        );
        this.displayModal = true;
      });

    /**
     * subscribe to submit answer status
     */
    this.submitAnswersStatus = this.trustsService.submitAnswersStatus.subscribe(
      (response) => {
        console.log(response);
        this.displayLoader = false;
        if (response === null) {
          // error occured while sending the answers
          this.displayMessageDialog("Error occured while sending the answers");
          return;
        }
        // answers have been sent
        this.displayMessageDialog(
          "Your answers were successfully submitted. Thank you!",
        );
        this.displayModal = true;
      },
    );

    /**
     * subscribe to deny request status
     */
    this.denyRequestStatus = this.trustsService.denyRequestStatus.subscribe(
      (response) => {
        console.log(response);
        this.displayLoader = false;
        if (response === null) {
          // error occured while denying the request
          this.displayMessageDialog(
            "Error occured while denying the requested information",
          );
          return;
        }
        // deny has been recorded
        // do nothing
        this.displayMessageDialog("You have successfully denied the request");
        this.displayModal = true;
      },
    );
  }

  /**
   * extract the company name from
   * requested Data
   */
  getCompanyName() {
    return this.requestData["companyName"];
  }

  /**
   * show dialog while initializing
   */
  OnInitModal() {
    this.formTitle = "Please stand by while we fetch the details";

    // build the content for the Request Information light box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      // 'group_name' : 'Active Integrity Checks',
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_information_form",
      result_details: [
        {
          group_result_title: "lighbox-request-information", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          result_title: "Provide information regarding integrity risk status",
          elements: [
            {
              type: "paragraph",
              params: {
                content:
                  "A service provider needs your information if there was an integrity risk associated with one of your previous clients",
              },
            },
            {
              type: "paragraph",
              params: {
                content:
                  "Please stand by while we fetch data about this request",
              },
            },
            {
              type: "alertLoader",
            },
            // FORM will be appended here
            // END OF FORM
          ],
        },
      ],
    };

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.displayModal = true;
  }
  /**
   * display modal dialog for checking if there has been
   * integrity risk associated with the company in question
   * no design for this
   */
  OnIsThereIntegrityRisk() {
    this.formTitle = this.getCompanyName();

    // build the content for the Request Information light box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      // 'group_name' : 'Active Integrity Checks',
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_information_form",
      submit_btn: {
        type: "submit",
        params: {
          form_id: "request_information_form",
          content: "No",
          action: "OnNoIntegrityRisk",
          display: true,
        },
      },
      cancel_btn: {
        type: "submit",
        params: {
          form_id: "request_information_form",
          content: "Yes, there was an integrity risk",
          action: "OnProvideRequestedInfo",
          display: true,
        },
      },
      result_details: [
        {
          group_result_title: "lighbox-request-information", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          result_title: "Provide information regarding integrity risk status",
          elements: [
            {
              type: "paragraph",
              params: {
                content:
                  "A service provider needs your information if there was an integrity risk associated with one of your previous clients",
              },
            },
            // FORM will be appended here
            // END OF FORM
          ],
        },
      ],
    };

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.displayModal = true;
  }

  /**
   * send a no integrity risk information to BE
   */
  OnNoIntegrityRisk() {
    this.displayLoader = true;
    this.trustsService.setNoIntegrityRisk(this.cryptoKey);
  }

  /**
   * display modal dialog for answerint information about company integrity hit
   * https://app.zeplin.io/project/5c03a96af47bd12c8aa6763f/screen/5cc02d9986dc2d2697f2246d
   */
  OnProvideRequestedInfo(event) {
    const targetKey = "hardcodedkey";
    this.formTitle = this.getCompanyName();

    // build the content for the Request Information light box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      // 'group_name' : 'Active Integrity Checks',
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_information_form",
      submit_btn: {
        type: "submit",
        params: {
          form_id: "request_information_form",
          content: "Submit Info",
          action: "OnSubmitRequestedInfo",
          display: true,
        },
      },
      cancel_btn: {
        type: "submit",
        params: {
          form_id: "request_information_form",
          content: "Deny request",
          action: "OnDenyRequest",
          display: true,
        },
      },
      result_details: [
        {
          group_result_title: "lighbox-request-information", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          result_title: "Provide information regarding integrity risk",
          elements: [
            {
              type: "paragraph",
              params: {
                content:
                  "A service provider needs your information about one of your previous client entities",
              },
            },
            {
              type: "paragraph",
              params: {
                content:
                  "Please answer the following questions. The answers will be sent to the service provider.",
              },
            },
            // FORM will be appended here
            // END OF FORM
          ],
        },
      ],
    };

    const result = this.requestData["record"];
    // parse questions into list items with form elements
    const parsedListItems: any = group.result_details[0].elements;
    result.questions.forEach((question) => {
      switch (question.questionType) {
        case "text":
          // push the form element
          parsedListItems.push({
            type: "textarea",
            params: {
              label: question.questionText,
              content: "",
              name: question.key,
            },
          });
          break;
        default:
          // push dummy element to display message that it needs to be handled
          parsedListItems.push({
            type: "dummy",
          });
          break;
      }
    });

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.displayModal = true;
  }

  OnSubmitRequestedInfo(params) {
    const answers = [];
    console.log("recieved params", params);
    params.forEach((answer) => {
      answers.push({
        questionKey: answer.name,
        answerText: answer.value,
      });
    });
    this.displayModal = true;
    this.displayLoader = true;
    this.trustsService.submitAnswersToRequestedInfo(
      { answers: answers },
      this.cryptoKey,
    );
  }

  OnDenyRequest(params) {
    this.displayModal = true;
    this.displayLoader = true;
    this.trustsService.rejectRequestedInfo(this.cryptoKey);
  }

  onLightboxConfirm(event) {
    if (typeof event.method === "string" && event.method !== "") {
      // allow only strings as acceptable method name
      let params = "";
      if (Array.isArray(event.params)) {
        params = event.params;
      }
      console.log(params);
      try {
        this[event.method](params); // call if it exists
      } catch (error) {
        console.log(
          event.method + " needs to be defined before it is called",
          error,
        );
      }
    } else {
      console.log("**method name not string or empty string");
    }
    // this.router.navigateByUrl('integrity-check/dashboard');
    if (typeof event.method === "undefined") {
      // allow only strings as acceptable method name
      // execute default OnConfirm action for this component
      // do nothing
    }
  }

  onLightboxClose() {
    this.displayModal = false;
  }

  /**
   * display message dialog box
   */
  displayMessageDialog(message) {
    this.formTitle = this.getCompanyName();

    // build the content for the Request Information light box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_information_form",
      submit_btn: {
        type: "submit",
        params: {
          form_id: "request_information_form",
          content: "Ok",
          action: "onLightboxClose",
          display: true,
        },
      },
      result_details: [
        {
          group_result_title: "lighbox-request-information", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          result_title: "Provide information regarding integrity risk status",
          elements: [
            {
              type: "paragraph",
              params: {
                content: message,
              },
            },
            // FORM will be appended here
            // END OF FORM
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.displayModal = true;
  }

  ngOnDestroy() {
    this.requestDataStatus.unsubscribe();
    this.noIntegrityRiskStatus.unsubscribe();
    this.submitAnswersStatus.unsubscribe();
    this.denyRequestStatus.unsubscribe();
  }
}
