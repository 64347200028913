import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { HelperServiceService } from "../helper-service.service";
import { UserService } from "../user.service";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  private baseUrl: string = environment.APIEndpoint;
  private swaggerUrl =
    "https://run.mocky.io/v3/1c08dad1-7c56-4216-96e0-505a932aa226"; // 5 new and 5 old
  private notificationEndpoints = {
    notifications: "api/notifications", // GET to query, POST to clear all
    clearNotification: "api/notifications/{key}", // POST to clear specific notification
  };
  private notifications = new Subject<any>();
  public notificationsList = this.notifications.asObservable();

  constructor(
    private userService: UserService,
    private helper: HelperServiceService,
    private router: Router,
  ) {}

  /**
   * build endpoint
   * @param endpoint destination url
   * @param notificationKey key of the notification we are targeting
   */
  buildEndPoint(endpoint, notificationKey = "", demoUrl = true) {
    return (
      demoUrl
        ? this.baseUrl + this.notificationEndpoints[endpoint]
        : this.swaggerUrl
    ).replace("{key}", notificationKey);
  }

  /**
   * get notifications (new and old) for the user
   */
  getNotifications() {
    this.userService
      .getEndPoint(
        this.buildEndPoint("notifications"),
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (notifications) => {
          if (notifications === undefined) {
            // fail silently
            this.notifications.next(null);
            return;
          }
          this.notifications.next(this.helper.sanitize(notifications));
        },
        (error) => {
          console.error(
            "Following error occured while retrieving notifications:",
            error,
          );
          this.notifications.next(null);
        },
      );
  }

  getCCType() {
    let cc = "verification";
    if (
      this.router.url.includes("funds") ||
      this.router.url.includes("investor")
    ) {
      cc = "funds";
    } else if (this.router.url.includes("id-verification")) {
      cc = "verification";
    }
    return cc;
  }

  /**
   * mark all notifications as seen
   */
  markNotificationsAsSeen() {
    this.userService
      .postEndPoint(
        this.buildEndPoint("notifications"),
        "",
        { responseType: "json" },
        { observe: "body" },
        this.getCCType(),
      )
      .subscribe(
        (notifications) => {
          if (notifications === undefined) {
            // fail silently
            this.notifications.next(null);
            return;
          }
          // do nothing as the notifications will have already been marked as seen on FE side
          // this.notifications.next(this.helper.sanitize(notifications));
        },
        (error) => {
          console.error(
            "Following error occured while retrieving notifications:",
            error,
          );
          this.notifications.next(null);
        },
      );
  }
}
