<div
  class="id-verification-container d-flex flex-column flex-fill justify-content-center"
  [ngClass]="{ 'min-height': headerInfo['hideMainNav'] === true }"
>
  <!-- <h1 class="text-center m-0">{{ headerInfo['headerTitle']}}</h1> -->
  <div
    class="embedded-form-wrapper"
    *ngIf="activeProjectList !== undefined && activeProjectList.length === 0"
  >
    <div
      class="iv-input-container d-flex justify-content-center"
      [ngClass]="{
        'mb-2':
          activeProjectList !== undefined && activeProjectList.length === 0
      }"
    >
      <div
        *ngIf="headerInfo['projectType'] === 'Project'"
        class="subtitle d-flex position-relative font-weight-bold text-white"
      >
        Start by creating a project. A project is a group of individuals you
        would like to verify
      </div>
      <div
        *ngIf="headerInfo['projectType'] === 'Fund'"
        class="subtitle d-flex position-relative font-weight-bold text-white"
      >
        Create a new project
      </div>
      <div
        *ngIf="headerInfo['projectType'] === 'Investor'"
        class="subtitle d-flex position-relative font-weight-bold text-white text-center text-lg-right"
      >
        {{ "dashboard.empty" | translate }}
      </div>
    </div>
    <div
      *ngIf="headerInfo['projectType'] !== 'Investor'"
      class="iv-embedded-form-container d-flex justify-content-center mx-auto"
    >
      <div class="w-100 d-block position-relative">
        <app-content-elements
          [contentDOM]="createProjectForm"
          (contentReturn)="parseContentReturn($event)"
        ></app-content-elements>
      </div>
    </div>
  </div>

  <div
    class="active-projects-wrapper"
    [ngClass]="{ 'd-none flex-row': activeProject }"
    *ngIf="activeProjectList !== undefined && activeProjectList.length > 0"
  >
    <div
      class="d-flex justify-content-center text-white my-3 active-project"
      *ngIf="activeProject !== undefined"
    >
      <a href="#" class="selector" (click)="OnSelectFromProjectList($event)"
        ><img
          src="/assets/images/ico-project-grid.svg"
          width="16px"
          height="16px"
          alt="selector icon"
      /></a>
      <span class="mr-2"
        >{{
          headerInfo["projectType"] === "Investor"
            ? ("appSpecific.Profile" | translate) + ":"
            : "Project: "
        }}
      </span>
      <div class="title mr-2">{{ activeProject["record"].name }}</div>
    </div>
    <div
      class="d-flex justify-content-center text-white my-3 select-project"
      *ngIf="!activeProject && headerInfo['projectType'] === 'Investor'"
    >
      <span>
        {{ "appSpecific.SelectInvestor" | translate }}
      </span>
    </div>
    <div *ngIf="!activeProject" class="initial-project-selector">
      <app-id-verification-project-list
        (DisplayLightbox)="OnDisplayLightbox()"
      ></app-id-verification-project-list>
    </div>
    <div
      class="ic-ctas new-person-wrapper mx-auto mt-3 pl-3"
      *ngIf="activeProject && headerInfo['projectType'] === 'Project'"
    >
      <a
        class="position-relative new-person"
        href="#"
        (click)="resetRepresentativesTags(); OnAddNewPerson($event)"
        routerLinkActive="router-link-active"
        >Create a new person</a
      >
    </div>
    <div
      class="ic-ctas new-person-funds new-person-wrapper mx-auto mt-1 pl-3"
      *ngIf="activeProject && headerInfo['projectType'] === 'Fund'"
    >
      <!-- <a class="position-relative new-person" href="#" (click)="onNewInvestor($event)" routerLinkActive="router-link-active">New profile</a> -->
      <div
        style="cursor: pointer"
        class="position-relative actionsMenuBtn h-100 w-100"
      >
        <a
          (click)="onAddNewInvestor($event)"
          style="width: 100%; height: 100%; display: flex"
          class="mx-0 mt-0 mb-2 filter-button"
        >
          <div
            class="d-flex justify-content-center align-items-center h-100 w-100"
            style="pointer-events: none"
          >
            <img
              width="20px"
              height="20px"
              style="margin-right: 8px"
              src="assets/images/ico.svg"
            />
            <span class="unselectable">New profile</span>
          </div>
        </a>
        <div class="actionsMenuWrapper unselectable actionsMenuProfile">
          <ul
            id="actionsMenuProfileHeader"
            style="z-index: 20; top: initial"
            class="actions-ul d-none"
          >
            <li (click)="onNewInvestorManual($event)">
              <div>Create manually</div>
            </li>
            <li (click)="onNewInvestorAutomatic($event)">
              <div>Generate automatically</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<app-id-verification-project-lightbox
  [projectType]="headerInfo['projectType']"
  (DisplayLightbox)="OnDisplayLightbox()"
></app-id-verification-project-lightbox>
