import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { ElementsService } from "../elements.service";

@Component({
  selector: "app-funds-parse-related-party",
  templateUrl: "./funds-parse-related-party.component.html",
  styleUrls: ["./funds-parse-related-party.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FundsParseRelatedPartyComponent implements OnInit {
  @Input() element;
  @Output() contentReturn = new EventEmitter<any>();
  @Output() displayLoader = new EventEmitter<any>();
  constructor(
    private elementService: ElementsService,
    private idVerificationService: IdVerificationService,
  ) {}

  ngOnInit(): void {}

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
  onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }

  documentUploadScreenRelatedParty(inputargs) {
    this.idVerificationService.skipToStep(6, inputargs);
  }

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
  onDisplayLoader(emitPayload: boolean) {
    if (emitPayload) {
      this.displayLoader.emit(emitPayload);
    }
  }

  onDisplayLoaderFromElements(params) {
    this.displayLoader.emit(params);
  }
}
