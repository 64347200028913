import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
} from "@angular/core";
import { Subscription } from "rxjs";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";

declare var $: any;

@Component({
  selector: "app-id-verification-project-lightbox",
  templateUrl: "./id-verification-project-lightbox.component.html",
  styleUrls: ["./id-verification-project-lightbox.component.scss"],
})
export class IdVerificationProjectLightboxComponent
  implements OnInit, OnDestroy
{
  displaySelectorStatus: any;
  activeProjectSettingsStatus: any;
  uploadImageStatus: any;
  displaySelector: boolean;
  displaySelectorProjectSettings: boolean;
  activeProjectSettingsContent: any[]; // content for project settings content builder
  displaySelectorProjectCustomization: boolean;
  activeProjectCustomizationContent: any[]; // content for project settings customization content builder and preview
  @Input() projectType: string;
  @Output() DisplayLightbox = new EventEmitter<any>();
  editedProject: Subscription;
  constructor(private idVerificationService: IdVerificationService) {}

  ngOnInit() {
    this.activeProjectSettingsContent = [];
    this.displaySelector = false;
    this.displaySelectorProjectSettings = false;
    this.displaySelectorStatus =
      this.idVerificationService.detectDisplayProjectSelectorStatus.subscribe(
        (status) => {
          if (status) {
            // this.idVerificationService.retrieveActiveProjects();
            this.displaySelector = status;
            const _this = this;
            $(".project-lightbox .active-project-list").niceScroll().remove();
            setTimeout(function () {
              $(".project-lightbox .projects-content-wrapper").removeClass(
                "collapse",
              );
              $("body").css("overflow", "hidden");
              $(".project-lightbox").fadeIn(150, function () {});
              // if (_this.idVerificationService.getActiveProject()) {
              //   setTimeout(function(){
              //     _this.scrollToElementId(_this.idVerificationService.getActiveProject());
              //   }, 1);
              // }
            }, 100);
          } else {
            $(".project-lightbox").fadeOut(50, function () {
              this.displaySelector = status;
            });
          }
        },
      );
    this.activeProjectSettingsStatus =
      this.idVerificationService.detectActiveProjectSettingsStatus.subscribe(
        (status) => {
          // we will display separate boxes display a submenu instead
          // this.displaySelectorProjectSettings = true;
          // this.DisplayProjectSettings(status);
        },
      );

    this.editedProject =
      this.idVerificationService.detectActiveProjectChange.subscribe(
        (status) => {
          // this.setProjectAsFirst();
        },
      );
  }

  setProjectAsFirst() {
    const projectIndex = this.idVerificationService
      .getActiveProjects()
      .findIndex(
        (p) => p.key === this.idVerificationService.getActiveProject(),
      );
    if (
      projectIndex !== -1 &&
      this.idVerificationService.getactiveProjectSortBy() === "last_modified"
    ) {
      const currentProejcts = [
        ...this.idVerificationService.getActiveProjects(),
      ];
      const currentProject = currentProejcts.splice(projectIndex, 1)[0];
      currentProejcts.push(currentProject);
      this.idVerificationService.setActiveProjectsSimplified(currentProejcts);
    }
  }

  /**
   * it will scroll to a project with selected id
   * @param id: string, the id of the project we are scrolling to
   */

  OnDisplayLightbox() {
    this.DisplayLightbox.emit();
  }

  OnCloseProjectSelect(event) {
    event.preventDefault();
    event.stopPropagation();
    $("body").css("overflow", "auto");
    this.displaySelector = false;
  }

  DisplayProjectSettings(projectDetails) {
    console.log("PROJECT DETAILS IN MANAGE PROJECT SETTINGS:", projectDetails);

    // build the content for the Project Settings box
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "perform_integrity_check",
      result_details: [
        {
          group_result_title: "perform_flag_a_company", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          // 'result_title' : 'What would you like to know?',
          elements: [
            {
              type: "h3",
              class: "mt-0",
              params: {
                classes: "p-0",
                content: "Manage your project",
              },
            },
            {
              type: "imageDragDropUpload",
              params: {
                label: "Please Select your logo",
                id: "projectEditLogo",
                uploadmethod: "handleImageUpload",
                display: true,
              },
            },
            {
              type: "inputColorSelector",
              params: {
                label: "Project color",
                id: "projectEditColor",
                // 'content': projectDetails.record.color,
                display: true,
                color:
                  projectDetails["record"].projectBranding !== undefined &&
                  projectDetails["record"].projectBranding.projectColor
                    ? projectDetails.record.projectBranding.projectColor
                    : "#00889c",
              },
            },
            {
              type: "hr",
              params: {
                class: "mt-2",
                display: true,
              },
            },
            {
              type: "inputTextWithButton",
              params: {
                label: "Project name",
                id: "projectEditName",
                content: projectDetails.record.name,
                button: {
                  content: "Save",
                  action: "OnProjectUpdateData",
                  display: true,
                },
              },
            },
            {
              type: "hr",
              params: {
                class: "mt-2",
                display: true,
              },
            },
            {
              type: "contributors",
              params: {
                class: "mt-2",
                display: true,
                email: "service.provider@trust&co.com",
                contributors: [
                  {
                    id: "CON:1",
                    email: "service.provider@trust&co.com",
                    role: "Member", // Member | Owner
                    makeOwner: true, // only if current role = Member
                  },
                  {
                    id: "CON:2",
                    email: "test@trust&co.com",
                    role: "Owner", // Member | Owner
                    makeOwner: false, // only if current role = Member
                  },
                ],
              },
            },
            {
              type: "button",
              params: {
                content: "Invite contributor",
                display: true,
                clickmethod: "OnInviteContributor",
              },
            },
            {
              type: "hr",
              params: {
                class: "py-2",
                display: true,
              },
            },
            {
              type: "subtitle",
              params: {
                content: "Delete project",
                display: true,
              },
            },
            {
              type: "paragraph",
              params: {
                content: "Did you stopped working on this project?",
                display: true,
              },
            },
            {
              type: "button",
              params: {
                content: "Delete project",
                display: true,
                clickmethod: "OnDeleteProject",
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.activeProjectSettingsContent = [];
    this.activeProjectSettingsContent.push(group);

    setTimeout(function () {
      console.log("fading in ....", $(".project-settings-lightbox").length);
      $(".project-settings-lightbox").fadeIn();
    }, 100);

    // this.displayModal = true;
    // this.cd.detectChanges();
  }

  // OnCloseProjectSettings(event) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   this.displaySelectorProjectSettings = false;
  // }

  // DisplayProjectCustomizationSettings(projectDetails) {

  //   console.log('PROJECT DETAILS IN MANAGE PROJECT SETTINGS:', projectDetails);

  //   // build the content for the Project Settings box
  //   const group = {
  //     'section_class' : 'results d-flex flex-column justify-items-center p-0',
  //     'result_container_class': '',
  //     'is_collapsable': false,
  //     'form_id': 'perform_integrity_check',
  //     'result_details' : [
  //       {
  //       'group_result_title' : 'perform_flag_a_company',  // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
  //       // 'result_title' : 'What would you like to know?',
  //       'elements' : [
  //         {
  //           'type': 'h3',
  //           'class': 'mt-0',
  //           'params': {
  //             'classes': 'p-0',
  //             'content': 'Project customization'
  //           }
  //         },
  //         {
  //           'type': 'imageDragDropUpload',
  //           'params': {
  //             'label': 'Please Select your logo',
  //             'id': 'projectEditLogo',
  //             'uploadmethod': 'handleImageUpload',
  //             'display': true,
  //           }
  //         },
  //         {
  //           'type': 'inputColorSelector',
  //           'params': {
  //             'label': 'Project color',
  //             'id': 'projectEditColor',
  //             // 'content': projectDetails.record.color,
  //             'display': true,
  //             'color': (projectDetails.record.projectColor !== undefined) ? projectDetails.record.projectColor : '#00889c',
  //           }
  //         },
  //         {
  //           'type': 'button_group',
  //           'params': [
  //             {
  //               'content': 'Close',
  //               'action': 'OnCloseProjectCustomization',
  //               'display': true
  //             },
  //             {
  //               'content': 'Save',
  //               'action': 'OnSaveProjectCustomization',
  //               'display': true
  //             }
  //           ]
  //         },
  //       ]
  //     }]
  //   };
  //   // set the content to the lightbox
  //   this.activeProjectSettingsContent = [];
  //   this.activeProjectSettingsContent.push(group);

  //   setTimeout(function() {
  //     console.log('fading in ....', $('.project-settings-lightbox').length);
  //     $('.project-settings-lightbox').fadeIn();
  //   }, 100);

  //   // this.displayModal = true;
  //   // this.cd.detectChanges();
  // }

  /**
   * call this method on click of a Content Builder button (not a submit)
   * @param returnedAction event
   */
  parseContentReturn(returnedAction) {
    if (
      typeof returnedAction.method === "string" &&
      returnedAction.method !== ""
    ) {
      // allow only strings as acceptable method name
      let params = "";
      // if (Array.isArray(returnedAction.params)) {
      params = returnedAction.params;
      // }
      try {
        this[returnedAction.method](params); // call it
      } catch (error) {
        console.log(returnedAction.method, "is not declared as method!", error);
      }
    } else {
      console.log("**method name not string or empty string");
    }
  }

  /**
   * update project name
   * @param event event
   */
  // OnProjectUpdateData(event) {
  //   this.idVerificationService.updateProjectName($('#projectEditName').val(), $('#projectEditColor').val());
  //   $('.project-settings-lightbox').fadeOut(250, function() {
  //     this.activeProjectSettingsContent = [];
  //   });
  // }

  /**
   * execute make owner when contributor is Member
   * @param params event
   */
  // OnMakeOwner(event) {
  //   console.log('TODO: execute MAKE OWNER for:', $(event.target).attr('id'));
  // }

  /**
   * handle image upload
   * @param file: file
   */
  // handleImageUpload(file) {
  //   $('.upload-status').text('Uploading ...');
  //   console.log(file);
  //   this.idVerificationService.uploadProjectLogo(this.idVerificationService.getActiveProjectSettings(), file);
  // }

  ngOnDestroy() {
    this.displaySelectorStatus.unsubscribe();
    // this.editedProject.unsubscribe();
  }
}
