<div class="custom-dialog rrapproval">
    <a class="close-button" (click)="close()"><img src="assets/images/close_icon_green.svg"></a>

    <div *ngIf="firstWindow" class="content">
        <div class="info-text">
            According to the risk score of {{riskRatingScore}} points, the calculated risk rating is 
            <span 
            class="risk-rating-status"
            [style.color]="riskRatingColor">{{riskRating.toLowerCase()}}</span>.</div>
        <div class="buttons">
            <div class="form-button form-button-6" (click)="close()"> <img class="right-arrow" src="/assets/images/arrow-left.svg" /> Go back and edit </div>
            <div class="form-button form-button-6" (click)="changeWindow()"> Edit risk rating </div>
            <div class="form-button form-button-1" (click)="acceptAndSendForApproval()"> Complete assessment </div>
        </div>
    </div>
    <div *ngIf="!firstWindow" class="content">
        <div class="d-flex align-items-center">
            <div class="select-label">Risk rating: </div> 
            <div class="select">
                <select 
                [(ngModel)]="changedRiskRating"
                class="select-input input-transparent">
                <option
                *ngFor="let riskRatingOption of data.record.riskRanges"
                [value]="riskRatingOption.label">
                {{riskRatingOption.label}}
                </option>
                </select>
            </div>
        </div> 
        <textarea
        maxlength="400"
        class="text-input-div"
        name="comment"
        rows="8"
        (keyup)="countCharacters($event)"
        [(ngModel)] ="changedComment">
        </textarea>
        <div id="the_count" [ngClass]="{'red' : characterCount > 350}">
            <span id="current">{{characterCount}}</span>
            <span id="maximum">/ 400</span>
        </div>
        <div class="buttons">
            <div class="form-button form-button-6" (click)="changeWindow()">Go back </div>
            <div class="form-button form-button-1" (click)="updateAndSendForApproval()">Complete assessment</div>
        </div>
    </div>
</div>