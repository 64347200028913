<app-header
  [headerInfo]="headerInfo"
  [headerClasses]="headerClasses"
  [kvkResults]="kvkResults"
  (selectedCompany)="OnSelectedCompanyChange($event)"
  (searchTerm)="onSearchTermChanged($event)"
></app-header>
<app-lightbox
  *ngIf="displayModal"
  [formType]="formType"
  [formTitle]="formTitle"
  [formSubTitle]="formSubTitle"
  [btnOkText]="btnOkText"
  [formValidator]="formValidator"
  [btnCancelText]="btnCancelText"
  [displayModal]="displayModal"
  [displayLoader]="displayLoader"
  [contentDOM]="lightboxContent"
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
></app-lightbox>
<div
  id="activeIntegrityChecks"
  [ngClass]="{
    'section-wrapper':
      activeUser === 1 && (groups.activeIntegrityChecks[0] | json) !== '{}'
  }"
  *ngIf="activeUser === 1 && (groups.activeIntegrityChecks[0] | json) !== '{}'"
>
  <app-content-builder
    [contentDOM]="groups.activeIntegrityChecks"
    (contentReturn)="parseContentReturn($event)"
  ></app-content-builder>
</div>
<div
  id="IntegrityChecksRequests"
  [ngClass]="{
    'section-wrapper':
      activeUser === 1 && (groups.integrityRequests[0] | json) !== '{}'
  }"
  *ngIf="activeUser === 1 && (groups.integrityRequests[0] | json) !== '{}'"
>
  <app-content-builder
    [contentDOM]="groups.integrityRequests"
    (contentReturn)="parseContentReturn($event)"
  ></app-content-builder>
</div>
<div
  id="closedIntegrityChecks"
  [ngClass]="{
    'section-wrapper':
      activeUser === 1 && (groups.closedIntegrityChecks[0] | json) !== '{}'
  }"
  *ngIf="activeUser === 1 && (groups.closedIntegrityChecks[0] | json) !== '{}'"
>
  <app-content-builder
    [contentDOM]="groups.closedIntegrityChecks"
    (contentReturn)="parseContentReturn($event)"
  ></app-content-builder>
</div>
<div
  id="flaggedCompanies"
  [ngClass]="{
    'section-wrapper':
      activeUser === 1 && (groups.flaggedCompanies[0] | json) !== '{}'
  }"
  *ngIf="activeUser === 1 && (groups.flaggedCompanies[0] | json) !== '{}'"
>
  <app-content-builder
    [contentDOM]="groups.flaggedCompanies"
    (contentReturn)="parseContentReturn($event)"
  ></app-content-builder>
</div>

<app-footer></app-footer>
