<div
  class="result mx-auto"
  [ngClass]="
    contentDOM
      ? contentDOM[0].wrapperClass
        ? contentDOM[0].wrapperClass
        : 'p-4'
      : ''
  "
>
  <a
    href="#"
    *ngIf="
      contentDOM !== undefined &&
      contentDOM[0] !== undefined &&
      contentDOM[0].closeBtn
    "
    class="closeBtn"
    (click)="closeAlertEmit($event)"
    ><img src="/assets/images/close_icon_teal.png"
  /></a>
  <app-content-elements
    [contentDOM]="contentDOM"
    (contentReturn)="parseContentReturn($event)"
    (displayLoader)="OnDisplayLoader($event)"
  ></app-content-elements>
</div>
