<section class="{{ group.section_class }}" *ngFor="let group of groups">
  <div
    [class]="group.special_class"
    [ngClass]="{ 'single-request-mode': group.singleRequestMode }"
  >
    <h3
      class="mb-4 filter-element"
      [ngClass]="{
        'results-group-name': true,
        'mt-5': group.emptyMessage !== undefined
      }"
      *ngIf="group.group_name"
    >
      {{ group.group_name }}
    </h3>
    <h3
      class="mb-2 mt-3 filter-element"
      [ngClass]="{
        'results-group-name results-with-filter mx-auto d-flex justify-content-between align-items-center': true,
        'mt-5': group.emptyMessage !== undefined
      }"
      *ngIf="
        group.group_sorter &&
        group.result_details &&
        !group.firstLoadEmpty &&
        !group.singleRequestMode
      "
    >
      <h4 class="sorter-title" class="mb-0">{{ group.sorterTitle }}</h4>
      <div
        *ngIf="!group.titleOnly"
        class="select-wrapper dashboard-select-wrapper mr-0"
      >
        <div class="select-wrapper-label">Sort by:</div>
        <select
          class="select-input input-transparent"
          (change)="
            group.isSP
              ? OnChangeInvestorFilter($event)
              : OnChangePersonFilter($event, group.activeTags)
          "
        >
          <!-- <option value="last_modified" [selected]=" !group.filterObj.sortBy || group.filterObj.sortBy === 'last_modified' " label="Last updated" > -->
          <option
            value="created_on"
            [selected]="group.filterObj.sortBy === 'created_on'"
            label="Created on"
          >
            <!-- <option *ngIf="group.isSP" value="shared_on" [selected]=" group.filterObj.sortBy === 'shared_on'" label="Shared on"> -->
          </option>
        </select>
      </div>
      <div
        *ngIf="group.show_report_generation && group.result_details.length > 0"
        class="position-relative actionsMenuBtn"
      >
        <button
          (click)="showReportActions($event)"
          class="form-button form-button-1 form-button-generate ml-2"
          id="claimData"
        >
          Generate report
        </button>
        <div
          class="actionsMenuWrapper unselectable actionsMenuProfile actionsMenuReport"
        >
          <ul
            style="top: initial; z-index: 20; left: 10px"
            class="actions-ul d-none"
          >
            <li (click)="onGenerateReportStatus($event)">
              <div>Status report</div>
            </li>
            <li *ngIf="!group.isSP" (click)="onGenerateReportUsage($event)">
              <div>Usage report</div>
            </li>
          </ul>
        </div>
      </div>
      <!-- <button  *ngIf="group.show_report_generation && group.isSP"
    (click)="onGenerateReportCDD($event)" class="form-button form-button-1 form-button-generate ml-2">
      Generate report
    </button>
    <button  *ngIf="group.show_report_generation && !group.isSP" (
      click)="onGenerateReportB($event)" class="form-button form-button-1 form-button-generate ml-2">
      Generate report
    </button> -->
    </h3>
    <p
      class="result-caption mx-auto mt-0 text-center mw-440"
      *ngIf="group.emptyMessage"
    >
      {{ group.emptyMessage }}
    </p>
    <p class="result-loader" *ngIf="group.displayLoader">
      <img src="assets/images/spinner-green.svg" />
    </p>
    <div
      class="result-caption mx-auto mt-0 text-center"
      *ngIf="group.emptyInvestorMessage"
    >
      <div class="disclaimer-wrapper d-flex align-items-center">
        <div class="content text-left" style="padding-right: 35px">
          This project is empty. To get started, please create a new profile by
          clicking the new profile button.
        </div>
        <div class="d-block buttons mr-0">
          <div class="position-relative actionsMenuBtn mb-2">
            <button
              (click)="onNewInvestor($event)"
              class="form-button form-button-1 mr-0"
              id="claimData"
            >
              New profile
            </button>
            <div
              class="actionsMenuWrapper unselectable actionsMenuProfile actionsMenuProfileDash"
            >
              <ul style="top: initial" class="actions-ul d-none">
                <li (click)="onNewInvestorManual($event)">
                  <div>Create manually</div>
                </li>
                <li (click)="onNewInvestorAutomatic($event)">
                  <div>Generate automatically</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      [ngClass]="group.show_person_filter ? 'position-relative' : ''"
      class="wrapper"
    >
      <div class="position-relative filters">
        <button
          (click)="viewProjectProfiles()"
          class="form-button form-button-1 form-button-generate mx-auto d-flex mb-3 text-center justify-content-center px-0"
          *ngIf="group.singleRequestMode"
        >
          Project profiles
        </button>
        <div
          *ngIf="
            group.show_list_filter && group.filter && !group.singleRequestMode
          "
          class="builder-filter filter-element"
        >
          <h4 class="filter-by-title" style="margin-bottom: 15px">
            Filter by status
          </h4>
          <div
            class="show-hide-completed-profiles-wrapper"
            *ngIf="group.show_hide_completed_profiles_filter.show_filter"
          >
            <div class="show-hide-completed">
              <app-toggle-button
                [toggleChecked]="
                  group.show_hide_completed_profiles_filter.filter_status
                "
                [id]="'show-hide-completed'"
                (toggle)="
                  showHideCompletedProfiles(
                    group.activeFilter,
                    group.show_hide_completed_profiles_filter.filter_status
                  )
                "
              >
              </app-toggle-button>
              Hide completed profiles
            </div>
          </div>
          <ul class="mb-0">
            <li
              [ngClass]="{
                'active-filter': group.activeStatusFilter === filter.key
              }"
              *ngFor="let filter of group.statusFilter"
            >
              <a (click)="filterListStatus(filter.key, group.activeFilter)"
                >{{ filter.name }}
              </a>
            </li>
          </ul>
          <h4
            class="filter-by-title"
            style="
              margin-bottom: 25px;
              margin-top: 25px;
              padding-top: 25px;
              border-top: 1px solid #d5d5d5;
            "
          >
            Filter by profile type
          </h4>
          <ul class="pb-2">
            <li
              [ngClass]="
                group.activeFilter === filter.key ? 'active-filter' : ' '
              "
              *ngFor="let filter of group.filter"
            >
              <a (click)="filterList(filter.key, group.activeFilter)">
                <img
                  class="filter-image"
                  *ngIf="filter.image"
                  [src]="filter.image"
                />
                <span>{{ filter.name }}</span>
              </a>
            </li>
          </ul>
          <!-- <button class="add-document-button mt-4 form-button form-button-type-2 form-button-1  "><span class="button-icon">+</span> Add investor </button> -->
        </div>
      </div>
      <div
        *ngIf="!group.firstLoadEmpty"
        class="not-empty-project"
        [ngClass]="{ funds: group.CDDDashboard }"
      >
        <div
          *ngIf="
            group.show_person_filter && group.filter && !group.singleRequestMode
          "
          class="builder-filter filter-element person-filter"
        >
          <h4 style="margin-bottom: 32px">Filter by status</h4>
          <ul>
            <li
              [ngClass]="
                group.activeFilter === filter.key ? 'active-filter' : ' '
              "
              *ngFor="let filter of group.filter"
            >
              <a
                (click)="
                  filterPersonList(
                    filter.key,
                    group.activeFilter,
                    group.activeTags
                  )
                "
                >{{ filter.name }}</a
              >
            </li>
          </ul>
        </div>
        <div
          *ngIf="
            group.tags && group.tags.length >= 1 && !group.singleRequestMode && !group.isSP
          "
          class="builder-filter builder-filter-tags"
        >
          <div class="dividing-line"></div>
          <h4>Filter by tags</h4>
          <div class="purposes-container filter-tags-container tags-dashboard">
            <div class="purpose-tags-container">
              <div
                style="cursor: pointer"
                [attr.tag-v]="tag"
                class="tag-marker"
                [ngClass]="
                  group.activeTags && group.activeTags.includes(tag)
                    ? 'active'
                    : ''
                "
                (click)="filterPersonByTag(tag, group.activeTags)"
                class="purpose-tag-wrapper"
                *ngFor="let tag of group.tags"
              >
                <div class="purpose-tag purpose-tag-no-close">
                  {{ helper.parseTagNameForFE(tag) }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="funds-sidebar">
          <div
          *ngIf="group.tags && group.tags.length >= 1 && !group.singleRequestMode && group.isSP"
          class="w-100"
          style="max-width: 200px;"
        >
          <h4 class="filter-by-title" 
          
          style=" margin-top: 25px; padding-top: 25px; border-top: 1px solid #d5d5d5;"

          >Filter by tags</h4>
          <div class="purposes-container filter-tags-container tags-dashboard">
            <div class="purpose-tags-container d-flex flex-wrap" >
              <div
                style="cursor: pointer"
                [attr.tag-v]="tag"
                class="tag-marker "
                [ngClass]="
                  group.activeTags && group.activeTags.includes(tag)
                    ? 'active'
                    : ''
                "
                (click)="filterInvestorByTag(tag, group.activeTags)"
                class="purpose-tag-wrapper"
                *ngFor="let tag of group.tags"
              >
                <div class="purpose-tag purpose-tag-no-close">
                  <p> {{ helper.parseTagNameForFE(tag) }}</p>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div class="dividing-line"></div>
          <div
          *ngIf="
            group.show_verification_filter &&
            group.verificationFilter?.length > 0 &&
            !group.singleRequestMode
          "
          class="builder-filter-verifications-slider w-100"
        >
          <div class="dividing-line"></div>
          <div>
            <label for="filterByVerification" class="filter-next-review">
              <app-toggle-button
                [toggleChecked]="
                  idVerificationService.getDisplayFilterByVerification()
                "
                toggleId="filterByVerification"
                (toggle)="OnToggleFilterByVerification()"
              >
              </app-toggle-button>

              <h4 class="filter-by-title mb-0">
                Filter by verification
              </h4></label
            >
          </div>
          <div
            class="purposes-container w-100 mt-2"
            *ngIf="idVerificationService.getDisplayFilterByVerification()"
          >
            <app-multiple-select
              [textForSelect]="'Choose a verification...'"
              [availableOptions]="group.verificationFilter"
              [selectedOptions]="
                idVerificationService.getActiveVerificationsFilters()
                  ? idVerificationService.getActiveVerificationsFilters()
                  : []
              "
              (onChange)="onChangeVerificationFilter($event)"
            ></app-multiple-select>

            <ul
              class="mb-0 mt-2"
              *ngIf="group.verificationStatuses?.length > 0"
            >
              <li
                [ngClass]="{
                  'active-filter':
                    idVerificationService.getVerificationFilterStatus()[0] ===
                    filter.key
                }"
                *ngFor="let filter of group.verificationStatuses"
              >
                <a (click)="filterVerificationsStatus(filter.key)"
                  >{{ filter.name }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          *ngIf="group.riskRatings && group.riskRatings.length >= 1 && !group.singleRequestMode"
          class="builder-filter-risk-rating-slider pb-3"
        >
          <div class="dividing-line"></div>
          <div>
            <label for="filterByNextReview" class="filter-next-review">
              <app-toggle-button
                [toggleChecked]="
                  idVerificationService.getDisplayRangeFilter()
                "
                toggleId="filterByNextReview"
                (toggle)="OnToggleNextReview()"
              >
              </app-toggle-button>

              <!-- ### Dodadvanje komponentata ### -->
              <h4 class="filter-by-title">
                Filter by Next Risk Review
              </h4></label
            >
          </div>
          <div
            class="purposes-container filter-tags-container tags-dashboard w-100"
            *ngIf="idVerificationService.getDisplayRangeFilter()"
          >
            <span class="select-wrapper m-0 rr-date-filter">
              <select
                class="select-input w-100"
                id="RRFilterByDate"
                (change)="applyNewRange()"
                [(ngModel)]="RRDateFilter"
              >
                <option
                  value="{{ option.key }}"
                  *ngFor="let option of group.RRFilterByDate"
                  [selected]="RRDateFilter == option.key"
                >
                  {{ option.name }}
                </option>
              </select>
            </span>

            <div class="date-picker mt-3" *ngIf="RRDateFilter == 'custom'">
              <app-my-date-picker
                name="rangeFrom"
                id="rangeFrom"
                method="setMinEndDate"
                dateInputId="rangeFromDate"
                isReqiured="true"
                value="{{ getRRDate('from') }}"
                startDate="{{ helper.getTodayAsMaxDate() }}"
                minDate="{{ helper.getTodayAsMaxDate() }}"
                (dateChange)="applyNewRange()"
              >
              </app-my-date-picker>

              <app-my-date-picker
                name="rangeTo"
                id="rangeTo"
                method="setMinEndDate"
                dateInputId="rangeToDate"
                isReqiured="true"
                value="{{ getRRDate('to') }}"
                startDate="{{ helper.getTodayAsMaxDate() }}"
                minDate="{{ helper.getTodayAsMaxDate() }}"
                (dateChange)="applyNewRange()"
              ></app-my-date-picker>
            </div>
          </div>
          <div class="dividing-line"></div>
          <h4 class="filter-by-title">
            Filter by Risk Rating
            <app-tooltip
              [tooltipText]="'Clear filter'"
              [icon]="'assets/images/clear-filter.svg'"
              (onClick)="filterPersonByRiskRating('', true)"
              class="mr-1"
            ></app-tooltip>
          </h4>
          <div
            class="purposes-container filter-tags-container tags-dashboard"
          >
            <div class="purpose-tags-container">
              <div
                style="cursor: pointer"
                [attr.tag-v]="tag.riskRating"
                class="tag-marker "
                (click)="filterPersonByRiskRating(tag.riskRating)"
                class="purpose-tag-wrapper"
                *ngFor="let tag of group.riskRatings"
              >
                <button
                  type="button"
                  class="btn position-relative purpose-tag purpose-tag-no-close risk-rating-tag"
                  [ngClass]="
                    tag.color
                      ? this.helper.detectTextColor(
                          this.helper.hexToRgb(tag.color)
                        )
                      : 'light-color'
                  "
                  [style.background]="tag.color"
                >
                  {{ helper.parseTagNameForFE(tag.riskRating) }}
                  <span
                    *ngIf="isActiveRRTag(tag.riskRating)"
                    class="position-absolute top-0 start-100 translate-middle badge rounded-pill"
                    [style.background]="tag.color ? tag.color : '#333333'"
                  >
                    <div
                      class="d-flex justify-content-center align-items-center"
                    >
                      <svg-icon
                        class="close-icon"
                        src="assets/images/icon-close-blue.svg"
                      ></svg-icon>
                    </div>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
          <div *ngIf="!group.singleRequestMode" class="delete-project-slider">
            <div class="delete-project-wrapper" *ngIf="group.CDDDashboard">
              <button
                class="form-button d-flex justify-content-center align-items-center"
                [disabled]="group.disableDeleteProjectBtn"
                [ngClass]="
                  group.disableDeleteProjectBtn
                    ? 'form-button-alternate-disabled'
                    : 'form-button-alternate'
                "
                [tooltip]="group.disabledDeleteProjectBtnTooltip"
                (click)="
                  idVerificationService.deleteProjectDialogue(
                    idVerificationService.getActiveProject()
                  )
                "
              >
                <img
                  *ngIf="group.disableDeleteProjectBtn; else deleteBtn"
                  src="/assets/images/delete-document-disabled.svg"
                  class="mr-2"
                />
                <ng-template #deleteBtn
                  ><img
                    src="/assets/images/delete-document-red.svg"
                    class="mr-2"
                /></ng-template>
                Delete project
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        [ngClass]="group.fixedHeight ? 'group-beyond-wrapper' : ''"
        *ngIf="group.result_details.length > 0 || group.resultShowAlways"
      >
        <div
          id="{{ result_details.id }}"
          [attr.key]="result_details.key"
          [attr.isInvestorLoading]="result_details.isInvestorLoading"
          [attr.trcKey]="result_details.trcKey"
          [attr.identity]="result_details.identity"
          [attr.kvk]="result_details.kvk"
          [attr.verificationKey]="result_details.verificationKey"
          [attr.addressVerificationKey]="result_details.addressVerificationKey"
          [attr.status]="result_details.status"
          [ngClass]="
            result_details.display_orange_dot
              ? result_details.result_container_class + ' orange-dot'
              : result_details.result_container_class
          "
          *ngFor="let result_details of group.result_details"
        >
          <h4
            class="result-title d-flex justify-content-between"
            [ngClass]="[
              result_details.result_title_classes !== undefined
                ? result_details.result_title_classes
                : ''
            ]"
            *ngIf="result_details.result_title"
          >
            <span
              class="unselectable entry-title"
              style="max-width: 390px; overflow: hidden"
              [ngClass]="
                result_details.isLazyFetch !== undefined &&
                result_details.isLazyFetch
                  ? 'lazyFetchTitle'
                  : ''
              "
            >
              <img
                class="img-icon mr-2"
                *ngIf="result_details.typeOfInvestorIcon"
                [src]="result_details.typeOfInvestorIcon"
                [style.marginBottom.px]="6"
              />
              {{ result_details.result_title }}
              <span
                class="rating"
                title="Risk Rating: {{
                  result_details.risk_rating.riskRating
                }} {{
                  result_details.risk_rating.nextReviewOn
                    ? ', Next review on: ' +
                      helper.formatISODate(
                        result_details.risk_rating.nextReviewOn,
                        'dd.MM.yyyy'
                      )
                    : ''
                }}"
                [ngClass]="{
                  colored:
                    result_details.risk_rating &&
                    result_details.risk_rating.color
                }"
                *ngIf="result_details.risk_rating"
              >
                <span
                  *ngIf="result_details.risk_rating?.color"
                  [style.background-color]="
                    result_details.risk_rating.color
                      ? result_details.risk_rating.color
                      : ''
                  "
                  class="dot"
                >
                </span>
                {{ result_details.risk_rating.riskRating }}</span
              >
            </span>
            <div
              class="actionsMenuWrapper unselectable action-new-style"
              *ngIf="result_details.actionsMenu"
            >
              <div
                class="actionsMenuBtn d-flex"
                *ngIf="result_details.hideActions"
              >
                <app-tooltip
                  [tooltipText]="
                    'No actions can be taken on this profile at this time. The share link has been clicked but the process has not yet been started.'
                  "
                  [icon]="'assets/images/ico-add-template.svg'"
                  class="mr-1 reduced-margin"
                ></app-tooltip>
                <span>Actions</span>
              </div>

              <a
                *ngIf="!result_details.hideActions"
                href="#"
                class="d-flex actionsMenuBtn justify-content-center align-items-center"
                (click)="toggleActionsMenu($event)"
              >
                <img class="mr-1 action-icon" />
                <span>Actions</span>
              </a>
              <ul class="d-none">
                <li
                  *ngFor="let action of result_details.actionsMenu"
                  [attr.method]="action.method"
                  (click)="
                    OnActionClick($event, action.method);
                    toggleActionsMenu($event)
                  "
                  [tooltip]="action.tooltip"
                  [ngClass]="action.class"
                >
                  <img
                    *ngIf="action.src"
                    [ngClass]="action.imageClass ? action.imageClass : ''"
                    [src]="action.src"
                  />
                  <div>{{ action.name }}</div>
                </li>
              </ul>
            </div>
            <div
              [attr.method]="'onDeleteVerification'"
              (click)="OnActionClick($event, 'onDeleteVerification')"
              class="special-delete-button form-button form-button-1"
              *ngIf="group.specialDeleteButton"
            >
              Delete
            </div>
            <button
              *ngIf="group.CDDDashboard"
              [attr.method]="'onDownloadCDDImages'"
              [attr.key]="result_details.key"
              (click)="OnActionClick($event, 'onDownloadCDDImages')"
              class="special-delete-button form-button form-button-1 download-all-images"
            >
              Download all
            </button>
            <div class="additional-info-container unselectable d-flex">
              <span
                class="additional-info"
                [ngClass]="[
                  result_details.additional_info_classes !== undefined
                    ? result_details.additional_info_classes
                    : ''
                ]"
                >{{ result_details.additional_info }}</span
              >
              <app-tooltip
                *ngIf="result_details.statusTooltip"
                [tooltipText]="result_details.statusTooltip.tooltipText"
                [icon]="result_details.statusTooltip.tooltipIcon"
                class="mr-1"
              ></app-tooltip>
              <!-- <span *ngIf="result_details.showScreeningElementsResults" class="additional-info ml-5" [ngClass]="[(result_details.screeningStatusClass !== undefined) ? result_details.screeningStatusClass : '']">{{ result_details.screeningStatus }}</span>  -->
            </div>
          </h4>
          <!-- <a [ngClass]="(result_details.isNew) ?  'collapse-button' : 'collapse-button collapsed'" *ngIf="group.is_collapsable" data-toggle="collapse" href="#{{ result_details.group_result_title }}" role="button" aria-expanded="false" [attr.aria-controls]="group.group_name" (click)="OnResultToggle($event, 'collapsed', 'button-collapsed')"> -->
          <div
            *ngIf="result_details.investorStatus"
            class="investor-list-status"
          ></div>
          <a
            [ngClass]="'collapse-button'"
            *ngIf="
              group.is_collapsable &&
              (result_details.isLazyFetch === undefined ||
                (result_details.isLazyFetch !== undefined &&
                  !result_details.isLazyFetch)) &&
              (result_details.isLazyFetchRefresh === undefined ||
                (result_details.isLazyFetchRefresh !== undefined &&
                  !result_details.isLazyFetchRefresh)) &&
              !result_details.loading_person_l &&
              !result_details.loading_person_l_investor
            "
            data-toggle="collapse"
            href="#{{ result_details.group_result_title }}"
            role="button"
            aria-expanded="false"
            [attr.aria-controls]="group.group_name"
            class="unselectable"
            (click)="OnResultToggle($event, 'collapsed', 'button-collapsed')"
          >
            <img
              [src]="
                result_details.isInvestorLoading
                  ? 'assets/images/spinner-green.svg'
                  : 'assets/images/result_arrow_down.png'
              "
              [ngStyle]="
                result_details.isInvestorLoading
                  ? { height: '30px' }
                  : { height: '24px' }
              "
              [attr.method]="result_details.onArrowClickMethod"
              [attr.partial]="result_details.isPartial"
              [attr.key]="result_details.id"
            />
          </a>
          <a
            [ngClass]="'collapse-button'"
            *ngIf="
              group.is_collapsable &&
              result_details.isLazyFetch !== undefined &&
              result_details.isLazyFetch &&
              result_details.isLazyFetchRefresh !== undefined &&
              !result_details.isLazyFetchRefresh
            "
          >
            <img
              src="assets/images/spinner-gray.svg"
              class="spinner"
              style="height: 30px"
              [attr.key]="result_details.id"
            />
          </a>
          <div
            (click)="group.fixedHeight ? loadMorePeople($event) : ''"
            *ngIf="result_details.loading_person_l"
            class="collapse-button collapse-button-loadmore"
          >
            Load more
          </div>
          <div
            (click)="loadMoreInvestors($event)"
            *ngIf="result_details.loading_person_l_investor"
            class="collapse-button collapse-button-loadmore"
          >
            Load more
          </div>
          <a
            [ngClass]="'collapse-button'"
            href="#{{ result_details.group_result_title }}"
            *ngIf="
              group.is_collapsable &&
              result_details.isLazyFetchRefresh !== undefined &&
              result_details.isLazyFetchRefresh
            "
            (click)="OnRefreshClick($event)"
          >
            <img
              src="assets/images/ico-refresh-button.svg"
              class="refresh"
              [attr.method]="result_details.onLazyFetchRefreshAction"
              [attr.key]="result_details.id"
            />
          </a>
          <div
            class="d-flex mt-2 align-middle align-items-center"
            *ngIf="result_details.result_identity"
          >
            <img
              class="d-flex my-auto mr-1"
              src="/assets/images/prize-badge-with-star-and-ribbon-svgrepo-com.svg"
              width="20px"
              *ngIf="result_details.result_identity_granted"
            />
            <img
              class="d-flex my-auto mr-1 noidentity-icon"
              src="/assets/images/question-mark-in-a-shield-svgrepo-com.svg"
              width="20px"
              *ngIf="
                !result_details.result_identity_granted &&
                result_details.result_identity_display
              "
            />
            <div
              class="d-flex"
              [ngClass]="
                !result_details.result_identity_granted
                  ? 'noidentity'
                  : 'identity-revealed'
              "
              *ngIf="result_details.result_identity_display"
            >
              {{ result_details.result_identity }}
            </div>
          </div>
          <div
            [ngClass]="
              result_details.isNew
                ? 'result-content collapse'
                : 'result-content collapse show'
            "
            id="{{ result_details.group_result_title }}"
            [attr.data-type]="
              result_details.group_result_type
                ? result_details.group_result_type
                : ''
            "
          >
            <form
              [attr.formKey]="group.formKey"
              [attr.form_id]="group.form_id ? group.form_id : group.formKey"
            >
              <app-content-elements
                [contentDOM]="result_details.elements"
                (contentReturn)="parseContentReturn($event)"
                (elementChangesCompleted)="parseElementChangesCompleted()"
                (displayLoader)="OnDisplayLoader($event)"
              ></app-content-elements>

              <div
                class="element-wrapper"
                *ngIf="
                  (group.submit_btn && group.submit_btn.params.display) ||
                  (group.cancel_btn && group.cancel_btn.params.display)
                "
              >
                <!-- SUBMIT -->
                <button
                  [attr.data-RequestKey]="group.submit_btn.params.requestKey"
                  class="form-button {{
                    group.submit_btn.params.className
                      ? group.submit_btn.params.className
                      : 'form-button-1'
                  }}"
                  *ngIf="group.submit_btn && group.submit_btn.params.display"
                  [attr.clickmethod]="group.submit_btn.params.action"
                  [attr.formid]="group.form_id"
                  [attr.isValidator]="group.submit_btn.params.formValidator"
                  (click)="OnSubmitClick($event)"
                >
                  {{ group.submit_btn.params.content }}
                </button>
                <!-- CANCEL -->
                <button
                  class="form-button {{
                    group.cancel_btn.params.className
                      ? group.cancel_btn.params.className
                      : 'form-button-2'
                  }}"
                  *ngIf="group.cancel_btn && group.cancel_btn.params.display"
                  [attr.clickmethod]="group.cancel_btn.params.action"
                  [attr.formid]="group.form_id"
                  (click)="OnSubmitClick($event)"
                >
                  {{ group.cancel_btn.params.content }}
                </button>
              </div>
            </form>
          </div>
        </div>
        <!-- <button (click) = "loadMorePeople()" class="form-button form-button-1">load more</button> -->
      </div>
      <p
        style="padding-top: 30px; font-style: italic; font-size: 1.15em"
        class="result-caption mx-auto mt-0 text-center mw-440 group-beyond-wrapper"
        *ngIf="
          (group.verificationDashboard &&
            group.group_sorter &&
            group.result_details &&
            group.result_details.length === 0 &&
            !group.firstLoadEmpty) ||
          group.emptyFilterMessage
        "
      >
        There are no results for the current filter. Change the filter using the
        buttons on the left panel.
      </p>
    </div>
  </div>
</section>
