import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ElementsService } from "../elements.service";

@Component({
  selector: "app-funds-parse-evidence-none",
  templateUrl: "./funds-parse-evidence-none.component.html",
  styleUrls: ["./funds-parse-evidence-none.component.scss"],
})
export class FundsParseEvidenceNoneComponent implements OnInit {
  @Input() element;
  @Output() contentReturn = new EventEmitter<any>();
  constructor(private elementService: ElementsService) {}

  ngOnInit(): void {}

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
  onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }
}
