import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-invalid-link-page",
  templateUrl: "./invalid-link-page.component.html",
  styleUrls: ["./invalid-link-page.component.scss"],
})
export class InvalidLinkPageComponent implements OnInit {
  errorMsg =
    "The link you are trying to open is either not a valid link or has already expired.";
  tooltipErrorMsg = `Invalid links are usually caused by missing characters in the link
                              (e.g. when clicking a link from an email you received, your mail app may only pick up part of the link).
                              If in doubt, please copy and paste the full link into the address bar of your browser.`;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.errorMsg = data.errorMsg;
      this.tooltipErrorMsg = data.tooltipErrorMsg;
    });
  }
}
