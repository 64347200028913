import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HelperServiceService } from "src/app/helper-service.service";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { TooltipPosition } from "src/app/ui/components/tooltip/tooltip-directive/tooltip.enums";

@Component({
  selector: "app-activity-log",
  templateUrl: "./activity-log.component.html",
  styleUrls: ["./activity-log.component.scss"],
})
export class ActivityLogComponent implements OnInit {
  @Input() label?: string;
  @Input() activityLog;
  @Input() tooltipPosition?: TooltipPosition = TooltipPosition.DEFAULT;
  activityLogText;

  constructor(
    public idVerificationService: IdVerificationService,
    // za brishenje koga kje smeni na BE
    private helperService: HelperServiceService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    // use parseHistoryEntries from id-verification-service when data changed from BE
    this.activityLogText = this.parseHistoryEntries(this.activityLog);
  }

  // Za brishenje koga BE kje smeni, samo da se smeni vo id-verification-service
  parseHistoryEntries(historyEntries) {
    let parsedHistoryEntries = [];
    let lastHistoryEntryType;
    let owner;
    let comment: string;

    historyEntries.forEach((historyEntry) => {
      lastHistoryEntryType = historyEntry.entryEnum;
      owner = historyEntry.actionDoneBy ? historyEntry.actionDoneBy : "";
      comment = historyEntry.comment ? historyEntry.comment : "";
      const date = this.helperService.formatISODate(
        historyEntry.timestamp,
        "yyy-MM-dd",
      );
      let label = "";
      let tooltip = "";
      const historyClass = "";
      switch (lastHistoryEntryType) {
        case "CREATED_ON":
          label = this.translate.instant("evidencePreview.historyEntry8", {
            owner: "Owner",
          });
          tooltip = tooltip + "date: " + date;
          break;
        case "WHITELIST_STATE_CHANGED":
          if (historyEntry.isWhitelisted) {
            label = this.translate.instant(
              "evidencePreview.hitWasWhitelisted",
              { owner: owner },
            );
          } else {
            label = this.translate.instant(
              "evidencePreview.hitWasNotWhitelisted",
              { owner: owner },
            );
          }
          if (comment) {
            tooltip = this.translate.instant(
              "evidencePreview.withTheFollowingComment",
              { comment: comment },
            );
          }

          tooltip = tooltip + "date: " + date;
          break;

        case "COMMENT":
          label = this.translate.instant("evidencePreview.hitWasCommented", {
            owner: owner,
            date: date,
          });
          tooltip = "comment: " + comment;
        default:
        // code block
      }
      parsedHistoryEntries.push({
        class: historyClass,
        text: label,
        owner: owner,
        tooltip: tooltip,
      });
    });
    parsedHistoryEntries = parsedHistoryEntries.reverse();
    return { parsedHistoryEntries: parsedHistoryEntries, owner: owner };
  }
}
