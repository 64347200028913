<div *ngIf="errorState">
  <header
    class="d-flex flex-column p-2 p-sm-3 errorState {{
      headerClasses['backgroundClass']
    }} {{ headerClasses['sectionClass'] }}"
    [ngStyle]="{ 'background-color': projectColor }"
  >
    <div class="d-flex flex-column flex-fill justify-content-center">
      <div class="header-top d-flex justify-content-between mx-auto py-3">
        <div class="navbar mx-auto p-0">
          <a [routerLink]="['/']" class="navbar-brand mr-0"
            ><img
              [ngClass]="{
                'finos-logo-img': true,
                'finos-logo-img-custom': false
              }"
              src="{{ logo }}"
              class="lightbox-class-logo-img"
              *ngIf="logo !== null"
          /></a>
        </div>
      </div>
      <div class="organizational-details" *ngIf="errorState">
        <h1 [ngClass]="projectTextColorClass" class="mb-4">
          PLEASE VERIFY YOURSELF
        </h1>
        <div>
          <div class="od-main-container">
            <img src="/assets/images/request_screening.svg" class="od-image" />
            <h4 class="od-subtitle text-center">
              {{ stateMessage }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  </header>
</div>

<div *ngIf="!errorState">
  <header
    class="d-flex flex-column p-2 p-sm-3 {{
      headerClasses['backgroundClass']
    }} {{ headerClasses['sectionClass'] }}"
    [ngStyle]="{ 'background-color': projectColor }"
  ></header>
</div>

<!-- FORM PREVIEW DIALOG -->
<div class="preview" *ngIf="previewFormio">
  <div class="content">
    <div class="close-preview" (click)="preview = !preview">
      <img _ngcontent-c4="" src="assets/images/close_form_icon.png" />
    </div>
    <h1 _ngcontent-c4="" class="form-type-heading">
      {{ "IDPCS.contract.title3" | translate }}
    </h1>
    <div class="wrapper">
      <formio (submit)="onSubmit($event)" [form]="formattedJson"></formio>
    </div>
  </div>
</div>

<!-- no error state -->
<!-- <header class="d-flex flex-column p-2 p-sm-3 {{ headerClasses['backgroundClass'] }} {{ headerClasses['sectionClass'] }}" [ngStyle]="{'background-color': projectColor}">
  <div class="header-top d-flex justify-content-between mx-auto py-3">
      <div class="navbar p-0">
        <a [routerLink]="['/']" class="navbar-brand"></a>
      </div>
  </div>
</header> -->

<app-lightbox
  *ngIf="displayModal && !errorState"
  [formType]="formType"
  [formTitle]="formTitle"
  [formClass]="formClass"
  [formSubTitle]="formSubTitle"
  [lightboxClass]="lightboxClass"
  [btnOkText]="btnOkText"
  [btnCancelText]="btnCancelText"
  [displayModal]="displayModal"
  [displayLoader]="displayLoader"
  [contentDOM]="lightboxContent"
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
></app-lightbox>
