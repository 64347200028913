import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RiskAssessmentService } from 'src/app/id-verification/pages/risk-assessment/risk-assessment.service';

@Component({
  selector: 'app-risk-rating-assign-contributor',
  templateUrl: './risk-rating-assign-contributor.component.html',
  styleUrls: ['./risk-rating-assign-contributor.component.scss']
})
export class RiskRatingAssignContributorComponent implements OnInit {

  constructor( private riskAssessmentService: RiskAssessmentService) { }

  @Input() data;
  @Output() closeLightbox : EventEmitter<any> = new EventEmitter();


  close(){
    this.closeLightbox.emit();
  }

  ngOnInit(): void {
    console.log(this.data);
  }

  acceptAndSendForApproval() {   
    let order = 1;
    let approvalRequestBody = { approvers: []};

    this.close();
    this.riskAssessmentService.loadRiskAssessmentLightbox();

    $('input[name="assignContributorN"]:checked').each(function() {
      
        approvalRequestBody.approvers.push({
          email: $(this).val(),
          level : 1,
          order: order
        });
        order++;
    });
    this.riskAssessmentService.assignApprovers(this.data.riskAssessmentData.key, approvalRequestBody).subscribe(
      ()=>{
        this.riskAssessmentService.buildRiskAssessmentLightbox(
          this.data.riskAssessmentData.key
        );
      },
      error => {}
    )
  }

}
