import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { Subscription } from "rxjs";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";

@Component({
  selector: "app-preview-image",
  templateUrl: "./preview-image.component.html",
  styleUrls: ["./preview-image.component.scss"],
})
export class PreviewImageComponent implements OnInit, OnDestroy {
  constructor(private idVerificationService: IdVerificationService) {}
  @Input() documentImage;
  @ViewChild('imageRef') imageElement!: ElementRef;
  showIframe = true;
  iframeData: Subscription;

  ngOnInit(): void {
    this.iframeData =
      this.idVerificationService.detectcompleteIframeData.subscribe(
        (response) => {
          if (this.documentImage.key === response.documentEvidence.key) {
            this.documentImage.filename = response.documentEvidence.filename;
            this.documentImage.data = response.data;
            this.showIframe = true;
            this.imageElement.nativeElement.src = response.data;
            this.imageElement.nativeElement.setAttribute('filename',response.documentEvidence.filename);

          }
        },
      );
  }
  ngOnDestroy() {
    this.iframeData.unsubscribe();
  }
}
