const selectComponent = Formio.Components.components.select;
import selectComponentForm from "./select.form";
import { HelperServiceService } from "src/app/helper-service.service";

class SelectExtendedComponent extends selectComponent {
  static schema(...extend) {
    return super.schema(
      {
        type: "SelectExtendedComponent",
        label: "",
        any_other_fields: "",
      },
      ...extend,
    );
  }

  static get builderInfo() {
    return {
      // title: 'Name in the Builder',
      title: "Dropdown",
      group: "customBasic",
      icon: "fa-solid fa-angle-down",
      weight: 6,
      schema: this.schema(),
    };
  }

  //   render() {
  //     const info = this.inputInfo;
  //     info.attr = info.attr || {};
  //     info.multiple = this.component.multiple;
  //     return super.render(this.wrapElement(this.renderTemplate('select', {
  //       input: info,
  //       selectOptions: '',
  //       index: null,
  //     })));
  //   }

  // render() {
  //     return Formio.Components.components.component.prototype.render.call(this, '<div>TEST</div>');

  // }

  render() {
    const submission = _.get(this.root, "submission", {});
    const radios = this.component.data.values;
    const id = "e8rbv53n";
    const mapped = radios.map((radio) => {
      const value = HelperServiceService.generateRandomString(5);
      return `
        <option data-weight ="${radio.answerWeight}" value="${value}_aw:${radio.answerWeight}">${radio.label}  
        </option>`;
    });
    this.tpl = `
        <div class="formio-select" ref='refExtSelect' id="${id}">
          <div class="col-form-label ">${this.component.label} </div>
          <div class="d-flex align-items-center">
            <div class="formio-select-wrapper">
            <select >
            ${mapped.join("")}
            </select>
            </div>
          <span style="margin-left: 22px" class="weight-count"></span>
          </div>
        </div>
      `;
    // Note the use of the 'ref' tag, which is used later to find
    // parts of your rendered element.

    // If you need to render the superclass,
    // you can do that with
    // tpl+=super.render(element);

    // This wraps your template to give it the standard label and bulider decorations
    return Formio.Components.components.component.prototype.render.call(
      this,
      this.tpl,
    );
  }

  attach(element) {
    //   // This code is called after rendering, when the DOM has been created.
    //   // You can find your elements above like this:
    //   this.loadRefs(element, {refExtRadio: 'single'});

    //   // var superattach = super.attach(element);
    //   //  // Here do whatever you need to attach event handlers to the dom.
    //   // this.refs.refExtRadio.on('click',()=>{alert("hi!");});

    //   // return superattach;

    // return super.attach(element);

    // This code is called after rendering, when the DOM has been created.
    // You can find your elements above like this:
    const points = $(element).find("option:selected").attr("data-weight");
    if (points !== "undefined") {
      $(element)
        .find(".weight-count")
        .html("(" + points + " points)");
    }
    this.loadRefs(element, { refExtSelect: "single" });
    var superattach = super.attach(element);
    //  // Here do whatever you need to attach event handlers to the dom.

    this.addEventListener(this.refs.refExtSelect, "click", () => {
      let points = $(this.element).find("option:selected").attr("data-weight");
      if (points != "undefined" || points === 0) {
        points = points;
      } else {
        points = "";
      }
      $(this.element)
        .find(".weight-count")
        .html("(" + points + " points)");
    });
    // this.refs.refExtRadio.on('click',()=>{alert("hi!");});
    return superattach;
  }
}

// This sets the form that pops up in the Builder when you create
// one of these.  It is basically just a standard form, and you
// can look at the contents of this in the debugger.
// RadioExtendedComponent.editForm = checkboxComponent.editForm;
SelectExtendedComponent.editForm = selectComponentForm;

// Register your component to Formio
Formio.Components.addComponent(
  "SelectExtendedComponent",
  SelectExtendedComponent,
);
export default SelectExtendedComponent;
