import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-integrity-check-bar",
  templateUrl: "./integrity-check-bar.component.html",
  styleUrls: ["./integrity-check-bar.component.scss"],
})
export class IntegrityCheckBarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
