import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HelperServiceService } from "src/app/helper-service.service";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { TooltipPosition } from "src/app/ui/components/tooltip/tooltip-directive/tooltip.enums";

@Component({
  selector: "app-activity-log",
  templateUrl: "./activity-log.component.html",
  styleUrls: ["./activity-log.component.scss"],
})
export class ActivityLogComponent implements OnInit {
  @Input() label?: string;
  @Input() activityLog;
  @Input() type? : string;
  @Input() tooltipPosition?: TooltipPosition = TooltipPosition.DEFAULT;
  activityLogText;

  constructor(
    public idVerificationService: IdVerificationService,
    // za brishenje koga kje smeni na BE
    private helperService: HelperServiceService,
    public translate: TranslateService,
  ) {}

  ngOnInit(): void {
    if(this.type == 'hitHistory')
    {
      this.activityLogText = this.parseHistoryEntriesIndicator(this.activityLog);
    }
    else
    {
    // use parseHistoryEntries from id-verification-service when data changed from BE
      this.activityLogText = this.parseHistoryEntries(this.activityLog);
    }
  }

  parseHistoryEntriesIndicator(historyEntries)
  {
    let parsedHistoryEntries = [];
    let label: string = '';
    let tooltip: string = '';
    historyEntries.forEach((historyEntry)=>{
      let date = this.helperService.formatISODate(
        historyEntry.answeredOn,
        "yyy-MM-dd",
      );
      if(historyEntry.answeredBy == "system")
      {
        label = this.translate.instant("riskAssessment.preview.indicatorHistoryEntrySystem", {
          riskNoRisk: historyEntry.value ? "risk" : "no risk",
          date: date,
        });
        if(historyEntry?.answers)
        {
          tooltip = this.translate.instant("riskAssessment.preview.indicatorHistoryTooltip");
          historyEntry.answers.forEach((element, index) => {
            tooltip+= element.profileName + this.helperService.generateCommaOrAnd(historyEntry.answers.length, index);
          });
        }
      }
      else if(historyEntry.comment)
      {
        label = this.translate.instant("riskAssessment.preview.indicatorHistoryEntryWithComment", {
          riskNoRisk: historyEntry.value ? "risk" : "no risk",
          date: date,
          user: historyEntry.answeredBy,
          comment: historyEntry.comment,
        });
      }
      else{
        label = this.translate.instant("riskAssessment.preview.indicatorHistoryEntry", {
          riskNoRisk: historyEntry.value ? "risk" : "no risk",
          user: historyEntry.answeredBy,
          date: date,
        });
      }
      parsedHistoryEntries.push({
        text: label,
        tooltip: tooltip,
      });

    })
    parsedHistoryEntries = parsedHistoryEntries.reverse();
      
    return { parsedHistoryEntries: parsedHistoryEntries};
  }
  // Za brishenje koga BE kje smeni, samo da se smeni vo id-verification-service
  parseHistoryEntries(historyEntries) {
    let parsedHistoryEntries = [];
    let lastHistoryEntryType;
    let owner = "Owner";
    let comment: string;
    let type: string;

    historyEntries.forEach((historyEntry) => {
      lastHistoryEntryType = historyEntry.entryEnum ? historyEntry.entryEnum : historyEntry.entry.entryEnum ;
      if(historyEntry?.actionDoneBy)
      {
        owner = historyEntry.actionDoneBy;
      }
      else if(historyEntry?.entry?.data?.actionDoneBy)
      {
        owner = historyEntry.entry.data.actionDoneBy;
      }
      comment = historyEntry.comment ? historyEntry.comment : "";
      type = historyEntry.entryType ? historyEntry.entryType : "";
      const date = this.helperService.formatISODate(
        historyEntry.timestamp,
        "yyy-MM-dd",
      );
      let label = "";
      let tooltip = "";
      const historyClass = "";
      switch (lastHistoryEntryType) {
        case "CREATED_ON":
          if(type == "risk-review")
          {
            if(owner === "Owner")
            {
              label = this.translate.instant("evidencePreview.startedRRENoOwner", {
                date: date,
              });
            }
            else
            {
              label = this.translate.instant("evidencePreview.startedRRE", {
                owner: owner,
                date: date,
              });
            }
          }
          else{
            label = this.translate.instant("evidencePreview.historyEntry8", {
              owner: owner,
            });
            label += " " + date;
          }
          tooltip = tooltip + "date: " + date;
          break;
        case "WHITELIST_STATE_CHANGED":
          if (historyEntry.isWhitelisted) {
            label = this.translate.instant(
              "evidencePreview.hitWasWhitelisted",
              { owner: owner },
            );
          } else {
            label = this.translate.instant(
              "evidencePreview.hitWasNotWhitelisted",
              { owner: owner },
            );
          }
          if (comment) {
            tooltip = this.translate.instant(
              "evidencePreview.withTheFollowingComment",
              { comment: comment },
            );
          }

          tooltip = tooltip + "date: " + date;
          break;

        case "COMMENT":
          label = this.translate.instant("evidencePreview.hitWasCommented", {
            owner: owner,
            date: date,
          });
          tooltip = "comment: " + comment;
          break;
        case "COMPLETED_ON":
          if(historyEntry?.entry?.data?.ratingChanged)
          {
            label = this.translate.instant(
              "evidencePreview.completedRREWithOverride",
              {
                overriddenRiskRatting: historyEntry.entry.data.ratingChanged.to.toLowerCase(),
                user: owner,
                date: date,
                riskRating:  historyEntry.entry.data.ratingChanged.from.toLowerCase(),
              },
            );
          }
          else if(historyEntry){
            label = this.translate.instant(
              "evidencePreview.completedRRE",
              {
                user: owner,
                date: date,
              },
            );
          }

        break;
        case "COMPLETED_BY_SERVICE_PROVIDER":
            label = this.translate.instant(
              "evidencePreview.providedAndMarkedAsCompleteBy",
              {
                owner: owner,
              },
            );
          break;
        default:
        // code block
      }
      parsedHistoryEntries.push({
        class: historyClass,
        text: label,
        owner: owner,
        tooltip: tooltip,
      });
    });
    parsedHistoryEntries = parsedHistoryEntries.reverse();
    return { parsedHistoryEntries: parsedHistoryEntries, owner: owner };
  }
}
