<div
  *ngIf="element.evidenceTypes && element.investorSide"
  class="document-entry-list"
>
  <!-- PARSE EVIDENCE ON INVESTOR SIDE -->
  <app-evidence-view-box
    [evidence]="evidence"
    [element]="element"
    *ngFor="let evidence of element.evidenceTypes"
  ></app-evidence-view-box>
</div>
