<!-- no error state -->
<header
  *ngIf="!errorState && addressProcessStarted"
  class="d-flex flex-column p-2 p-sm-3 {{ headerClasses['backgroundClass'] }} {{
    headerClasses['sectionClass']
  }}"
  [ngStyle]="{ 'background-color': projectColor }"
>
  <div class="header-top d-flex justify-content-between mx-auto py-3">
    <div class="navbar mx-auto p-0">
      <a [routerLink]="['/']" class="navbar-brand mr-0"></a>
    </div>
  </div>
  <div
    [ngClass]="separatePage ? 'd-none' : ''"
    class="close-preview"
    (click)="closeAddressPreview()"
  >
    <img
      *ngIf="projectTextColorClass === 'light-color'"
      _ngcontent-c4=""
      src="assets/images/close_form_icon.png"
    />
    <img
      *ngIf="projectTextColorClass === 'dark-color'"
      _ngcontent-c4=""
      src="assets/images/close_icon_teal.png"
    />
  </div>
</header>

<app-lightbox
  class="app-lightbox-address-step"
  *ngIf="displayModal && !errorState && addressProcessStarted"
  [formType]="formType"
  [formTitle]="formTitle"
  [formClass]="formClass"
  [formSubTitle]="formSubTitle"
  [lightboxClass]="lightboxClass"
  [btnOkText]="btnOkText"
  [btnCancelText]="btnCancelText"
  [displayModal]="displayModal"
  [displayLoader]="displayLoader"
  [contentDOM]="lightboxContent"
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
></app-lightbox>

<div
  style="box-shadow: none; padding-bottom: 0; padding: 24px"
  class="form-container-side review-process-dv-contaziner"
  [ngClass]="addressProcessEnded && separatePage ? 'process-ended' : ''"
>
  <div *ngIf="!separatePage" class="mt-3 element-wrapper">
    <div>
      <img
        src="/assets/images/check-svgrepo-com.svg"
        class="result-image request-share-img-2"
      />
    </div>
    <h3 class="mt-4 mb-2 verify-title">
      {{ "review.digitalVerifications.title2" | translate }}
    </h3>
    <p
      [innerHTML]="
        'review.digitalVerifications.content8'
          | translate: { entity: entityName ? entityName : 'entity' }
      "
      *ngIf="typeOfInvestor !== 'LegalPerson'"
      class="mb-4 subtext"
    ></p>

    <div
      style="padding-bottom: 20px; border-bottom: solid 1px #d5d5d5"
      class="d-flex justify-content-between align-items-center"
    >
      <div>
        <h3 class="verify-title mt-4 sharing-wrapper-title mb-1">
          {{ "review.digitalVerifications.button2" | translate }}
        </h3>

        <div
          class="sharing-wrapper-sub"
          [innerHTML]="
            'review.digitalVerifications.content10'
              | translate: { entity: entityName ? entityName : 'entity' }
          "
        ></div>
      </div>

      <div>
        <button
          (click)="startAddressProcess()"
          *ngIf="!addressProcessStarted && !addressProcessEnded"
          class="form-button form-button-1 mt-1 ml-auto"
        >
          {{ "common.start" | translate }}
        </button>
      </div>
    </div>
    <h3 class="verify-title mt-4 sharing-wrapper-title mb-1">
      {{ "review.digitalVerifications.label" | translate }}
    </h3>

    <p
      [innerHTML]="
        'review.digitalVerifications.content8'
          | translate: { entity: entityName ? entityName : 'entity' }
      "
      *ngIf="typeOfInvestor !== 'LegalPerson'"
      class="mb-4 sharing-wrapper-sub"
    ></p>
  </div>

  <div
    *ngIf="separatePage && !addressProcessEnded"
    class="mt-3 element-wrapper"
  >
    <div>
      <img
        src="/assets/images/spinner-green.svg"
        class="result-image request-share-img-2"
      />
    </div>
    <h3 class="verify-title mt-4 mb-2 text-center">
      Please wait while the data is being loaded
    </h3>
  </div>

  <div *ngIf="addressProcessEnded" class="mt-3 element-wrapper">
    <div>
      <img
        src="/assets/images/check-svgrepo-com.svg"
        class="result-image request-share-img-2"
      />
    </div>
    <h3 class="verify-title mt-4 mb-2 text-center">
      The process has been completed
    </h3>
  </div>

  <div
    *ngIf="!separatePage && !addressProcessEnded"
    class="d-flex position-relative input-text-button-wrapper justify-content-center"
  >
    <span class="mr-3 d-flex align-items-center">
      {{ "appSpecific.CopyLink" | translate }}</span
    >
    <!-- Status notification -->
    <div class="input-action-status"></div>
    <!-- Input Text -->
    <input
      style="max-width: 500px"
      [readonly]="true"
      type="text"
      class="text-input"
      id="generatedverificationLink"
      name="generatedverificationLink"
      value="{{
        idinTransactionURL ? idinTransactionURL : originalVerificaitonLink
      }}"
    />
    <!-- Button -->

    <button
      class="form-button form-button-1 ml-2 mr-0"
      (click)="CopyLinkToClipboard2($event)"
    >
      {{ "common.copy" | translate }}
    </button>
  </div>

  <div *ngIf="!separatePage && !addressProcessEnded" class="element-wrapper">
    <div
      class="d-flex position-relative input-text-button-wrapper justify-content-center"
    >
      <span class="mr-3 d-flex align-items-center">{{
        "appSpecific.SendLink" | translate
      }}</span>
      <!-- Status notification -->
      <div class="input-action-status"></div>
      <!-- Input Text -->

      <input
        style="max-width: 500px"
        type="text"
        class="text-input"
        placeholder="Type in an email address"
        id="generatedverificationLink"
        name="generatedverificationLink"
      />
      <!-- Button -->
      <button
        class="form-button form-button-1 ml-2 mr-0"
        [attr.clickmethod]="'sendverificationLink'"
        (click)="sendverificationLink($event)"
      >
        {{ "common.send" | translate }}
      </button>
    </div>
    <div class="button-error-field">
      {{ "validation.validation5" | translate }}
    </div>
  </div>

  <div  *ngIf="!separatePage && !addressProcessEnded"  class="mt-5 mb-5">
    <button (click) = "checkDVEvidenceStatus()" class="form-button form-button-8 pl-0">
      {{ "review.digitalVerifications.button6" | translate }}
    </button>
  </div>

</div>
