<div
  class="id-verification-container d-flex flex-column flex-fill justify-content-center"
>
  <div class="active-projects-wrapper d-flex flex-row">
    <div class="ic-ctas new-person-wrapper mx-auto mt-3 mb-3 pl-0">
      <span *ngIf="!qBuilderService.currentTemplate"
        >Currently editing new questionnaire</span
      >
      <span *ngIf="qBuilderService.currentTemplate"
        >Currently editing
        <strong>{{
          qBuilderService.getNameBySlug(qBuilderService.currentTemplate)
        }}</strong>
        template in
        <strong>{{ qBuilderService.currentMode }}</strong> mode</span
      >
    </div>
  </div>
</div>
