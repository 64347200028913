import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-kyc-template-dashboard",
  templateUrl: "./kyc-template-dashboard.component.html",
  styleUrls: ["./kyc-template-dashboard.component.scss"],
})
export class KycTemplateDashboardComponent implements OnInit {
  headerClasses: {};
  headerInfo: {};
  constructor() {}

  ngOnInit(): void {
    this.headerClasses = {
      backgroundClass: "tealish-gradient-bg",
      sectionClass: "ic_height_auto", // 'ic_height_auto',
    };
    this.headerInfo = {
      headerType: "full", // 'inline',
      headerTitle: "KYC Templates",
      activeNavLink: "KYC Templates",
      projectType: "Project",
    };
  }
}
