import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { HelperServiceService } from "src/app/helper-service.service";

@Component({
  selector: "app-preview-basic-fields",
  templateUrl: "./preview-basic-fields.component.html",
  styleUrls: ["./preview-basic-fields.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PreviewBasicFieldsComponent implements OnInit {
  @Input() element;
  @Output() contentReturn = new EventEmitter<any>();
  validateFieldsSource = {};
  constructor(private helper: HelperServiceService) {}

  ngOnInit(): void {
    // console.log('this is element', this.element);
    if (this.element["params"]?.fieldsSource) {
      this.validateFieldsSource = this.element["params"].fieldsSource;
    }
    if (
      this.element &&
      this.element["params"] &&
      this.element["params"].fieldTypes &&
      !this.element["params"].fieldTypesDV
    ) {
      let lastTitle;
      lastTitle = {};
      this.element["params"].fieldTypes.forEach((fieldType) => {
        if (fieldType.type === "title") {
          lastTitle = fieldType;
          lastTitle.showTitle = false;
        } else if (fieldType.value) {
          lastTitle.showTitle = true;
        }
        if (this.validateFieldsSource) {
          let profileVerification = this.addProfileVerificationInformations(
            fieldType.name,
          );
          if (profileVerification) {
            Object.assign(fieldType, profileVerification);
          }
        }
      });
    }
  }

  addProfileVerificationInformations(fieldName: string) {
    let fields = this.validateFieldsSource[fieldName];
    if (fields) {
      let icon = "";
      let tooltip = "";
      let date = fields?.verifiedOn
        ? this.helper.formatISODate(fields.verifiedOn, "yyyy-MM-dd", false)
        : "";

      if (fields.status?.toLowerCase() == "verified") {
        icon = "assets/images/icon-checkmark-prepared-green.svg";
        tooltip = date + " Full match was found in the following source";
      } else if (fields.status?.toLowerCase() == "partially verified") {
        icon = "assets/images/in-progress.svg";
        tooltip = date + " Partial match was found in the following source";
      } else if (fields.status?.toLowerCase() == "not verified") {
        icon = "assets/images/cancel-svgrepo-com.svg";
        tooltip = date + " No match was found in the following source";
      } else {
        return null;
      }
      if (fields.sources.length > 1) {
        tooltip += "s";
      }
      fields?.sources?.forEach((source) => {
        if (source == "CompanySearch") {
          source = "Company search";
        }
        tooltip =
          tooltip +
          "<div><img width='auto' height='10px' src='assets/images/start_alternate-white.svg' class='mr-1'/>" +
          source +
          "</div>";
      });

      return {
        profileVerification: true,
        profileVerificationIcon: icon,
        profileVerificationTooltip: tooltip,
      };
    }
    return null;
  }
  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
  onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }
}
