<div
  id="{{ relatedParty.key }}"
  class="related-party-details mb-3"
  *ngIf="
    relatedParty.key == element.activeRelatedPartyKey && element.investorSide
  "
>
  <div class="element-wrapper dashboard-tabs rp-tabs">
    <div class="tab-container">
      <div
        [ngClass]="tab.active ? 'tab active unselectable' : 'tab unselectable'"
        [attr.activatesTab]="tab.activates"
        [attr.fromTabGroup]="element.tabGroup"
        *ngFor="let tab of element.tabs"
        (click)="elementService.OnChangeDashTab($event)"
        id="{{ tab.activates }}"
      >
        <img
          class="img-icon"
          *ngIf="!tab.tooltip && tab.iconURL"
          src="{{ tab.iconURL }}"
        />
        <img
          [attr.activatesTab]="tab.activates"
          [attr.fromTabGroup]="element.tabGroup"
          (click)="elementService.OnChangeDashTab($event)"
          *ngIf="tab.tooltip && tab.iconURL"
          src="{{ tab.iconURL }}"
          [tooltip]="tab.tooltip"
        />
        <span>{{ tab.name }}</span>
      </div>
    </div>
  </div>

  <!-- RELATED PARTIES DOCS -->
  <div
    [attr.tabGroup]="relatedParty.key"
    [attr.tabKey]="relatedParty.key + '_evidence'"
    class="d-flex-low justify-content-between flex-wrap"
    [ngClass]="element.emptyRPEvidenceTypes ? 'd-none' : ''"
  >
    <app-evidence-view-box
      [evidence]="relatedPartyEvidence"
      [element]="element"
      *ngFor="
        let relatedPartyEvidence of element.activeRelatedParty.evidenceTypes
      "
    ></app-evidence-view-box>
  </div>

  <!-- RELATED PARTIES FIELDS -->
  <div
    [ngClass]="!element.emptyRPEvidenceTypes ? 'd-none' : ''"
    [attr.tabGroup]="relatedParty.key"
    [attr.tabKey]="relatedParty.key + '_details'"
  >
    <div class="d-flex w-100">
      <div style="margin-bottom: -1px" class="kvk-title mt-2 pt-2">
        {{ "appSpecific.Summary" | translate }}
      </div>
    </div>
    <div
      class="kvk-container related-parties-kvk-container investors-related-parties my-3 pb-4"
    >
      <div
        class="kvk-element-wrapper"
        [ngClass]="[
          tabelement['type'] !== 'html' &&
          tabelement['content'] !== undefined &&
          tabelement['title'] == true
            ? 'full-width-kvk'
            : '',
          tabelement['type'] !== 'html' &&
          tabelement['content'] == undefined &&
          tabelement['title'] == undefined
            ? 'd-none'
            : ''
        ]"
        *ngFor="let tabelement of element.activeRelatedPartyFields"
      >
        <div
          class="d-flex w-100"
          *ngIf="
            tabelement['type'] !== 'html' &&
            tabelement['content'] !== undefined &&
            tabelement['title'] == undefined
          "
        >
          <div class="kvk-label">{{ tabelement.key }}</div>
          <div class="kvk-information">{{ tabelement.content }}</div>
        </div>
        <div
          class="d-flex w-100"
          *ngIf="
            tabelement['type'] !== 'html' &&
            tabelement['content'] !== undefined &&
            tabelement['title'] == true
          "
        >
          <div style="margin-bottom: 16px" class="kvk-title mt-2 pt-2">
            {{ tabelement.content }}
          </div>
        </div>
        <div
          class="d-flex w-100"
          *ngIf="
            tabelement['type'] === 'html' && tabelement['content'] !== undefined
          "
        >
          <div class="kvk-label">{{ tabelement.key }}</div>
          <div class="kvk-information" [innerHTML]="tabelement.content"></div>
        </div>
      </div>
    </div>
    <div *ngIf="!element.basicFieldEvidenceReviewState" class="w-100">
      <!-- BUTTON -->
      <button
        class="ml-0 form-button form-button-4 mb-0"
        (click)="
          elementService.documentPreviewFunctionInvestor(
            element.activeRelatedPartyEvidenceTypes,
            element.basicFieldEvidence,
            element.activeRelatedPartyKey,
            element.basicFieldEvidenceKey
          )
        "
      >
        <img src="/assets/images/update_white.svg" />
        <span> {{ "appSpecific.ViewDetails" | translate }}</span>
      </button>
    </div>
  </div>
  <!-- RELATED PARTY URLS -->
  <div
    class="d-none"
    [attr.tabGroup]="relatedParty.key"
    [attr.tabKey]="relatedParty.key + '_url'"
  >
    <div
      style="padding-left: 24px; padding-right: 24px"
      class="element-wrapper mb-4"
    >
      <!-- <div class="result-inner-title mb-3">{{'appSpecific.VerificationLinks' | translate}}</div> -->
      <div
        *ngFor="let verificationLink of element.verificationLinks"
        class="d-flex flex-column mb-3"
      >
        <div class="element-wrapper mt-0">
          <div
            style="border-bottom: 1px solid rgba(0, 0, 0, 0.12)"
            class="pb-3 align-items-center d-flex position-relative input-text-button-wrapper mb-3"
          >
            <!-- Status notification -->
            <div style="font-size: 16px" class="mr-3">
              {{ "appSpecific.linkLabel" | translate }}
            </div>
            <div class="input-action-status"></div>
            <a
              target="_blank"
              style="color: white !important"
              href="{{ verificationLink.link }}"
              class="form-button form-button-1 ml-2 mr-0"
            >
              {{ "common.start" | translate }}
            </a>
            <!-- Button -->
          </div>
        </div>
        <p style="color: #808080; font-weight: 400" class="mb-1">
          {{ "appSpecific.linkLabel2" | translate }}
        </p>
        <div class="element-wrapper mt-2">
          <div
            class="align-items-center d-flex position-relative input-text-button-wrapper mb-3"
          >
            <!-- Status notification -->
            <div class="input-action-status"></div>
            <div class="text-button-label mr-3">
              {{ "appSpecific.CopyLink" | translate }}
            </div>
            <!-- Input Text -->
            <input
              [readonly]="true"
              type="text"
              class="text-input"
              id="generatedVerificationLink"
              name="generatedVerificationLink"
              value="{{ verificationLink.link }}"
            />
            <!-- Button -->
            <button
              class="form-button form-button-1 ml-2 mr-0"
              [attr.clickmethod]="'CopyLinkToClipboard2'"
              (click)="onEmit(elementService.OnButtonClick($event))"
            >
              {{ "common.copy" | translate }}
            </button>
          </div>
        </div>
        <div class="element-wrapper mt-3">
          <div
            class="d-flex align-items-center position-relative input-text-button-wrapper"
          >
            <!-- Status notification -->
            <div class="input-action-status"></div>
            <div class="text-button-label mr-3">
              {{ "appSpecific.EmailLink" | translate }}
            </div>
            <!-- Input Text -->
            <input
              [value]="element.RPEmail ? element.RPEmail : ''"
              placeholder="Type in an email address"
              type="text"
              class="text-input"
              id="generatedVerificationLink"
              name="generatedVerificationLink"
            />
            <!-- Button -->
            <button
              class="form-button form-button-1 ml-2 mr-0"
              [attr.requestKey]="
                element.activeRelatedParty.originRequest
                  ? element.activeRelatedParty.originRequest
                  : element.activeRelatedPartyKey
              "
              [attr.clickmethod]="'sendVerificationLink'"
              (click)="onEmit(elementService.OnButtonClick($event))"
            >
              {{ "common.send" | translate }}
            </button>
          </div>
          <div class="button-error-field">
            {{ "validation.validation5" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- RELATED PARTY VERIFICATIONS TAB -->
  <div
    *ngIf="
      element.activeRelatedPartyDV && element.activeRelatedPartyDV.length > 0
    "
    [attr.tabGroup]="relatedParty.key"
    [attr.tabKey]="relatedParty.key + '_checks'"
    class="element-wrapper d-none mt-0"
  >
    <!-- STATUS LIST -->
    <app-verification-statuses
      [statuses]="element.dvStatuses"
      [evidenceTypes]="element.activeRelatedPartyEvidenceTypes"
      [vtype]="element.vtype"
      [requestKey]="element.activeRelatedPartyKey"
    ></app-verification-statuses>
  </div>
</div>
