import { Component, OnInit } from "@angular/core";
import { HelperServiceService } from "src/app/helper-service.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  constructor(private helperService: HelperServiceService) {}

  ngOnInit() {
    this.loadScripts();
  }

  private loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.helperService
      .load("smallchat")
      .then((data) => {
        // Script Loaded Successfully
        console.log("script loaded successfully");
      })
      .catch((error) => console.log(error));
  }
}
