<div
  *ngIf="tooltip != '' && tooltip"
  class="tooltip"
  [ngClass]="['tooltip--' + position]"
  [class.tooltip--visible]="visible"
  [style.left]="left + 'px'"
  [style.top]="top + 'px'"
  [innerHTML]="tooltip"
>
  {{ tooltip }}
</div>

<!-- [innerHTML]="tooltip" -->
