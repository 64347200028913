<div
  class="element-wrapper document-preview-container mt-0"
  [ngClass]="element.class ? element.class : ''"
>
  <div class="load-container">
    <img
      src="/assets/images/spinner-green.svg"
      width="65px"
      height="65px"
      class="my-auto mx-auto"
    />
  </div>
  <div class="document-preview-title-badge">
    <h3 class="document-preview-title">
      {{ element.params.documentPreviewTitle }}
    </h3>
    <div
      *ngIf="element.params.isMonitored"
      class="document-preview-badge-wrapper"
    >
      <span class="badge badge-pill badge-success d-in">Monitoring is on</span>
    </div>
  </div>
  <div
    *ngIf="
      idVerificationService.getAPSStatusses() &&
      (element.params.isAPSPreview || element.params.isAPSpreviewEmpty)
    "
    class="preview-verification-statuses flex-wrap"
  >
    <div
      *ngFor="let verificationStatus of idVerificationService.getAPSStatusses()"
      class="preview-verification-status"
    >
      <div>
        <img
          *ngIf="verificationStatus.src"
          [src]="verificationStatus.src"
          width="16px"
          height="16px"
        />
        <span
          [ngClass]="[
            verificationStatus.title ? 'verification-title' : '',
            verificationStatus.titleClass ? verificationStatus.titleClass : ''
          ]"
          >{{
            verificationStatus.title
              ? verificationStatus.title
              : verificationStatus.subtitle
          }}</span
        >
      </div>
    </div>
    <div
      *ngIf="element.params.verificationSubtitle"
      class="verification-status-subtitle w-100 mt-3 font-weight-bold"
    >
      {{ element.params.verificationSubtitle }}
    </div>
  </div>
  <div
    *ngIf="!element.params.isAPSPreview && !element.params.isAPSpreviewEmpty"
  >
    <div
      class="status"
      [ngClass]="status.class"
      *ngFor="let status of element.params.statuses"
    >
      <div *ngIf="status.status" class="d-flex">
        <img
          *ngIf="status.icon"
          style="width: 17px; height: auto"
          src="{{ status.icon }}"
        />
        <div class="title" *ngIf="status.title">{{ status.title }}</div>
        <div class="details" [innerHTML]="status.status"></div>
      </div>
    </div>
  </div>
  <div *ngIf="element.params.generalStatus">
    <div
      class="status"
      [ngClass]="status.class"
      *ngFor="let status of element.params.generalStatus"
    >
      <div *ngIf="status.status" class="d-flex">
        <img
          *ngIf="status.icon"
          style="width: 17px; height: auto"
          src="{{ status.icon }}"
        />
        <div class="title" *ngIf="status.title">{{ status.title }}</div>
        <div class="details" [innerHTML]="status.status"></div>
      </div>
    </div>
  </div>
  <div class="document-preview-content d-flex flex-column flex-lg-row">
    <div *ngIf="!element.params.isAPSPreview" class="document-preview-left">
      <div
        class="evidence-preview-subtitle-status mb-2"
        *ngIf="
          element.params.expired &&
          !(
            !element.params.isReviewFlow &&
            element.params.showVersions &&
            !element.params.versionNotInUse &&
            element.params.currentEvidenceKey !==
              element.params.latestVersionKey
          )
        "
      >
        <img
          src="assets/images/icon-triangle-orange.svg"
          width="16px"
          height="16px"
        />
        <span *ngIf="element.params.reasonForExpire">
          {{ "evidencePreview.expiredLabel" | translate }}
          {{ element.params.reasonForExpire }}</span
        >
        <span *ngIf="!element.params.reasonForExpire">
          {{
            element.params.vtype === "APS"
              ? ("evidencePreview.complianceCheckResolved" | translate)
              : ("evidencePreview.expiredLabel2" | translate)
          }}
        </span>
      </div>
      <div
        class="evidence-preview-notification-container d-flex justify-content-between align-items-center"
        *ngIf="
          !element.params.isReviewFlow &&
          element.params.showVersions &&
          element.params.hasNewerVersions &&
          element.params.currentEvidenceKey === element.params.evidenceKey
        "
      >
        <div>
          <img
            src="assets/images/icon-triangle-orange.svg"
            width="16px"
            height="16px"
          />
          {{ "evidencePreview.versionLabel1" | translate }}
        </div>
        <a
          (click)="
            elementService.previewVersion(
              element.params.evidenceKey,
              element.params.latestVersion.key,
              element.params.latestVersion.record,
              element.params.evidenceTypes,
              element.params.latestVersion.record.documents,
              element.params.requestKey
            )
          "
          class="form-button form-button-1"
        >
          {{ "evidencePreview.versionLabel2" | translate }}
        </a>
      </div>
      <div
        class="evidence-preview-notification-container d-flex justify-content-between align-items-center"
        *ngIf="
          !element.params.isReviewFlow &&
          element.params.versionNotInUse &&
          element.params.currentEvidenceKey !==
            element.params.latestVersionKey &&
          !element.params.hasNewerVersions
        "
      >
        <div>
          <img
            src="assets/images/icon-triangle-orange.svg"
            width="16px"
            height="16px"
          />

          {{ "evidencePreview.versionLabel3" | translate }}
        </div>
        <a
          (click)="
            elementService.setLatestVersionInUse(
              element.params.evidenceKey,
              element.params.currentEvidenceKey,
              element.params.evidence,
              element.params.evidenceTypes,
              element.params.requestKey
            )
          "
          class="form-button form-button-1"
        >
          {{ "evidencePreview.versionLabel4" | translate }}
        </a>
      </div>
      <div
        *ngIf="
          !element.params.noDocumentsDisplay &&
          element.params.documentKeys &&
          !element.params.basicFieldsPreview &&
          !element.params.profileVerificationPreview &&
          !element.params.isAPSPreview
        "
        id="documentCarousel"
        style="background-color: #d9d9d9"
        class="carousel slide document-carousel"
        [ngClass]="element.params.isDigitalVerification ? 'dv-Carousel' : ''"
        data-interval="false"
        data-ride="carousel"
      >
        <!-- The slideshow -->
        <div class="carousel-inner">
          <ng-container
            *ngFor="
              let documentImage of element.params.documentKeys;
              first as isFirst
            "
          >
            <div
              [ngClass]="
                element.params.startingFilname === documentImage.filename &&
                documentImage.filename
                  ? 'carousel-item active'
                  : isFirst && element.params.startingFilname === undefined
                  ? 'carousel-item active'
                  : 'carousel-item'
              "
            >
              <div
                class="document-slide-container document-slide-container-imgs"
              >
                <app-preview-image
                  [attr.documentKey]="
                    documentImage.Key ? documentImage.Key : documentImage.key
                  "
                  style="width: 100%; height: 100%"
                  class="d-none d-flex-low justify-content-center align-items-center"
                  [documentImage]="documentImage"
                ></app-preview-image>
                <img
                  class="downloadble-preview-file preview-file-loading"
                  [attr.filename]="documentImage.filename"
                  [src]="'/assets/images/spinner-green.svg'"
                  alt=""
                />
                <app-preview-iframe
                  [attr.documentKey]="
                    documentImage.Key ? documentImage.Key : documentImage.key
                  "
                  style="width: 100%; height: 100%"
                  class="d-none"
                  [documentImage]="documentImage"
                ></app-preview-iframe>
              </div>
              <!-- <div *ngIf='documentImage.type == "pdf"' class="document-slide-container">
              <iframe scrolling="no" class="downloadble-preview-file downloadable-iframe-preview"
              [attr.filename] = "documentImage.filename"  style="z-index: 1000;"
              height="100%" width="100%" [src]="documentImage.data | safeUrl" alt=""></iframe>
            </div> -->
            </div>
          </ng-container>
        </div>
        <!-- Left and right controls -->
        <a
          *ngIf="!element.params.hideArrows"
          (click)="elementService.refreshIframe()"
          class="carousel-control-prev"
          href="#documentCarousel"
          data-slide="prev"
        >
          <span
            (click)="elementService.refreshIframe()"
            class="carousel-control-prev-icon"
          ></span>
        </a>
        <a
          *ngIf="!element.params.hideArrows"
          (click)="elementService.refreshIframe()"
          class="carousel-control-next"
          href="#documentCarousel"
          data-slide="next"
        >
          <span
            (click)="elementService.refreshIframe()"
            class="carousel-control-next-icon"
          ></span>
        </a>
      </div>
      <!-- if there are basic fields of DV types we display them differently in a separate tab -->
      <div
        class="no-images d-flex align-items-center flex-column justify-content-center h-100"
        *ngIf="
          element.params.noDocumentsDisplay && !element.params.isAPSPreview
        "
      >
        <div>
          {{
            element.params.isAPSpreviewEmpty
              ? ("evidencePreview.noHitsFound" | translate) +
                " for " +
                element.params.apsSearchSubject
              : ("evidencePreview.noDocumentsUploaded" | translate)
          }}
        </div>
        <small *ngIf="element?.params?.fieldTypes?.checkDateTextText">{{
          element.params.fieldTypes.checkDateTextText
        }}</small>
      </div>
      <app-preview-basic-fields
        *ngIf="
          !element.params.isDigitalVerification && !element.params.isAPSPreview
        "
        [element]="element"
        (contentReturn)="onEmit($event)"
      ></app-preview-basic-fields>
    </div>
    <div
      *ngIf="!element.params.claimScreen && !element.params.isAPSPreview"
      class="document-preview-right"
    >
      <div
        *ngIf="
        element.params.basicFieldsPreview || element.params.profileVerificationPreview
        "
        class="detail-information-buttons"
      >
        <a
          id="verifyProfileDetails"
          class="verify-profile-details button"
          [attr.clickMethod]="
            helperService.isRPKey(element.params.requestKey)
              ? 'startProfileVerificationRP'
              : 'startProfileVerification'
          "
          [attr.requestKey]="element.params.requestKey"
          [ngClass]="{'disabled': disablePV()}"
          (click)="!disablePV()
          ? onEmit(elementService.OnButtonClick($event))
          : $event.preventDefault()"
          [tooltip]="getTooltip()"
        >
          <img
            class="preview-download-icon-loading"
            src="/assets/images/spinner-green.svg"
          />
          <img
            class="preview-download-icon"
            [src]="disablePV() ? '/assets/images/tasks-grey.svg' : '/assets/images/tasks-darker-grey.svg'"
          />
          <span>Verify profile details</span>
        </a>
      </div>
      <div class="preview-evidence-key">
        {{
          element.params.evidenceKey
            ? element.params.evidenceKey.replace(
                "EVD:",
                "evidencePreview.label" | translate
              )
            : ""
        }}
      </div>
      <div class="document-preview-tabs d-flex">
        <a
          *ngIf="
            element.params.fieldTypesDV && !element.params.isAPSpreviewEmpty
          "
          id="previewDetailsTab"
          class="ev-preview-tab details-tab"
          [ngClass]="element.params.fieldTypesDV ? 'active' : ''"
          (click)="elementService.changePreviewClass('previewDetailsTab')"
        >
          {{ "evidencePreview.detailsLabel" | translate }}
        </a>
        <a
          id="previewHistoryTab"
          class="ev-preview-tab history-tab"
          [ngClass]="
            !element.params.fieldTypesDV || element.params.isAPSpreviewEmpty
              ? 'active'
              : ''
          "
          (click)="elementService.changePreviewClass('previewHistoryTab')"
        >
          {{ "evidencePreview.historyLabel" | translate }}
        </a>
        <a
          *ngIf="
            element.params.hasAccessTab &&
            !element.params.basicFieldsPreview &&
            !element.params.profileVerificationPreview
          "
          id="previewAccessTab"
          class="ev-preview-tab access-tab"
          (click)="elementService.changePreviewClass('previewAccessTab')"
        >
          {{ "evidencePreview.accessLabel" | translate }}
        </a>
      </div>
      <div class="document-preview-tab-content">
        <div
          id="previewHistoryTabContent"
          [ngClass]="
            element.params.fieldTypesDV && !element.params.isAPSpreviewEmpty
              ? 'd-none'
              : ''
          "
          class="ev-preview-tab-content history-content"
        >
          <div *ngIf="element.params.historyEntries" class="preview-label">
            {{ "evidencePreview.historyLabel2" | translate }}
          </div>
          <div *ngIf="element.params.historyEntries">
            <div
              [ngClass]="historyEntry.class ? historyEntry.class : ''"
              class="history-entry"
              *ngFor="let historyEntry of element.params.historyEntries"
            >
              {{ historyEntry.text }}
              <div class="history-entry-dot history-entry-dot-slim">
                <div></div>
              </div>
            </div>
          </div>
          <div>
            <div
              *ngIf="
                element.params.purposesOfUse && !element.params.isReviewFlow
              "
              class="preview-label mt-0"
            >
              {{ "evidencePreview.historyLabel3" | translate }}
            </div>
            <div
              *ngIf="
                element.params.purposesOfUse && !element.params.isReviewFlow
              "
              class="purposes-container mt-0"
            >
              <div
                class="purpose-tag purpose-tag-no-close"
                purpose-tag
                *ngFor="let purpose of element.params.purposesOfUse"
              >
                {{ helperService.translatePurposesOfUse(purpose) }}
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            element.params.hasAccessTab &&
            !element.params.basicFieldsPreview &&
            !element.params.profileVerificationPreview
          "
          id="previewAccessTabContent"
          [ngClass]="element.params.hideActions ? 'access-not-interactive' : ''"
          class="ev-preview-tab-content access-content access-content-preview d-none"
        >
          <div
            *ngIf="
              element.params.accessInformation &&
              element.params.accessInformation.length > 0
            "
            class="access-labels"
          >
            <div class="label-fund">
              {{ "appSpecific.Profile" | translate }}
            </div>
            <div class="label-provider">
              {{ "appSpecific.ServiceProvider" | translate }}
            </div>
            <div class="label-granted-on text-center">
              {{ "evidencePreview.accessLabel3" | translate }}
            </div>
            <div class="label-granted-on text-center">
              {{ "appSpecific.Updates" | translate }}
            </div>
          </div>
          <div class="access-values-wrapper">
            <div
              class="document-preview-right-on-claim"
              *ngIf="
                element.params.accessInformation &&
                element.params.accessInformation.length === 0
              "
            >
              {{ "evidencePreview.accessLabel2" | translate }}
            </div>
            <div
              *ngFor="let acessInfo of element.params.accessInformation"
              class="access-values"
            >
              <div class="access-provider">
                <div class="label-fund">
                  <span>{{ acessInfo.fund }}</span>
                </div>
                <div class="content-fund">
                  {{
                    acessInfo.providerName.organizationName
                      ? acessInfo.providerName.organizationName
                      : acessInfo.providerName
                  }}
                </div>
                <div class="content-fund text-center">
                  {{ helperService.formatISODate(acessInfo.createdOn) }}
                </div>
                <div class="access-toggle text-right">
                  <label class="switch">
                    <input
                      type="checkbox"
                      [checked]="!acessInfo.revoked"
                      (click)="
                        elementService.OnToggleDocumentAccessClick(
                          acessInfo.providerId,
                          acessInfo.toglleActionEnabled,
                          $event,
                          acessInfo.accessKey,
                          element.params.evidenceKey,
                          element.params.requestKey
                        )
                      "
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <div style="margin-left: 100px">
                <div
                  *ngIf="acessInfo.purposesOfUse"
                  style="padding-bottom: 10px"
                  class="preview-label"
                >
                  {{ "evidencePreview.historyLabel3" | translate }}
                </div>
                <div class="purposes-container mt-0">
                  <div
                    class="purpose-tag purpose-tag-no-close"
                    purpose-tag
                    *ngFor="let purpose of acessInfo.purposesOfUse"
                  >
                    {{ helperService.translatePurposesOfUse(purpose) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="previewDetailsTabContent"
          [ngClass]="
            !element.params.fieldTypesDV || element.params.isAPSpreviewEmpty
              ? 'd-none'
              : ''
          "
          class="ev-preview-tab-content"
        >
          <!-- if there are basic fields of DV types we display them differently in a separate tab -->
          <app-preview-basic-fields
            *ngIf="
              element.params.fieldTypesDV && !element.params.isAPSpreviewEmpty
            "
            [element]="element"
            (contentReturn)="onEmit($event)"
          ></app-preview-basic-fields>
        </div>
      </div>
    </div>
    <div
      *ngIf="
        element.params.claimScreen === true && !element.params.isAPSPreview
      "
      class="document-preview-right-on-claim"
    >
      {{ "evidencePreview.historyOnClaim" | translate }}
    </div>
    <app-preview-window-aps
      class="w-100"
      [apsFields]="element.params.fieldTypes"
      [isFunds]="true"
      [historyEntries]="element.params.historyEntries"
      [olderComplianceCheck]="element.params.isMonitored == undefined"
      *ngIf="element.params.isAPSPreview"
    >
    </app-preview-window-aps>
  </div>
  <div
    *ngIf="
      !element.params.claimScreen &&
      !element.params.hideActions &&
      !element.params.basicFieldsPreview &&
      !element.params.profileVerificationPreview
    "
    class="document-preview-buttons"
  >
    <a
      *ngIf="
        !element.params.buttons &&
        !element.params.claimScreen &&
        !element.params.basicFieldsPreview &&
        !element.params.profileVerificationPreview &&
        !element.params.noDocumentsDisplay &&
        !element.params.noDocumentsDownload
      "
      id="evidencePreviewDownloadButton"
      class="download-loading"
      [attr.clickMethod]="'downloadPreviewFiles'"
      (click)="onEmit(elementService.OnButtonClick($event))"
    >
      <img
        class="preview-download-icon-loading"
        src="/assets/images/spinner-green.svg"
      />
      <img class="preview-download-icon" src="/assets/images/download.svg" />
      <span>{{ "evidencePreview.button" | translate }}</span>
    </a>
    <a
      *ngIf="
        !element.params.buttons &&
        !element.params.claimScreen &&
        !element.params.basicFieldsPreview &&
        !element.params.profileVerificationPreview &&
        !element.params.noDocumentsDisplay &&
        !element.params.noDocumentsDownload &&
        element.params.vtype !== 'Address' &&
        element.params.finalizeRequired
      "
      id="evidenceAcceptDocument"
      [attr.requestKey]="element.params.requestKey"
      class="download-loading"
      [attr.clickMethod]="'onAcceptEVDConfirm'"
      (click)="onAcceptEVDConfirm($event)"
    >
      <img
        style="pointer-events: none"
        class="preview-download-icon-loading"
        src="/assets/images/spinner-green.svg"
      />
      <img
        style="pointer-events: none"
        class="preview-download-icon"
        src="/assets/images/ico-accept.svg"
      />
      <span style="pointer-events: none">Pass</span>
    </a>
    <a
      *ngIf="
        !element.params.buttons &&
        !element.params.claimScreen &&
        !element.params.basicFieldsPreview &&
        !element.params.profileVerificationPreview &&
        !element.params.noDocumentsDisplay &&
        !element.params.noDocumentsDownload &&
        element.params.vtype !== 'Address' &&
        element.params.finalizeRequired
      "
      id="evidenceRejectDocument"
      class="download-loading"
      [attr.clickMethod]="'onRejectEVDConfirm'"
      (click)="onRejectEVDConfirm($event)"
      [attr.requestKey]="element.params.requestKey"
    >
      <img
        class="preview-download-icon-loading"
        src="/assets/images/spinner-green.svg"
        style="pointer-events: none"
      />
      <img
        style="pointer-events: none"
        class="preview-download-icon"
        src="/assets/images/ico-reject.svg"
      />
      <span style="pointer-events: none">Fail</span>
    </a>
    <a
      *ngIf="
        !element.params.profileVerificationPreview &&
        !element.params.buttons &&
        element.params.pdfDownloadButton
      "
      class="download-loading"
      [attr.clickMethod]="element.params.pdfDownloadFuction"
      (click)="onEmit(elementService.OnButtonClick($event))"
    >
      <img
        class="preview-download-icon-loading"
        src="/assets/images/spinner-green.svg"
      />
      <img class="preview-download-icon" src="/assets/images/download.svg" />
      <span>{{ "evidencePreview.button2" | translate }}</span>
    </a>
    <a
    class="download-loading"
    [attr.clickMethod]="'deleteEvidenceInPreviewPopUp'"
    (click)="onEmit(elementService.OnButtonClick($event))"
  >
    <img
      class="preview-download-icon-loading"
      src="/assets/images/spinner-green.svg"
    />
    <img class="preview-download-icon" src="/assets/images/delete-document-red.svg" />
  </a>
    <!-- EOF: !element.params.buttons -->
    <!-- element.params.buttons -->
    <div *ngFor="let button of element.params.buttons">
      <a
        *ngIf="!button.hideButton"
        [ngClass]="{ 'download-loading': button.showSpinner }"
        [attr.clickMethod]="button.action"
        (click)="onEmit(elementService.OnButtonClick($event))"
      >
        <img
          class="preview-download-icon-loading"
          src="/assets/images/spinner-green.svg"
        />
        <img class="preview-download-icon" src="{{ button.icon }}" />
        <span>{{ button.title }}</span>
      </a>
    </div>
    <!-- EOF: element.params.buttons -->
    <a
      *ngIf="
        !element.params.claimScreen &&
        !element.params.basicFieldsPreview &&
        !element.params.profileVerificationPreview &&
        element.params.documentImages &&
        element.params.documentImages.length
      "
      [attr.clickMethod]="'downloadPreviewFiles'"
      (click)="onEmit(elementService.OnButtonClick($event))"
    >
      <img class="preview-download-icon" src="/assets/images/download.svg" />
      <span>{{ "evidencePreview.button3" | translate }}</span>
    </a>

    <a
      *ngIf="
        !element.params.isDigitalVerification &&
        element.params.isReviewFlow &&
        element.params.incomplete
      "
      id="downloadButtonPreview"
      (click)="
        elementService.startEditFlowIncomplete(
          element.params.evidenceTypes,
          element.params.fieldValues,
          element.params.requestKey,
          element.params.evidenceKey,
          element.params.evidenceType,
          element.params.evidence.record.documents
        )
      "
    >
      <img class="preview-updatge-icon" src="/assets/images/update.svg" />
      <span>
        <span>{{ "evidencePreview.button4" | translate }}</span>
      </span>
    </a>

    <a
      *ngIf="
        !element.params.isDigitalVerification &&
        element.params.isReviewFlow &&
        element.params.incomplete
      "
      id="downloadButtonPreview"
      (click)="
        elementService.revertEvidence(
          element.params.requestKey,
          element.params.evidenceKey,
          element.params.documentKeys
        )
      "
    >
      <img class="preview-replace-icon" src="/assets/images/replace.svg" />
      <span>
        <span>{{ "evidencePreview.button5" | translate }}</span>
      </span>
    </a>

    <a
      *ngIf="
        !element.params.isDigitalVerification &&
        element.params.isReviewFlow &&
        !element.params.expired &&
        !element.params.basicFieldsPreview &&
        !element.params.profileVerificationPreview &&
        !element.params.incomplete
      "
      id="downloadButtonPreview"
      (click)="elementService.showExpireWindow()"
    >
      <img class="preview-expired-icon" src="/assets/images/expired.svg" />
      <span>
        {{ "evidencePreview.button6" | translate }}
      </span>
    </a>

    <a
      *ngIf="
        !element.params.isDigitalVerification &&
        element.params.isReviewFlow &&
        !element.params.incomplete
      "
      id="downloadButtonPreview"
      (click)="
        elementService.startEditFlow(
          element.params.evidenceTypes,
          element.params.fieldValues,
          element.params.requestKey,
          element.params.evidenceKey,
          element.params.evidenceType,
          element.params.requestKey
        )
      "
    >
      <img class="preview-replace-icon" src="/assets/images/replace.svg" />
      <span>
        {{ "evidencePreview.button7" | translate }}
      </span>
    </a>

    <a
      *ngIf="
        !element.params.profileVerificationPreview &&
        !element.params.isDigitalVerification &&
        !element.params.dontShowUpdate &&
        !element.params.subjectIsOwner
      "
      (click)="
        elementService.documentUpdateFunction(
          $event,
          element.params.evidenceTypes,
          element.params.evidence,
          element.params.requestKey,
          element.params.evidenceKey
        )
      "
    >
      <img class="preview-update-icon" src="/assets/images/update.svg" />
      <span>
        {{ "evidencePreview.button8" | translate }}
      </span>
    </a>
    <a
      *ngIf="
        element.params.showVersions &&
        !element.params.incomplete &&
        !element.params.hideActions
      "
      id="downloadButtonPreview"
      (click)="elementService.showVersionLightbox($event)"
    >
      <img class="preview-related-icon" src="/assets/images/related.png" />
      <span>
        {{
          element.params.vtype === "Contract"
            ? ("evidencePreview.versionLabelContracts" | translate)
            : element.params.vtype === "APS"
            ? ("evidencePreview.versionLabelAPS" | translate)
            : ("evidencePreview.versionLabel" | translate)
        }}
      </span>
    </a>
    <a
      *ngIf="
        element.params.isSP &&
        !element.params.incomplete &&
        element.params.vtype === 'Identity' &&
        element.params.evidence.status !== 'In progress' &&
        element.params.evidence.status === 'Completed'
      "
      id="rejectDViDV"
      [attr.clickMethod]="'onRejectIDVDV'"
      (click)="onEmit(elementService.OnButtonClick($event))"
    >
      <img class="preview-related-icon" src="/assets/images/ico-reject.svg" />
      <span> Reject </span>
    </a>
    <a
      *ngIf="
        element.params.isSP &&
        !element.params.incomplete &&
        element.params.vtype === 'Identity' &&
        element.params.evidence.status !== 'In progress' &&
        element.params.evidence.status !== 'Completed'
      "
      id="acceptDViDV"
      [attr.clickMethod]="'onAcceptIDVDV'"
      (click)="onEmit(elementService.OnButtonClick($event))"
    >
      <img class="preview-related-icon" src="/assets/images/ico-accept.svg" />
      <span> Accept </span>
    </a>
  </div>
  <!-- PREVIEW BUTTON CONTAINER FOR BASIC FIELDS -->
  <div
    *ngIf="
      !element.params.claimScreen &&
      !element.params.hideActions &&
      element.params.basicFieldsPreview &&
      element.params.status !== 'IN_PROGRESS'
    "
    class="document-preview-buttons"
  >
    <a
      *ngIf="
        !element.params.isDigitalVerification &&
        element.params.isReviewFlow &&
        !element.params.incomplete &&
        element.params?.latestVersionKey === element.params.currentEvidenceKey
      "
      id="downloadButtonPreview"
      (click)="
        elementService.startEditFlow(
          element.params.evidenceTypes,
          element.params.fieldValues,
          element.params.requestKey,
          element.params.evidenceKey,
          element.params.evidenceType,
          true
        )
      "
    >
      <img class="preview-replace-icon" src="/assets/images/update.svg" />
      <span>
        {{ "common.update" | translate }}
      </span>
    </a>
    <a
      *ngIf="
        element.params.showVersions &&
        !element.params.incomplete &&
        !element.params.hideActions
      "
      id="downloadButtonPreview"
      (click)="elementService.showVersionLightbox($event)"
    >
      <img class="preview-related-icon" src="/assets/images/related.png" />
      <span>
        {{ "evidencePreview.versionLabel" | translate }}
      </span>
    </a>
    <a
      *ngIf="
        !element.params.isDigitalVerification &&
        element.params.isReviewFlow &&
        element.params.incomplete
      "
      id="downloadButtonPreview"
      (click)="
        elementService.revertEvidence(
          element.params.requestKey,
          element.params.evidenceKey,
          element.params.documentKeys
        )
      "
    >
      <img class="preview-replace-icon" src="/assets/images/replace.svg" />
      <span>
        <span>{{ "evidencePreview.button5" | translate }}</span>
      </span>
    </a>
    <a
      *ngIf="
        !element.params.isDigitalVerification &&
        element.params.isReviewFlow &&
        element.params.incomplete
      "
      id="downloadButtonPreview"
      (click)="
        elementService.startEditFlowIncomplete(
          element.params.evidenceTypes,
          element.params.fieldValues,
          element.params.requestKey,
          element.params.evidenceKey,
          element.params.evidenceType,
          element.params.evidence.record.documents,
          true
        )
      "
    >
      <img class="preview-updatge-icon" src="/assets/images/update.svg" />
      <span>
        <span>{{ "evidencePreview.button4" | translate }}</span>
      </span>
    </a>
  </div>
  <div
    *ngIf="element.params.showVersions"
    id="versionLightbox"
    class="version-lightbox-wrapper"
    [ngClass]="element.params.showVersionsLightbox ? '' : 'd-none'"
  >
    <div class="version-lightbox">
      <div class="version-lighbtox-title d-flex justify-content-between">
        <div>
          {{
            element.params.vtype === "Contract"
              ? ("evidencePreview.versionLabelContracts" | translate)
              : element.params.vtype === "APS"
              ? ("evidencePreview.versionLabelAPS" | translate)
              : ("evidencePreview.versionLabel" | translate)
          }}
        </div>
        <a
          (click)="elementService.closeVersionLightbox($event)"
          class="evidence-versions-close"
        ></a>
      </div>
      <div
        class="version-lightbox-contet d-flex flex-column justify-content-center align-items-center pt-2"
      >
        <!-- NEWER -->
        <div
          *ngFor="
            let newerVersion of element.params.versionInfo.newer | reverse
          "
          class="access-values py-2"
          [ngClass]="
            newerVersion.record.access && newerVersion.record.access.inUse
              ? 'in-use'
              : ''
          "
          (click)="
            elementService.previewVersion(
              element.params.evidenceKey,
              newerVersion.key,
              element.params.evidence,
              element.params.evidenceTypes,
              newerVersion.record.documents,
              element.params.requestKey
            )
          "
        >
          <div class="label-fund">
            {{ helperService.formatISODate(newerVersion.record.createOn) }}
          </div>
          <div class="label-granted-on">
            {{
              newerVersion.label
                ? newerVersion.label
                : element.params.vtype === "APS"
                ? ("evidencePreview.complianceCheckResolved" | translate)
                : ("evidencePreview.expiredLabel2" | translate)
            }}
          </div>
          <div
            class="use-subtitle"
            *ngIf="
              newerVersion.record.access && newerVersion.record.access.inUse
            "
          >
            {{
              element.params.vtype === "Contract"
                ? ("evidencePreview.documentInUseContract" | translate)
                : element.params.vtype === "APS"
                ? ("evidencePreview.documentInUseAPS" | translate)
                : ("evidencePreview.documentInUse" | translate)
            }}
          </div>
        </div>
        <!-- ACTIVE -->
        <div class="access-values position-relative py-2 active-version">
          <div class="label-fund">
            {{
              helperService.formatISODate(
                element.params.versionInfo.current.record.createOn
              )
            }}
          </div>
          <div class="label-granted-on">
            {{
              element.params.versionInfo.current.record.access &&
              element.params.versionInfo.current.record.access.inUse &&
              !(
                element.params.latestVersionKey &&
                element.params.latestVersionKey !==
                  element.params.currentEvidenceKey
              )
                ? element.params.vtype === "Contract"
                  ? ("evidencePreview.documentLatest" | translate)
                  : element.params.vtype === "APS"
                  ? ("legalStatus.ACTIVE" | translate)
                  : ("evidencePreview.documentInUseShort" | translate)
                : element.params.latestVersionKey &&
                  element.params.latestVersionKey !==
                    element.params.currentEvidenceKey
                ? element.params.vtype === "Contract"
                  ? ""
                  : element.params.vtype === "APS"
                  ? ("evidencePreview.complianceCheckResolved" | translate)
                  : ("evidencePreview.expiredLabel2" | translate)
                : ("evidencePreview.documentLatest" | translate)
            }}
          </div>
          <div
            class="use-subtitle"
            *ngIf="
              element.params.versionInfo.current.record.access &&
              element.params.versionInfo.current.record.access.inUse
            "
          >
            {{
              element.params.vtype === "Contract"
                ? ("evidencePreview.documentInUseContract" | translate)
                : element.params.vtype === "APS"
                ? ("evidencePreview.documentInUseAPS" | translate)
                : ("evidencePreview.documentInUse" | translate)
            }}
          </div>
          <div
            *ngIf="
              element.params.versionInfo.current.record.access &&
              element.params.versionInfo.current.record.access.inUse &&
              element.params.latestVersionKey &&
              element.params.latestVersionKey !==
                element.params.currentEvidenceKey
            "
            class="version-warning"
          >
            <img src="assets/images/icon-triangle-orange.svg" />
          </div>
        </div>
        <!-- OLDER -->
        <div
          *ngFor="
            let olderVersions of element.params.versionInfo.previous | reverse
          "
          class="access-values position-relative py-2"
          (click)="
            elementService.previewVersion(
              element.params.evidenceKey,
              olderVersions.key,
              olderVersions.record,
              element.params.evidenceTypes,
              olderVersions.record.documents,
              element.params.requestKey
            )
          "
        >
          <div class="label-fund">
            {{ helperService.formatISODate(olderVersions.record.createOn) }}
          </div>
          <div
            *ngIf="element.params.vtype !== 'Contract'"
            class="label-granted-on"
          >
            {{
              element.params.vtype === "APS"
                ? ("evidencePreview.complianceCheckResolved" | translate)
                : ("evidencePreview.expiredLabel2" | translate)
            }}
          </div>
          <div
            class="use-subtitle"
            *ngIf="
              olderVersions.record.access && olderVersions.record.access.inUse
            "
          >
            {{
              element.params.vtype === "Contract"
                ? ("evidencePreview.documentInUseContract" | translate)
                : element.params.vtype === "APS"
                ? ("evidencePreview.documentInUseAPS" | translate)
                : ("evidencePreview.documentInUse" | translate)
            }}
          </div>
          <div
            class="version-warning"
            *ngIf="
              olderVersions.record.access && olderVersions.record.access.inUse
            "
          >
            <img src="assets/images/icon-triangle-orange.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="expire-lightbox d-none form-container">
    <div
      class="form-content-container"
      [ngClass]="{
        turnOffMonitoringAPS: reasonDialogueType === 'turnOffMonitoringAPS'
      }"
    >
      <h1 class="form-type-heading position-relative">
        <div *ngIf="reasonDialogueType === 'expiry'">
          {{ "evidencePreview.button6" | translate }}
        </div>
        <div *ngIf="reasonDialogueType === 'turnOffMonitoringAPS'">
          {{ "evidencePreview.areYouSure" | translate }}
        </div>
        <div *ngIf="reasonDialogueType == 'deleteEvidencePreview'">
          {{ "evidencePreview.deleteEvidence" | translate }}
        </div>
        <div
          *ngIf="
            reasonDialogueType !== 'expiry' &&
            reasonDialogueType != 'turnOffMonitoringAPS' && 
            reasonDialogueType != 'deleteEvidencePreview'
          "
        >
          {{ "evidencePreview.expiredLabel5" | translate }}
        </div>
        <a (click)="elementService.closeExpireWindow()" class="close-button">
          <img src="assets/images/close_form_icon.png" />
        </a>
      </h1>

      <div class="position-relative dtzc">
        <div class="loading-icon d-none">
          <img
            src="/assets/images/spinner-green.svg"
            width="20px"
            height="20px"
            class="my-auto mx-auto"
          />
        </div>
        <div *ngIf="reasonDialogueType != 'turnOffMonitoringAPS' && reasonDialogueType != 'deleteEvidencePreview'">
          <span
            *ngIf="reasonDialogueType === 'expiry'"
            class="input-label font-weight-bold"
          >
            {{ "evidencePreview.expiredLabel3" | translate }}
          </span>
          <span
            *ngIf="reasonDialogueType !== 'expiry'"
            class="input-label font-weight-bold"
          >
            {{ "evidencePreview.expiredLabel4" | translate }}
          </span>
          <input
            [attr.isRequired]="
              reasonDialogueType.reason &&
              reasonDialogueType.reason == 'whitelisting'
                ? false
                : true
            "
            [attr.isMaxLength]="500"
            id="actionReason"
            type="text"
            class="text-input"
            (input)="uiService.checkAndClearInvalidation($event)"
            (keydown.enter)="$event.preventDefault()"
            (blur)="elementService.OnInputBlur($event)"
            *ngIf="
              !(
                reasonDialogueType.payload &&
                reasonDialogueType.payload.isWhitelisted
              )
            "
          />
          <div
            class="error-field"
            *ngIf="
              !(
                reasonDialogueType.payload &&
                reasonDialogueType.payload.isWhitelisted
              )
            "
            [attr.validatorMessage]="'*this field is required'"
            [attr.validatorMessageField]="'*this field is required'"
          ></div>

          <app-autocomplete-input
            [id]="'actionReason'"
            [isMaxLength]="500"
            *ngIf="
              reasonDialogueType.reason != undefined &&
              reasonDialogueType.payload != undefined &&
              reasonDialogueType.reason == 'whitelisting' &&
              reasonDialogueType.payload.isWhitelisted
            "
            [options]="commentsOnWhitelistingHit"
          >
          </app-autocomplete-input>
        </div>
        <div *ngIf="reasonDialogueType === 'turnOffMonitoringAPS'" class="my-4">
          <h4 class="font-weight-bold" style="color: #767676">
            You are about to turn off monitoring for this compliance check
          </h4>
          <p class="result-paragraph">
            You will not be alerted to any new hits that may arise for this
            search term. Should you need to switch on monitoring again in the
            future, you will need to perform a new compliance check. This will
            also mean that you will need to review all hits again and mark those
            for which you do not want to be alerted about new information as not
            relevant.
          </p>
        </div>

        <div *ngIf="reasonDialogueType == 'deleteEvidencePreview'" class="my-4">
          <p class="result-paragraph">
            You are about to delete this evidence. Are you sure that you want to continue?
          </p>
        </div>

        <button
          *ngIf="reasonDialogueType === 'expiry'"
          type="submit"
          class="form-button form-button-1 mt-4"
          (click)="
            elementService.expireDocument(
              $event,
              element.params.evidenceTypes,
              element.params.fieldValues,
              element.params.requestKey,
              element.params.evidenceKey,
              element.params.documentKeys
            )
          "
        >
          {{ "evidencePreview.button6" | translate }}
        </button>
        <button
          *ngIf="reasonDialogueType === 'acceptAPS'"
          type="submit"
          class="form-button form-button-1 mt-4"
          [attr.clickMethod]="'onAcceptDvConfirm'"
          (click)="onEmit(elementService.OnButtonClick($event))"
        >
          {{ "evidencePreview.button9" | translate }}
        </button>
        <button
          *ngIf="
            reasonDialogueType.reason != undefined &&
            reasonDialogueType.payload != undefined &&
            reasonDialogueType.reason == 'whitelisting'
          "
          type="submit"
          class="form-button form-button-1 mt-4"
          (click)="
            idVerificationService.whitelistHit(
              reasonDialogueType.payload,
              reasonDialogueType.index,
              element.params.requestKey
            )
          "
        >
          {{ "common.continue" | translate }}
        </button>
        <button
          *ngIf="
            reasonDialogueType.reason != undefined &&
            reasonDialogueType.reason == 'commentHit'
          "
          type="submit"
          class="form-button form-button-1 mt-4"
          (click)="
            idVerificationService.commentHit(
              reasonDialogueType.index,
              element.params.requestKey
            )
          "
        >
          {{ "evidencePreview.expiredLabel5" | translate }}
        </button>
        <button
          *ngIf="reasonDialogueType === 'rejectAPS'"
          type="submit"
          class="form-button form-button-1 mt-4"
          [attr.clickMethod]="'onRejectDvConfirm'"
          (click)="onEmit(elementService.OnButtonClick($event))"
        >
          {{ "evidencePreview.button10" | translate }}
        </button>
        <button
          *ngIf="reasonDialogueType === 'rejectIDVDV'"
          type="submit"
          class="form-button form-button-1 mt-4"
          [attr.clickMethod]="'onRejectIDVDvConfirm'"
          (click)="onEmit(elementService.OnButtonClick($event))"
        >
          Reject
        </button>
        <button
          *ngIf="reasonDialogueType === 'acceptIDVDV'"
          type="submit"
          class="form-button form-button-1 mt-4"
          [attr.clickMethod]="'onAcceptIDVDvConfirm'"
          (click)="onEmit(elementService.OnButtonClick($event))"
        >
          Accept
        </button>

        <button
          *ngIf="reasonDialogueType === 'turnOffMonitoringAPS'"
          type="submit"
          class="form-button form-button-1 form-button-red mt-4"
          [attr.clickMethod]="'onTurnOffMonitoring'"
          (click)="onEmit(elementService.OnButtonClick($event))"
        >
          Turn off monitoring
        </button>   
        <button
          *ngIf="reasonDialogueType == 'deleteEvidencePreview'"
          type="submit"
          class="form-button form-button-1 form-button-red mt-4"
          [attr.clickMethod]="'onDeleteEvidenceInPreview'"
          [attr.evidenceKey]="element.params.evidenceKey"
          [attr.requestKey] = "element.params.requestKey"
          (click)="onEmit(elementService.OnButtonClick($event))"
        >
          Yes, delete this evidence
        </button>

        <button
          *ngIf="reasonDialogueType === 'turnOffMonitoringAPS' || reasonDialogueType == 'deleteEvidencePreview'"
          type="submit"
          class="form-button form-button-link-2 mt-4"
          (click)="elementService.closeExpireWindow()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
