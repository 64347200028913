// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environment: 'dev',
  APIEndpoint: '/',
  corsAnywhere: 'https://cors-anywhere.herokuapp.com',
  // corsAnywhere: 'http://0.0.0.0:8080' || 'https://cors-anywhere.herokuapp.com',
  // APIEndpoint: 'https://virtserver.swaggerhub.com/SingleTruth/singletruth/1.0.3/',
  // APIEndpoint: 'https://dev.meshid.app/',

  auth: {
    domain: 'finos-dev.eu.auth0.com',
    client_id: 'uZ4vxjT8SJkZz9lgXWI_c9wAnd551jBG',
    redirect_uri: `${window.location.origin}`,
    audience: 'https://prod.finos.app/api',
    scopes: 'openid profile email'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
