const checkboxComponent = Formio.Components.components.radio;
import CheckboxComponentForm from "./checkbox.form";
import { HelperServiceService } from "src/app/helper-service.service";

class CheckboxExtendedComponent extends checkboxComponent {
  static schema(...extend) {
    return super.schema(
      {
        type: "CheckboxExtendedComponent",
        label: "",
        any_other_fields: "",
      },
      ...extend,
    );
  }

  static get builderInfo() {
    return {
      // title: 'Name in the Builder',
      title: "Checkbox(s)",
      group: "customBasic",
      icon: "check-square",
      weight: 6,
      schema: this.schema(),
    };
  }

  render() {
    const submission = _.get(this.root, "submission", {});
    const checkboxes = this.component.values;
    const mapped = checkboxes.map((checkbox) => {
      const value = HelperServiceService.generateRandomString(5);
      return `
        <div class="questionnaire-check-container form-check mt-2">
          <label for="${value}" class="ml-3 form-check-label label-position-right">
            <input id="${value}" type="checkbox" name="data[checkbox]" form-check-input />
            <div class="d-inline">${checkbox.label} 
              <span class="weight-count" style="margin-left: 12px">(${
                checkbox.answerWeight || checkbox.answerWeight === 0
                  ? checkbox.answerWeight
                  : ""
              } points)</span>
            </div>
            <span class="checkmark"></span>
          </label>
        </div>`;
    });
    this.tpl = `
        <div ref='refExtCheckbox'>
          <div class="col-form-label">${this.component.label}</div>
          <div class="formio-positonal-wrapper ${
            this.component.optionsLabelPosition
          }">
            ${mapped.join("")}
          </div>
        </div>
      `;
    // Note the use of the 'ref' tag, which is used later to find
    // parts of your rendered element.

    // If you need to render the superclass,
    // you can do that with
    // tpl+=super.render(element);

    // This wraps your template to give it the standard label and bulider decorations
    return Formio.Components.components.component.prototype.render.call(
      this,
      this.tpl,
    );
  }

  attach(element) {
    // This code is called after rendering, when the DOM has been created.
    // You can find your elements above like this:
    this.loadRefs(element, { refExtCheckbox: "single" });

    // var superattach = super.attach(element);
    //  // Here do whatever you need to attach event handlers to the dom.
    // this.refs.refExtCheckbox.on('click',()=>{alert("hi!");});

    // return superattach;

    this.addEventListener(this.refs.customRef, "click", () => {
      console.log("Custom Ref has been clicked!!!");
    });
    return super.attach(element);
  }

  getvalue() {
    return 3; // the value this element puts into the form
  }
  // OR, depending on which component type you're basing on:
  getValueAt(index, value, flags) {}

  setValue(value) {
    // modify your DOM here to reflect that the value should be 'value'.
  }
  // OR, depending on what component type:
  getValueAt(index) {}
}

// This sets the form that pops up in the Builder when you create
// one of these.  It is basically just a standard form, and you
// can look at the contents of this in the debugger.
// RadioExtendedComponent.editForm = checkboxComponent.editForm;
CheckboxExtendedComponent.editForm = CheckboxComponentForm;

// Register your component to Formio
Formio.Components.addComponent(
  "CheckboxExtendedComponent",
  CheckboxExtendedComponent,
);
export default CheckboxExtendedComponent;
