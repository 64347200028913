<div
  [ngClass]="{
    'document-fields-basicFields': element.params.basicFieldsPreview ||  element.params.profileVerificationPreview
  }"
  class="document-fields"
>
  <div
    *ngFor="let field of element.params.fieldTypes"
    [ngClass]="{
      'title-field': field.type === 'title',
      'd-none': !(
        field.type === 'title' ||
        (field.type !== 'title' && field.value)
      ),
      'd-none': field.type === 'title' && field.showTitle === false,
      'w-0': field.type !== 'title' && field.value === undefined
    }"
    class="document-field {{ field.titleClass }}"
  >
    <div
      *ngIf="field.type === 'title' || (field.type !== 'title' && field.value)"
      class="document-field-label"
    >
      {{ field.label }}
        
        <app-tooltip
        *ngIf="field.profileVerification"
        [tooltipText]="field.profileVerificationTooltip"
        [icon]="field.profileVerificationIcon"
        [iconWidth] = "'18'"
        [iconHeight] = "'18'"
       ></app-tooltip>
       <img 
       *ngIf="field.icon" 
       [src]="field.icon"
       [style.vertical-align]="'top'"
       width = "18"
       height = "18"/>
    </div>
    <div 
    *ngIf="field.value" 
    class="document-field-value"
    [style.color]="field.valueColor? field.valueColor :''">
      {{ field.value }}
    </div>
    <div
      *ngIf="
        element.params.fieldsSource &&
        element.params.fieldsSource.length &&
        element.params.fieldsSource[0].fieldNames.includes(field.name) &&
        element.params.fieldsSource[0].lastUpdate
      "
      class="document-additional-info"
    >
      last updated on
      {{
        helper.getDateFromTimeStampAdditional(
          element.params.fieldsSource[0].lastUpdate
        )
      }}
    </div>
  </div>
</div>
