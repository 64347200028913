import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { UserService } from "../user.service";


// in navigation guard
@Injectable({
    providedIn: "root",
  })
export class NavigationGuard implements CanDeactivate<any> {
  constructor(private userService: UserService) {}
  canDeactivate(component: any) {
    // will prevent user from going back
    if (this.userService.getBackClicked()) {
      this.userService.setBackClicked(false);
      // push current state again to prevent further attempts.
      history.pushState(null, null, location.href);
      return false;
    }
    return true;
  }
}