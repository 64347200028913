<div *ngIf="element.isEmpty && !element.investorSide">
  <div
    (click)="addRelatedParty()"
    [attr.data-evidenceKey]=""
    class="document-inner-entry no-documents mt-1"
  >
    <div style="background-color: #adadad" class="document-upload-entry-image">
      +
    </div>
    <div class="document-upload-entry-detail">
      <h5 class="document-name">Create related profile</h5>
    </div>
  </div>
</div>
