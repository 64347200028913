import { animate, style, transition, trigger } from "@angular/animations";
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { HelperServiceService } from "src/app/helper-service.service";

const enterTransition = transition(":enter", [
  style({
    opacity: 0,
  }),
  animate(
    "500ms ease-in",
    style({
      opacity: 1,
    }),
  ),
]);

const leaveTrans = transition(":leave", [
  style({
    opacity: 1,
  }),
  animate(
    "500ms ease-out",
    style({
      opacity: 0,
    }),
  ),
]);

const fadeIn = trigger("fadeIn", [enterTransition]);

const fadeOut = trigger("fadeOut", [leaveTrans]);

@Component({
  selector: "app-language-switcher",
  templateUrl: "./language-switcher.component.html",
  styleUrls: ["./language-switcher.component.scss"],
  animations: [fadeIn, fadeOut],
})
export class LanguageSwitcherComponent implements OnInit {
  @ViewChild("languageSwitcher") languageSwitcher: ElementRef;
  currentLang: string;
  showLanguages = false;

  constructor(
    private helperService: HelperServiceService,
    public translate: TranslateService,
  ) {
    this.currentLang = this.translate.currentLang;
  }

  @HostListener("window:click", ["$event"])
  onClick(event) {
    if (
      this.showLanguages &&
      !this.languageSwitcher.nativeElement.contains(event.target)
    ) {
      this.showLanguages = false;
    }
  }

  ngOnInit(): void {}

  changeLanguage(language: string) {
    this.currentLang = language;
    this.helperService.changeLanguage(language);
    this.helperService.reloadLocation(true);
  }

  showLanguageList() {
    this.showLanguages = !this.showLanguages;
  }
}
