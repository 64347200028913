<form *ngIf="element.isActive" class="form-container" autocomplete="chrome-off">
  <div class="document-upload-side-overlay">
    <img
      src="/assets/images/spinner-green.svg"
      width="65px"
      height="65px"
      class="my-auto mx-auto"
    />
  </div>
  <div class="mt-2 input-label-large">
    <!--ALTERNATE  SUBTITLE USED IN NEW FORMS-->
    <h4>Address to be verified</h4>
  </div>
  <div class="element-wrapper">
    <div class="input-fields-wrapper">
      <!-- INPUT FIELDS -->
      <div class="evidence-fields">
        <div class="element-wrapper font-weight-bold">
          <!-- Radio Group -->
          <span>
            <input
              [attr.changeMethod]="'addressVerificationTypeChange'"
              (change)="onEmit(elementService.OnChangeSelect($event))"
              class="custom-radio-button"
              type="radio"
              [attr.checked]="true"
              [id]="'idAddressVerificationInvestor'"
              [name]="'idAddressVerificationRadio'"
              [value]="'investorAddressVerificaiton'"
            />
            <label
              class="custom-radio-label"
              for="idAddressVerificationInvestor"
              >The person will provide the address</label
            >
          </span>
        </div>
      </div>
      <div class="evidence-fields">
        <div class="element-wrapper font-weight-bold">
          <!-- Radio Group -->
          <span>
            <input
              [attr.changeMethod]="'addressVerificationTypeChange'"
              (change)="onEmit(elementService.OnChangeSelect($event))"
              class="custom-radio-button"
              type="radio"
              [attr.checked]="undefined"
              [id]="'idAddressVerificationManual'"
              [name]="'idAddressVerificationRadio'"
              [value]="'manualAddressVerificaiton'"
            />
            <label class="custom-radio-label" for="idAddressVerificationManual"
              >I will provide the address</label
            >
          </span>
        </div>
      </div>
      <div class="evidence-fields">
        <div
          class="element-wrapper addressVerificationManualField ml-4"
          [ngClass]="manualAddress ? '' : 'd-none'"
        >
          <div id="mapImage" [ngClass]="!map ? '' : 'display-none'"></div>
          <div #mapWrapper id="map" [ngClass]="map ? '' : 'display-none'"></div>
          <div class="mapInfo" [ngClass]="map ? '' : 'display-none'">
            {{ "review.validation2" | translate }}
          </div>
          <span class="input-label {{ element.params.labelClass }}"
            >Search for an address<sup class="sup-required">*</sup></span
          >
          <input
            type="text"
            class="text-input google-autocomplete"
            #gautocomplete
            name="{{ element.params.name }}"
            value="{{ addressData && addressData.Address }}"
            [attr.isValid]="true"
            [attr.isRequired]="false"
            [attr.isMinLength]="element.params.minLength"
            [attr.isMaxLength]="element.params.maxLength"
            [attr.patternMatch]="element.params.patternMatch"
            [attr.enterkeyaction]="element.params.onenterkeyaction"
            autocomplete="chrome-off"
            (focus)="OnInitGoogleAutocomplete($event)"
            (keyup)="onEmit(elementService.OnInputKeyUp($event))"
            (blur)="onEmit(elementService.OnInputBlur($event))"
          />
          <div
            class="error-field"
            [attr.validatorMessage]="element.params.validatorMessage"
            [attr.validatorMessageField]="element.params.validatorMessage"
          ></div>
        </div>
      </div>
      <div class="evidence-fields">
        <div
          class="element-wrapper checkboxUseClassicAddress addressVerificationManualField ml-4"
          [ngClass]="manualAddress ? '' : 'd-none'"
        >
          <input
            class="custom-checkbox"
            type="checkbox"
            id="useClassicAddress"
            name="useClassicAddress"
            value="useClassicAddress"
            [attr.clickaction]="useClassicAddressToggle"
            (click)="useClassicAddressToggle($event)"
            [attr.changeMethod]="useClassicAddressToggle"
          />
          <!-- (change)="OnChangeSelect($event)" -->
          <label class="custom-checkbox-label" for="useClassicAddress"
            >Manually enter address</label
          >
        </div>
      </div>
      <div class="evidence-fields">
        <div class="element-wrapper manualInput d-none ml-4">
          <span class="input-label font-weight-bold"
            >Street<sup class="sup-required">*</sup></span
          >
          <!-- Input Text -->
          <input
            type="text"
            value="{{ parseAdressDataStreet(addressData) }}"
            class="text-input"
            id="AddressInputField"
            name="AddressInputField"
            [attr.isValid]="true"
            [attr.isRequired]="false"
            (keyup)="onEmit(elementService.OnInputKeyUp($event))"
            (blur)="onEmit(elementService.OnInputBlur($event))"
          />
          <div
            class="error-field"
            [attr.validatorMessage]="'Field is required'"
            [attr.validatorMessageField]="'Field is required'"
          ></div>
        </div>
      </div>
      <div class="evidence-fields">
        <div class="element-wrapper manualInput d-none ml-4">
          <span class="input-label font-weight-bold">Number</span>
          <!-- Input Text -->
          <input
            type="text"
            value="{{ addressData && addressData.Number }}"
            class="text-input"
            id="NumberInputField"
            name="NumberInputField"
            [attr.isValid]="true"
            [attr.isRequired]="false"
            (keyup)="onEmit(elementService.OnInputKeyUp($event))"
            (blur)="onEmit(elementService.OnInputBlur($event))"
          />
          <div
            class="error-field"
            [attr.validatorMessage]="'Field is required'"
            [attr.validatorMessageField]="'Field is required'"
          ></div>
        </div>
      </div>
      <div class="evidence-fields">
        <div class="element-wrapper manualInput d-none ml-4">
          <span class="input-label font-weight-bold"
            >Zip code<sup class="sup-required">*</sup></span
          >
          <!-- Input Text -->
          <input
            type="text"
            value="{{ addressData && addressData.ZipCode }}"
            class="text-input"
            id="ZipInputField"
            name="ZipInputField"
            [attr.isValid]="true"
            [attr.isRequired]="false"
            (keyup)="onEmit(elementService.OnInputKeyUp($event))"
            (blur)="onEmit(elementService.OnInputBlur($event))"
          />
          <div
            class="error-field"
            [attr.validatorMessage]="'Field is required'"
            [attr.validatorMessageField]="'Field is required'"
          ></div>
        </div>
      </div>
      <div class="evidence-fields">
        <div class="element-wrapper manualInput d-none ml-4">
          <span class="input-label font-weight-bold"
            >City<sup class="sup-required">*</sup></span
          >
          <!-- Input Text -->
          <input
            value="{{ addressData && addressData.City }}"
            type="text"
            class="text-input"
            id="CityInputField"
            name="CityInputField"
            [attr.isValid]="true"
            [attr.isRequired]="false"
            (keyup)="onEmit(elementService.OnInputKeyUp($event))"
            (blur)="onEmit(elementService.OnInputBlur($event))"
          />
          <div
            class="error-field"
            [attr.validatorMessage]="'Field is required'"
            [attr.validatorMessageField]="'Field is required'"
          ></div>
        </div>
      </div>
      <div class="evidence-fields">
        <div class="element-wrapper manualInput d-none input-transparent ml-4">
          <span class="input-label font-weight-bold"
            >Country<sup class="sup-required">*</sup></span
          >

          <span class="select-wrapper">
            <select
              class="select-input"
              id="CountryInputField"
              (change)="onEmit(elementService.OnChangeSelect($event))"
              name="CountryInputFieldN"
              [attr.isRequired]="false"
              [attr.isValid]="true"
              [value]="addressData && addressData.Country"
            >
              <option
                value="{{ option.value }}"
                *ngFor="let option of element.params.countryListAv"
              >
                {{ option.label }}
              </option>
            </select>
          </span>
          <div
            class="error-field"
            [attr.validatorMessage]="'Field is required'"
            [attr.validatorMessageField]="'Field is required'"
          ></div>
        </div>
      </div>
      <!-- <div class="evidence-fields">
        <div class="element-wrapper" [ngClass]="(element.class) ? element.class : ''" *ngSwitchCase="'inputHidden'">
          <!-- Input Hidden --
          <input
              type="hidden"
              class="text-input"
              id="LocationInputField"
              name="{{element.params.name}}"
              value='{{ element.params.content }}'
              [attr.isValid] = 'true'
              [readonly] = 'element.params.readOnly'
              [attr.isRequired] = 'element.params.required'
              [attr.isMinLength] = 'element.params.minLength'
              [attr.isMaxLength] = 'element.params.maxLength'
              [attr.patternMatch] = 'element.params.patternMatch'
              [attr.enterkeyaction] = 'element.params.onenterkeyaction'
              (keyup)="OnInputKeyUp($event)"
              (blur)="OnInputBlur($event)">
          <div class="error-field"
              [attr.validatorMessage] = 'element.params.validatorMessage'
              [attr.validatorMessageField] = 'element.params.validatorMessage'>
          </div>
        </div>
      </div> -->
      <div *ngIf="element.params.allowiDIN" class="evidence-fields">
        <div class="element-wrapper increased-margin-top">
          <div class="input-label-large">
            <!--ALTERNATE  SUBTITLE USED IN NEW FORMS-->
            <h4>iDIN verification (NL only)</h4>
          </div>
        </div>
      </div>
      <div *ngIf="element.params.allowiDIN" class="evidence-fields">
        <div class="element-wrapper mt-3">
          <input
            class="custom-checkbox"
            type="checkbox"
            id="addressVerificationiDN"
            name="addressVerificationiDN"
            value="IDn verification"
            (click)="onEmit(elementService.OnContentElementClick($event))"
            (change)="onEmit(elementService.OnChangeSelect($event))"
            [checked]="false"
            [attr.changeMethod]="element.params.changemethod"
          />
          <label class="custom-checkbox-label" for="addressVerificationiDN">
            Allow the use of iDIN verification</label
          >
        </div>
      </div>
      <div class="evidence-fields">
        <div class="element-wrapper increased-margin-top">
          <div class="input-label-large">
            <!--ALTERNATE  SUBTITLE USED IN NEW FORMS-->
            <h4>Enhanced verification</h4>
          </div>
        </div>
      </div>
      <div class="evidence-fields">
        <div class="element-wrapper mt-3">
          <input
            class="custom-checkbox"
            type="checkbox"
            id="addressVerificaitonLetter"
            name="addressVerificaitonLetter"
            value="Include verification letter"
            (click)="onEmit(elementService.OnContentElementClick($event))"
            (change)="onEmit(elementService.OnChangeSelect($event))"
            [checked]="false"
            [attr.changeMethod]="element.params.changemethod"
          />
          <label class="custom-checkbox-label" for="addressVerificaitonLetter">
            Send a verification letter by physical mail to address</label
          >
        </div>
      </div>
      <div class="evidence-fields">
        <div class="element-wrapper increased-margin-top">
          <div class="input-label-large">
            <!--ALTERNATE  SUBTITLE USED IN NEW FORMS-->
            <h4>Supporting evidence</h4>
          </div>
        </div>
      </div>
      <div class="evidence-fields">
        <div class="element-wrapper mt-3">
          <input
            class="custom-checkbox"
            type="checkbox"
            id="addressSupportingDocument"
            name="addressSupportingDocument"
            value="Include supporting document"
            (click)="onEmit(elementService.OnContentElementClick($event))"
            (change)="onEmit(elementService.OnChangeSelect($event))"
            [checked]="false"
            [attr.changeMethod]="'addressVerificationDocumentChange'"
          />
          <label class="custom-checkbox-label" for="addressSupportingDocument">
            Request additional documents</label
          >
        </div>
      </div>
      <div class="evidence-fields">
        <div
          class="element-wrapper counter-group checkbox-borderless mt-3 d-none"
        >
          <div class="custom-checkbox-label">
            Select which of the below documents will be accepted forms of
            evidence
          </div>
          <!-- Checkbox Group -->
          <div class="counter-container checkbox-container">
            <div class="single-checkbox-wrapper">
              <div class="custom-counter counter-alternate">
                <div
                  [ngClass]="undefined ? 'd-none' : 'd-flex'"
                  class="counter-passive"
                >
                  <button
                    (click)="elementService.increaseCountEmpty($event)"
                    class="button-plus-empty"
                  >
                    <span>+</span>
                  </button>
                </div>
                <div
                  [ngClass]="undefined ? 'd-flex' : 'd-none'"
                  class="counter-active"
                >
                  <button
                    (click)="
                      elementService.increaseCountMinus(
                        $event,
                        undefined ? undefined : 0
                      )
                    "
                    class="button-minus button-inactive"
                  >
                    <span>-</span>
                  </button>
                  <input
                    id="UtilityBill"
                    [attr.baseValue]="undefined ? undefined : 0"
                    type="text"
                    readonly="readonly"
                    class="custom-counter-count d-none"
                    value="{{ undefined ? undefined : 0 }}"
                  />
                  <button
                    (click)="elementService.increaseCountPlus($event)"
                    class="button-plus d-none"
                  >
                    <span>+</span>
                  </button>
                </div>
                <div class="custom-counter-label">Utility bill</div>
              </div>
            </div>
            <div class="single-checkbox-wrapper">
              <div class="custom-counter counter-alternate">
                <div
                  [ngClass]="undefined ? 'd-none' : 'd-flex'"
                  class="counter-passive"
                >
                  <button
                    (click)="elementService.increaseCountEmpty($event)"
                    class="button-plus-empty"
                  >
                    <span>+</span>
                  </button>
                </div>
                <div
                  [ngClass]="undefined ? 'd-flex' : 'd-none'"
                  class="counter-active"
                >
                  <button
                    (click)="
                      elementService.increaseCountMinus(
                        $event,
                        undefined ? undefined : 0
                      )
                    "
                    class="button-minus button-inactive"
                  >
                    <span>-</span>
                  </button>
                  <input
                    id="BankStatement"
                    [attr.baseValue]="undefined ? undefined : 0"
                    type="text"
                    readonly="readonly"
                    class="custom-counter-count d-none"
                    value="{{ undefined ? undefined : 0 }}"
                  />
                  <button
                    (click)="elementService.increaseCountPlus($event)"
                    class="button-plus d-none"
                  >
                    <span>+</span>
                  </button>
                </div>
                <div class="custom-counter-label">Bank Statement</div>
              </div>
            </div>
            <div class="single-checkbox-wrapper">
              <div class="custom-counter counter-alternate">
                <div
                  [ngClass]="undefined ? 'd-none' : 'd-flex'"
                  class="counter-passive"
                >
                  <button
                    (click)="elementService.increaseCountEmpty($event)"
                    class="button-plus-empty"
                  >
                    <span>+</span>
                  </button>
                </div>
                <div
                  [ngClass]="undefined ? 'd-flex' : 'd-none'"
                  class="counter-active"
                >
                  <button
                    (click)="
                      elementService.increaseCountMinus(
                        $event,
                        undefined ? undefined : 0
                      )
                    "
                    class="button-minus button-inactive"
                  >
                    <span>-</span>
                  </button>
                  <input
                    id="CreditCardStatement"
                    [attr.baseValue]="undefined ? undefined : 0"
                    type="text"
                    readonly="readonly"
                    class="custom-counter-count d-none"
                    value="{{ undefined ? undefined : 0 }}"
                  />
                  <button
                    (click)="elementService.increaseCountPlus($event)"
                    class="button-plus d-none"
                  >
                    <span>+</span>
                  </button>
                </div>
                <div class="custom-counter-label">Credit card statement</div>
              </div>
            </div>
            <div class="single-checkbox-wrapper">
              <div class="custom-counter counter-alternate">
                <div
                  [ngClass]="undefined ? 'd-none' : 'd-flex'"
                  class="counter-passive"
                >
                  <button
                    (click)="elementService.increaseCountEmpty($event)"
                    class="button-plus-empty"
                  >
                    <span>+</span>
                  </button>
                </div>
                <div
                  [ngClass]="undefined ? 'd-flex' : 'd-none'"
                  class="counter-active"
                >
                  <button
                    (click)="
                      elementService.increaseCountMinus(
                        $event,
                        undefined ? undefined : 0
                      )
                    "
                    class="button-minus button-inactive"
                  >
                    <span>-</span>
                  </button>
                  <input
                    id="InsuranceAgreement"
                    [attr.baseValue]="undefined ? undefined : 0"
                    type="text"
                    readonly="readonly"
                    class="custom-counter-count d-none"
                    value="{{ undefined ? undefined : 0 }}"
                  />
                  <button
                    (click)="elementService.increaseCountPlus($event)"
                    class="button-plus d-none"
                  >
                    <span>+</span>
                  </button>
                </div>
                <div class="custom-counter-label">Insurance agreement</div>
              </div>
            </div>
            <div class="single-checkbox-wrapper">
              <div class="custom-counter counter-alternate">
                <div
                  [ngClass]="undefined ? 'd-none' : 'd-flex'"
                  class="counter-passive"
                >
                  <button
                    (click)="elementService.increaseCountEmpty($event)"
                    class="button-plus-empty"
                  >
                    <span>+</span>
                  </button>
                </div>
                <div
                  [ngClass]="undefined ? 'd-flex' : 'd-none'"
                  class="counter-active"
                >
                  <button
                    (click)="
                      elementService.increaseCountMinus(
                        $event,
                        undefined ? undefined : 0
                      )
                    "
                    class="button-minus button-inactive"
                  >
                    <span>-</span>
                  </button>
                  <input
                    id="DriversLicence"
                    [attr.baseValue]="undefined ? undefined : 0"
                    type="text"
                    readonly="readonly"
                    class="custom-counter-count d-none"
                    value="{{ undefined ? undefined : 0 }}"
                  />
                  <button
                    (click)="elementService.increaseCountPlus($event)"
                    class="button-plus d-none"
                  >
                    <span>+</span>
                  </button>
                </div>
                <div class="custom-counter-label">Driver's licence</div>
              </div>
            </div>
          </div>
          <div
            class="error-field"
            [attr.validatorMessage]="element.params.validatorMessage"
            [attr.validatorMessageField]="element.params.validatorMessage"
          ></div>
        </div>
      </div>
      <div class="evidence-fields">
        <div style="padding-left: 5px" class="counter-group d-none">
          <div
            class="counter-container custom-evidence-counter-container checkbox-container"
          >
            <div
              class="single-checkbox-wrapper"
              *ngFor="let checkbox of element.params.checkboxes"
            >
              <div class="custom-counter">
                <div
                  c
                  [ngClass]="checkbox.params.counter ? 'd-none' : 'd-flex'"
                  class="counter-passive pr-2"
                >
                  <button
                    (click)="elementService.increaseCountEmpty($event, true)"
                    class="button-plus-empty"
                  >
                    <span>+</span>
                  </button>
                </div>
                <div
                  [ngClass]="checkbox.params.counter ? 'd-flex' : 'd-none'"
                  class="counter-active counter-active-letters"
                >
                  <button
                    (click)="
                      elementService.increaseCountMinus(
                        $event,
                        checkbox.params.counter ? checkbox.params.counter : 0,
                        true
                      )
                    "
                    class="button-minus button-inactive"
                  >
                    <span>-</span>
                  </button>
                  <input
                    id="{{ checkbox.params.id }}"
                    [attr.baseValue]="
                      checkbox.params.counter ? checkbox.params.counter : 0
                    "
                    type="text"
                    readonly="readonly"
                    class="custom-counter-count invisible"
                    value="{{
                      checkbox.params.counter ? checkbox.params.counter : 0
                    }}"
                  />
                  <input
                    *ngFor="let evidenceKey of checkbox.params.dataEvidenceKeys"
                    [attr.data-Oftype]="checkbox.params.id"
                    readonly="readonly"
                    class="hidden-counter-field"
                    value="{{ evidenceKey }}"
                    type="hidden"
                  />
                  <button
                    (click)="elementService.increaseCountPlus($event, true)"
                    class="custom-counter-add-more"
                  >
                    Add more
                  </button>
                </div>
                <div class="custom-counter-label pl-0">
                  {{ checkbox.params.label }}
                </div>
              </div>
              <div
                *ngIf="checkbox.params.existingCustomTypes.length"
                class="custom-evidence-types-input-wrapper"
              >
                <div
                  [ngClass]="
                    existingCustomType.customEvidenceType ? 'evd-disabled' : ''
                  "
                  class="mt-2 custom-evidence-types-container"
                  *ngFor="
                    let existingCustomType of checkbox.params
                      .existingCustomTypes;
                    let i = index
                  "
                >
                  <input
                    [value]="existingCustomType.customEvidenceType"
                    [disabled]="existingCustomType.customEvidenceType"
                    type="text"
                    class="text-input"
                    [id]="'customEvidenceType' + i + 1"
                  />
                  <div class="custom-evidence-delete-button d-none"></div>
                </div>
              </div>
              <div
                *ngIf="!checkbox.params.existingCustomTypes.length"
                class="custom-evidence-types-input-wrapper ml-0 mt-1 d-none"
              >
                <div class="custom-evidence-types-container mb-3">
                  <input
                    type="text"
                    class="text-input"
                    [id]="'customEvidenceType' + '1'"
                  />
                  <div class="custom-evidence-delete-button"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="element-wrapper mt-1">
            <!-- Textarea (autogrow) -->
            <span class="input-label">
              Additional document notes for the subject
            </span>
            <textarea
              maxlength="400"
              autosize
              class="text-input-div"
              id="avNotes"
              name="avNotes"
              (focus)="onEmit(elementService.OnInputFocus($event))"
              (blur)="onEmit(elementService.OnInputBlur($event))"
              (input)="elementService.onChangeNotes($event)"
            ></textarea>

            <div
              class="error-field"
              [attr.validatorMessage]="element.params.validatorMessage"
              [attr.validatorMessageField]="element.params.validatorMessage"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!-- BUTTONS -->
    <div
      class="element-wrapper d-flex justify-content-between align-items-center mt-2"
    >
      <button
        [attr.clickmethod]="'idvGenerationStepTwo'"
        (click)="onEmit(elementService.OnButtonClick($event))"
        type="submit"
        class="form-button form-button-1 mt-4 evidence-submit-button"
      >
        Save
      </button>
    </div>
    <!-- <div class="button-error-field" id="AVDocumentsNotSelected">
      *No documents were selected
    </div> -->
    <div class="button-error-field" id="AVEmptyCustomDocument">
      *Custom document type field cannot be empty
    </div>
  </div>
</form>
