import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { UserService } from 'src/app/user.service';
import { emailPattern } from "src/app/id-verification/id-verification-const";

@Component({
  selector: 'app-contract-share-dialog',
  templateUrl: './contract-share-dialog.component.html',
  styleUrls: ['./contract-share-dialog.component.scss']
})
export class ContractShareDialogComponent implements OnInit {

  @Input() requestKey: string;
  @Input() profile;

  cosigners;
  entityName: string;
  isSigned: boolean = true;
  contractVerificaitonLink;
  contractStep;
  isRP: boolean = false;

  constructor(public userService: UserService,
    public idVerificationService: IdVerificationService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    if(this.idVerificationService.isKeyRelatedParty(this.requestKey)) {
      this.isRP = true;
      const relatedParty =   this.idVerificationService.getActiveInvestorDetaisl()['relatedParties'].find(obj => obj['key'] === this.requestKey);
      console.log(relatedParty);;
      this.contractStep = relatedParty.record.contractStep[0];
    } else {
      this.contractStep = this.idVerificationService.getActiveInvestorDetaisl().contractStep[0];
    }
    this.getCurrentCosigners();
    this.entityName = this.idVerificationService.generateEntityName(this.profile.typeOfInvestor, this.profile.investorFields);
  }

  getCurrentCosigners() {
    this.userService
      .getEndPoint(
        this.idVerificationService.buildEndPoint(
          "coSigners",
          this.idVerificationService.getActiveProject(),
          this.requestKey,
          "",
          true,
          this.contractStep.originalEvidenceKey 
          ? this.contractStep.originalEvidenceKey  : this.contractStep.key ,
        ),
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (response === undefined) {
            console.log("Error while fetching record co-signers!");
            return;
          }


          this.cosigners = response;
          this.cosigners?.forEach(cosigner => {
            if(cosigner.email == this.userService.getUserInfo()["username"])
            {
              this.isSigned = cosigner.isSigned;
              // cosigner.isSigned = true;
              cosigner.cannotDelete = true;


            }
            return 
          })
          if(!this.cosigners || this.cosigners.length == 0) {
            this.cosigners.push({
              email: this.userService.getUserInfo()["username"],
              isSigned: false,
              cannotDelete : true
            });
            this.submitCosigner(
              null,
              this.userService.getUserInfo()["username"],
            );
            this.isSigned = false;
          }

        },
        (error) => {
          return;
        },
      );

  }
  submitCosigner($event, emailCosigner = null) {
    if ($event) {
      $event.preventDefault();
    }
    const email = emailCosigner
      ? emailCosigner
      : $("#ContractEmailSigner").val().toString().trim();
    let emailValid = true;
    if (!emailCosigner) {
      if (email.match(emailPattern,)) {
        this.cosigners?.forEach((addedEmail) => {
          if (addedEmail.email == email) {
            emailValid = false;
            $(".contract-signer-error-field").text(
              this.translate.instant("validation.emailWasAdded"),
            );
            return;
          }
        });
      } else {
        emailValid = false;
        $(".contract-signer-error-field").text(
          this.translate.instant("validation.validation5"),
        );
      }
    }

    if (emailValid) {
      emailCosigner ?? $("#contractSubmitButtonSigner").addClass("loadinge");
      this.userService
        .postEndPoint(
          this.idVerificationService.buildEndPoint(
            "coSigners",
            this.idVerificationService.getActiveProject(),
            this.requestKey,
            "",
            true,
            this.contractStep.originalEvidenceKey 
            ? this.contractStep.originalEvidenceKey  : this.contractStep.key ,
          ),
          { singerEmails: [email] },
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (typeof response === undefined) {
              console.log(
                "There was an unexpected error while adding a co-signer",
              );
              return;
            }
            emailCosigner ??
              this.cosigners.push({ email: email, isSigned: false });
            emailCosigner ??
              $("#contractSubmitButtonSigner").removeClass("loadinge");
          },
          (error) => {
            $(".contract-error-field").text(error.message.message);
          },
        );
    } else {
      $(".contract-signer-error-field").removeClass("d-none");
    }
  }

  addNewCollaborator($event) {
    if ($event) {
      $event.preventDefault();
    }
    const email = $("#ContractEmail").val().toString();
    $(".contract-error-field").addClass("d-none");
    $(".contract-error-field").text(
      this.translate.instant("validation.validation5"),
    );
    if (email.match(emailPattern,)) {
      $(".contract-button-contr ").addClass("loadinge");

      let key = this.contractStep.originalEvidenceKey ? this.contractStep.originalEvidenceKey  : this.contractStep.key;
      this.userService
        .postEndPoint(
          "api/projects/" +
          this.idVerificationService.getActiveProject() +
          "/information/requests/" +
          this.requestKey +
          "/verifications/" +
          key  +
          "/initiate",
          { email: $("#ContractEmail").val().toString() },
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            $(".contract-button-contr ").removeClass("loadinge");
            $(".invite-sent").addClass("show");
            $("#ContractEmail").val("");
            if (response["verificationLink"]) {
              this.contractVerificaitonLink = response["verificationLink"];
            }
          },
          (error) => {
            $(".contract-error-field").text(error.message.message);
          },
        );
    } else {
      $(".contract-error-field").removeClass("d-none");
    }
  }
  deleteValidation() {
    $(".contract-signer-error-field").addClass("d-none");
  }

  newCollaborator() {
    $(".invite-sent").removeClass("show");
  }

  removeCosigner($event, email: string, index: number) {
    $event.preventDefault();
    $("#delete-co-signer" + index).addClass("loadinge");
    this.userService
      .postEndPoint(
        this.idVerificationService.buildEndPoint(
          "removeCosigner",
          this.idVerificationService.getActiveProject(),
          this.requestKey,
          "",
          true,
          this.contractStep.originalEvidenceKey 
          ? this.contractStep.originalEvidenceKey  : this.contractStep.key ,
        ),
        { singerEmails: [email] },
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe(
        (response) => {
          if (typeof response === undefined) {
            console.log(
              "There was an unexpected error while deleting a co-signer",
            );
            return;
          }
          this.cosigners.splice(index, 1);
          $("#delete-co-signer").removeClass("loadinge");
        },
        (error) => {
          $(".contract-error-field").text(error.message.message);
        },
      );
  }
  
  redirectInvestorToDocument() {
    if (!this.contractVerificaitonLink) {
      let key = this.contractStep.originalEvidenceKey ? this.contractStep.originalEvidenceKey  : this.contractStep.key;
      $("#continueToDocument").addClass("loadinge");
      this.userService
        .postEndPoint(
          "api/projects/" +
          this.idVerificationService.getActiveProject() +
          "/information/requests/" +
          this.requestKey +
          "/verifications/" +
          key +
          "/initiate",
          { email: this.userService.getUserInfo()["username"] },
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (typeof response === undefined) {
              console.log(
                "There was an unexpected error while redirecting to the document",
              );
              return;
            }
            if (response["verificationLink"]) {
              this.contractVerificaitonLink = response["verificationLink"];
              window.location.href = this.contractVerificaitonLink;
              $("#continueToDocument").removeClass("loadinge");
            }
          },
          (error) => {
            $(".contract-error-field").text(error.message.message);
          },
        );
    } else {
      window.location.href = this.contractVerificaitonLink;
    }
  }

}
