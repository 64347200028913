<div *ngIf="errorState">
  <header
    class="d-flex flex-column p-2 p-sm-3 errorState {{
      headerClasses['backgroundClass']
    }} {{ headerClasses['sectionClass'] }}"
    [ngStyle]="{ 'background-color': projectColor }"
  >
    <div class="d-flex flex-column flex-fill justify-content-center">
      <div class="header-top d-flex justify-content-between mx-auto py-3">
        <div class="navbar mx-auto p-0">
          <a [routerLink]="['/']" class="navbar-brand mr-0"
            ><img
              [ngClass]="{
                'finos-logo-img': true,
                'finos-logo-img-custom': false
              }"
              src="{{ logo }}"
              class="lightbox-class-logo-img"
              *ngIf="logo !== null"
          /></a>
        </div>
      </div>
      <div class="organizational-details" *ngIf="errorState">
        <h1 [ngClass]="projectTextColorClass" class="mb-3">
          Verification Request
        </h1>
        <div>
          <div class="od-main-container">
            <img src="/assets/images/request_screening.svg" class="od-image" />
            <h4 class="od-subtitle text-center">
              We could not start the verification process for you.
            </h4>
          </div>
        </div>
      </div>
    </div>
  </header>
</div>

<!-- no error state -->
<header
  *ngIf="!errorState && callLoaded"
  class="d-flex flex-column p-2 p-sm-3 {{ headerClasses['backgroundClass'] }} {{
    headerClasses['sectionClass']
  }}"
  [ngStyle]="{ 'background-color': projectColor }"
>
  <div
    class="d-flex flex-column flex-fill justify-content-center align-items-center mb-5 vlanding-container"
  >
    <div
      class="header-top d-flex justify-content-center justify-content-lg-between mx-auto py-3"
    >
      <div class="mx-auto navbar p-0">
        <a class="navbar-brand mr-0">
          <img
            class="lightbox-class-logo-img"
            src="{{ logo }}"
            *ngIf="logo !== null"
        /></a>
      </div>
    </div>

    <div
      class="unauth-message text-center mx-auto"
      [ngClass]="projectTextColorClass"
    >
      <h1 [ngClass]="projectTextColorClass" class="mb-4">
        {{ verificationHeading }}
      </h1>
      <div class="contentWrapper mx-auto">
        <!-- (contentReturn)='parseContentReturn($event)' -->
        <app-content-builder
          [contentDOM]="lightboxContent"
          (contentReturn)="onLightboxConfirm($event)"
        ></app-content-builder>
      </div>
    </div>
  </div>
</header>

<!-- <app-lightbox *ngIf='displayModal'
  [formType]='formType'
  [formTitle]='formTitle'
  [formClass]='formClass'
  [formSubTitle]='formSubTitle'
  [lightboxClass]='lightboxClass'
  [btnOkText]='btnOkText'
  [btnCancelText]='btnCancelText'
  [displayModal]='displayModal'
  [displayLoader]='displayLoader'
  [contentDOM]="lightboxContent"
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
  ></app-lightbox> -->
