<div
  id="toast"
  [ngClass]="{
    show: notificationDisplay,
    warning: notificationMode === 'warning',
    error: notificationMode === 'error'
  }"
>
  <h2
    *ngIf="notificationTitle"
    [ngClass]="{ 'mb-0': notificationMessage === '' }"
  >
    {{ notificationTitle }}
  </h2>
  <div *ngIf="notificationMessage">{{ notificationMessage }}</div>
</div>
