<div
  class="integrity-check-container d-flex flex-column flex-fill justify-content-center"
>
  <!-- <h1 class="text-center m-0">INTEGRITY CHECK</h1> -->
  <div
    class="ic-input-container d-flex justify-content-center"
    [ngClass]="{ 'mb-2': getKvkServiceState() }"
  >
    <div class="d-flex position-relative">
      <input
        class="ic-input ic-input-main"
        [(ngModel)]="companySearchTerm"
        (keyup)="OnAutoSuggest($event)"
        (focus)="OnAutoSuggestFocus($event)"
        [disabled]="getKvkServiceState()"
      />
      <button
        class="ic-check-button"
        (click)="OnSubmitCompanyForReview()"
        [disabled]="getKvkServiceState()"
      >
        Perform check
      </button>
      <div
        class="error-field"
        *ngIf="validatorMessage"
        [attr.validatorMessage]="validatorMessage"
      ></div>
      <div class="autosuggest-container" *ngIf="kvkResults">
        <ul>
          <li *ngFor="let result of kvkResults">
            <a
              href="#"
              [attr.kvkNumber]="result.kvkNumber"
              [attr.companyName]="result.businessName"
              (click)="OnSelectCompanyForReview($event)"
            >
              <div class="autosuggest-name">{{ result.businessName }}</div>
              <div class="autosuggest-kvk">{{ result.kvkNumber }}</div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div
    class="ic-input-container d-column justify-content-center mt-0 mb-6"
    *ngIf="getKvkServiceState()"
  >
    <div class="d-flex justify-content-center mb-2 text-center text-white">
      KVK service is unavailable at the moment.<br />Please enter the KVK Number
      below to start integrity check!
    </div>
    <div class="d-flex justify-content-center position-relative">
      <input class="ic-input ic-input-main" [(ngModel)]="fallbackKVK" />
      <button
        class="ic-check-button"
        (click)="OnSubmitKvkOnlyCompanyForReview()"
      >
        Perform check
      </button>
    </div>
  </div>
  <div class="ic-ctas mx-auto mb-5" *ngIf="displayFlagging">
    <a
      class="position-relative new-flag"
      href="#"
      (click)="OnFlagCompany($event)"
      routerLinkActive="router-link-active"
      >New Flag</a
    >
  </div>
</div>
