import { Component, OnInit } from "@angular/core";
import { ToastNotificationsService } from "../toast-notifications.service";

@Component({
  selector: "app-toast-notifications",
  templateUrl: "./toast-notifications.component.html",
  styleUrls: ["./toast-notifications.component.scss"],
})
export class ToastNotificationsComponent implements OnInit {
  notificationDisplay = false;
  notificationMode: "info" | "warning" | "error" = "info";
  notificationTitle = "";
  notificationMessage = "";

  constructor(private toastNotifications: ToastNotificationsService) {}

  ngOnInit(): void {
    this.toastNotifications.events$.forEach((event) => {
      this.displayToastNotification(event);
    });
  }

  displayToastNotification(event) {
    if (event.message || event.title) {
      this.notificationDisplay = true;
      if (event.mode) {
        this.notificationMode = event.mode;
      }
      if (event.title) {
        this.notificationTitle = event.title;
      }
      this.notificationMessage = event.message;

      if (event.fade) {
        this.hideToastNotification();
      }
    }
  }

  hideToastNotification() {
    setTimeout(() => {
      this.notificationDisplay = false;
    }, 3000);
  }
}
