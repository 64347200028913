import { Component, Input, OnInit } from "@angular/core";
import { UiService } from "../../ui.service";

@Component({
  selector: "app-autocomplete-input",
  templateUrl: "./autocomplete-input.component.html",
  styleUrls: ["./autocomplete-input.component.scss"],
})
export class AutocompleteInputComponent implements OnInit {
  @Input() options: string[]; // List of autocomplete options
  @Input() id: string;
  @Input() isRequired = false;
  @Input() isMaxLength?: number;
  filteredOptions: string[] = [];
  inputValue = "";

  constructor(public uiService: UiService) {}

  ngOnInit(): void {}

  filterOptions(value: string): void {
    this.filteredOptions = !value
      ? this.options
      : this.options.filter((option) =>
          option.toLowerCase().includes(value.toLowerCase()),
        );
  }

  onFocus(value: string): void {
    this.filterOptions(value);
  }

  selectOption(option: string): void {
    this.inputValue = option + ' ';
    this.filterOptions(this.inputValue);
    $(".autocomplete-input").focus();
  }
}
