import _ from "lodash";

export default [
  // {
  //   key: 'multiple',
  //   ignore: true,
  // },
  {
    type: "textarea",
    input: true,
    label: "Label",
    key: "label",
    tooltip: "The description of the question",
    weight: 10,
    reorder: true,
  },
  // example of dropdown:
  // {
  //   type: 'select',
  //   input: true,
  //   label: 'Storage Type',
  //   key: 'dataType',
  //   clearOnHide: true,
  //   tooltip: 'The type to store the data. If you select something other than autotype, it will force it to that type.',
  //   weight: 12,
  //   template: '<span>{{ item.label }}</span>',
  //   dataSrc: 'values',
  //   data: {
  //     values: [
  //       { label: 'Autotype', value: 'auto' },
  //       { label: 'String', value: 'string' },
  //       { label: 'Number', value: 'number' },
  //       { label: 'Boolean', value: 'boolean' },
  //       { label: 'Object', value: 'object' },
  //     ],
  //   },
  // },
];
