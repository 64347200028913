<div class="custom-multi-select">
  <div class="selected-options tags">
    <div
      class="tag purpose-tag light-color"
      *ngFor="let option of selectedOptions"
    >
      {{ option.name }}
      <span
        class="position-absolute badge rounded-pill"
        (click)="removeOption(option)"
      >
        <div class="d-flex justify-content-center align-items-center">
          <svg-icon
            class="close-icon"
            src="assets/images/icon-close-blue.svg"
          ></svg-icon>
        </div>
      </span>
    </div>
  </div>
  <span class="select-wrapper">
    <select
      #options
      class="select-input"
      (change)="selectOption(options.value)"
    >
      <option *ngIf="textForSelect" disabled selected value>
        {{ textForSelect }}
      </option>
      <option value="{{ option.key }}" *ngFor="let option of availableOptions">
        {{ option.name }}
      </option>
    </select>
  </span>
</div>
