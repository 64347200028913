import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector, TemplateRef } from "@angular/core";
import { LightboxComponent } from 
'./lightbox.component';
@Injectable({
  providedIn: "root",
})
export class LightboxService {
  private lightboxComponentRef: any;

  constructor(private appRef: ApplicationRef, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector) {}

  public open(contentTemplate) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LightboxComponent);
    this.lightboxComponentRef = componentFactory.create(this.injector);
    
    this.appRef.attachView(this.lightboxComponentRef.hostView);
    document.body.appendChild(this.lightboxComponentRef.location.nativeElement);
    this.lightboxComponentRef.instance.open(contentTemplate);
  }

  public close() {
    if (this.lightboxComponentRef) {
      this.lightboxComponentRef.instance.close();
      this.appRef.detachView(this.lightboxComponentRef.hostView);
      this.lightboxComponentRef.destroy();
}}
}
