import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { HelperServiceService } from "src/app/helper-service.service";
import { UserService } from "src/app/user.service";

@Injectable({
  providedIn: "root",
})
export class QuestionnaireBuilderService {
  public form: Object = {
    components: [],
  };
  preview = false;
  previewList = true;
  previewJson = false;
  editOutput = false;
  editMode = true;

  questionareTemplates = null;
  currentTemplate = null;
  currentTemplateName;
  currentTemplateResponse;
  currentMode = null;
  currentOutputs = [];
  currentOutputIndex = null;
  formattedJson = {};
  formattedHTML = "";
  versionsData;
  previewingOlderVersion;
  currentVersionKey;

  public stringifiedJson = null;
  public downloadJson = null;
  private jsonElement = null;

  constructor(
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private helper: HelperServiceService,
  ) {
    this.formattedJson = {
      components: [
        // {
        //   'label': 'HTML',
        //   'tag': 'h1',
        //   'className': 'risk-assesment-title',
        //   'attrs': [
        //     {
        //       'attr': '',
        //       'value': ''
        //     }
        //   ],
        //   'content': 'Risk assessment',
        //   'refreshOnChange': false,
        //   'key': 'html',
        //   'type': 'htmlelement',
        //   'input': false,
        //   'tableView': false
        // }
      ],
    };
  }

  getUserOrgId() {
    return this.userService.getUserOrganizationId();
  }

  async loadMyForm(template, mode, toggleList = true) {
    this.currentTemplate = template;
    this.currentMode = mode;

    // load the versions at the same time as the form
    this.userService
      .getEndPoint(
        "/api/organizations/questionnaires/" + template + "/versions",
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .toPromise()
      .then((versions) => {
        // this.formattedJson =  JSON.parse(schema['record'].formattedJson);
        // this.setFormJson(this.formattedJson, true);
        // this.togglePreviewList();
        this.versionsData = versions["results"];
      })
      .catch((error) => console.error(error));

    await this.userService
      .getEndPoint(
        "/api/organizations/questionnaires/" + template,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .toPromise()
      .then((schema) => {
        this.currentTemplateResponse = schema["record"];
        this.formattedJson = JSON.parse(schema["record"].formattedJson);
        this.currentTemplateName = schema["record"].name;
        this.setFormJson(this.formattedJson, true);
        if (toggleList) {
          this.previewingOlderVersion = false;
          this.togglePreviewList();
        } else {
        }
      })
      .catch((error) => console.error(error));
  }

  removeQuestionareFromList(key) {
    this.questionareTemplates = this.questionareTemplates.filter(
      (q) => q.key !== key,
    );
  }

  togglePublishMarker(key) {
    this.questionareTemplates.forEach((questionnare) => {
      if (questionnare.key === key) {
        questionnare.published = !questionnare.published;
      }
    });
  }

  parseQuestionareTemplates(data) {
    if (data) {
      const contracTemplates = [];
      data.results.forEach((template) => {
        contracTemplates.push({
          key: template.key,
          name: template.record.name,
          // 'type' : template.type,
          mode: template.record.status,
          published: template.record.published,
          // 'modes' : template.modes
        });
      });
      this.questionareTemplates = contracTemplates;
    }
  }

  setFormJson(formJson, setForm = false, stringified = false) {
    if (!formJson) {
      return;
    }
    if (stringified) {
      this.formattedJson = JSON.parse(formJson);
    }
    this.stringifiedJson = JSON.stringify(this.formattedJson, null, 4);
    this.downloadJson = this.sanitizer.bypassSecurityTrustUrl(
      "data:text/json;charset=UTF-8," +
        encodeURIComponent(this.stringifiedJson),
    );
    if (setForm) {
      this.form = this.formattedJson;
      if (this.jsonElement) {
        this.jsonElement.nativeElement.innerHTML = "";
        this.jsonElement.nativeElement.appendChild(
          document.createTextNode(this.stringifiedJson),
        );
      }
    }
  }

  displayEditForm() {
    this.previewJson = false;
    this.previewList = false;
    this.preview = false;
    this.editOutput = false;
  }

  // displayEditOutput() {
  //   if (this.currentOutputs.length > 0) {
  //     if (this.currentOutputIndex === null) {
  //       this.setCurrentOutput(0);
  //     }
  //     this.previewJson = false;
  //     this.previewList = false;
  //     this.preview = false;
  //     this.editOutput = true;
  //   }
  // }

  parseQuestionsResponse() {
    const parsedQuestions = [];
    this.formattedJson["components"].forEach((component) => {
      if (
        component.type === "RadioExtendedComponent" ||
        component.type === "CheckboxExtendedComponent" ||
        component.type === "SwitchExtendedComponent" ||
        component.type === "SelectExtendedComponent"
      ) {
        let newQuestion;
        let newQuestionOptions;
        let valuesSet;
        newQuestion = {};
        newQuestionOptions = [];
        valuesSet = [];
        (newQuestion.type = this.parseComponentType(component.type)),
          (newQuestion.text = component.label);
        valuesSet = component.values;
        if (component.type === "SelectExtendedComponent") {
          valuesSet = component.data ? component.data.values : undefined;
        }
        if (valuesSet) {
          component.values.forEach((option) => {
            newQuestionOptions.push({
              value: option.label,
              riskPoints: option.answerWeight,
            });
          });
        }
        newQuestion.options = newQuestionOptions;
        parsedQuestions.push(newQuestion);
      }
      if (component.type === "tabs") {
        component.components.forEach((tabComponent) => {
          tabComponent.components.forEach((tabComponentComponents) => {
            let newQuestion;
            let newQuestionOptions;
            let valuesSet;
            newQuestion = {};
            newQuestionOptions = [];
            valuesSet = [];
            (newQuestion.type = this.parseComponentType(
              tabComponentComponents.type,
            )),
              (newQuestion.text = tabComponentComponents.label);
            valuesSet = tabComponentComponents.values;
            if (tabComponentComponents.type === "SelectExtendedComponent") {
              valuesSet = tabComponentComponents.data
                ? tabComponentComponents.data.values
                : undefined;
            }
            if (valuesSet) {
              tabComponentComponents.values.forEach((option) => {
                newQuestionOptions.push({
                  value: option.label,
                  riskPoints: option.answerWeight,
                });
              });
            }
            newQuestion.options = newQuestionOptions;
            parsedQuestions.push(newQuestion);
          });
        });
      }
      // handle tabs
    });
    return parsedQuestions;
  }
  parseComponentType(type) {
    let parsedType;
    switch (type) {
      case "RadioExtendedComponent":
        parsedType = "Radio";
        break;
      case "CheckboxExtendedComponent":
        parsedType = "Checkbox";
        break;
      case "SwitchExtendedComponent":
        parsedType = "Switch";
        break;
      case "SelectExtendedComponent":
        parsedType = "Select";
        break;
      default:
        parsedType = "Select";
    }
    return parsedType;
  }

  togglePreviewJson() {
    this.previewJson = !this.previewJson;
    if (this.previewJson) {
      this.previewList = !this.previewJson;
      // if (this.jsonElement) {
      this.preview = !this.previewJson;
      // }
    }
  }

  displayPreviewJson() {
    this.previewJson = true;
    this.previewList = false;
    this.preview = false;
    this.editOutput = false;
  }

  togglePreviewList() {
    this.previewList = !this.previewList;
    if (this.previewList) {
      this.preview = !this.previewList;
      this.previewJson = !this.previewList;
    }
  }

  displayPreviewList() {
    this.previewList = true;
    this.preview = false;
    this.previewJson = false;
    this.editOutput = false;
  }

  togglePreview() {
    this.preview = !this.preview;
    if (this.preview) {
      this.previewList = !this.preview;
      this.previewJson = !this.preview;
    }
  }

  displayPreview() {
    this.preview = true;
    this.previewList = false;
    this.previewJson = false;
    this.editOutput = false;
  }

  // Helper methods

  /**
   * TODO: check if all questions in the questionnaire have weights associated with them
   * @returns true if all questions have set weight, otherwise returns false
   */
  areWeightsReady() {
    const isEmpty = this.currentOutputs.filter((output) => output.data === "");
    if (isEmpty.length === 0) {
      return true;
    }
    return false;
  }

  areQuestionsValid() {
    return true;
  }

  /**
   * Turn str into slug
   * @param str Name of the form that needs to be slugified
   * @returns Slugified version of the form name
   */
  slugify(str) {
    return (
      str
        // .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "")
    );
  }

  prepareOutputsForPayload() {
    this.currentOutputs.map(
      (output) => (output.slug = this.slugify(output.fileName)),
    );
    return this.currentOutputs;
  }

  /**
   * checks if the slugified name we are passing
   * is already found in myOrganisationTemplates list
   * @param str Slugified name of the form that needs to be checked
   * @returns boolean: true if name is unique, false otherwise
   */
  checkIfNameIsUniqueBySlug(str) {
    const filtered = this.questionareTemplates.filter(
      (template) => template.key === str,
    );
    // found slug in my organisation templates that equals the name we are checking
    // the name is not unique
    if (filtered.length > 0) {
      return false;
    }
    return true;
  }

  /**
   * returns the name of the template extracted from myOrgTemplates by slug
   * @returns if found name of the template, otherwise empty string
   */
  getNameBySlug(str) {
    const filtered = this.questionareTemplates.filter(
      (template) => template.key === str,
    );
    // found slug in my organisation templates that equals the name we are checking
    // the name is not unique
    if (filtered.length > 0) {
      return filtered[0].name;
    }
    return "";
  }

  /**
   * clears any changes made to the current template
   * and allows for new form to be created
   */
  clearCurrentTemplate() {
    this.currentMode = null;
    this.currentTemplate = null;
    this.currentOutputs = [];
    this.formattedHTML = "";
    this.formattedJson = {};
    this.form = {};
    this.stringifiedJson = null;
    this.downloadJson = null;
    this.versionsData = null;
    this.previewingOlderVersion = null;
  }

  // form/pdf generate methods

  checkIfFormioDate(field, components) {
    let ret = false;
    components.map((component) => {
      if (
        field[0] === component.key &&
        component.widget &&
        component.type === "datetime"
      ) {
        ret = true;
      } else if (component.components) {
        if (this.checkIfFormioDate(field, component.components)) {
          ret = true;
        }
      }
    });
    return ret;
  }
}
