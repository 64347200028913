import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HelperServiceService } from "src/app/helper-service.service";
import { environment } from "src/environments/environment";
import QRCode from "qrcode";
import { IpServiceService } from "src/app/ip-service.service";
import { takeUntil, tap } from "rxjs/operators";
import { Subject } from "rxjs";
import { IdVerificationService } from "../../id-verification.service";
import { VerificationsService } from "src/app/verifications/verifications.service";
import { TranslateService } from "@ngx-translate/core";
import { UiService } from "src/app/ui/ui.service";

@Component({
  selector: "app-address-verification-investor",
  templateUrl: "./address-verification-investor.component.html",
  styleUrls: ["./address-verification-investor.component.scss"],
})
export class AddressVerificationInvestorComponent implements OnInit, OnDestroy {
  // starting parameters
  headerInfo;
  headerClasses;
  projectColor;
  logo;
  projectTextColorClass: string;
  refreshedData;

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private helper: HelperServiceService,
    private ref: ChangeDetectorRef,
    private ipService: IpServiceService,
    private idVerificationService: IdVerificationService,
    private verificationsService: VerificationsService,
    private router: Router,
    public translate: TranslateService,
    private uiService: UiService,
  ) {}
  // lightbox parameters:
  displayModal: boolean;
  displayLoader: boolean;
  formType: string;
  formTitle: string;
  formSubTitle: string;
  formClass: string;
  lightboxClass: any;
  lightboxContent: any[];
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;
  map: google.maps.Map;
  position: any;
  ipAddress: string;
  geoIpAddress: any;
  ipInfo: any;
  unsubscribe$: Subject<void> = new Subject<void>();
  @ViewChild("mapWrapper") mapElement: ElementRef;
  errorState = false;

  btnContentElements: string;
  refreshType: string;
  baseUrl = environment.APIEndpoint;

  verificationRecord;
  stepsArray; // information on what steps there are in the lightbox
  verificationKey: string;
  cryptoKey: string;

  ngOnInit() {
    this.headerInfo = {
      headerType: "full", // 'inline',
      headerTitle: "Integrity Check",
      activeNavLink: "integrityCheck",
    };
    this.headerClasses = {
      backgroundClass: "tealish-gradient-bg",
      sectionClass: "ic_height_full_page", // 'ic_height_auto',
    };

    this.projectColor = "#00889c";
    this.verificationsService.setProjectColor("#00889c");
    this.projectTextColorClass =
      this.verificationsService.getProjectTextColorClass();
    this.logo = "assets/images/meshId_logo.svg";
    this.verificationKey = this.activatedRoute.snapshot.params.verificationKey;
    this.http
      .get(
        this.baseUrl + "api/verifications/" + this.verificationKey + "/address",
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          if (response) {
            this.cryptoKey = response["key"];
            if (response["record"]) {
              this.verificationRecord = response["record"];
              if (!this.verificationRecord.projectBranding) {
                this.projectColor = "#00889c";
                this.projectTextColorClass = "light-color";
              } else {
                if (this.verificationRecord.projectBranding.projectColor) {
                  this.projectColor =
                    this.verificationRecord.projectBranding.projectColor;
                  this.projectTextColorClass = this.helper.detectTextColor(
                    this.helper.hexToRgb(this.projectColor),
                  );
                }
              }
              // if there are documents to be uploaded set the fisrt as the active one
              if (
                this.verificationRecord &&
                this.verificationRecord.verificationPresets.allowedDocuments &&
                this.verificationRecord.verificationPresets.allowedDocuments
                  .length >= 1
              ) {
                // local representation of the documents because we need more than just a string
                const expandedDocumentsArray = [];
                this.verificationRecord.verificationPresets.allowedDocuments.forEach(
                  (documentType) => {
                    expandedDocumentsArray.push({
                      type: documentType,
                    });
                  },
                );
                this.verificationRecord.verificationPresets.allowedDocuments =
                  expandedDocumentsArray;
                this.verificationRecord.verificationPresets.allowedDocuments[0].documentActive =
                  true;
              }
              // get verification logo
              if (this.verificationRecord.projectBranding.projectLogo) {
                this.logo = this.verificationRecord.projectBranding.projectLogo;
                this.decideAddressVerificationStep(this.verificationRecord);
              } else {
                this.http
                  .get(
                    this.baseUrl +
                      "api/verifications/" +
                      this.verificationKey +
                      "/logo",
                    { responseType: "blob" },
                  )
                  .subscribe(
                    async (response2) => {
                      if (response2["type"] === "application/json") {
                        this.logo = JSON.parse(
                          await (response2 as Blob).text(),
                        ).link;
                      } else {
                        const reader = new FileReader();
                        reader.addEventListener(
                          "load",
                          () => {
                            this.logo = reader.result;
                            this.decideAddressVerificationStep(
                              this.verificationRecord,
                            );
                          },
                          false,
                        );
                        reader.readAsDataURL(response2);
                      }
                    },
                    (error) => {
                      console.log(
                        "Error occured while fetching the project logo",
                        error,
                      );
                      this.decideAddressVerificationStep(
                        this.verificationRecord,
                      );
                    },
                  );
                //  this.decideAddressVerificationStep(this.verificationRecord);
              }
            }
          }
        },
        (error) => {
          this.errorState = true;
          console.log("Error occured while fetching the access token", error);
          this.router.navigate(["/invalid-link"]);
        },
      );
  }
  // function to decide what step we are on and to load the proper component
  async decideAddressVerificationStep(verificationRecord) {
    this.displayLoader = false;

    // build steps array (just to visually display the existing steps has no other functionality)
    let counter = 1;
    // {name: 'Terms and conditions', count: counter++, id:"terms"}
    this.stepsArray = [];
    if (verificationRecord.verificationPresets.verificationLetterRequested) {
      if (verificationRecord.verificationPresets.addressRequested) {
        this.stepsArray.push({
          name: this.translate.instant("IDPCS.address.provideAddress"),
          count: counter++,
          id: "address_input",
        });
      }
      this.stepsArray.push({
        name: this.translate.instant("IDPCS.address.shareLocation"),
        count: counter++,
        id: "address_lookup",
      });
      if (verificationRecord.verificationPresets.documentsRequested) {
        this.stepsArray.push({
          name: this.translate.instant("IDPCS.address.provideDocument"),
          count: counter++,
          id: "document_upload",
        });
      }
      this.stepsArray.push({
        name: this.translate.instant("IDPCS.address.verification"),
        count: counter++,
        id: "verification",
      });
      this.stepsArray.push({
        name: this.translate.instant("IDPCS.address.confirmation"),
        count: counter++,
        id: "confirmation",
      });
    } else {
      if (verificationRecord.verificationPresets.addressRequested) {
        this.stepsArray.push({
          name: this.translate.instant("IDPCS.address.provideAddress"),
          count: counter++,
          id: "address_input",
        });
        this.stepsArray.push({
          name: this.translate.instant("IDPCS.address.shareLocation"),
          count: counter++,
          id: "address_lookup",
        });
        if (verificationRecord.verificationPresets.documentsRequested) {
          this.stepsArray.push({
            name: this.translate.instant("IDPCS.address.provideDocument"),
            count: counter++,
            id: "document_upload",
          });
        }
        this.stepsArray.push({
          name: this.translate.instant("IDPCS.address.confirmation"),
          count: counter++,
          id: "confirmation",
        });
      } else if (!verificationRecord.verificationPresets.addressRequested) {
        this.stepsArray.push({
          name: this.translate.instant("IDPCS.address.shareLocation"),
          count: counter++,
          id: "address_lookup",
        });
        if (verificationRecord.verificationPresets.documentsRequested) {
          this.stepsArray.push({
            name: this.translate.instant("IDPCS.address.provideDocument"),
            count: counter++,
            id: "document_upload",
          });
        }
        this.stepsArray.push({
          name: this.translate.instant("IDPCS.address.confirmation"),
          count: counter++,
          id: "confirmation",
        });
      }
    }
    // end build steps array

    // decide what step we are on
    if (false && !verificationRecord.consentedAt) {
      // NEVER show the terms and conditions - consent screen
      // if there is no record of consenting we are at the consent step
      this.onAddressVerificationTandC();
    } else if (
      // if user has consented
      verificationRecord.consentedAt &&
      // letter was NOT requested
      (!verificationRecord.verificationPresets.verificationLetterRequested ||
        // or verification letter was requested
        (verificationRecord.verificationPresets.verificationLetterRequested &&
          // BUT verification letter was NOT confirmed
          !verificationRecord.confirmedAt)) &&
      // address is requested
      verificationRecord.verificationPresets.addressRequested &&
      // address was not inputed
      !verificationRecord.addressFieldsUploadedAt
    ) {
      // go to address input screen
      this.onAddressVerificationAdressInput();
    } else if (
      // if user has consented
      verificationRecord.consentedAt &&
      // verification letter is requested
      verificationRecord.verificationPresets.verificationLetterRequested &&
      // verification letter is not confirmed
      !verificationRecord.confirmedAt
      // // address is requested
      // && verificationRecord.verificationPresets.addressRequested
      // //adress was inputed
      // && verificationRecord.addressFieldsUploadedAt
    ) {
      // user needs to input the six digit code
      this.onAddressVerificationVerificaitonCode();
    } else if (
      // user has consented
      verificationRecord.consentedAt &&
      // letter was NOT requested
      !verificationRecord.verificationPresets.verificationLetterRequested &&
      // address was requested
      verificationRecord.verificationPresets.addressRequested &&
      // address was not uploadted
      !verificationRecord.addressFieldsUploadedAt
    ) {
      // go to address input screen
      this.onAddressVerificationAdressInput();
    } else if (
      // user has consented
      verificationRecord.consentedAt &&
      // letter was NOT requested
      (!verificationRecord.verificationPresets.verificationLetterRequested ||
        // or verification letter was requested
        (verificationRecord.verificationPresets.verificationLetterRequested &&
          // BUT verification letter was NOT confirmed
          verificationRecord.confirmedAt)) &&
      // address was NOT requested
      (!verificationRecord.verificationPresets.addressRequested ||
        // address was uploaded
        (verificationRecord.verificationPresets.addressRequested &&
          verificationRecord.addressFieldsUploadedAt)) &&
      // address was inputed
      !verificationRecord.geoLocationFieldsUploadedAt
    ) {
      // if it's not mobile geolocaiton cannot be inputed, go to simple message screen
      if (!this.helper.isMobile()) {
        // let extendedDomain = `${location.protocol}//` + window.location.hostname + ((!location.port) ? '' : `:${location.port}`);
        const extendedDomain = window.location.href;
        let genQrCode;
        try {
          genQrCode = await QRCode.toDataURL(extendedDomain);
        } catch (err) {
          console.error(err);
        }
        this.geolocationScreenNonMobile(genQrCode);
      } else {
        this.geolocationScreenMobile();
        // go to geolocation screen mobile
      }
    } else if (
      // user has consented
      verificationRecord.consentedAt &&
      // letter was NOT requested
      (!verificationRecord.verificationPresets.verificationLetterRequested ||
        // or verification letter was requested
        (verificationRecord.verificationPresets.verificationLetterRequested &&
          // BUT verification letter was confirmed
          verificationRecord.confirmedAt)) &&
      // geolocation was uploaded
      verificationRecord.geoLocationFieldsUploadedAt &&
      // documents were requested
      verificationRecord.verificationPresets.documentsRequested &&
      // documents were not uploaded
      !(
        verificationRecord.documents ||
        verificationRecord.documentFieldsUploadedAt
      )
    ) {
      // go to address input screen
      this.onDocumentsUpload(
        undefined,
        this.verificationRecord.verificationPresets.allowedDocuments,
      );
    } else if (
      // user has consented
      verificationRecord.consentedAt &&
      // letter was NOT requested
      (!verificationRecord.verificationPresets.verificationLetterRequested ||
        // or verification letter was requested
        (verificationRecord.verificationPresets.verificationLetterRequested &&
          // BUT verification letter was confirmed
          verificationRecord.confirmedAt)) &&
      // geolocation was uploaded
      verificationRecord.geoLocationFieldsUploadedAt
    ) {
      // go to last step verification is over
      this.onAddressVerificationEnd();
    } else if (
      // user has consented
      verificationRecord.consentedAt &&
      // leter was not requested
      !verificationRecord.verificationPresets.verificationLetterRequested &&
      // address was NOT requested
      !verificationRecord.verificationPresets.addressRequested &&
      // geolocation was uploaded
      verificationRecord.geoLocationFieldsUploadedAt
    ) {
      this.onAddressVerificationEnd();
    }
  }
  // *****ADDRESS VERIFICATION STEP FUNCTIONS
  // START AGREEMENT
  onAddressVerificationTandC(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const thisStep = this.stepsArray.find((step) => step.id === "terms");
    thisStep.class = "form-step-active";
    const isMobile = this.helper.isMobile();
    // const isMobile = true;
    let lightboxClass =
      "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list not-closable " +
      this.projectTextColorClass;
    if (isMobile) {
      lightboxClass =
        "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list not-closable form-lightbox-fullscreen-mobile";
    }

    this.formClass = "";
    this.formType = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = {
      class: lightboxClass,
      stepsTitle: this.translate.instant("IDPCS.address.title"),
      stepsSubtitle: "",
      showStepsMobile: isMobile,
      logoImage: this.logo,
      projectColor: this.projectColor,
      projectTextColorClass: this.projectTextColorClass,
      steps: this.stepsArray,
    };
    // build the content for the Create new project lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_new_verification",
      result_details: [
        {
          group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "subtitle-alternate",
              class: "input-label-large increased-margin-top",
              params: {
                content:
                  "Please aggree to the terms below before proceeding to your address verification",
              },
            },
            {
              type: "paragraph",
              params: {
                content:
                  "I aggree to the fact that this message is entered in AuthO, and will show in the consent screen in the application. This message is a test to see if really long message will show up in the front end. This message is a test to see if really long message will show up in the front end. This message is a test to see if really long message will show up in the front end.",
              },
            },
            {
              type: "paragraph",
              paragraphClass: "paragraph-privacylink",
              params: {
                contentHTML:
                  '<div>For more information please see our <a target="_blank" href="https://meshid.com/privacy-policy/">privacy policy</a></div>',
              },
            },
            {
              type: "button",
              class: "mt-5",
              params: {
                content: "Accept",
                display: true,
                action: "AddressVerificationConsent",
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  AddressVerificationConsent() {
    this.displayLoader = true;
    this.http
      .post(
        this.baseUrl +
          "api/verifications/address/" +
          this.verificationKey +
          "/consent",
        "",
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.verificationRecord.consentedAt = true;
          this.decideAddressVerificationStep(this.verificationRecord);
        },
        (error) => {
          console.log("Error occured while fetching the access token", error);
        },
      );
  }
  // END AGREEMENT
  // ADDRESS INPUT
  onAddressVerificationAdressInput(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const thisStep = this.stepsArray.find(
      (step) => step.id === "address_input",
    );
    thisStep.class = "form-step-active";
    const isMobile = this.helper.isMobile();
    // const isMobile = true;
    let lightboxClass =
      "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list not-closable";
    if (isMobile) {
      lightboxClass =
        "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list" +
        " not-closable form-lightbox-fullscreen-mobile";
    }
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.formClass = isMobile ? "form-container-mobile" : "";
    this.formType = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = {
      class: lightboxClass,
      loaderClass: "address-loading-full-screen",
      stepsTitle: this.translate.instant("IDPCS.address.title"),
      stepsSubtitle: "",
      showStepsMobile: isMobile,
      logoImage: this.logo,
      projectColor: this.projectColor,
      projectTextColorClass: this.projectTextColorClass,
      steps: this.stepsArray,
    };
    // build the content for the Create new project lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_new_verification",
      result_details: [
        {
          group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "subtitle-alternate",
              class: "input-label-large increased-margin-top pb-3",
              params: {
                content: this.translate.instant(
                  "IDPCS.address.stepShareLocation.label14",
                ),
              },
            },
            {
              type: "inputGoogleAutocomplete",
              class: "addressVerificationManualField ",
              params: {
                label: this.translate.instant(
                  "IDPCS.address.stepShareLocation.label15",
                ),
                id: "AddressInputField",
                labelClass: "font-weight-bold",
                required: true,
                placeholder: this.translate.instant(
                  "evidencePreview.addressLabel2",
                ),
              },
            },
            {
              type: "checkbox",
              class: "checkboxUseClassicAddress  ",
              params: {
                id: "useClassicAddress",
                name: "useClassicAddress",
                label: this.translate.instant(
                  "evidenceFields.useClassicAddress",
                ),
                value: "useClassicAddress",
                changemethod: "useClassicAddressToggle",
              },
            },
            {
              type: "inputText",
              class: "addressVerificationManualField manualInput d-none",
              params: {
                label: this.translate.instant(
                  "evidenceFields.useClassicAddress",
                ),
                labelClass: "font-weight-bold",
                id: "AddressInputField",
                required: true,
              },
            },
            {
              type: "inputText",
              class: "addressVerificationManualField manualInput d-none",
              params: {
                label: this.translate.instant(
                  "evidenceFields.AddressPostalCode",
                ),
                labelClass: "font-weight-bold",
                id: "ZipInputField",
                required: true,
              },
            },
            {
              type: "inputText",
              class: "addressVerificationManualField manualInput d-none",
              params: {
                label: this.translate.instant("evidenceFields.AddressCity"),
                labelClass: "font-weight-bold",
                id: "CityInputField",
                required: true,
              },
            },
            {
              type: "selectCountryAv",
              class:
                "input-transparent addressVerificationManualField manualInput reduced-margin-top  d-none",
              params: {
                id: "CountryInputField",
                label: this.translate.instant("evidenceFields.AddressCountry"),
                required: true,
                labelClass: "font-weight-bold",
                options: this.idVerificationService.getCountryListAV(),
                validatorMessage: "this field is required",
              },
            },
            {
              type: "inputHidden",
              params: {
                id: "LocationInputField",
              },
            },
            {
              type: "button",
              class: "mt-5",
              params: {
                content: this.translate.instant("common.next"),
                class: "form-button form-button-1 form-button-full-width",
                display: true,
                action: "addressVerificationAddressInput",
                type: "submit",
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  useClassicAddressToggle() {
    if ($("#useClassicAddress").is(":checked")) {
      $(".manualInput").removeClass("d-none");
      $("#googleAutoComplete").attr("isRequired", "false");
      $("#AddressInputField").attr("isRequired", "true");
      $("#ZipInputField").attr("isRequired", "true");
      $("#CityInputField").attr("isRequired", "true");
      $("#CountryInputField").attr("isRequired", "true");
      this.uiService.clearInvalidationTarget($(`[id="googleAutoComplete"]`));
    } else {
      $(".manualInput").addClass("d-none");
      $("#googleAutoComplete").attr("isRequired", "true");
      $("#AddressInputField").attr("isRequired", "false");
      $("#ZipInputField").attr("isRequired", "false");
      $("#CityInputField").attr("isRequired", "false");
      $("#CountryInputField").attr("isRequired", "false");
      this.uiService.clearInvalidationTarget($(`[id="AddressInputField"]`));
      this.uiService.clearInvalidationTarget($(`[id="ZipInputField"]`));
      this.uiService.clearInvalidationTarget($(`[id="CityInputField"]`));
      this.uiService.clearInvalidationTarget($(`[id="CountryInputField"]`));
    }
  }

  addressVerificationAddressInput() {
    let payload;
    payload = {
      addressData: {
        // 'Address': $('.google-autocomplete').val(),
        Address: $("#AddressInputField").val(),
        ZipCode: $("#ZipInputField").val(),
        City: $("#CityInputField").val(),
        Country: $("#CountryInputField option:selected").text().trim(),
      },
    };
    this.displayLoader = true;
    this.http
      .post(
        this.baseUrl +
          "api/verifications/" +
          this.verificationKey +
          "/address/",
        payload,
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.verificationRecord.addressFieldsUploadedAt = true;
          this.decideAddressVerificationStep(this.verificationRecord);
        },
        (error) => {
          console.log("Error occured while fetching the access token", error);
        },
      );
  }
  // END ADDRESS INPUT
  // START DETECT LOCATION
  geolocationScreenNonMobile(qrCode) {
    const thisStep = this.stepsArray.find(
      (step) => step.id === "address_lookup",
    );
    thisStep.class = "form-step-active";
    const isMobile = this.helper.isMobile();
    let lightboxClass =
      "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list not-closable";
    if (isMobile) {
      lightboxClass =
        "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list" +
        " not-closable form-lightbox-fullscreen-mobile";
    }
    this.formClass = "";
    this.formType = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = {
      class: lightboxClass,
      stepsTitle: this.translate.instant("IDPCS.address.title"),
      stepsSubtitle: "",
      showStepsMobile: isMobile,
      logoImage: this.logo,
      projectColor: this.projectColor,
      projectTextColorClass: this.projectTextColorClass,
      steps: this.stepsArray,
    };
    // build the content for the Create new project lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_new_verification",
      result_details: [
        {
          group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "subtitle-alternate",
              class: "input-label-large increased-margin-top",
              params: {
                content: this.translate.instant(
                  "IDPCS.address.stepShareLocation.title",
                ),
              },
            },
            {
              type: "subtitle-alternate",
              class: "increased-margin-top text-center px-4 mt-5 location-text",
              params: {
                content: this.translate.instant(
                  "IDPCS.address.stepShareLocation.title2",
                ),
              },
            },
            {
              type: "numberedList",
              params: {
                entries: [
                  {
                    text: this.translate.instant(
                      "IDPCS.address.stepShareLocation.content",
                    ),
                    count: "1",
                  },
                  {
                    text: this.translate.instant(
                      "IDPCS.address.stepShareLocation.content2",
                    ),
                    count: "2",
                  },
                  {
                    text: this.translate.instant(
                      "IDPCS.address.stepShareLocation.content3",
                    ),
                    count: "3",
                  },
                ],
              },
            },
            {
              type: "paragraph",
              class: "text-center mb-3",
              params: {
                content: this.translate.instant(
                  "IDPCS.address.stepShareLocation.content4",
                ),
              },
            },
            {
              type: "button_group",
              class: "justify-content-center d-flex mt-1",
              params: [
                {
                  content: this.translate.instant(
                    "IDPCS.address.stepShareLocation.button",
                  ),
                  action: "refreshDataAddress",
                  class: "form-button-1 mx-auto",
                  display: true,
                },
              ],
            },
            {
              type: "image",
              class: "image-reduced-top",
              params: {
                width: "196px",
                height: "196px",
                src: qrCode,
              },
            },
            {
              type: "subtitle-alternate",
              class:
                "increased-margin-top text-center px-4 mt-3 location-text-2",
              params: {
                content: this.translate.instant(
                  "IDPCS.address.stepShareLocation.label",
                ),
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  refreshDataAddress() {
    this.refreshedData = true;
    const currentRoute = this.router.url;
    const parts = currentRoute.split("/"); // Split the URL into parts
    const newUrl = parts.slice(0, parts.length - 1).join("/");
    this.router.navigateByUrl(newUrl);
  }

  /**
   * detect geolocation on Mobile/Tablet device
   */
  async geolocationScreenMobile() {
    let elements: any;
    const message = "Please stand by while we detect your location ..."; // message displayed by the geolocation step
    const extendedDomain =
      `${location.protocol}//` +
      window.location.hostname +
      (!location.port ? "" : `:${location.port}`);

    this.displayLoader = true;

    elements = [
      {
        type: "subtitle-alternate",
        class: "increased-margin-top text-center px-4 mt-5 location-text",
        params: {
          content: this.translate.instant(
            "IDPCS.address.stepShareLocation.label2",
          ),
        },
      },
      {
        type: "paragraph",
        paragraphClass:
          "increased-margin-top text-center px-4 mt-3 location-text-2",
        params: {
          content: message,
        },
      },
      {
        type: "button_group",
        params: [
          {
            content: this.translate.instant(
              "IDPCS.address.stepShareLocation.button2",
            ),
            // 'id': 'accept',
            // 'action': 'OnAcceptLocation',
            class: "form-button-0",
            display: true,
          },
          {
            content: this.translate.instant(
              "IDPCS.address.stepShareLocation.button3",
            ),
            // 'id': 'rescan',
            // 'action': 'OnReScanLocation',
            class: "form-button-0",
            display: true, // TODO: check if user may edit the information
          },
        ],
      },
    ];

    const thisStep = this.stepsArray.find(
      (step) => step.id === "address_lookup",
    );
    thisStep.class = "form-step-active";
    const isMobile = this.helper.isMobile();
    let lightboxClass =
      "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list not-closable";
    if (isMobile) {
      lightboxClass =
        "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list" +
        " not-closable form-lightbox-fullscreen-mobile";
    }
    this.formClass = "";
    this.formType = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.stepsArray.map((step) => {
      if (step.class === "form-step-active") {
        step.name =
          this.position === null
            ? this.translate.instant("IDPCS.address.locationNotDetected")
            : this.translate.instant("IDPCS.address.shareLocation");
      }
    });
    this.lightboxClass = {
      class: lightboxClass,
      stepsTitle: this.translate.instant("IDPCS.address.title"),
      stepsSubtitle: "",
      showStepsMobile: isMobile,
      logoImage: this.logo,
      projectColor: this.projectColor,
      projectTextColorClass: this.projectTextColorClass,
      steps: this.stepsArray,
      loaderClass: "address-loading-screen address-loading-full-screen",
    };
    // build the content for the Create new project lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_new_verification",
      result_details: [
        {
          group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: elements,
        },
      ],
    };

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
    // do the actual detection:
    this.lazyLoadGeolocationScreenMobile();
  }

  async lazyLoadGeolocationScreenMobile() {
    let elements: any;
    let message = this.translate.instant(
      "IDPCS.address.stepShareLocation.label3",
    ); // message displayed by the geolocation step
    const extendedDomain =
      `${location.protocol}//` +
      window.location.hostname +
      (!location.port ? "" : `:${location.port}`);
    let enableReScan = false;

    if (!navigator.geolocation) {
      message = this.translate.instant(
        "IDPCS.address.stepShareLocation.label4",
      );
    } else {
      try {
        this.position = await this.getUserLocation();
        if (this.position !== null) {
          // do nothing
        } else {
          message = this.translate.instant(
            "IDPCS.address.stepShareLocation.label5",
          );
          if (this.helper.isIOS()) {
            message += this.translate.instant(
              "IDPCS.address.stepShareLocation.label6",
            );
            if (this.helper.isChrome()) {
              message += this.translate.instant(
                "IDPCS.address.stepShareLocation.label7",
              );
            }
          } else {
            // android
            message += this.translate.instant(
              "IDPCS.address.stepShareLocation.label7",
            );
          }
        }
      } catch (e) {
        console.log(e.code, e.message);
        message = e.message; // 'An error occured while attempting to fetch your location info! Please try again later.';
        if (e.code === 3) {
          enableReScan = true;
        }
        if (e.code === 1 || e.code === 3) {
          if (e.code === 3 && this.helper.isMobile()) {
            message += this.translate.instant(
              "IDPCS.address.stepShareLocation.label5",
            );
          } else {
            message += this.translate.instant(
              "IDPCS.address.stepShareLocation.label5",
            );
          }

          if (this.helper.isIOS()) {
            message += this.translate.instant(
              "IDPCS.address.stepShareLocation.label6",
            );
            if (this.helper.isChrome()) {
              message += this.translate.instant(
                "IDPCS.address.stepShareLocation.label7",
              );
            }
          } else {
            // android
            if (this.helper.isChrome()) {
              message += this.translate.instant(
                "IDPCS.address.stepShareLocation.label7",
                { extendedDomain: extendedDomain },
              );
            }
            message += message += this.translate.instant(
              "IDPCS.address.stepShareLocation.label9",
            );
          }
        }
        console.log(e);
      }
    }

    this.displayLoader = false;

    if (this.position) {
      elements = [
        {
          type: "notificationParagraph",
          params: {
            content: this.translate.instant(
              "IDPCS.address.stepShareLocation.label10",
            ),
          },
        },
        {
          type: "displayGoogleMap",
          class: "mobile-map-wrapper",
          params: {
            latitude: this.position.coords.latitude,
            longitude: this.position.coords.longitude,
          },
        },
        {
          type: "paragraph",
          class: "mt-3",
          paragraphClass: "mt-3",
          params: {
            content: this.translate.instant(
              "IDPCS.address.stepShareLocation.label11",
            ),
          },
        },
        {
          type: "button_group",
          params: [
            {
              content: this.translate.instant("common.continue"),
              action: "OnAcceptLocation",
              class: "form-button-1 form-button-full-width",
              display: true,
            },
          ],
        },
        {
          type: "paragraph",
          class: "mt-3 text-center",
          paragraphClass: "mt-3",
          params: {
            content: this.translate.instant(
              "IDPCS.address.stepShareLocation.label12",
            ),
          },
        },
        {
          type: "actionAnchor",
          class: "mt-3 text-center",
          params: {
            content: this.translate.instant("common.tryAgain"),
            clickFunction: "OnRescanLocation",
          },
        },
      ];
    } else if (enableReScan) {
      elements = [
        {
          type: "subtitle-alternate",
          class: "increased-margin-top text-center px-4 mt-5 location-text",
          params: {
            content: this.translate.instant(
              "IDPCS.address.stepShareLocation.label13",
            ),
          },
        },
        {
          type: "paragraph",
          paragraphClass:
            "increased-margin-top text-center px-4 mt-3 location-text-2",
          params: {
            content: message,
          },
        },
        {
          type: "button_group",
          params: [
            {
              content: this.translate.instant(
                "IDPCS.address.stepShareLocation.button2",
              ),
              // 'id': 'accept',
              // 'action': 'OnAcceptLocation',
              class: "form-button form-button-0",
              display: true,
            },
            {
              content: this.translate.instant(
                "IDPCS.address.stepShareLocation.button3",
              ),
              // 'id': 'rescan',
              action: "OnRescanLocation",
              class: "form-button form-button-1",
              display: true, // TODO: check if user may edit the information
            },
          ],
        },
      ];
    } else {
      elements = [
        {
          type: "subtitle-alternate",
          class: "increased-margin-top text-center px-4 mt-5 location-text",
          params: {
            content: this.translate.instant(
              "IDPCS.address.stepShareLocation.label13",
            ),
          },
        },
        {
          type: "paragraph",
          paragraphClass:
            "increased-margin-top text-center px-4 mt-3 location-text-2",
          params: {
            content: message,
          },
        },
        {
          type: "button_group",
          params: [
            {
              content: this.translate.instant(
                "IDPCS.address.stepShareLocation.button2",
              ),
              // 'id': 'accept',
              // 'action': 'OnAcceptLocation',
              class: "form-button form-button-0",
              display: true,
            },
            {
              content: this.translate.instant(
                "IDPCS.address.stepShareLocation.button3",
              ),
              // 'id': 'rescan',
              // 'action': 'OnRescanLocation',
              class: "form-button form-button-0",
              display: true, // TODO: check if user may edit the information
            },
          ],
        },
      ];
    }

    const thisStep = this.stepsArray.find(
      (step) => step.id === "address_lookup",
    );
    thisStep.class = "form-step-active";
    const isMobile = this.helper.isMobile();
    let lightboxClass =
      "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list not-closable";
    if (isMobile) {
      lightboxClass =
        "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list" +
        " not-closable form-lightbox-fullscreen-mobile";
    }
    this.formClass = "";
    this.formType = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.stepsArray.map((step) => {
      if (step.class === "form-step-active") {
        step.name =
          this.position === null
            ? this.translate.instant("IDPCS.address.locationNotDetected")
            : this.translate.instant("IDPCS.address.shareLocation");
      }
    });
    this.lightboxClass = {
      class: lightboxClass,
      loaderClass: "address-loading-full-screen",
      stepsTitle: this.translate.instant("IDPCS.address.title"),
      stepsSubtitle: "",
      showStepsMobile: isMobile,
      logoImage: this.logo,
      projectColor: this.projectColor,
      projectTextColorClass: this.projectTextColorClass,
      steps: this.stepsArray,
    };
    // build the content for the Create new project lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_new_verification",
      result_details: [
        {
          group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: elements,
        },
      ],
    };

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  private async getUserLocation() {
    if (!navigator.geolocation) {
      console.log("getLocation not supported or not enabled");
      alert(this.translate.instant("appSpecific.LocationDisabled"));
      return new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject, null),
      );
    }

    return new Promise((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        maximumAge: 0,
        timeout: 10000,
        enableHighAccuracy: true,
      }),
    );
  }

  OnRescanLocation() {
    this.displayLoader = true;
    this.geolocationScreenMobile();
  }

  OnAcceptLocation() {
    this.displayLoader = true;
    this.ipService.getIPAddress().then((resIpAddress) => {
      this.ipAddress = resIpAddress;
      this.ipService
        .getGeoIpAddress(resIpAddress, this.verificationKey)
        .pipe(
          tap((resGeoIpInfo) => {
            this.geoIpAddress = resGeoIpInfo["responseData"];
          }),
          takeUntil(this.unsubscribe$),
        )
        .subscribe(() => {
          this.ipInfo = {
            ipAddress: this.ipAddress,
            ipGeoInfo: this.geoIpAddress,
          };
          this.sendGeolocation();
        });
    });
  }

  sendGeolocation() {
    this.displayLoader = true;
    if (!this.position || !this.ipInfo) {
      console.log("We could not gather all location info!");
    }
    this.http
      .post(
        this.baseUrl +
          "api/verifications/" +
          this.verificationKey +
          "/address/location",
        {
          geoLocationData: {
            geolocation: this.helper.cloneAsObject(this.position),
            ipGeolocationData: this.ipInfo,
            browser: this.helper.myBrowser(),
            device: this.helper.myDeviceType(),
          },
        },
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.verificationRecord.geoLocationFieldsUploadedAt = true;
          this.decideAddressVerificationStep(this.verificationRecord);
        },
        (error) => {
          console.log("Error occured while fetching the access token", error);
        },
      );
  }
  // END DETECT LOCATION
  // START VERIFICAITON CODE
  onAddressVerificationVerificaitonCode(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    const isMobile = this.helper.isMobile();
    let lightboxClass =
      "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list not-closable";
    if (isMobile) {
      lightboxClass =
        "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list" +
        " not-closable form-lightbox-fullscreen-mobile";
    }
    const thisStep = this.stepsArray.find((step) => step.id === "verification");
    thisStep.class = "form-step-active";
    this.formClass = "";
    this.formType = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = {
      class: lightboxClass,
      stepsTitle: this.translate.instant("IDPCS.address.title"),
      stepsSubtitle: "",
      showStepsMobile: isMobile,
      logoImage: this.logo,
      projectColor: this.projectColor,
      projectTextColorClass: this.projectTextColorClass,
      steps: this.stepsArray,
    };
    // build the content for the Create new project lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_new_verification",
      result_details: [
        {
          group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "image",
              class: "my-5",
              params: {
                src: "/assets/images/mail-verification.svg",
                class: "full-max-size",
              },
            },
            {
              type: "subtitle-alternate",
              class: "input-label-large increased-margin-top",
              params: {
                content: this.translate.instant(
                  "IDPCS.address.stepVerificationCode.label1",
                ),
              },
            },
            {
              type: "paragraph",
              class: "mt-2",
              params: {
                paragraphClass: "mt-2",
                content: this.translate.instant(
                  "IDPCS.address.stepVerificationCode.content1",
                ),
              },
            },
            {
              type: "verification_code",
              params: {
                errorMessage: this.translate.instant(
                  "IDPCS.address.stepVerificationCode.error",
                ),
              },
            },
            {
              type: "button",
              class: "mt-5",
              params: {
                content: this.translate.instant(
                  "IDPCS.address.stepShareLocation.button2",
                ),
                display: true,
                action: "addressVerificationConfirmCode",
              },
            },
          ],
        },
      ],
    };

    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);

    this.displayModal = true;
  }
  addressVerificationConfirmCode() {
    // find confirmation code
    let code = "";
    let codeInvadid = false;
    const letters = $("#SMSArea")
      .find(".smsCode")
      .each(function () {
        if ($(this).val() === "") {
          // trigger message for inavlid code
          codeInvadid = true;
        } else {
          code += $(this).val().toString().toUpperCase();
        }
      });
    // find confirmation code
    if (codeInvadid) {
      // show message
      $(".sms-area-wrapper .button-error-field").addClass("d-flex");
    } else {
      // hide message
      $(".sms-area-wrapper .button-error-field").removeClass("d-flex");
      this.displayLoader = true;
      this.http
        .post(
          this.baseUrl +
            "api/verifications/" +
            this.verificationKey +
            "/address/confirm",
          {
            confirmationCode: code,
          },
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (response) => {
            this.verificationRecord.confirmedAt = true;
            this.decideAddressVerificationStep(this.verificationRecord);
          },
          (error) => {
            console.log("Error occured while fetching the access token", error);
            this.displayLoader = false;
            $(".sms-area-wrapper .button-error-field").addClass("d-flex");
          },
        );
    }
  }
  // END VERIFICAITON CODE
  // START DOCUMENT UPLOAD
  onDocumentsUpload(event?, allowedDocuments?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    // Allowed documents must be here in order to continue. Need to redirect to failure screen after that screen is made
    if (!allowedDocuments) {
      console.error("no allowed documents");
      this.router.navigate(["/invalid-general"]);
      return;
    }
    const activeDocument = allowedDocuments.find(
      (document) => document.documentActive === true,
    );
    // If there is no active document something went wrong we need to redirect to failure screen after it is made
    if (!activeDocument) {
      this.router.navigate(["/invalid-general"]);
      return;
    }
    const thisStep = this.stepsArray.find(
      (step) => step.id === "document_upload",
    );
    thisStep.class = "form-step-active";
    const isMobile = this.helper.isMobile();
    // const isMobile = true;
    let lightboxClass =
      "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list not-closable no-form-padding-address border-top-left-fix";
    if (isMobile) {
      lightboxClass =
        "form-lightbox-fullscreen form-lightbox-breadcrumbs document-upload-list no-form-padding-address border-top-left-fix" +
        " not-closable form-lightbox-fullscreen-mobile";
    }
    this.formClass = "";
    this.formType = "";
    this.formTitle = "";
    this.btnOkText = "";
    this.formValidator = "";
    this.btnCancelText = "";
    this.lightboxClass = {
      class: lightboxClass,
      loaderClass: "address-loading-full-screen",
      stepsTitle: this.translate.instant("IDPCS.address.title"),
      stepsSubtitle: "",
      showStepsMobile: isMobile,
      logoImage: this.logo,
      projectColor: this.projectColor,
      projectTextColorClass: this.projectTextColorClass,
      steps: this.stepsArray,
    };
    // build the content for the Create new project lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "request_new_verification",
      result_details: [
        {
          group_result_title: "request_new_address_verification_group", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "documentUploaderSide",
              id: activeDocument.type,
              enabled: true,
              evidence: activeDocument,
              isActive: true,
              class:
                "document-upload-no-list address-document-upload address-document-select",
              params: {
                evidenceKey: this.cryptoKey,
                fundVerification: false,
                reviewState: false,
                evidenceTypes: undefined,
                requestKey: undefined,
                document_category_name: activeDocument.customEvidenceType
                  ? activeDocument.customEvidenceType
                  : this.helper.parseBackendName(activeDocument.type),
                image_upload_id: "evidence_image_id",
                minDate: this.helper.getAsStartDate("01.01.1915"),
                supportedCountries:
                  this.idVerificationService.parseCountryOptions(),
                email_pattern:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                documents: activeDocument.uploadedFiles,
                purposesOfUse: undefined,
                evidenceCategories:
                  this.idVerificationService.parseEvidenceFields(
                    activeDocument.type,
                    activeDocument.savedFields,
                    false,
                  ),
                stateFields: activeDocument.savedFields,
                loadingFromBe: false,
                flowType: "Update",
                isFinalize: false,
                replaceFlow: true,
                addressDocumentUpload: true,
                multipleChoiceDocuments: allowedDocuments,
                isMobile: isMobile,
                uploadNotes:
                  this.verificationRecord.verificationPresets.documentNotes,
              },
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }
  uploadAddressFields(event) {
    // CHECK IF AT LEAST ONE DOCUMENT IS UPLOADED
    if (!$(".evidence-list-image-container").length) {
      event.preventDefault();
      $(".error-field-required-image").removeClass("d-none");
      $(".dropzone").addClass("error-required");
      return;
    } else {
      this.displayLoader = true;
      // failure state
      if (!this.verificationRecord.verificationPresets.allowedDocuments) {
        return;
      }
      const activeDocument =
        this.verificationRecord.verificationPresets.allowedDocuments.find(
          (document) => document.documentActive === true,
        );
      // failure state
      if (!activeDocument) {
        return;
      }
      activeDocument.savedFields = this.idVerificationService.saveFieldsState(
        undefined,
        undefined,
      )["fields"];

      if (!activeDocument.savedFields) {
        return;
      }
      const payload: any = {};
      payload.documentFields = activeDocument.savedFields;
      payload.selectedDocumentType = activeDocument.type;

      if (!payload) {
        console.error("could not create the payload");
        return;
      }
      // make image upload preparatiosn
      const fd = new FormData();
      activeDocument.uploadedFiles.forEach((uploadedDfile) => {
        fd.append("documents", uploadedDfile.file, uploadedDfile.filename);
      });
      this.http
        .post(
          this.baseUrl +
            "api/verifications/" +
            this.verificationKey +
            "/address/documents",
          payload,
        )
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          () => {
            this.http
              .post(
                this.baseUrl +
                  "api/verifications/" +
                  this.verificationKey +
                  "/address/documents/upload",
                fd,
              )
              .pipe(takeUntil(this.unsubscribe$))
              .subscribe(
                () => {
                  this.verificationRecord.documents = true;
                  this.decideAddressVerificationStep(this.verificationRecord);
                },
                (error) => {
                  console.log("Error occured while updating image", error);
                },
              );
          },
          (error) => {
            console.log("Error occured while updating document fields", error);
          },
        );
    }
  }
  addressVerificaitonOnImageUpload(files) {
    $("#AVDocumentsNotSelected").hide();
    // let file = files[0];
    if (!files) {
      return;
    }
    // failure state
    if (!this.verificationRecord.verificationPresets.allowedDocuments) {
      return;
    }
    const activeDocument =
      this.verificationRecord.verificationPresets.allowedDocuments.find(
        (document) => document.documentActive === true,
      );
    // failure state
    if (!activeDocument) {
      return;
    }
    // check if number of uploaded files exsceeds 5
    let docNum = 0;
    if (activeDocument.uploadedFiles) {
      docNum += activeDocument.uploadedFiles.length;
    }
    docNum += files.length;
    if (docNum > 5) {
      $("#AVDocumentsNotSelected").text(
        this.translate.instant("errors.error13"),
      );
      $("#AVDocumentsNotSelected").show();
      return;
    }
    const match = ["image/jpeg", "image/png", "image/gif", "application/pdf"];
    // check if any file exceeds 5MB or in the wrong format
    for (let i = 0; i < files.length; i++) {
      if (
        files[i]["size"] > 5242880 &&
        files[i]["type"] === "application/pdf"
      ) {
        $("#AVDocumentsNotSelected").text(
          this.translate.instant("errors.error15"),
        );
        $("#AVDocumentsNotSelected").show();
        return;
      }
      if (
        !(
          files[i]["type"] === match[0] ||
          files[i]["type"] === match[1] ||
          files[i]["type"] === match[2] ||
          files[i]["type"] === match[3]
        )
      ) {
        $("#AVDocumentsNotSelected").text(
          this.translate.instant("errors.error12"),
        );
        $("#AVDocumentsNotSelected").show();
        return;
      }
    }
    Array.from(files).forEach((file) => {
      // check if active document has files array
      if (!activeDocument.uploadedFiles) {
        activeDocument.uploadedFiles = [];
      }
      activeDocument.uploadedFiles.push({
        key: "DOC" + activeDocument.uploadedFiles.length,
        filename: file["name"],
        file: file,
      });
      activeDocument.savedFields = this.idVerificationService.saveFieldsState(
        undefined,
        undefined,
        undefined,
        undefined,
        true,
      )["fields"];
      this.onDocumentsUpload(
        undefined,
        this.verificationRecord.verificationPresets.allowedDocuments,
      );
    });
  }
  // document deletion
  addressVerificaitonOnDocumentDelete(document) {
    const documentKey = document["key"];
    // failure state
    if (!this.verificationRecord.verificationPresets.allowedDocuments) {
      return;
    }
    const activeDocument =
      this.verificationRecord.verificationPresets.allowedDocuments.find(
        (document1) => document1.documentActive === true,
      );
    // failure state
    if (!activeDocument) {
      return;
    }
    const indexToDelete = activeDocument.uploadedFiles.findIndex(
      (document2) => document2.key === documentKey,
    );
    activeDocument.uploadedFiles.splice(indexToDelete, 1);
    activeDocument.savedFields = this.idVerificationService.saveFieldsState(
      undefined,
      undefined,
      undefined,
      undefined,
      true,
    )["fields"];
    this.onDocumentsUpload(
      undefined,
      this.verificationRecord.verificationPresets.allowedDocuments,
    );
  }
  multipleChoiceEvidenceChange(event) {
    if (!this.verificationRecord.verificationPresets.allowedDocuments) {
      return;
    }
    const activeDocument =
      this.verificationRecord.verificationPresets.allowedDocuments.find(
        (document) => document.documentActive === true,
      );
    // failure state
    if (!activeDocument) {
      return;
    }
    // save the fields
    activeDocument.savedFields = this.idVerificationService.saveFieldsState(
      undefined,
      undefined,
      undefined,
      undefined,
      true,
    )["fields"];
    // change the active evidence
    activeDocument.documentActive = undefined;
    const newEvidence = $(event.target)
      .attr("id")
      .toString()
      .replace("multipleradio", "");

    let newActiveDocument =
      this.verificationRecord.verificationPresets.allowedDocuments.find(
        (document) => document.type === newEvidence,
      );
    if (!newActiveDocument) {
      newActiveDocument =
        this.verificationRecord.verificationPresets.allowedDocuments.find(
          (document) => document.customEvidenceType === newEvidence,
        );
    }
    newActiveDocument.documentActive = true;
    this.onDocumentsUpload(
      undefined,
      this.verificationRecord.verificationPresets.allowedDocuments,
    );
  }
  setMinEndDate() {}
  // END DOCUMENT UPLOAD
  // FINAL SCREEN
  // Verification is done and this is just a confirmation
  onAddressVerificationEnd(event?) {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    // keep the final step for AV but do not display it
    // instead return to global verifications link
    this.helper.navigateToGeneralVerificationsLink(this.verificationKey);
    return;
  }
  // END FINAL SCREEN
  // ***** END ADDRESS VERIFICATION STEP FUNCTIONS

  // HELPER FUNCTIONS FOR THE LIGHTBOX
  /**
   * handle click on lightbox confirm
   * @param event event
   */
  onLightboxConfirm(event) {
    if (typeof event.method === "string" && event.method !== "") {
      // allow only strings as acceptable method name
      let params = "";
      if (Array.isArray(event.params) || typeof event.params === "object") {
        params = event.params;
      }
      if (typeof event.params.name === "string") {
        params = event.params;
      }
      try {
        // console.log('calling', event.method, 'with params', params);
        this[event.method](params); // call if it exists
      } catch (error) {
        console.log(
          event.method + " needs to be defined before it is called",
          "sending params:",
          params,
          error,
        );
      }
    } else {
      console.log("**method name not string or empty string");
    }
    // execute default action
    if (typeof event.method === "undefined") {
      // allow only strings as acceptable method name
      // execute default OnConfirm action for this component
      this.displayLoader = true; // display the loading screen
    }
  }
  /**
   * handle click on lightbox close
   */
  onLightboxClose(event) {
    let classes = "";
    if ($(event.target).hasClass("close-form-button")) {
      classes = $(event.target).attr("class");
    } else {
      classes = $(event.target).closest(".close-form-button").attr("class");
    }
    let lightboxType = "displayModal";
    if (classes && classes.includes("top-dialog-box")) {
      lightboxType = "topDisplayModal";
    }
    if (classes && classes.includes("foremost-dialog-box")) {
      lightboxType = "foremostDisplayModal";
    }
    this[lightboxType] = false;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
