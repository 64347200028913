<div class="collapisble-evidence">
  <form *ngIf="element.isActive" class="form-container">
    <div
      (click)="onEmit(elementService.collapseDigitalVerification($event))"
      class="collapsible-title input-label-large d-flex justify-content-between align-items-center"
      [attr.elementToCollapse]="
        'id-verification-collapsible' + digitalVerification.originalEvidenceKey
      "
      [ngClass]="
        element.params.activeDVKey === digitalVerification.originalEvidenceKey
          ? 'collapsed'
          : ''
      "
    >
      <!--ALTERNATE  SUBTITLE USED IN NEW FORMS-->
      <h4>Identity Verification</h4>
      <div>
        <img
          *ngIf="
            (!element.params.reviewSide && !digitalVerification.notSharable) ||
            (element.params.reviewSide && !digitalVerification.reviewState)
          "
          class="mr-3"
          src="assets/images/check.svg"
        />
        <img class="collapse-icon" src="assets/images/result_arrow_down.png" />
      </div>
    </div>
    <div
      class="element-wrapper mt-0 collapsible-verification id-verification-collapsible"
      id="{{
        'id-verification-collapsible' + digitalVerification.originalEvidenceKey
      }}"
      [attr.collapseKey]="
        'id-verification-collapsible' + digitalVerification.originalEvidenceKey
      "
      [ngClass]="
        element.params.activeDVKey === digitalVerification.originalEvidenceKey
          ? 'collapsed'
          : ''
      "
    >
      <div class="input-fields-wrapper">
        <!-- INPUT FIELDS -->
        <div
          *ngIf="element.params.showCountriesAndTypes"
          class="evidence-fields type-country-wrapper"
        >
          <div class="element-wrapper input-transparent">
            <span class="input-label font-weight-bold"
              >Accept documents from</span
            >
            <span class="select-wrapper">
              <select
                class="select-input"
                [disabled]="element.investorSide"
                id="IDVerificationCountry"
                name="IDVerificationCountry"
                (change)="onEmit(elementService.OnChangeSelect($event))"
                (click)="onEmit(elementService.OnChangeSelect($event))"
                [attr.changemethod]="'onChangeTypeCountry'"
                [attr.isRequired]="'false'"
                [value]="'emptySelect'"
              >
                <option
                  value="{{ option.countryCode }}"
                  [selected]="
                    verificationPresets &&
                    verificationPresets.country == option.countryCode
                      ? true
                      : false
                  "
                  *ngFor="let option of element.params.countryList"
                >
                  {{ option.countryName }}
                </option>
              </select>
            </span>
            <div
              class="error-field"
              [attr.validatorMessage]="element.params.validatorMessage"
              [attr.validatorMessageField]="element.params.validatorMessage"
            ></div>
          </div>
        </div>
        <div
          *ngIf="element.params.showCountriesAndTypes"
          class="evidence-fields verification-type-wrapper"
        >
          <div class="element-wrapper input-transparent">
            <span class="input-label font-weight-bold"
              >Accept documents of type</span
            >
            <span class="select-wrapper">
              <select
                class="select-input"
                [disabled]="element.investorSide"
                id="IDVerificationType"
                name="IDVerificationType"
                (change)="onEmit(elementService.OnChangeSelect($event))"
                [attr.isRequired]="'false'"
                [value]="'emptySelect'"
              >
                <option
                  [selected]="
                    verificationPresets &&
                    verificationPresets.type &&
                    verificationPresets.type.toLowerCase() ==
                      option.key.toLowerCase()
                      ? true
                      : false
                  "
                  value="{{ option.key }}"
                  *ngFor="let option of element.params.idTypeList"
                >
                  {{ option.name }}
                </option>
              </select>
            </span>
            <div
              class="error-field"
              [attr.validatorMessage]="element.params.validatorMessage"
              [attr.validatorMessageField]="element.params.validatorMessage"
            ></div>
          </div>
        </div>
        <div class="evidence-fields">
          <div class="element-wrapper input-transparent">
            <span class="input-label font-weight-bold">Language</span>
            <span class="select-wrapper">
              <select
                class="select-input"
                [disabled]="element.investorSide"
                id="Locale"
                name="Locale"
                (change)="onEmit(elementService.OnChangeSelect($event))"
                [attr.isRequired]="'false'"
                [(ngModel)]="
                  element.params.reviewSide && verificationPresets
                    ? verificationPresets.locale
                    : element.params.defaultLocale
                "
              >
                <option
                  value="{{ option.key }}"
                  [selected]="
                    verificationPresets &&
                    verificationPresets.locale == option.key
                      ? true
                      : false
                  "
                  *ngFor="let option of element.params.localeList"
                >
                  {{ option.name }}
                </option>
              </select>
            </span>
            <div
              class="error-field"
              [attr.validatorMessage]="element.params.validatorMessage"
              [attr.validatorMessageField]="element.params.validatorMessage"
            ></div>
          </div>
        </div>
        <div class="evidence-fields verification-methods-wrapper">
          <div class="element-wrapper input-transparent">
            <span class="input-label font-weight-bold"
              >Capture methods allowed</span
            >
            <span class="select-wrapper">
              <select
                class="select-input"
                [disabled]="element.investorSide"
                id="WorkflowID"
                name="WorkflowID"
                (change)="onEmit(elementService.OnChangeSelect($event))"
                [attr.isRequired]="'false'"
                [value]="'201'"
              >
                <option
                  [selected]="
                    verificationPresets &&
                    verificationPresets.workflowId == option.key
                      ? true
                      : false
                  "
                  value="{{ option.key }}"
                  *ngFor="let option of element.params.workflowList"
                >
                  {{ option.name }}
                </option>
              </select>
            </span>
            <div
              class="error-field"
              [attr.validatorMessage]="element.params.validatorMessage"
              [attr.validatorMessageField]="element.params.validatorMessage"
            ></div>
          </div>
        </div>
        <div class="evidence-fields">
          <div class="element-wrapper mt-2 input-transparent">
            <input
              class="custom-checkbox"
              type="checkbox"
              id="idVerificationAlternate"
              name="idVerificationAlternateN"
              value="id alternate"
              [checked]="false"
            />
            <label class="custom-checkbox-label" for="idVerificationAlternate">
              Verify document only</label
            >
          </div>
        </div>
        <div class="evidence-fields">
          <div class="input-label font-weight-bold mt-4">
            Document used for:
          </div>
          <div class="purpose-tag-wrapper d-flex">
            <div class="purpose-tag px-3 mt-1">Proof of identity</div>
          </div>
        </div>
        <!-- for now not older than is not needed on ID Verification -->
        <div
          *ngIf="
            !element.investorSide &&
            !element.params.replaceFlow &&
            !element.params.addressDocumentUpload
          "
          class="evidence-fields"
        >
          <div class="element-wrapper">
            <!-- Input Date -->
            <span class="input-label font-weight-bold">
              Not older than
              <!-- <sup class="sup-required">*</sup> -->
            </span>
            <app-my-date-picker
              name="notOlderThanNID"
              id="notOlderThanID"
              method="setMinEndDate"
              dateInputId="notOlderThanNID"
              isReqiured="false"
              startDate="{{ helperService.getAsMinDateMinusYears(1) }}"
              maxDate=""
              minDate=""
              (dateChange)="
                onEmit(elementService.parseDateContentReturnOlderThan($event))
              "
              value="{{
                helperService.getAsStartDate(digitalVerification.notOlderThan)
              }}"
              [(ngModel)]="element.notOlderThanModel"
            ></app-my-date-picker>
            <div
              class="error-field"
              [attr.validatorMessage]="'*this field is required'"
              [attr.validatorMessageField]="'*this field is required'"
            ></div>
            <div
              class="error-field-wrong-format d-none"
              [attr.validatorMessage]="
                '*the date must be in the correct format: dd.mm.yyyy'
              "
            ></div>
          </div>
        </div>
        <!-- force newer version -->
        <div
          *ngIf="
            !element.investorSide &&
            !element.params.replaceFlow &&
            !element.params.addressDocumentUpload
          "
          class="evidence-fields"
        >
          <div class="element-wrapper input-transparent">
            <input
              class="custom-checkbox"
              type="checkbox"
              id="forceID"
              name="forceN"
              value="force"
              [checked]="
                digitalVerification && digitalVerification.force ? true : false
              "
            />
            <label class="custom-checkbox-label" for="forceID">
              Force the client to perform this step (do not accept existing
              information)
            </label>
          </div>
        </div>
      </div>
      <!-- BUTTONS -->
      <div class="d-flex justify-content-between align-items-center">
        <button
          *ngIf="
            !element.params.reviewSide ||
            (element.params.reviewSide &&
              !digitalVerification.reviewState === false)
          "
          [attr.clickmethod]="'htpmSubmitEvidenceFields'"
          [attr.data-evidenceKey]="element.params.evidenceKey"
          [attr.data-dvType]="digitalVerification.originalEvidenceKey"
          [attr.data-verificationType]="'Identity'"
          [attr.data-originalKey]="digitalVerification.originalEvidenceKey"
          [attr.data-requestKey]="element.params.requestKey"
          (click)="
            onEmit(
              elementService.OnButtonClick($event, element.params.evidenceTypes)
            )
          "
          type="submit"
          class="form-button form-button-1 mt-4 evidence-submit-button"
        >
          {{
            isLastElement && element.params.reviewSide
              ? "Save and next"
              : isLastElement && !element.params.reviewSide
              ? "Save and request"
              : "Save"
          }}
        </button>
        <button
          (click)="
            onEmit(
              elementService.onDeleteEvidence(
                $event,
                element.params.requestKey,
                element.params.evidenceTypes,
                element.params.evidenceKey,
                digitalVerification.originalEvidenceKey
              )
            )
          "
          *ngIf="
            !element.investorSide &&
            !element.params.replaceFlow &&
            !element.params.addressDocumentUpload
          "
          class="evidence-delete-button mt-auto"
          [attr.data-requestKey]="element.params.requestKey"
          [attr.data-evidenceKey]="element.params.evidenceKey"
        ></button>
      </div>
    </div>
  </form>
</div>
