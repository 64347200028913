import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DomSanitizer } from "@angular/platform-browser";
import { HelperServiceService } from "src/app/helper-service.service";
import { VerificationsService } from "src/app/verifications/verifications.service";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { count, takeUntil } from "rxjs/operators";
import { UserService } from "src/app/user.service";
import { environment } from "./../../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { ElementsService } from "src/app/ui/content-builder/content-elements/components/elements.service";

@Component({
  selector: "app-verification-landing",
  templateUrl: "./verification-landing.component.html",
  styleUrls: ["./verification-landing.component.scss"],
})
export class VerificationLandingComponent implements OnInit, OnDestroy {
  waitForJumioResponse = false; // if IDV is part of the verification always attempt to lazy resolve it on VS side
  idvResolved = false; // for use with waitForJumioResponse! If set to true it means the IDV was resolved successfully
  idvRetry = false; // if set to true it will not display PCS but instead it will attempt to retry IDV
  intervalDuration = 7000;
  intervalCounter = 60;
  intervalCycleDuration = 7; // 60;
  intervalCycle = 0;
  // maxIntervalCycles: number = 3;  // retry max 3 times
  maxIntervalCycles = 25; // retry max 25 times aprox. 3 minutes
  interval: any;
  pills: any;
  activePill: any;
  completedPill: any;
  debugMode: boolean; // if set to true it will test local iframe embedding for debugging purposes
  unsubscribe$: Subject<void> = new Subject<void>();
  logo: string;
  projectColor: string;
  projectTextColorClass: string;
  projectMessage: string;
  message: string;
  verificationKey: string;
  privacyUrl: string;
  headerInfo: {};
  headerClasses: {};
  iframeSrc;
  consentParsed = false; // have all vfe's been processed to determine if all of them are consented
  consent = true; // can be: false - no consent, true - consent
  project: string;
  displayLoader: boolean;
  iosUnsupported: boolean;
  androidUnsupported: boolean;
  isUnsupportedBrowser: boolean;
  isCustomLogo = false; // Variable for adding extra classes for logo size
  userAgent;
  responseRecord = null;
  contentDom = [];
  activeVFEs = [];
  iDinEnabled = false;
  resolveIdin = false;
  forceResolveIdin = false; // onload, whenever there is an AV with iDIN pending, it should attempt to resolve
  fullyCompleted = true;
  failed = false;
  consentGiven = false;
  nextPill: any = null;
  idvVerified = false;
  VFEs;

  verificationHeading;
  showVerificationButton;
  showAVerificationButton;
  errorState;
  waitingState = false;
  pleaseWaitMsg = "";
  stateMessage;
  showLanguageOption;
  baseUrl = environment.APIEndpoint;
  showBackButton;
  backbuttonText;

  constructor(
    private activatedRoute: ActivatedRoute,
    private verificationsService: VerificationsService,
    private helper: HelperServiceService,
    private router: Router,
    private http: HttpClient,
    public translate: TranslateService,
    private elementService: ElementsService,
  ) {}

  ngOnInit() {
    this.waitingState = true;
    this.pleaseWaitMsg = this.translate.instant("common.pleaseWait");
    this.showLanguageOption = false;
    this.debugMode = false; // SET THIS TO TRUE TO DIRECTLY EMBED A SUCCESS OR FAILURE PAGE
    this.displayLoader = false;
    this.logo = null;
    this.projectColor = "#ffffff";
    // the default is already set even though background color is not retreived
    this.projectTextColorClass =
      this.verificationsService.getProjectTextColorClass();
    this.message = "";
    this.project = "";
    this.iosUnsupported = false;
    this.isUnsupportedBrowser = false;
    this.androidUnsupported = false;
    this.userAgent = navigator.userAgent;
    this.showVerificationButton = false;
    this.showBackButton = window.top.location.href.includes("investor");

    this.headerInfo = {
      headerType: "full", // 'inline',
      headerTitle: "Integrity Check",
      activeNavLink: "integrityCheck",
    };
    this.headerClasses = {
      backgroundClass: "tealish-gradient-bg",
      sectionClass: "ic_height_full_page", // 'ic_height_auto',
    };

    this.verificationKey = this.activatedRoute.snapshot.params.verificationKey;
    if(this.verificationsService.waitUntilVFECall) {
      this.verificationsService.waitUntilVFECall = false;
      setTimeout(() => {
        this.makeVFECall();
      }, 5000);
    } else {
      this.makeVFECall();
    }

    /**
     * subscribe to verification logo changes
     */
    this.verificationsService.detectMyVerificationLogo
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        this.logo = "assets/images/meshId_logo.svg";
        if (response !== null) {
          this.logo = response;
          this.isCustomLogo = true;
        }
        this.verificationsService.setProjectLogo(this.logo);
      });
  }


  makeVFECall() {
    // call GET verifications/data/:verificationKey to fetch verification event
    // look if it is already rejected/verified if yes, notifyt he user of it,
    // check if it is expired, if yes, notify the user of it. If all is good then
    // load the url in an iframe
    this.verificationsService
      .getVerificationLandingToken(this.verificationKey)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (response) => {
        this.showLanguageOption = true;
        this.verificationHeading = this.translate.instant(
          "IDPCS.landing.title2",
        );
        // VFE definition
        this.VFEs = [
          {
            type: "lastRequestedDataVerification",
            name: this.translate.instant(
              "IDPCS.landing.pills.lastRequestedDataVerification.name",
            ),
            subTitle: this.translate.instant(
              "IDPCS.landing.pills.lastRequestedDataVerification.subTitle",
            ),
            subTitleCompleted: this.translate.instant(
              "IDPCS.landing.pills.lastRequestedDataVerification.subTitleCompleted",
            ),
            subTitleRejected: this.translate.instant(
              "IDPCS.landing.pills.lastRequestedDataVerification.subTitleRejected",
            ),
            method: "startBasicInformation",
            vfe: `person.record.lastRequestedDataVerification.key`,
            resolveEndpoint: `api/verifications/{projectKey}/data`,
          },
          {
            type: "lastVerification",
            name: this.translate.instant(
              "IDPCS.landing.pills.lastVerification.name",
            ),
            subTitle: this.translate.instant(
              "IDPCS.landing.pills.lastVerification.subTitle",
            ),
            subTitleCompleted: this.translate.instant(
              "IDPCS.landing.pills.lastVerification.subTitleCompleted",
            ),
            subTitleRejected: this.translate.instant(
              "IDPCS.landing.pills.lastVerification.subTitleRejected",
            ),
            method: "startIDVerificationProcess",
            lazyCondition: `person.record.lastVerification !== undefined
                                  && person.record.lastVerification.record
                                  && ((person.record.lastVerification.record.isPartial !== undefined
                                  && person.record.lastVerification.record.isPartial)
                                  || person.record.lastVerification.record.status === 'processing' )`,
            lazyConditionMet: `person.record.lastVerification !== undefined
                                  && (!person.record.lastVerification.record
                                  || (person.record.lastVerification.record && person.record.lastVerification.record.isPartial !== undefined
                                  && !person.record.lastVerification.record.isPartial))`,
            lazyDelay: 15 * 60 * 1000, // delay lazy load for 15 minutes (900000ms)
            vfe: `person.record.lastVerification.key`,
            resolveEndpoint: `api/projects/{projectKey}/people/{personKey}/verifications/identity/{personVfe}/resolve`,
          },
          {
            type: "lastAddressVerification",
            name: this.translate.instant(
              "IDPCS.landing.pills.lastAddressVerification.name",
            ),
            subTitle: this.translate.instant(
              "IDPCS.landing.pills.lastAddressVerification.subTitle",
            ),
            subTitleCompleted: this.translate.instant(
              "IDPCS.landing.pills.lastAddressVerification.subTitleCompleted",
            ),
            subStepsTitle: this.translate.instant(
              "IDPCS.landing.pills.lastAddressVerification.subStepsTitle",
            ),
            method: "startAVerificationProcess",
            subStepsLabel: this.translate.instant(
              "IDPCS.landing.pills.lastAddressVerification.subStepsLabel",
            ),
            substeps: [
              {
                id: "standard",
                title: this.translate.instant(
                  "IDPCS.landing.pills.lastAddressVerification.substeps.standard",
                ),
                description: this.translate.instant(
                  "IDPCS.landing.pills.lastAddressVerification.substeps.descriptionStandard",
                ),
                clickAction: "startAVerificationProcess",
              },
              {
                id: "idin",
                title: this.translate.instant(
                  "IDPCS.landing.pills.lastAddressVerification.substeps.iDIN",
                ),
                description: this.translate.instant(
                  "IDPCS.landing.pills.lastAddressVerification.substeps.descriptioniDIN",
                ),
                logo: "/assets/images/idin-logo.svg",
                logoWidth: "35px",
                clickAction: "startIdinVerificationProcess",
              },
            ],
            substepsradio: [
              {
                type: "radiobutton_group",
                params: [
                  {
                    type: "radio",
                    params: {
                      id: "phone_call",
                      name: "activity",
                      label: this.translate.instant(
                        "IDPCS.landing.pills.lastAddressVerification.substeps.label",
                      ),
                      value: "Phone Call",
                    },
                  },
                  {
                    type: "radio",
                    params: {
                      id: "face_to_face",
                      name: "activity",
                      label: this.translate.instant(
                        "IDPCS.landing.pills.lastAddressVerification.substeps.label2",
                      ),
                      value: "Face to face meeting",
                    },
                  },
                ],
              },
            ],
            lazyCondition: `person.record.lastAddressVerification !== undefined
                                  && person.record.lastAddressVerification.record.relatedIdinVerification !== undefined
                                  && person.record.lastAddressVerification.record.status
                                  && (person.record.lastAddressVerification.record.status.toUpperCase() === 'IN_PROGRESS'
                                  || person.record.lastAddressVerification.record.status.toUpperCase() === 'LAZY_REFRESH'
                                  )`,
            // vfe: `person.record.lastAddressVerification.key`,
            lazyDelay: 0,
            vfe: `person.record.lastAddressVerification.record.relatedIdinVerification`, // we need to actually use the relatedIdinVerification key for this one
            resolveEndpoint: `api/projects/{projectKey}/people/{personKey}/verifications/idin/{relatedIdinVerification}/resolve`,
          },
          // iDIN is no longer considered a separate VFE
          // , {
          //     type: 'lastIDin',
          //     substep: true
          // }
          {
            type: "lastContractVerification",
            name: this.translate.instant(
              "IDPCS.landing.pills.lastContractVerification.name",
            ),
            subTitle: this.translate.instant(
              "IDPCS.landing.pills.lastContractVerification.subTitle",
            ),
            subTitleCompleted: this.translate.instant(
              "IDPCS.landing.pills.lastContractVerification.subTitleCompleted",
            ),
            method: "startContractSigningProcess",
          },
          {
            type: "lastApsVerification",
            automaticExecution: true, // pill will not be displayed for this step and it will be auto executed when it becomes the next pill
          },
        ];
        // VFE definition
        this.backbuttonText = this.translate.instant("common.goBack");
        if (response === null) {
          this.errorState = true;
          this.router.navigate(["/invalid-link"]);
        }
        this.responseRecord = response;
        if (
          response["record"] &&
          response["record"].projectBranding &&
          response["record"].projectBranding.projectColor
        ) {
          this.verificationsService.setProjectColor(
            response["record"].projectBranding.projectColor,
          );
        } else {
          this.verificationsService.setProjectColor("#00889c");
          this.projectTextColorClass = "light-color";
        }
        this.projectColor = this.verificationsService.getProjectColor();
        this.projectTextColorClass =
          this.verificationsService.getProjectTextColorClass();
        this.project = response["record"].projectName;
        this.projectMessage = response["record"].projectBranding
          ? response["record"].projectBranding.consentMessage
          : undefined;
        this.privacyUrl = response["record"].projectBranding
          ? response["record"].projectBranding.privacyUrl
          : undefined;

        if (
          response["record"].projectBranding &&
          response["record"].projectBranding.projectLogo
        ) {
          // set it through subscription
          this.verificationsService.setVerificationLogo(
            response["record"].projectBranding.projectLogo,
          );
        } else {
          // ask for verification logo from BE
          this.verificationsService.getVerificationLogo(this.verificationKey);
        }

        // check if to show verificaiton button
        // if verification object is created and status is pending
        this.generateActiveVFEs(response.record);

        // check if IDV should be retried
        if (this.idvRetry) {
          this.retryIdVerification(this.verificationKey);
          return;
        }

        if (
          (this.activatedRoute.snapshot.url[
            this.activatedRoute.snapshot.url.length - 1
          ].path === "resolve" &&
            this.resolveIdin) ||
          this.forceResolveIdin
        ) {
          if (this.forceResolveIdin) {
            console.log("idin is FORCED to resolve");
          }
          this.displayLoader = true;
          this.waitingState = true;
          // attempting iDIN resolve
          this.verificationHeading =
            this.translate.instant("common.pleaseWait");
          this.stateMessage = this.translate.instant("IDPCS.landing.content3");
          await this.resolveIdinVerification(this.verificationKey, true);
        }

        this.pills = this.generatePills();
        this.activePill = this.getActiveClickMethod(this.pills);
        // check if active pill should be automatically executed:
        if (this.activePill && !!this.activePill.isAutomaticPill) {
          this.displayLoader = true;
          this.startApsVerification(this.verificationKey);
          return;
        }
        // new requirements:
        // we will always execute jumio lazy resolve as long as IDV was part of the verification
        // this.completedPill = this.getLastCompletedPill(this.pills);
        // console.log('pills', this.pills, 'activePill', this.activePill, 'completed pill', this.completedPill);
        // lazy resolve testinng change
        if (
          // (this.waitForJumioResponse && this.fullyCompleted)
          // || (this.waitForJumioResponse && this.nextPill && this.nextPill.requiresIdVerification))
          (this.waitForJumioResponse && this.fullyCompleted) ||
          (this.waitForJumioResponse &&
            this.nextPill &&
            this.nextPill.requiresIdVerification)
        ) {
          // if (
          //   this.waitForJumioResponse
          // ) {
          // wait for jumio resolve to complete
          // console.log('test1', this.waitForJumioResponse, this.nextPill, this.nextPill.requiresIdVerification);
          this.displayWaitingScreen(
            this.responseRecord,
            !this.idvVerified &&
              this.waitForJumioResponse &&
              this.nextPill &&
              this.nextPill.requiresIdVerification,
          );
        } else {
          console.log("genrating the content as we don't need to resolve!");
          this.waitingState = false;
          this.generateContent();
        }
      });
  }

  /**
   * displays waiting screen and handles appropriate actions
   * for now we are only handling IDV as this is the only dependent step
   * @param person - full person record
   */
  async displayWaitingScreen(person, force = false) {
    this.waitingState = true;
    // this.intervalCounter = this.intervalCycleDuration;
    const idv = this.VFEs.filter((vfe) => vfe.type === "lastVerification")[0];
    // console.log(idv);
    // console.log(person);
    // for testing purposes:
    // person.record.lastVerification = {
    //   record: {
    //     isPartial: true,
    //   }
    // }
    // console.log('********person, expected that lastVerification will have .record param', person);
    if (eval(idv.lazyConditionMet)) {
      this.idvResolved = true;
    }

    // console.log('test', this.idvResolved, eval(idv.lazyCondition), force);
    // lazy resolve testinng change
    // if (!this.idvResolved && (eval(idv.lazyCondition) || force)) {
    if (!this.idvResolved) {
      this.verificationHeading = this.translate.instant("common.pleaseWait");
      this.stateMessage = this.translate.instant("IDPCS.landing.content3");
      // await this.resolveIdVerification(this.verificationKey);
      if (!this.idvResolved) {
        this.doIdvLazyResolve();
      }
    } else {
      console.log("lazyCondition not met! I will skip IDV lazy resolve!");
      this.waitingState = false;
      this.generateContent();
    }
  }

  /**
   * resolveIdinVerification - usually on /verifications/{cryptoKey}/idin/resolve
   * @param cryptoKey - cryptoKey for the verification
   */
  async startApsVerification(cryptoKey) {
    this.displayLoader = false;
    this.waitingState = true;
    this.verificationHeading = this.translate.instant("common.pleaseWait");
    this.stateMessage = this.translate.instant("IDPCS.landing.content3");
    await this.http
      .post(this.baseUrl + `api/verifications/${cryptoKey}/aps`, "")
      .toPromise()
      .then((response) => {
        if (response === undefined) {
          this.errorState = true;
          this.displayLoader = false;
          this.stateMessage = "";
        }
        // refresh the page
        // this.generateContent();
        this.verificationHeading = this.translate.instant(
          "IDPCS.landing.title2",
        );
        this.reloadLocation();
        this.waitingState = false;
        this.displayLoader = false;
      })
      .catch((error) => {
        console.error(
          `Error while attempting to resolve APS for ${cryptoKey}`,
          error,
        );
        this.displayLoader = false;
        this.errorState = true;
        this.stateMessage = "";
      });
  }

  // helper function to properly reload the page
  reloadLocation() {
    const currentRoute = this.router.url;
    this.router
      .navigateByUrl("/verifications", { skipLocationChange: true })
      .then(() => {
        this.router.navigate([currentRoute]); // navigate to same route
      });
  }
  /**
   * resolveIdinVerification - usually on /verifications/{cryptoKey}/idin/resolve
   * @param cryptoKey - cryptoKey for the verification
   */
  async resolveIdinVerification(cryptoKey, continueOnError = false) {
    await this.http
      .post(this.baseUrl + `api/verifications/${cryptoKey}/idin/resolve`, "")
      .toPromise()
      .then((response) => {
        if (response === undefined) {
          console.log(
            `Error while attempting to resolve iDIN for: ${cryptoKey}`,
          );
          if (continueOnError) {
            this.displayLoader = this.waitingState = this.errorState = false;
          } else {
            this.displayLoader = false;
            this.stateMessage = this.translate.instant("IDPCS.landing.error2");
          }
        }
        // update the verification of the person with personKey
        // and push an event for change in list of active legal representatives
        // we are not updating the ACTIVE person verification information
        // because by the time we got a reply from server the active person may have been changed
        // this.updatePersonVerificationStatus(person.key, this.helperService.sanitize(response), person.vfeType);
        // console.log('RESPONSE FOR IDN RESOLVE', response);
        this.router.navigate([`/verifications/${cryptoKey}`]);
      })
      .catch((error) => {
        console.error(
          `Error while attempting to resolve iDIN for ${cryptoKey}`,
          error,
        );
        if (continueOnError) {
          this.displayLoader = this.waitingState = this.errorState = false;
        } else {
          this.displayLoader = false;
          this.stateMessage = this.translate.instant("IDPCS.landing.error2");
        }
      });
  }

  /**
   * do IDV lazy resolve
   */
  doIdvLazyResolve() {
    this.interval = setInterval(() => {
      if (this.intervalCycle === this.maxIntervalCycles - 1) {
        console.log(
          "clearing interval and generating the content",
          this.intervalCycle,
          this.maxIntervalCycles,
        );
        clearInterval(this.interval);
        this.waitingState = false;
        this.generateContent();
        return;
      }
      // this.intervalCounter--;
      // this.stateMessage = `We could not resolve verification!<br>Retrying in ${this.intervalCounter}s`;
      this.verificationHeading = this.translate.instant("common.pleaseWait");
      this.stateMessage = this.translate.instant("IDPCS.landing.content3");
      // if(this.intervalCounter === 0) {
      console.log("continuing cycle", this.intervalCounter);
      this.intervalCycle++;
      // this.stateMessage = `Retrying ...`;
      this.resolveIdVerification(this.verificationKey);
      // this.intervalCounter = this.intervalCycleDuration;
      // }
    }, this.intervalDuration);
  }

  /**
   * resolveIdVerification - resolve identity verification - usually when waitForJumioResponse == true
   * @param cryptoKey - cryptoKey for the verification
   */
  async resolveIdVerification(cryptoKey) {
    await this.http
      .post(
        this.baseUrl + `api/verifications/${cryptoKey}/identity/resolve`,
        "",
      )
      .toPromise()
      .then((response) => {
        if (response === undefined) {
          console.log(
            `Error while attempting to resolve identity for: ${cryptoKey}`,
          );
          this.displayLoader = false;
          this.verificationHeading =
            this.translate.instant("common.pleaseWait");
          this.stateMessage = this.translate.instant("IDPCS.landing.content3");
        }
        // id verification was successfully resolved
        clearInterval(this.interval);
        console.log(
          "clearing, on success, interval and generating the content",
        );
        if (response["status"] === "rejected_id_none") {
          // redirect to retry
          this.retryIdVerification(cryptoKey);
        } else {
          // continue with the proces control screen
          this.verificationHeading = this.translate.instant(
            "IDPCS.landing.title2",
          );
          this.generateContent();
          this.waitingState = false;
          this.idvResolved = true;
        }
      })
      .catch((error) => {
        console.error(
          `Error while attempting to resolve verification for ${cryptoKey}`,
          error,
        );
        this.displayLoader = false;
        this.verificationHeading = this.translate.instant("common.pleaseWait");
        this.stateMessage = this.translate.instant("IDPCS.landing.content3");
      });
  }

  async retryIdVerification(cryptoKey) {
    this.waitingState = true;
    this.stateMessage = this.translate.instant("IDPCS.landing.content4");
    await this.http
      .post(this.baseUrl + `api/verifications/${cryptoKey}/identity/retry`, "")
      .toPromise()
      .then((response) => {
        if (response === undefined) {
          console.log(
            `Error while attempting to retry identity verification for: ${cryptoKey}`,
          );
          this.displayLoader = false;
          this.stateMessage = this.translate.instant("IDPCS.error");
        }
        this.waitingState = this.errorState = false;
        // we expect that the user will be able to reach here
        // only if they can/must retry IDV. Accordding to process flow
        // we should directly retrigger new IDV process and not display any PCS steps
        this.startIDVerificationProcess();
      })
      .catch((error) => {
        console.error(
          `Error while attempting to retry identity verification for ${cryptoKey}`,
          error,
        );
        this.displayLoader = false;
        this.stateMessage = this.translate.instant("IDPCS.error");
      });
  }

  /**
   * call this method on click of a Content Builder button (not a submit)
   * @param returnedAction event
   */
  parseContentReturn(returnedAction) {
    if (
      typeof returnedAction.method === "string" &&
      returnedAction.method !== ""
    ) {
      // allow only strings as acceptable method name
      let params = "";
      // if (Array.isArray(returnedAction.params)) {
      params = returnedAction.params;
      // }
      try {
        this[returnedAction.method](params); // call it
      } catch (error) {
        console.log(returnedAction.method, "is not declared as method!", error);
      }
    } else {
      console.log("**method name not string or empty string");
    }
  }

  /**
   * Generate a list of all active VFE's belonging to this person/global verification
   * @param record - person record holding information about all the VFE's
   */
  generateActiveVFEs(record) {
    this.activeVFEs = this.VFEs.filter((vfe) => record[vfe.type]).map((vfe) => {
      if (!record[vfe.type].consentedAt) {
        this.consent = false;
      }

      // if IDV was part of the verification always attempt to resolve jumio response on VS side
      if (vfe.type === "lastVerification" && record[vfe.type]) {
        this.waitForJumioResponse = true;
      }

      // if IDV was already verified do not attempt to resolve for jumio status
      if (
        vfe.type === "lastVerification" &&
        !!record[vfe.type].record &&
        record[vfe.type].status === "verified"
      ) {
        this.idvVerified = true;
      }

      // for some reason IDV failed but VS is allowed to retry
      if (
        record[vfe.type] === "lastVerification" ||
        record[vfe.type].canBeRetried ||
        record[vfe.type].status.toLowerCase() === "rejected_id_none"
      ) {
        this.idvRetry = true;
      }

      // if idin is ackowledged as separate VFE as initially expected, or
      // if idin is just a flag set to true under lastAddressVerification
      if (record[vfe.type] === "lastIDin" || record[vfe.type].idinAllowed) {
        this.iDinEnabled = true;
      }

      // if idin is ackowledged as separate VFE as initially expected, or
      // if idin is just a flag set to true under lastAddressVerification, and
      // idin ver was completed and it needs to be resolved
      if (
        (record[vfe.type] === "lastIDin" || record[vfe.type].idinAllowed) &&
        record[vfe.type].doneWithIdin &&
        record[vfe.type].status.toUpperCase() !== "FAILED"
      ) {
        this.resolveIdin = true;
      }

      // if idin is ackowledged as separate VFE as initially expected, or
      // if idin is just a flag set to true under lastAddressVerification, and
      // idin ver status is set to inprogress or lazyrefresh
      if (
        (record[vfe.type] === "lastIDin" || record[vfe.type].idinAllowed) &&
        record[vfe.type].idinResults &&
        record[vfe.type].status.toUpperCase() !== "IN_PROGRESS" &&
        record[vfe.type].status.toUpperCase() !== "LAZY_REFRESH"
      ) {
        this.forceResolveIdin = true;
      }

      // if status for this vfe is not completed than mark the verification as not completed
      if (!this.checkIfCompleted(record[vfe.type].status.toLowerCase())) {
        this.fullyCompleted = false;
      }
      // if status for this vfe is rejected than mark the verification as failed
      if (this.checkIfRejected(record[vfe.type].status.toLowerCase())) {
        this.failed = true;
      }
      vfe["record"] = record[vfe.type];
      return vfe;
    });
    this.consentParsed = true;
    console.log("activeVFEs", this.activeVFEs);
  }

  /**
   * checks if the pill is completed according to the vfe status
   * @param status - the status of vfe
   * @returns boolean - true if completed | false otherwise
   */
  checkIfCompleted(status) {
    status = status.toLowerCase();
    if (
      status === "completed" ||
      status === "verified" ||
      status === "rejected" ||
      // || status === 'rejected_id_none'
      status === "action_required" ||
      status === "processing"
    ) {
      return true;
    }
    return false;
  }

  // Special status right now only used for id verification
  checkIfRejected(status) {
    status = status.toLowerCase();
    if (
      status === "rejected_failed" ||
      status === "rejected_id_type" ||
      status === "rejected_id_country" ||
      status === "rejected_id_not_readable" ||
      status === "rejected_similarity" ||
      status === "rejected_validity"
    ) {
      return true;
    }
    return false;
  }

  /**
   * generate the content for the active (configured) pills/steps
   * @returns array of content elements for the active pills/steps
   */
  generatePills() {
    if (this.activeVFEs.length === 0) {
      return [];
    }

    let active = false;
    let next = false;
    let idvPillExists = false;
    const pills = this.activeVFEs
      .filter((vfe) => !vfe.substep)
      .map((vfe, index) => {
        console.log(vfe.type);
        const ret: any = {
          id: this.helper.parseTagNameForBE(vfe.type).toLowerCase(),
          isAutomaticPill: this.isAutomatic(vfe) ? true : false,
          displayPill: this.isAutomatic(vfe) ? false : true,
          title: vfe.name,
          type: vfe.type,
          subTitle: this.checkIfRejected(vfe.record.status.toUpperCase())
            ? vfe.subTitleRejected
            : this.checkIfCompleted(vfe.record.status.toUpperCase())
            ? vfe.subTitleCompleted
            : vfe.subTitle,
          isActive: this.checkIsPillActive(vfe) && !active ? true : false,
          rejected: this.checkIfRejected(vfe.record.status.toUpperCase()),
          status: this.checkIfCompleted(vfe.record.status.toUpperCase())
            ? "COMPLETED"
            : vfe.record.status.toUpperCase(),
          subStepsLabel: vfe.subStepsLabel,
          substeps: [],
          clickAction: vfe.method,
        };
        if (this.checkIsPillActive(vfe) && !active) {
          active = true;
        }
        if (vfe.record.requiresIdVerification) {
          ret["requiresIdVerification"] = vfe.record.requiresIdVerification;
        }
        // if (vfe.type !== 'lastVerification') {
        //   ret['requiresIdVerification']  = true;
        // }
        switch (vfe.type) {
          case "lastVerification":
            idvPillExists = true;
            break;
          case "lastAddressVerification":
            if (this.iDinEnabled && !vfe.record.initiatedOn) {
              ret.substeps = vfe.substeps;
              if (ret.isActive) {
                ret.subTitle = vfe.subStepsTitle;
              }
              // if iDIN was attempted but has failed
              // allow users to re-try iDIN or standard AV
              if (vfe.record.status.toLowerCase() === "failed" && !active) {
                ret.isActive = true;
                ret.subTitle = vfe.subStepsTitle;
                active = true;
              }
            }
            break;

          default:
            break;
        }
        if (active && !next) {
          this.nextPill = ret;
          next = true;
          // if (this.nextPill.requiresIdVerification && this.pills) {
          //   this.waitForJumioResponse = true;
          // }
        }
        return ret;
      });
    if (idvPillExists && pills.length > 1 && this.noRequiredIdvResolve(pills)) {
      pills[pills.length - 1]["requiresIdVerification"] = true;
    }
    return pills;
  }

  /**
   * check if there is a step that requires IDV resolve
   * @returns true if no step requires IDV resolve, otherwise false
   */
  noRequiredIdvResolve(pills) {
    let ret = true;
    pills.map((pill) => {
      if (pill.requiresIdVerification) {
        ret = false;
      }
    });
    return ret;
  }

  /**
   * check if the current pill should be made active
   * @param vfe - details about the vfe we are checking
   * @returns boolean
   */
  checkIsPillActive(vfe) {
    return (
      vfe.record.status.toLowerCase() === "pending" ||
      vfe.record.status.toLowerCase() === "in_progress"
    );
  }

  /**
   * check if the current pill should be executed automatically or not
   * it also controls if the pill should be displayed on UI or not
   * @param vfe - details about the vfe we are checking
   * @returns boolean
   */
  isAutomatic(vfe) {
    const constVfe = this.VFEs.filter(
      (constVfeE) => constVfeE.type === vfe.type,
    );
    return !!constVfe[0].automaticExecution;
  }

  visiblePills() {
    return this.pills.filter((pill) => pill.displayPill);
  }

  /**
   * returns the click method for the currently active pill/step
   */
  getActiveClickMethod(pills) {
    const activePill = pills.filter((pill) => pill.isActive);
    if (activePill && activePill.length === 1) {
      return activePill[0];
    }
    return null;
  }

  /**
   * returns the previously active/currently completed pill/step
   */
  getLastCompletedPill(pills) {
    const activePill = pills.filter((pill) => pill.isActive);
    let completedPill = null;
    let found = false;
    if (pills.length > 1 && activePill && activePill.length === 1) {
      pills.forEach((pill) => {
        if (!found && pill.type !== activePill[0].type) {
          completedPill = pill;
        }
        if (pill.type === activePill[0].type) {
          found = true;
        }
      });
    }
    return completedPill;
  }

  /**
   * Checks if consent for the global verification was given
   * @returns boolean - true if consent was given | false otherwise
   */
  async generalConsentGiven() {
    this.consentGiven = true;
    this.consentParsed = true;
    if (!this.verificationKey) {
      return false;
    }
    if (!this.consent) {
      const ret = await this.verificationsService
        .recordGeneralConsent(this.verificationKey)
        .then((result) => {
          this.consent = true;
          return true;
        })
        .catch((error) => {
          console.error(`Error occured while consenting: ${error}`);
          this.errorState = true;
          return false;
        });
    } else {
      return true;
    }
  }

  /**
   * Starts ID verification process
   * @returns void
   */
  async startIDVerificationProcess() {
    this.displayLoader = true;
    if (
      this.responseRecord.record.lastAddressVerification &&
      !!this.responseRecord.record.lastAddressVerification.consentedAt
    ) {
      this.router.navigate([
        "/verifications/" + this.verificationKey + "/identity/warning",
      ]);
      return;
    }
    // not yet consented display the consent screen first:
    const consentStatus = await this.generalConsentGiven();
    if (consentStatus) {
      this.router.navigate([
        "/verifications/" + this.verificationKey + "/identity/warning",
      ]);
      return;
    }
    this.errorState = true;
    return;
  }

   /**
   * Starts ID verification process
   * @returns void
   */
  async startBasicInformation() {
    this.displayLoader = true;
    // not yet consented display the consent screen first:
    const consentStatus = await this.generalConsentGiven();
    if (consentStatus) {
      this.router.navigate([
        "/verifications/" + this.verificationKey + "/basicInfo",
      ]);
      return;
    }
    this.errorState = true;
    return;
  }

  /**
   * Starts Address Verification process
   * @returns void
   */
  async startAVerificationProcess() {
    this.displayLoader = true;
    const avRecord = this.responseRecord.record.lastAddressVerification;
    // 1st check if iDIN was already attempted and cancel it
    if (!avRecord.initiatedOn) {
      // if(avRecord.status.toUpperCase() === 'IN_PROGRESS') {
      await this.initiateStandardAv();
    }
    if (avRecord && !!avRecord.consentedAt) {
      this.router.navigate([
        "/verifications/" + this.verificationKey + "/address",
      ]);
      return;
    }
    // not yet consented display the consent screen first:
    const consentStatus = await this.generalConsentGiven();
    if (consentStatus) {
      this.router.navigate([
        "/verifications/" + this.verificationKey + "/address",
      ]);
      return;
    }
    this.errorState = true;
    return;
  }

  /**
   * Initiates Address Verification process after attempted iDIN
   * @returns void
   */
  async initiateStandardAv() {
    this.displayLoader = true;
    if (!this.verificationKey) {
      this.displayLoader = false;
      this.waitingState = true;
      this.stateMessage = this.translate.instant("IDPCS.landing.error4");
      console.error(
        "Standard AV could not be initiated as verification key is missing",
      );
      return;
    }
    const cryptoKey = this.verificationKey;
    await this.http
      .post(
        this.baseUrl + `api/verifications/${cryptoKey}/address/initiate`,
        "",
      )
      .toPromise()
      .then((response) => {
        if (response === undefined) {
          console.log(
            `Error while attempting to initiate standard AV for: ${cryptoKey}`,
          );
          this.displayLoader = false;
          this.waitingState = true;
          this.stateMessage = this.translate.instant("IDPCS.landing.error4");
        }
        // all is good
        return true;
      })
      .catch((error) => {
        console.error(
          `Error while attempting to resolve iDIN for ${cryptoKey}`,
          error,
        );
        this.displayLoader = false;
        this.waitingState = true;
        this.stateMessage = this.translate.instant("IDPCS.landing.error4");
        return false;
      });
  }

  /**
   * Starts iDIN Verification process
   */
  async startIdinVerificationProcess() {
    this.displayLoader = true;
    await this.verificationsService
      .getIdinRedirectLink(this.verificationKey)
      .then((response) => {
        if (response === undefined || !response["transactionURL"]) {
          console.log("Error while fetching the iDIN redirect link");
          this.displayLoader = false;
          this.errorState = true;
          this.stateMessage = this.translate.instant("IDPCS.landing.error5");
          return;
        }
        window.location = response["transactionURL"];
      })
      .catch((error) => {
        this.displayLoader = false;
        this.errorState = true;
        this.stateMessage = this.translate.instant("IDPCS.landing.error5");
        console.error("Error while fetching the iDIN redirect link", error);
      });
  }

  /**
   * Starts Address Verification process
   * @returns void
   */
  async startContractSigningProcess() {
    this.displayLoader = true;
    if (
      this.responseRecord.record.lastContractVerification &&
      !!this.responseRecord.record.lastContractVerification.consentedAt
    ) {
      this.router.navigate([
        "/verifications/" + this.verificationKey + "/contract",
      ]);
      return;
    }
    // not yet consented display the consent screen first:
    const consentStatus = await this.generalConsentGiven();
    if (consentStatus) {
      this.router.navigate([
        "/verifications/" + this.verificationKey + "/contract",
      ]);
      return;
    }
    this.errorState = true;
    return;
  }

  /**
   * Generates the content of the Process Control Screen
   * i.e. displays the list of pills and their corresponding statuses
   */
  generateContent() {
    let title = this.translate.instant("IDPCS.landing.title3");
    let message = this.translate.instant("IDPCS.landing.content5");
    let imgClass = "d-none";
    let tooltipMessage = "";
    if (this.fullyCompleted) {
      title = this.translate.instant("IDPCS.landing.title4");
      message = this.translate.instant("IDPCS.landing.content6");
      imgClass = "";
      this.verificationHeading = this.translate.instant("common.thankYou");
      this.onCloseIframe();
    } else if (this.failed && !this.nextPill) {
      title = this.translate.instant("IDPCS.landing.title5");
      message = this.translate.instant("IDPCS.landing.content7");
      imgClass = "";
      this.verificationHeading = this.translate.instant(
        "common.noFurtherActions",
      );
      this.onCloseIframe();
    } else {
      tooltipMessage = this.translate.instant("IDPCS.landing.tooltip");
    }

    const res: any = {
      section_class:
        "results d-flex flex-column justify-items-center px-2 pt-2",
      group_name: "",
      result_container_class: "result-container my-2",
      is_collapsable: false,
      result_details: [
        {
          elements: [
            {
              type: "image",
              params: {
                src: "/assets/images/request_screening.svg",
                class: imgClass,
              },
            },
            {
              type: "title",
              class: "text-left",
              params: {
                content: title,
                tooltipMessage: tooltipMessage,
              },
            },
            {
              type: "paragraph",
              paragraphClass: "text-left",
              params: {
                content: message,
              },
            },
            {
              type: "stacked_pill_choices",
              params: {
                pills: this.visiblePills(),
              },
            },
          ],
        },
      ],
    };
    res.result_details[0]["elements"].push({
      type: "button_group",
      displayFlex: false,
      alignRight: true,
      params: [
        {
          content: this.translate.instant("common.start"),
          class: "form-button form-button-1 form-button-full-width",
          action:
            this.activePill && this.activePill.clickAction
              ? this.activePill.clickAction
              : "",
          display:
            this.activePill &&
            !this.fullyCompleted &&
            this.activePill.substeps.length === 0,
        },
        {
          content: this.translate.instant("common.goBack"),
          class:
            "form-button form-button-1 form-button-full-width mt-4 form-button-red",
          action: "onCloseIframe",
          display: this.helper.checkIfIframe() && this.fullyCompleted,
        },
      ],
    });

    this.contentDom.push(res);

    if(this.helper.checkIfIframe() && this.fullyCompleted) {
      this.onCloseIframe();
    }

  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  goBackButton() {
    parent.postMessage("iframe is closed back", undefined);
  }

  onCloseIframe() {
    parent.postMessage("iframe is closed completed", undefined);
  }

}
