import { Component, OnInit, Input } from "@angular/core";
import { VerificationsService } from "src/app/verifications/verifications.service";

@Component({
  selector: "app-contributor-invitation-header",
  templateUrl: "./contributor-invitation-header.component.html",
  styleUrls: ["./contributor-invitation-header.component.scss"],
})
export class ContributorInvitationHeaderComponent implements OnInit {
  @Input() projectTextColorClass: any;
  @Input() message: any;
  @Input() displayLoader: boolean;
  @Input() invitationKey: string;
  @Input() initiated: boolean;
  consent: boolean; // can be: false - no consent, true - consent
  project: string;
  contributorType: string;

  constructor(private verificationsService: VerificationsService) {}

  ngOnInit() {
    // call GET verifications/data/:invitationKey to fetch verification event
    // look if it is already rejected/verified if yes, notifyt he user of it,
    // check if it is expired, if yes, notify the user of it. If all is good then
    // load the url in an iframe

    this.verificationsService.getInvitationDetails().subscribe((response) => {
      this.displayLoader = false;
      // console.log(response);
      if (response === null) {
        this.message =
          "WE COULD NOT START THE INVITATION ACCEPTANCE PROCESS FOR YOU!";
        return;
      }

      this.initiated = true;
      // if (this.helper.getTimestamp(response.expiresOn) <= this.helper.getTimestamp('current')) {
      //   this.message = 'YOUR LINK HAS EXPIRED!<br>PLEASE ASK YOUR PROVIDER TO RE-ISSUE A NEW LINK!';
      //   return;
      // }
      // WE DON'T RECEIVE A PROJECT ID AT THIS POINT SO WE CANNOT EXTRACT LOGO OR COLOR TO BRAND
      // if (typeof response.projectColor !== 'undefined') {
      //   this.verificationsService.setProjectColor(response.projectColor);
      // } else {
      this.verificationsService.setProjectColor("#00889c");
      // }
      // this.projectColor =  this.verificationsService.getProjectColor();
      // this.projectTextColorClass = this.verificationsService.getProjectTextColorClass();
      this.project = response.record.projectName;
      this.contributorType = response.record.type;
      // if (response.consent !== undefined) {
      //   this.consent = true;
      // }
    });
  }
  OnExplicitConsent() {
    this.initiated = false;
    this.message = "Please stand by while we record your consent!";
    this.displayLoader = true;
    this.verificationsService
      .recordContributorConsent()
      .subscribe((response) => {
        this.initiated = false;
        this.displayLoader = false;
        if (!response) {
          this.message =
            "WE COULD NOT START THE INVITATION ACCEPTANCE PROCESS FOR YOU!";
          return;
        }
        // allow user to move forward with the invitation
        // take him to id verification dashboard
        // currently BE doesn't support identifying the project by key
        // it only returns the name of the project so try to parse
        // active project list and redirect the user to the project
        // this.idVerificationService.setJoinedProjectName(this.project);
        // this.router.navigate(['/id-verification/dashboard']);
        // 27062019 the user will no longer be directly redirected to the project
        // we now first display a message that they need to be approved by the project owner/s
        this.message =
          "The project owner has been alerted of your invitation acceptance. You need to be approved before you gain access to the project!";
      });
  }
}
