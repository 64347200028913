export const debugActiveLegalRepresentativeResponse = {
  metadata: {
    bookmark:
      "g2wAAAACaAJkAA5zdGFydGtleV9kb2NpZG0AAAAoVkZTOmFkZjI5MTgwLWM1NDgtNGY2Yi1hNzZmLWE4ZWNkYmNmYTFhOGgCZAAIc3RhcnRrZXlsAAAAAW4GAKnlgRd5AWpq",
    pageCount: 15,
    recordsCount: 6,
  },
  records: [
    {
      key: "VFS:3afedb52-8c12-4f45-8382-4010a28f725b",
      record: {
        name: "DEJAN BRSAKOSKI",
        firstName: "DEJAN",
        lastName: "BRSAKOSKI",
        dateOfBirth: "1981-02-22",
        createdOn: "2021-04-30T06:39:01.453Z",
        lastModified: "2021-04-30T06:48:45.403Z",
        tags: [],
        verificationSubjectStatus: "COMPLETED",
        lastVerification: {
          key: "VFE:62ee5362-7ade-4e27-a71a-d4d9b0fa58af",
          record: {
            docType: "verification_event",
            createdOn: "2021-04-30T06:39:09.179Z",
            expiresOn: "2021-06-29T06:39:00.000Z",
            lastModified: "2021-04-30T06:46:38.992Z",
            status: "verified",
            isPartial: false,
            areImagesFetched: true,
            isJumioDeleted: true,
            consentedAt: "2021-04-30T06:39:36.583Z",
          },
        },
        lastAddressVerification: {
          key: "VFA:eb5c70cb-5cfe-4221-8f0c-daf53dce9354",
          record: {
            docType: "verification_address_event",
            projectKey: "PRJ:327f89b3-148f-4dd1-9948-289aab9a338c",
            personKey: "VFS:3afedb52-8c12-4f45-8382-4010a28f725b",
            createdOn: "2021-04-30T06:39:18.287Z",
            lastModified: "2021-04-30T06:48:43.107Z",
            status: "COMPLETED",
            verificationPresets: {
              idinAllowed: true,
              documentsRequested: false,
              verificationLetterRequested: false,
              addressRequested: false,
            },
            consentedAt: "2021-04-30T06:39:36.583Z",
            completedAt: "2021-04-30T06:48:45.403Z",
            geoLocationFieldsUploadedAt: "2021-04-30T06:48:43.107Z",
          },
        },
      },
    },
    {
      key: "VFS:b548f573-77af-4069-a969-4d70eb8c1a6f",
      record: {
        name: "DB_IDIN",
        createdOn: "2021-04-30T06:36:15.863Z",
        lastModified: "2021-04-30T06:36:27.055Z",
        tags: [],
        verificationSubjectStatus: "IN_PROGRESS",
        lastAddressVerification: {
          key: "VFA:4b0d7675-6d1e-4d61-8954-403771d45f3f",
          record: {
            docType: "verification_address_event",
            projectKey: "PRJ:327f89b3-148f-4dd1-9948-289aab9a338c",
            personKey: "VFS:b548f573-77af-4069-a969-4d70eb8c1a6f",
            createdOn: "2021-04-30T06:36:27.055Z",
            lastModified: "2021-04-30T06:36:27.055Z",
            status: "PENDING",
            verificationPresets: {
              idinAllowed: true,
              documentsRequested: false,
              verificationLetterRequested: false,
              addressRequested: false,
            },
            consentedAt: "2021-04-30T06:36:50.487Z",
          },
        },
        url: "https://dev.meshid.app/verifications/da2a0ce58de5cf9029944fff11d4ddaf81426f9871e462117a99cd9c29aefa926a2200689f53bda8fb4513a2db8a85f03ae5525ab107ca3f0cdc10f7989de8f356be31f5eff27ddeea8de9cc6441e468",
      },
    },
    {
      key: "VFS:9818e9dd-49e3-45b4-b84c-677d642faa56",
      record: {
        name: "DEJAN BRSAKOSKI",
        firstName: "DEJAN",
        lastName: "BRSAKOSKI",
        dateOfBirth: "1981-02-22",
        createdOn: "2021-04-30T06:15:11.982Z",
        lastModified: "2021-04-30T06:34:39.957Z",
        tags: [],
        verificationSubjectStatus: "COMPLETED",
        lastVerification: {
          key: "VFE:0b559851-48fa-4255-ab5c-5053aacf5362",
          record: {
            docType: "verification_event",
            createdOn: "2021-04-30T06:15:21.221Z",
            expiresOn: "2021-06-29T06:15:00.000Z",
            lastModified: "2021-04-30T06:34:39.957Z",
            status: "verified",
            isPartial: false,
            areImagesFetched: true,
            isJumioDeleted: true,
            consentedAt: "2021-04-30T06:15:47.196Z",
          },
        },
        lastAddressVerification: {
          key: "VFA:6622c752-923b-4b38-a939-d8e3b366e0c1",
          record: {
            docType: "verification_address_event",
            projectKey: "PRJ:327f89b3-148f-4dd1-9948-289aab9a338c",
            personKey: "VFS:9818e9dd-49e3-45b4-b84c-677d642faa56",
            createdOn: "2021-04-30T06:15:30.574Z",
            lastModified: "2021-04-30T06:28:09.277Z",
            status: "COMPLETED",
            relatedIdinVerification: "VFI:e2f2e2cc-cdd0-47db-8eee-75998bedce30",
            verificationPresets: {
              idinAllowed: true,
              documentsRequested: false,
              verificationLetterRequested: false,
              addressRequested: false,
            },
            consentedAt: "2021-04-30T06:15:47.196Z",
            completedAt: "2021-04-30T06:28:11.683Z",
            geoLocationFieldsUploadedAt: "2021-04-30T06:28:09.277Z",
          },
        },
      },
    },
    {
      key: "VFS:0047e5fc-a670-4e1b-8f4c-43300196541b",
      record: {
        name: "DB_AV_IDIN",
        createdOn: "2021-04-28T06:26:14.695Z",
        lastModified: "2021-04-28T08:56:57.249Z",
        tags: [],
        verificationSubjectStatus: "IN_PROGRESS",
        lastAddressVerification: {
          key: "VFA:a76cd136-af57-460c-b385-e76ba68b3d2f",
          record: {
            docType: "verification_address_event",
            projectKey: "PRJ:327f89b3-148f-4dd1-9948-289aab9a338c",
            personKey: "VFS:0047e5fc-a670-4e1b-8f4c-43300196541b",
            createdOn: "2021-04-28T06:26:35.249Z",
            lastModified: "2021-04-28T06:26:35.249Z",
            status: "IN_PROGRESS",
            relatedIdinVerification: "VFI:c4603895-5344-4f03-8f05-0bf4f2989b7c",
            verificationPresets: {
              idinAllowed: true,
              documentsRequested: false,
              verificationLetterRequested: false,
              addressRequested: false,
            },
            consentedAt: "2021-04-28T06:26:57.093Z",
          },
        },
        url: "https://dev.meshid.app/verifications/da2a0ce58de5cf9029944fff11d4ddaf1557755e224c26ae786a857e58a1d40f7057730603d97f5f872df0db71f1406b97a30f61a3b7b96e9c1185b7f039d9e24016deabec776c59c419d5512a5a1854",
      },
    },
    {
      key: "VFS:eee739a3-db61-4e50-b6de-80d3d47b6904",
      record: {
        name: "DB_BOTH",
        createdOn: "2021-04-28T08:11:38.275Z",
        lastModified: "2021-04-28T08:11:54.183Z",
        tags: [],
        verificationSubjectStatus: "IN_PROGRESS",
        lastVerification: {
          key: "VFE:755bb025-51a6-4153-bca8-74e6d0149ee1",
          record: {
            docType: "verification_event",
            createdOn: "2021-04-28T08:11:46.367Z",
            expiresOn: "2021-06-27T08:11:00.000Z",
            lastModified: "2021-04-28T08:11:46.367Z",
            status: "pending",
            consentedAt: "2021-04-28T08:12:09.301Z",
            isPartial: true,
          },
        },
        lastAddressVerification: {
          key: "VFA:cb452ebf-6c0f-4f89-9d7b-91ed83b588c8",
          record: {
            docType: "verification_address_event",
            projectKey: "PRJ:327f89b3-148f-4dd1-9948-289aab9a338c",
            personKey: "VFS:eee739a3-db61-4e50-b6de-80d3d47b6904",
            createdOn: "2021-04-28T08:11:54.183Z",
            lastModified: "2021-04-28T08:11:54.183Z",
            status: "PENDING",
            verificationPresets: {
              idinAllowed: false,
              documentsRequested: false,
              verificationLetterRequested: false,
              addressRequested: false,
            },
            consentedAt: "2021-04-28T08:12:09.301Z",
          },
        },
        url: "https://dev.meshid.app/verifications/da2a0ce58de5cf9029944fff11d4ddafe240e8c598fbb8fc9872069a4b71539b390247f7349647debf55f99189ccd3645beb63c1899c123475a279c5c75eda1fe297e35e872cade405689d671b89922e",
      },
    },
    {
      key: "VFS:adf29180-c548-4f6b-a76f-a8ecdbcfa1a8",
      record: {
        name: "DB_ADDRESS",
        createdOn: "2021-04-28T08:04:07.534Z",
        lastModified: "2021-04-28T08:04:19.497Z",
        tags: [],
        verificationSubjectStatus: "IN_PROGRESS",
        lastAddressVerification: {
          key: "VFA:0ccabda4-bfe1-4d55-87b9-480090f80c84",
          record: {
            docType: "verification_address_event",
            projectKey: "PRJ:327f89b3-148f-4dd1-9948-289aab9a338c",
            personKey: "VFS:adf29180-c548-4f6b-a76f-a8ecdbcfa1a8",
            createdOn: "2021-04-28T08:04:19.497Z",
            lastModified: "2021-04-28T08:04:19.497Z",
            status: "PENDING",
            verificationPresets: {
              idinAllowed: false,
              documentsRequested: false,
              verificationLetterRequested: false,
              addressRequested: false,
            },
            consentedAt: "2021-04-28T08:04:36.474Z",
          },
        },
        url: "https://dev.meshid.app/verifications/da2a0ce58de5cf9029944fff11d4ddaf2542808152d58975b259ec586e5f6ae22ff23d451acf45b564e61fdf30a390ac4492c8c04866aae6674c1ea0fadc46d7dd53349b01e8ff7bea40acf5c73b1a11",
      },
    },
    {
      key: "VFS:eee739a3-db61-4e50-b6de-80d3d47b6904",
      record: {
        name: "DB_BOTH_COPY",
        createdOn: "2021-04-28T08:11:38.275Z",
        lastModified: "2021-04-28T08:11:54.183Z",
        tags: [],
        verificationSubjectStatus: "IN_PROGRESS",
        lastVerification: {
          key: "VFE:755bb025-51a6-4153-bca8-74e6d0149ee1",
          record: {
            docType: "verification_event",
            createdOn: "2021-04-28T08:11:46.367Z",
            expiresOn: "2021-06-27T08:11:00.000Z",
            lastModified: "2021-04-28T08:11:46.367Z",
            status: "pending",
            consentedAt: "2021-04-28T08:12:09.301Z",
            isPartial: true,
          },
        },
        lastAddressVerification: {
          key: "VFA:cb452ebf-6c0f-4f89-9d7b-91ed83b588c8",
          record: {
            docType: "verification_address_event",
            projectKey: "PRJ:327f89b3-148f-4dd1-9948-289aab9a338c",
            personKey: "VFS:eee739a3-db61-4e50-b6de-80d3d47b6904",
            createdOn: "2021-04-28T08:11:54.183Z",
            lastModified: "2021-04-28T08:11:54.183Z",
            status: "PENDING",
            verificationPresets: {
              idinAllowed: false,
              documentsRequested: false,
              verificationLetterRequested: false,
              addressRequested: false,
            },
            consentedAt: "2021-04-28T08:12:09.301Z",
          },
        },
        url: "https://dev.meshid.app/verifications/da2a0ce58de5cf9029944fff11d4ddafe240e8c598fbb8fc9872069a4b71539b390247f7349647debf55f99189ccd3645beb63c1899c123475a279c5c75eda1fe297e35e872cade405689d671b89922e",
      },
    },
    {
      key: "VFS:eee739a3-db61-4e50-b6de-80d3d47b6904",
      record: {
        name: "DB_BOTH_OLDER_THAN_15MIN",
        createdOn: "2021-04-28T08:11:38.275Z",
        lastModified: "2021-04-28T08:11:54.183Z",
        tags: [],
        verificationSubjectStatus: "IN_PROGRESS",
        lastVerification: {
          key: "VFE:755bb025-51a6-4153-bca8-74e6d0149ee1",
          record: {
            docType: "verification_event",
            createdOn: "2021-04-28T08:11:46.367Z",
            expiresOn: "2021-06-27T08:11:00.000Z",
            lastModified: "2021-04-28T08:11:46.367Z",
            status: "processing",
            consentedAt: "2021-04-28T08:12:09.301Z",
          },
        },
        lastAddressVerification: {
          key: "VFA:cb452ebf-6c0f-4f89-9d7b-91ed83b588c8",
          record: {
            docType: "verification_address_event",
            projectKey: "PRJ:327f89b3-148f-4dd1-9948-289aab9a338c",
            personKey: "VFS:eee739a3-db61-4e50-b6de-80d3d47b6904",
            createdOn: "2021-04-28T08:11:54.183Z",
            lastModified: "2021-04-28T08:11:54.183Z",
            status: "PENDING",
            verificationPresets: {
              idinAllowed: false,
              documentsRequested: false,
              verificationLetterRequested: false,
              addressRequested: false,
            },
            consentedAt: "2021-04-28T08:12:09.301Z",
          },
        },
        url: "https://dev.meshid.app/verifications/da2a0ce58de5cf9029944fff11d4ddafe240e8c598fbb8fc9872069a4b71539b390247f7349647debf55f99189ccd3645beb63c1899c123475a279c5c75eda1fe297e35e872cade405689d671b89922e",
      },
    },
    {
      key: "VFS:adf29180-c548-4f6b-a76f-a8ecdbcfa1a8",
      record: {
        name: "DB_TEST_FAILED",
        createdOn: "2021-04-28T08:04:07.534Z",
        lastModified: "2021-04-28T08:04:19.497Z",
        tags: [],
        verificationSubjectStatus: "FAILED",
        lastAddressVerification: {
          key: "VFA:0ccabda4-bfe1-4d55-87b9-480090f80c84",
          record: {
            docType: "verification_address_event",
            projectKey: "PRJ:327f89b3-148f-4dd1-9948-289aab9a338c",
            personKey: "VFS:adf29180-c548-4f6b-a76f-a8ecdbcfa1a8",
            createdOn: "2021-04-28T08:04:19.497Z",
            lastModified: "2021-04-28T08:04:19.497Z",
            status: "PENDING",
            verificationPresets: {
              idinAllowed: false,
              documentsRequested: false,
              verificationLetterRequested: false,
              addressRequested: false,
            },
            consentedAt: "2021-04-28T08:04:36.474Z",
          },
        },
        url: "https://dev.meshid.app/verifications/da2a0ce58de5cf9029944fff11d4ddaf2542808152d58975b259ec586e5f6ae22ff23d451acf45b564e61fdf30a390ac4492c8c04866aae6674c1ea0fadc46d7dd53349b01e8ff7bea40acf5c73b1a11",
      },
    },
  ],
};
