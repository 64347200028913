<div class="custom-dialog rrapproval">
    <a class="close-button" (click)="close()"><img src="assets/images/close_icon_green.svg"></a>

    <div class="content">
        <div class="info-text text-left">
            Choose an assignee
        </div>
        <small>You may only assign this assessment to a contributor of the current project</small>
        <div class="contributor-container mt-3">
            <div *ngFor="let contributor of data.contributors">
                <input
                class="custom-radio-button"
                type="radio"
                [id]="contributor"
                name="assignContributorN"
                [value]="contributor"
              />
              <label class="custom-radio-label" for="{{contributor}}">
                {{contributor}}
                </label
              >
            </div>
        </div>
        <div class="buttons">
            <div class="form-button form-button-6" (click)="close()"> Cancel </div>
            <div class="form-button form-button-1" (click)="acceptAndSendForApproval()">Assign assessment</div>
        </div>
    </div>
</div>