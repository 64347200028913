import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
  AfterContentInit,
} from "@angular/core";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { Subscription } from "rxjs";
import { HelperServiceService } from "src/app/helper-service.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";

declare var $: any;

@Component({
  selector: "app-id-verification-project-list",
  templateUrl: "./id-verification-project-list.component.html",
  styleUrls: ["./id-verification-project-list.component.scss"],
})
export class IdVerificationProjectListComponent
  implements OnInit, OnDestroy, AfterContentInit
{
  showEditNameFor: string;
  createProjectForm: {};
  activeProject: {};
  activeProjectList: any[];
  userType = ""; // owner | member
  projectsType: string;
  // variables connected to pagination
  loadingLocked = false;
  allProjectsLoaded = false;
  showLoader = false;
  selectedProjectFilter = "last_modified";

  @Output() DisplayLightbox = new EventEmitter<any>();

  // permissions
  permissionCustomizeProject: boolean;
  permissionReportGeneration: boolean;
  permissionKYCEmplates: boolean;

  // subscriptions
  activeProjectsStatus: Subscription;
  activeProjectStatus: Subscription;
  detectJoinProjectStatus: Subscription;
  editedProject: Subscription;

  constructor(
    private idVerificationService: IdVerificationService,
    private helper: HelperServiceService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    // reset active id verification
    this.idVerificationService.resetScrollToKey();
    this.idVerificationService.resetActivePerson();
    this.idVerificationService.resetActivePersons();
    // if (!this.idVerificationService.isInvestorType()) {
    //   this.idVerificationService.resetActiveInvestor();
    //   this.idVerificationService.resetActiveInvestors();
    // }
    this.loadingLocked = false;
    this.allProjectsLoaded = false;
    this.showLoader = false;
    if (this.router.url.includes("investor")) {
      if (!this.idVerificationService.kompanyTypeCountries) {
        this.idVerificationService.prepareIdConfigurationdata(
          false,
          false,
          true,
        );
      }
    }
    if (this.router.url.includes("funds")) {
      if (!this.idVerificationService.jumioTypeCountries) {
        this.idVerificationService.prepareIdConfigurationdata(true, true, true);
      }
    }
    if (this.router.url.includes("funds")) {
      this.projectsType = "funds";
    }
    if (this.router.url.includes("id-verification")) {
      this.projectsType = "id-verification";
    } else if (this.router.url.includes("investor")) {
      this.projectsType = "investor";
    }

    this.permissionCustomizeProject =
      this.idVerificationService.permissionUpdateProjectBranding();
    this.permissionReportGeneration =
      this.idVerificationService.permissionUpdateReportGeneration();
    this.permissionKYCEmplates =
      this.idVerificationService.permissionKYCTemplates();
    this.showEditNameFor = "";
    this.activeProject = this.idVerificationService.getActiveProjectDetails();
    if (this.idVerificationService.getJoinedProjectName() !== undefined) {
      $(".projects-content-wrapper").addClass("collapse");
      this.idVerificationService.resetJoinedProjectName();
    }
    this.activeProjectList = this.idVerificationService.getActiveProjects();
    this.activeProjectsStatus =
      this.idVerificationService.detectActiveProjectsStatus.subscribe(
        (response) => {
          // a change in Active Project list is detected so reload it from the service
          this.activeProjectList =
            this.idVerificationService.getActiveProjects();
          // if lastAppend is false it tells us that projects have just been loaded
          if (response && response.activeFilter) {
            this.loadingLocked = false;
            this.showLoader = false;
            if(response.lastAppend) {
              this.allProjectsLoaded = true;
            }
            $(".active-project-list").scrollTop($(".active-project-list").height() - 50);
          } else if (response && response.lastAppend === false) {
            this.loadingLocked = false;
            this.showLoader = false;
          } else if (response && response.lastAppend) {
            this.allProjectsLoaded = true;
          }
          if (response && response.loadMore === true) {
          } else if (response) {
            $(".active-project-list").scrollTop($(".active-project-list").height() - 50);
          }
        },
      );
    this.activeProjectStatus =
      this.idVerificationService.detectActiveProjectStatus.subscribe(() => {
        this.activeProject =
          this.idVerificationService.getActiveProjectDetails();
      });
    this.detectJoinProjectStatus =
      this.idVerificationService.detectJoinProjectStatus.subscribe((status) => {
        $(".projects-content-wrapper").addClass("collapse");
      });

    this.editedProject =
      this.idVerificationService.detectActiveProjectChange.subscribe(
        (status) => {
          // not neccessary anymore
        },
      );
  }
  // function to update current project to top of projectList

  /**
   * Get color for the spinner
   * @param color: string, project color
   */
  getDefaultProjectSpinner(color: string) {
    return this.helper.getDefaultProjectSpinner(color);
  }

  /**
   * create new project directly from the embeded form
   */
  OnEmbedCreateProject(params) {
    if ($("#embed-create-project-name").val() === "") {
      alert("You must ender a project name!");
      return;
    }
    this.idVerificationService.createProject(
      $("#embed-create-project-name").val(),
    );
  }

  /**
   * select a project and set it as active
   */
  OnSelectActiveProject(event, projectId: string = "", ignoreIfNotInList?) {
    this.loadingLocked = false;
    this.allProjectsLoaded = false;
    this.idVerificationService.setRiskRatingsForProject([]);
    let selectedPrId = $(event.target).closest(".card").attr("id");
    if (projectId !== "") {
      selectedPrId = projectId;
    }
    if (selectedPrId === "empty") {
      // do nothing
      return;
    }
    if (selectedPrId === "failed") {
      // attempt creation of new project
      // this.OnDisplayCreateProjectLightbox();
      this.DisplayLightbox.emit();
      return;
    }
    this.idVerificationService.resetScrollToKey();
    this.idVerificationService.resetActivePersons();
    if (!this.idVerificationService.isInvestorType()) {
      this.idVerificationService.resetActiveInvestor();
      this.idVerificationService.resetActiveInvestors();
    }

    $("body").css("overflow", "auto");
    this.showLoader = false;
    // select as active project
    $(".projects-content-wrapper").addClass("collapse");
    // $('.ic-ctas.new-person-wrapper').removeClass('mt-5').addClass('mt-4');
    this.idVerificationService.toggleInlineHeader("inline");
    this.idVerificationService.displayProjectSelector(false);
    const _this = this;
    setTimeout(() => {
      _this.idVerificationService.setActiveProject(
        selectedPrId,
        true,
        _this.projectsType,
        ignoreIfNotInList,
      );
    });
    // remove the nicescroll
    // set the correct URL for the project
    if (this.router.url.includes("id-verification")) {
      this.route.params.subscribe((params) => {
        const urlProjectId = params["projectId"];
        // why this code ?
        // const verificationId = params['projectId'];
        // replaced with this for now
        this.location.go("/id-verification/dashboard/" + selectedPrId);
      });
    } else if (this.router.url.includes("funds")) {
      this.route.params.subscribe((params) => {
        const urlProjectId = params["projectId"];
        this.location.go("/funds/dashboard/" + selectedPrId);
      });
    } else if (this.router.url.includes("investor")) {
      this.route.params.subscribe((params) => {
        const urlProjectId = params["projectId"];
        this.location.go("/investor/dashboard/" + selectedPrId);
      });
    }
    // if (this.router.url.includes('investor') || this.router.url.includes('funds')) {
    //   if (!this.idVerificationService.jumioTypeCountries) {
    //     this.idVerificationService.prepareIdConfigurationdata(true, true);
    //   }
    // }
  }

  OnDisplayCreateProjectLightbox() {
    this.DisplayLightbox.emit();
  }

  // temporary function for generating a dummy template
  OnGenerateDummyTemplate() {
    // this.idVerificationService.generateDummyTemplate();
  }

  DisplayMyOptions(event) {
    event.preventDefault();
    event.stopPropagation();
    this.idVerificationService.setActiveProjectSettings(
      $(event.target).closest(".card").attr("id"),
    );
    $(".project-submenu").hide();
    // get the height -> take it offscreen
    const seticon =
      $(event.target).closest(".card").find(".settings-icon").offset().top -
      $(window).scrollTop();
    const submenu = $(event.target).closest(".card").find(".project-submenu");
    submenu.css("top", "-10000px").show();
    const submenu_height = submenu.height();
    submenu.hide();
    submenu.css("top", seticon - submenu_height + 20 + "px");
    submenu.fadeIn();
  }

  OnCustomizeProject(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest(".card");
    closestCard.find(".project-submenu").fadeOut(); // close the menu
    this.idVerificationService.setActiveProjectSettings(closestCard.attr("id"));
    this.displayCustomizeProjectLightbox();
    this.loadingLocked = false;
    this.allProjectsLoaded = false;
  }

  OnInviteContributors(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest(".card");
    closestCard.find(".project-submenu").fadeOut(); // close the menu
    this.idVerificationService.setActiveProjectSettings(closestCard.attr("id"));
    this.displayInviteContributorsLightbox();
  }

  onCreateTemplate(event) {
    event.preventDefault();
    event.stopPropagation();
    this.idVerificationService.createNewTemplate();
  }

  onEditTemplate(event) {
    event.preventDefault();
    event.stopPropagation();
    this.idVerificationService.setDisplayEditTemplateLightbox();
  }

  OnManageContributors(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest(".card");
    closestCard.find(".project-submenu").fadeOut(); // close the menu
    this.idVerificationService.setActiveProjectSettings(closestCard.attr("id"));
    this.displayManageContributorsLightbox();
  }

  OnGenerateReport(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest(".card");
    closestCard.find(".project-submenu").fadeOut(); // close the menu
    this.idVerificationService.setActiveProjectSettings(closestCard.attr("id"));
    this.displayGenerateReportLightbox();
  }

  OnManageAdvancedSettings(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest(".card");
    closestCard.find(".project-submenu").fadeOut(); // close the menu
    this.idVerificationService.setActiveProjectSettings(closestCard.attr("id"));
    this.displayAdvancedSettingsLightbox();
  }

  /**
   * initiate a leave a project flow
   * @param event: Event, the click event on the Leave menu item
   */
  OnAttemptLeaveAProject(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest(".card");
    closestCard.find(".project-submenu").fadeOut(); // close the menu
    console.log(
      this.idVerificationService.setActiveProjectLeave(closestCard.attr("id")),
    );
    // check if current user is allowed to leave a project
    // load current contributors
    this.idVerificationService.loadContributors(
      this.idVerificationService.getActiveProjectSettings(),
      true,
    );
  }

  OnAttemptLeaveAProjectMember(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCardId = $(event.target).closest(".card").attr("id");
    this.idVerificationService.leaveProject(closestCardId);
  }

  onAttemptDeleteProject(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest(".card");
    closestCard.find(".project-submenu").fadeOut(); // close the menu
    this.idVerificationService.setActiveProjectSettings(closestCard.attr("id"));
    this.idVerificationService.deleteProjectDialogue(closestCard.attr("id"));
  }

  /**
   * display lightbox for handling image upload
   * color selection and result preview for
   * currently selected project
   */
  displayCustomizeProjectLightbox() {
    /**
     * set subscription to display customize
     * project lightbox which will be handled
     * in id-verification-dashboard
     */
    this.idVerificationService.setDisplayCustomizeProjectLightbox();
  }

  /**
   * display lightbox for inviting contributors
   */
  displayInviteContributorsLightbox() {
    /**
     * set subscription to display manage
     * contributors lightbox which will be handled
     * in id-verification-dashboard
     */
    this.idVerificationService.setDisplayInviteContributorsLightbox();
  }

  /**
   * display lightbox for handling contributors
   */
  displayManageContributorsLightbox() {
    /**
     * set subscription to display manage
     * contributors lightbox which will be handled
     * in id-verification-dashboard
     */
    this.idVerificationService.setDisplayManageContributorsLightbox();
  }

  /**
   * display lightbox report generation
   */
  displayGenerateReportLightbox() {
    /**
     * set subscription to display manage
     * contributors lightbox which will be handled
     * in id-verification-dashboard
     */
    this.idVerificationService.setDisplayGenerateReportLightbox();
  }

  /**
   * display lightbox for advanced settings
   * at this point advanced settings includes
   * only project delete
   */
  displayAdvancedSettingsLightbox() {
    /**
     * set subscription to display advanced
     * settings lightbox which will be handled
     * in id-verification-dashboard
     */
    this.idVerificationService.setDisplayAdvancedSettingsLightbox();
  }

  /**
   * display the edit project name box
   */
  OnEditName(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest(".card");
    this.showEditNameFor = closestCard.attr("id");
    this.idVerificationService.setActiveProjectSettings(this.showEditNameFor);

    setTimeout(function () {
      closestCard.find(".card-title-editor").focus();
    }, 100);
  }

  /**
   * make sure the project
   * doesnt get selected on click
   * to focus in the input box
   */
  OnFocusName(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * finish editing the name
   */
  OnEditNameComplete(event) {
    const newName = $(event.target).val();
    // const pattern = /^([-@.\/#&+\w\s]+)*$/;
    const patternWithLength = /^.{2,60}$/;
    // if (!pattern.test(newName)) {
    //   $('.error-field').text('Project name is not supported');
    //   return;
    // }
    // '/assets/images/spinner-gray.svg'
    if (
      !patternWithLength.test(newName) ||
      newName.replace(/\s/g, "").length === 0
    ) {
      $(".error-field").text("Project name is invalid (min 2, max 60 chars)");
      $(".click-save").addClass("d-none");
      return;
    }
    this.showEditNameFor = "";
    if (
      newName !==
      this.idVerificationService.getActiveProjectSettingsDetails()["record"]
        .name
    ) {
      // update the name, because of the
      // way it is implemented BE must receive
      // the project color too. Since we are not
      // doing anything with the project color
      // we are passing the current project color

      // project color is no longer mandatory when renaming a project
      // let projectColor = '#00889c';
      // if (this.idVerificationService.getActiveProjectSettingsDetails()['record'].projectColor) {
      //   projectColor = this.idVerificationService.getActiveProjectSettingsDetails()['record'].projectColor;
      // }
      $(event.target)
        .closest(".card")
        .find(".card-img-top")
        .attr("src", "/assets/images/spinner-gray.svg");
      this.idVerificationService.updateProjectName(newName);
    }
  }

  /**
   * handle press enter key event
   */
  OnEditNameKeyUp(event) {
    console.log("keyUp");
    const keycode = event.keyCode ? event.keyCode : event.which;
    $(".error-field").text("");
    $(".click-save").removeClass("d-none");
    const newName = $(event.target).val();
    const patternWithLength = /^.{2,60}$/;
    if (
      !patternWithLength.test(newName) ||
      newName.replace(/\s/g, "").length === 0
    ) {
      $(".error-field").text("Project name is invalid (min 2, max 60 chars)");
      $(".click-save").addClass("d-none");
      return;
    }
    if (keycode === "13" || keycode === 13) {
      // enter key is pressed - check if there is action defined for this input field
      this.OnEditNameComplete(event);
    }
  }
  OnChangeSelectFilter(event) {
    const selectedFilter = $(event.target).children("option:selected").val();
    this.idVerificationService.setactiveProjectSortBy(selectedFilter);
    this.loadingLocked = true;
    this.showLoader = true;
    this.allProjectsLoaded = false;
    this.idVerificationService.retrieveActiveProjects(
      undefined,
      selectedFilter,
      "desc",
    );
  }
  loadAdditionalProjects() {
    this.idVerificationService.retrieveActiveProjects(
      this.idVerificationService.getActiveProjectBookmark(),
    );
  }
  onScrollProjectList(event) {
    const scrollTop = event.target.scrollTop;

    if (
      event.target.scrollHeight -
        event.target.scrollTop -
        event.target.clientHeight <
      20
    ) {
   
      if (!this.loadingLocked && !this.allProjectsLoaded) {

        this.loadingLocked = true;
        this.showLoader = true;
        const selectedFilter = $(event.target)
          .children("option:selected")
          .val();
        this.idVerificationService.retrieveActiveProjects(
          this.idVerificationService.getActiveProjectBookmark(),
          this.idVerificationService.getactiveProjectSortBy(),
          "desc",
          true,
        );
      }
    }
  }
  ngAfterContentInit() {
    // checking URL after we have set active projects in case we need to  redirect to an active project
    if (
      this.router.url.includes("id-verification") ||
      this.router.url.includes("funds") ||
      this.router.url.includes("investor")
    ) {
      this.route.params.subscribe((params) => {
        const urlProjectId = params["projectId"];
        if (urlProjectId === undefined || urlProjectId === "") {
          $(".id-verification-container").removeAttr("style");
        } else {
          const projectRecords = this.idVerificationService.getActiveProjects();
          let projectFound = false;
          projectRecords.forEach((project, key) => {
            if (project.key === urlProjectId) {
              projectFound = true;
            }
          });
          if (projectFound) {
            this.OnSelectActiveProject("", urlProjectId);
            $(".id-verification-container").removeAttr("style");
          } else {
            this.OnSelectActiveProject("", urlProjectId, true);
            $(".id-verification-container").removeAttr("style");
          }
        }
      });
    }
  }

  ngOnDestroy() {
    this.activeProjectsStatus.unsubscribe();
    this.activeProjectStatus.unsubscribe();
    this.detectJoinProjectStatus.unsubscribe();
    this.editedProject.unsubscribe();
  }
}
