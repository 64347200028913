import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class EditorService {
  public typeSensitivity: number;
  private typingMode: boolean;
  public timeOutHelper: any;

  constructor() {
    this.typeSensitivity = 80; // execute spellcheck only after 80ms of inactivity
    this.typingMode = false;
  }

  public setTypingMode() {
    this.typingMode = true;
  }

  public removeTypingMode() {
    this.typingMode = false;
  }
}
