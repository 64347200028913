import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";

@Component({
  selector: "app-funds-add-related-party",
  templateUrl: "./funds-add-related-party.component.html",
  styleUrls: ["./funds-add-related-party.component.scss"],
})
export class FundsAddRelatedPartyComponent implements OnInit {
  @Input() element;
  constructor(private idVerificationService: IdVerificationService) {}

  ngOnInit(): void {}

  // Starts flow for new related party
  addRelatedParty() {
    this.idVerificationService.skipToStep(11, {
      requestKey: this.idVerificationService.getActiveInvestor(),
    });
  }
}
