import { Component, OnInit } from "@angular/core";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { UserService } from "src/app/user.service";

@Component({
  selector: "app-kyc-templates-list",
  templateUrl: "./kyc-templates-list.component.html",
  styleUrls: ["./kyc-templates-list.component.scss"],
})
export class KycTemplatesListComponent implements OnInit {
  constructor(
    private idVerificationService: IdVerificationService,
    private userService: UserService,
  ) {}

  myOrganisationTemplates;

  ngOnInit(): void {
    this.myOrganisationTemplates = [];
    this.idVerificationService.settfundsOrganizationTemplates([]);
    this.idVerificationService.prepareIdConfigurationdata(true, true, false);
    this.idVerificationService.fundsOrganizationTemplates = [];
    // if (this.idVerificationService.getfundsOrganizationTemplates()) {
    if (false) {
      this.setMyOrganisationTemplates(
        this.idVerificationService.getfundsOrganizationTemplates(),
      );
    } else {
      this.setMyOrganisationTemplates(
        this.idVerificationService.fundsOrganizationTemplates,
      );
      this.userService
        .getEndPoint(
          "api/organizations/templates",
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
              // console.log('Error while fetching record for additional data!');
              return;
            }
            this.idVerificationService.settfundsOrganizationTemplates(response);
            this.setMyOrganisationTemplates(
              this.idVerificationService.fundsOrganizationTemplates,
            );
          },
          (error) => {
            // console.log('Following error occured while fetching record for additional data!', error);
            this.setMyOrganisationTemplates(
              this.idVerificationService.fundsOrganizationTemplates,
            );
            return;
          },
        );
    }
  }

  CreateNewTemplate(event) {
    event.preventDefault();
    event.stopPropagation();
    this.idVerificationService.createNewTemplate();
  }

  onTemplateEdit(event) {
    event.preventDefault();
    event.stopPropagation();
    // get selected template id
    const closestCard = $(event.target).closest(".card");
    const selectedTemplate = closestCard
      .find(".body")
      .attr("data-template-key");
    const imgElem = $(event.target).find("img");
    imgElem.attr("src", "/assets/images/spinner-green.svg");
    // retreive selected template data
    this.userService
      .getEndPoint(
        "/api/organizations/templates/" + selectedTemplate,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe((response) => {
        if (response === undefined) {
          console.log("Error while fetching record for additional data!");
          return;
        }
        response["record"]["existingTemplateKey"] = selectedTemplate;
        // this.displayLoader = false;
        // this.displayModal = false;
        imgElem.attr("src", "/assets/images/ico-edit-template.svg");
        this.idVerificationService.createNewTemplate(response["record"]);
      });
  }

  OnTemplateDelete(event) {
    event.preventDefault();
    event.stopPropagation();
    const answer = window.confirm(
      "Are you sure that you want to delete the selected KYC template? ",
    );
    if (answer) {
      const closestCard = $(event.target).closest(".card");
      $(closestCard).addClass("disabled");
      const selectedTemplate = closestCard
        .find(".body")
        .attr("data-template-key");
      const imgElem = $(event.target).find("img");
      imgElem.attr("src", "/assets/images/spinner-green.svg");
      // retreive selected template data
      this.userService
        .deleteEndpoint(
          "/api/organizations/templates/" + selectedTemplate,
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (response === undefined) {
              console.log("Error while fetching record for additional data!");
              return;
            }
            const existingTemplatesObject =
              this.idVerificationService.getfundsOrganizationTemplates();
            const templateIndex = existingTemplatesObject.findIndex(
              (template) => template.key === selectedTemplate,
            );
            existingTemplatesObject.splice(templateIndex, 1);
            this.idVerificationService.settfundsOrganizationTemplates(
              existingTemplatesObject,
            );
            imgElem.attr("src", "/assets/images/ico-delete-template.svg");
          },
          (error) => {
            if (error.status === 403) {
              alert("KYC Templates that are in use cannot be deleted");
            }
          },
        );
    }
  }

  publishToggle(event, published, key) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest(".card");
    const selectedTemplate = closestCard
      .find(".body")
      .attr("data-template-key");
    const imgElem = $(event.target).find("img");
    imgElem.attr("src", "/assets/images/spinner-green.svg");
    // retreive selected template data
    let payload = { published: true };
    let callbackImage = "/assets/images/ico-unpublish-template.svg";
    if (published) {
      payload = { published: false };
      callbackImage = "/assets/images/ico-publish-template.svg";
    }
    this.userService
      .postEndPoint(
        "/api/organizations/templates/" + selectedTemplate + "/publish",
        payload,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe((response) => {
        if (response === undefined) {
          console.log("Error while fetching record for additional data!");
          return;
        }
        const existingTemplatesObject =
          this.idVerificationService.getfundsOrganizationTemplates();
        const templateIndex = existingTemplatesObject.findIndex(
          (template) => template.key === selectedTemplate,
        );
        existingTemplatesObject[templateIndex].record.published = !published;
        this.idVerificationService.settfundsOrganizationTemplates(
          existingTemplatesObject,
        );
        imgElem.attr("src", callbackImage);
      });
  }

  setMyOrganisationTemplates(templates) {
    this.myOrganisationTemplates = templates;
  }
}
