/**
 * service that will help us communicate with the PDF Server
 * all methods for generating and/or signing PDF's should reside here
 * all required information should be provided to below's methods
 */
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { HelperServiceService } from "./helper-service.service";
import { UserService } from "./user.service";
import { pdfGenerationStatus } from "./types/types";
import { environment } from "../environments/environment";

@Injectable({
  providedIn: "root",
})
export class PdfServerService {
  private baseUrl: string = environment.APIEndpoint;
  private swaggerUrl =
    "https://virtserver.swaggerhub.com/SingleTruth/singletruth/1.0.5/";
  private pdfGenerationStatusEvt = new Subject<any>();
  private endpoints = {
    generatePDF: "api/pdf/generatepdf",
  };
  public detectPdfGenerationStatusEvt =
    this.pdfGenerationStatusEvt.asObservable();

  private pdfGenerationStatus: pdfGenerationStatus = {
    status: "INIT",
    type: "none",
  };

  constructor(
    private http: HttpClient,
    private helper: HelperServiceService,
    private userService: UserService,
  ) {}

  buildEndPoint(
    endpoint,
    projectKey = "",
    verificationKey = "",
    query = "",
    demoUrl = true,
    screeningKey = "",
    documentKey = "",
    toBeReplaced = "",
    toBeReplacedWith = "",
  ) {
    return (
      (demoUrl ? this.baseUrl : this.swaggerUrl) +
      this.endpoints[endpoint]
        .replace("{key}", projectKey)
        .replace("{projectKey}", projectKey)
        .replace("{verificationKey}", verificationKey)
        .replace("{requestKey}", verificationKey)
        .replace("{screeningKey}", screeningKey)
        .replace("{evidenceKey}", screeningKey)
        .replace("{documentKey}", documentKey)
        .replace("{toBeReplaced}", toBeReplaced)
        .replace("{toBeReplacedWith}", toBeReplacedWith) +
      query
    );
  }

  async generateIdVerificationPDFext(
    verificationDetails,
    frontImage,
    faceImage,
    backImage,
    domain,
    vfeCode,
    fileName,
    completePDF,
    verificationGo?,
  ) {
    const extendedDomain =
      `${location.protocol}//` +
      window.location.hostname +
      (!location.port ? "" : `:${location.port}`);
    const jsonData = {
      customOptions: {
        topMargin: "95px",
      },
      documentName: "Identity Verification",
      document: verificationDetails.document,
      transaction: verificationDetails.transaction,
      createdBy: verificationDetails.createdBy,
      completedOn: verificationDetails.completedOn,
      finalizeComment: verificationDetails.comment,
      domain: domain,
      extendedDomain: extendedDomain,
      vfeCode: `${vfeCode}`,
      logo: `${extendedDomain}/assets/images/mesh_id.png`,
      frontImg: frontImage.slice(0, -2), // remove == as it sometimes converts to unicode chars on BE and it causes images not to display
      backImg: backImage.slice(0, -2), // remove == as it sometimes converts to unicode chars on BE and it causes images not to display
      faceImg: faceImage.slice(0, -2), // remove == as it sometimes converts to unicode chars on BE and it causes images not to display
      validitySign: verificationDetails.validitySign,
      qrCode: verificationDetails["qrCode"],
    };

    let templateFile = "idVerificationTemplateUpdated";
    //  generate the PDF
    if (verificationGo) {
      templateFile = "idVerificationTemplateGo";
    }
    const pdfData = await this.generatePDF(
      templateFile,
      fileName,
      JSON.stringify(jsonData),
      "",
      completePDF,
    );
    return pdfData;
  }

  /**
   *  send a request to pdf generating server
   * @param templateFile string, the template file excluding the ".html" extension used to generate the pdf
   * @param jsonData string, json encoded data used to populate the pdf
   * @param completePDF variable to know that we are in the process of the complete PDF merging
   * *                  so no file will be downloaded but the data will be returned
   */
  async generatePDF(
    templateFile: string,
    fileName: string,
    jsonData: string,
    mergeDocuments: string = "",
    completePDF?,
    returnHtml?,
  ) {
    if (templateFile === "" || jsonData === "") {
      console.log(
        "Could not resolve status of a verification: required parameters are missing!",
      );
      return;
    }
    const payload = {
      htmlTemplateFile: templateFile,
      jsonData: jsonData,
      mergeDocuments: mergeDocuments,
    };

    if (returnHtml) {
      payload["contract"] = true;
    }

    return new Promise((resolve, reject) => {
      // this.http.post('http://localhost:4000/generatepdf', payload, {
      //                                         responseType:'blob'
      //                                         , observe: 'body'}).subscribe(async response => {
      this.http
        .post(this.buildEndPoint("generatePDF"), payload, {
          responseType: "blob",
          observe: "body",
        })
        .subscribe(
          async (response) => {
            if (response === undefined) {
              console.log("Error while fetching the generated PDF!");
              resolve("error");
            }

            const newBlob = new Blob([response as Blob], {
              type: "application/pdf",
            });
            // let newBlob = new Blob([response], { type: "application/pdf" });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
            }

            if (returnHtml) {
              resolve(await newBlob.text());
              return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            this.pdfGenerationStatus.status = "DONE";
            this.setPdfGenerationStatus(this.pdfGenerationStatus);
            if (!completePDF) {
              const link = document.createElement("a");
              link.href = data;
              link.download = fileName;
              // this is necessary as link.click() does not work on the latest firefox
              link.dispatchEvent(
                new MouseEvent("click", {
                  bubbles: true,
                  cancelable: true,
                  view: window,
                }),
              );

              setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
                link.remove();
              }, 100);
              resolve("done");
            } else {
              const reader = new FileReader();
              reader.readAsDataURL(newBlob);
              reader.onloadend = function () {
                const base64data = reader.result;
                resolve(base64data);
              };
            }
          },
          (error) => {
            console.log(
              "Following error occured while attempting to generate PDF for a verification!",
              error,
            );
            // we are displaying fancy error meessage when the PDF could not be generated
            // so below code is no longer needed
            // if (!completePDF) {
            //   alert('An error occured while attempting to generate PDF for a verification!');
            // }
            this.pdfGenerationStatus.status = "ERROR";
            this.setPdfGenerationStatus(this.pdfGenerationStatus);
            resolve("error");
          },
        );
    });
  }

  setPdfGenerationStatus(status: pdfGenerationStatus) {
    this.pdfGenerationStatus = status;
    this.pdfGenerationStatusEvt.next(this.pdfGenerationStatus);
  }

  getPdfGenerationStatus() {
    return this.pdfGenerationStatus;
  }
}
