import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { VerificationsService } from "src/app/verifications/verifications.service";
import { AuthService } from "src/app/auth/auth.service";
import { UserService } from "src/app/user.service";
import { tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-share-request",
  templateUrl: "./share-request.component.html",
  styleUrls: ["./share-request.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ShareRequestComponent implements OnInit {
  headerInfo: {};
  headerClasses: {};
  message: string;
  displayLoader = false;
  logo = "assets/images/logo-alternate.svg";
  elementsVisible = false;
  email: string;
  shareKey: string;
  currentStep;
  terms;
  errorState;
  token;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public translate: TranslateService,
    private http: HttpClient,
    private auth: AuthService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    console.log("app init");
    // need to check if auth token is passed from BE in URL parameter
    if (this.activatedRoute.snapshot.queryParams["token"]) {
      this.token = this.activatedRoute.snapshot.queryParams["token"];
      this.auth.sAuthenticateUrl(this.token);
      this.auth.noLoginTokenAuth = true;
      // add the session token to cookies
      // const date = new Date();
      // date.setTime(date.getTime() + (1 * 60 * 1000));
      // const expires = 'expires= ' + date.toUTCString();
      // const sessionToken = 'msessionToken= ' +  this.activatedRoute.snapshot.queryParams['sessionToken'] + ';';
      // document.cookie = sessionToken + expires;
    }
    this.currentStep = "terms";
    this.headerInfo = {
      headerType: "full", // 'inline',
      headerTitle: "Invitation as a colaborator",
      activeNavLink: "requestSharing",
    };
    this.headerClasses = {
      sectionClass: "", // 'ic_height_auto',
    };

    // retreive terms file
    // get the file name
    console.log(this.translate.currentLang);
    const filename = "terms" + "-" + this.translate.currentLang + ".html";
    this.http.get(filename, { responseType: "text" }).subscribe((terms) => {
      this.terms = terms;
    });

    this.auth.auth0InitedSubject$.subscribe((res) => {
      if (res && !this.userService.getUserPermissions()) {
        this.userService.auth();
      }
    });

    // the default is already set even though background color is not retreived
    this.shareKey = this.activatedRoute.snapshot.params.shareKey;
    this.http.get("/api/information/request/share/" + this.shareKey).subscribe(
      (response) => {
        if (response === undefined) {
          this.message = this.translate.instant("shareRequest.errorMessage");
        }
        if (response["status"] === "Email not verified" && !this.token) {
          console.log("UNVERIFIED EMAIL");
          this.email = response["email"];
          this.elementsVisible = true;
          this.initiateNiceScroll();
        } else {
          if (response["projectKey"] && response["claimed"] === true) {
            if (response["typeOfInvestor"] === "AutomaticRequest") {
              this.router.navigateByUrl(
                "investor/dashboard/" +
                  response["projectKey"] 
              );
            } else {
              this.router.navigateByUrl(
                "/investor/dashboard/" + response["projectKey"],
              );
            }
          } else {
            this.router.navigateByUrl(
              "/information/request/share/" + response["requestKey"] + "/user",
            );
          }
        }
      },
      (error) => {
        this.errorState = true;
        console.log("ERROR WITH SHARING PROCESS " + error);
        this.message = this.translate.instant("shareRequest.errorMessage");
        this.router.navigate(["/invalid-link"]);
      },
    );
    // this.invitationKey = this.activatedRoute.snapshot.params.invitationKey;
    // this.verificationsService.setInvitationKey(this.invitationKey);
  }

  initiateNiceScroll() {
    let container;
    container = $(".terms-container");
    container.niceScroll({
      cursorcolor: "#d8d8d8",
      cursorwidth: "10px",
      cursorborderradius: "6px",
      cursoropacitymin: 1,
      railpadding: { top: 21, right: 7, left: 0, bottom: 21 },
    });
  }

  agreeToTerms(event) {
    if ($(event.target).prop("checked")) {
      $("#provideInformationButton").prop("disabled", false);
    } else {
      $("#provideInformationButton").prop("disabled", true);
    }
  }

  agreeWithTermsClick() {
    let container;
    container = $(".terms-container");
    container.niceScroll().remove();
    $(".agreement-container .loader-overlay").removeClass("d-none");
    const payload = {
      cryptoKey: this.shareKey,
    };
    this.http
      .post("api/information/request/share/confirmed", payload)
      .subscribe(
        (response) => {
          if (response === undefined) {
            this.message = this.translate.instant("shareRequest.errorMessage");
          }
          this.currentStep = "finished";
          $(".agreement-container .loader-overlay").addClass("d-none");
        },
        (error) => {
          this.currentStep = "error";
          $(".agreement-container .loader-overlay").addClass("d-none");
          console.log("ERROR WITH SHARING PROCESS " + error);
        },
      );
  }

  OnClaimEmail(event) {
    event.preventDefault();
    event.stopPropagation();
    $(".agreement-container .loader-overlay").removeClass("d-none");
    const payload = {
      cryptoKey: this.shareKey,
    };
    this.http
      .post("api/information/request/share/confirmed", payload)
      .subscribe(
        (response) => {
          if (response === undefined) {
            this.message = this.translate.instant("shareRequest.errorMessage");
          }
          this.currentStep = "finished";
          $(".agreement-container .loader-overlay").addClass("d-none");
        },
        (error) => {
          this.currentStep = "error";
          $(".agreement-container .loader-overlay").addClass("d-none");
          console.log("ERROR WITH SHARING PROCESS " + error);
        },
      );
  }
}
