<iframe
  scrolling="no"
  *ngIf="showIframe"
  class="downloadble-preview-file downloadable-iframe-preview"
  style="z-index: 1000"
  [attr.documentKey]="documentImage.Key ? documentImage.Key : documentImage.key"
  height="100%"
  width="100%"
  alt=""
  allow="camera;accelerometer;gyroscope;magnetometer;fullscreen"
  allowfullscreen
  #imageRef
></iframe>
