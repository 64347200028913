<div class="px-4 py-4 dv-review-step-one d-flex flex-column d-none mb-0 position-relative form-container-side" *ngIf="!loadingScreen && !verificationLink">
    <div class="dv-review-step">
        <h3 class="verify-heading my-3" [innerHTML]="
        'review.digitalVerifications.title'
          | translate: { entity: entityName ? entityName : 'entity' }
      "></h3>
        <p class="pr-2" *ngIf="profile.typeOfInvestor === 'LegalPerson'" [innerHTML]="
        'review.digitalVerifications.content6'
          | translate: { entity: entityName ? entityName : 'entity' }
      "></p>
        <p class="pr-2" *ngIf="profile.typeOfInvestor !== 'LegalPerson'" [innerHTML]="
        'review.digitalVerifications.content'
          | translate: { entity: entityName ? entityName : 'entity' }
      "></p>
      <div class="digital-evidence-label" *ngFor="let digitalEvidence of digitalVerifications">  {{
        this.helperService.translateVerificationType(
        digitalEvidence.verificationType
        )
        }}  </div>

        <p *ngIf="profile.typeOfInvestor !== 'LegalPerson'" class="mt-4">
            {{ "review.digitalVerifications.content1" | translate }}
        </p>
        <p *ngIf="profile.typeOfInvestor === 'LegalPerson'" [innerHTML]="
        'review.digitalVerifications.content2'
          | translate: { entityName: entityName ? entityName : 'entity' }
      " class="mt-4"></p>
        <button (click)="initiateLink()" id="dvGenerateLink" type="submit" class="form-button form-button-1 my-3 float-right">
            {{ "common.continue" | translate }}
        </button>
    </div>
    <div class="dv-review-step-loading d-none flex-column">
        <p class="mb-1">{{ "review.digitalVerifications.content4" | translate }}</p>
        <p>
            <strong> {{ "review.digitalVerifications.content5" | translate }}</strong>
        </p>
        <div class="digital-evidence-labels-container">
            <div class="digital-evidence-label digital-evidence-label-sharing"
                [ngClass]="{ 'd-none': digitalEvidence.verificationKey }"
                *ngFor="let digitalEvidence of digitalVerifications">
                <div>
                    {{
                    this.helperService.translateVerificationType(
                    digitalEvidence.verificationType
                    )
                    }}
                </div>
                <img class="evidence-label-check d-none" src="assets/images/check.svg" />
                <img class="evidence-label-spinner d-none" src="assets/images/spinner-green.svg" />
            </div>
        </div>
    </div>
</div>
<app-verify-yourself-or-share-dialog 
*ngIf="!loadingScreen && verificationLink" 
[entityName]="entityName"
[typeOfInvestor]="profile.typeOfInvestor"
[verificationLink]="verificationLink"
[requestKey] = "requestKey"
[verification] = this.digitalVerifications[0]
(markCurrentStepAsCompletedTwo) = "markCurrentStepAsCompletedTwo()"
(retreiveEvidenceData) = "retreiveEvidenceData()"
></app-verify-yourself-or-share-dialog>
<div class="px-4 py-4 dv-review-step-one d-flex flex-column d-none mb-0 position-relative form-container-side" >
<div *ngIf="loadingScreen" class="fields-loading-screen">
    <div class="fields-loading-screen-wrapper">
      <div class="loading">
        <img
          src="/assets/images/MeshID-Loading-Animation-WhiteBG.gif"
          width="100px"
          height="100px"
          class="my-auto mx-auto"
        />
        <div
          class="px-3"
          style="
            font-size: 22px;
            font-weight: 700;
            margin-top: 30px;
            text-align: center;
          "
        >
          {{ "common.pleaseWait" | translate }}
        </div>
      </div>
    </div>
  </div>

</div>

