import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin, Subject } from "rxjs";
import { IdVerificationService } from "../../id-verification.service";
import { UserService } from "src/app/user.service";
import { LightboxService } from "src/app/ui/lightbox/lightbox.service";

@Injectable({
  providedIn: "root",
})
export class RiskAssessmentService {
  
  public riskEventPreviewComplete = new Subject<any>();
  public detectRiskEventPreviewComplete = this.riskEventPreviewComplete.asObservable();
  public riskEventPreviewClose = new Subject<any>();
  public detectRiskEventPreviewClose = this.riskEventPreviewClose.asObservable();
  constructor( 
    private idVerificationService: IdVerificationService,
    private userService: UserService,
    private lightboxService: LightboxService
  ) 
  {
    
  }

  loadRiskAssessmentLightbox() {
    this.idVerificationService.skipToStep(25, {isLoading: true});

  }

  buildRiskAssessmentLightbox(
    riskEventKey,
    disableScroll = false,
    hideActions = true,
    dontReloadAfterClosing = true,
    showDeeplink = false
  ) {

    let projectKey = this.idVerificationService.getActiveProject();
    let requestKey = this.idVerificationService.getActiveInvestor();


    this.idVerificationService.skipToStep(25, {isLoading: true});

    const observableBatch = [];
    if (!requestKey) {
      requestKey = this.idVerificationService.getActiveInvestor();
    }
    const history = this.userService.getEndPoint(
      this.idVerificationService.buildEndPoint(
        "getRiskReviewHistory",
        riskEventKey,
      ),
      { responseType: "json" },
      { observe: "body" },
      "funds",
    );
    const riskAssessmentData = this.userService.getEndPoint(
      this.idVerificationService.buildEndPoint(
        "riskReviewEvent",
        riskEventKey,
      ),
      { responseType: "json" },
      { observe: "body" },
      "funds",
    );

    observableBatch.push(riskAssessmentData);
    observableBatch.push(history);
    // observableBatch.push(getContributors);

    forkJoin(observableBatch).subscribe(async (response) => {
      // decide wether to show the additional fields tab for the digital verifications
        response.unshift({
        dsiableScroll: disableScroll,
        requestKey: requestKey,
        hideActions: hideActions,
        dontReloadAfterClosing : dontReloadAfterClosing,
        showDeeplink: showDeeplink
      });
      this.idVerificationService.skipToStep(25, response);
    });
  }

  parseStatus(status) {
    return status.toLowerCase().replace(/_/g, ' ');
  }
  
  oppenRRELightbox(riskAssessmentData)
  {
    this.lightboxService.open(riskAssessmentData);
  }

  getRiskAssessmentList(filter?) {
    let queryArguments = '?';
    if(filter) {
      if(filter.pageSize) {
        queryArguments = queryArguments.concat('pageSize=' + filter.pageSize + "&");
      }
      if(filter.bookmark) {
        queryArguments = queryArguments.concat('bookmark=' + filter.bookmark + "&");
      }
      if(filter.sortBy) {
        queryArguments = queryArguments.concat('sortBy=' + filter.sortBy + "&");
      }
      if(filter.sortOrder) {
        queryArguments = queryArguments.concat('sortOrder=' + filter.sortOrder + "&");
      }
      if(filter.afterDate) {
        queryArguments = queryArguments.concat('next_review_after=' + filter.afterDate + "&");
      }
      if(filter.beforeDate) {
        queryArguments = queryArguments.concat('next_review_before=' + filter.beforeDate + "&");
      }
      
      if(filter.assignedToUser) {
        queryArguments = queryArguments.concat('assignedToUser=true' + "&");
      }
      if (queryArguments.slice(-1) === "&") {
        queryArguments = queryArguments.slice(0, -1);
      }
    }
    return    this.userService.getEndPoint(
      '/api/risk/review' + queryArguments,
      { responseType: "json" },
      { observe: "body" },
      'funds',
    )
  }

  checkIfUserIsApprover(approvers):boolean
  {
    let user =  this.userService.getUserInfo();
    let isApprover = false;
    if(user["username"])
    {
      for( let i = 0; i < approvers.length ; i++ )
        {
          if(approvers[i].user.email == user["username"])
          {
            isApprover = true;
            break;
          }
        }
    }
    return isApprover;
  }

  getApprovers(riskAssessmentKey) {
    return this.userService.getEndPoint(
      this.idVerificationService.buildEndPoint(
        "assignApprovers",
        riskAssessmentKey,
      ),
      { responseType: "json" },
      { observe: "body" },
      "funds",
    );
  }

  acceptRiskApprover(riskAssessmentKey) {
    return this.userService.getEndPoint(
      this.idVerificationService.buildEndPoint(
        "recalculateRiskAssessment",
         riskAssessmentKey
      ),
      { responseType: "json" },
      { observe: "body" },
      "funds",
    );
  }

  recalculateRiskAssessment(riskAssessmentKey) {
    return this.userService.postEndPoint(
      this.idVerificationService.buildEndPoint(
        "recalculateRiskAssessment",
         riskAssessmentKey
      ),
      {},
      { responseType: "json" },
      { observe: "body" },
      "funds",
    );
  }

  assignApprovers(riskAssessmentKey, approvers) {
    return this.userService.postEndPoint(
      this.idVerificationService.buildEndPoint(
        "assignApprovers",
        riskAssessmentKey
      ),
      approvers,
      { responseType: "json" },
      { observe: "body" },
      "funds",
    );
  }

  getRiskReviewHistory(riskAssessmentKey) {
    return this.userService.getEndPoint(
      this.idVerificationService.buildEndPoint(
        "getRiskReviewHistory",
        riskAssessmentKey,
      ),
      { responseType: "json" },
      { observe: "body" },
      "funds",
    );
  }

  overrideRiskIndicator(riskAssessmentKey, payload) {
      this.userService.postEndPoint(
      this.idVerificationService.buildEndPoint(
        "overrideRiskIndicator",
        riskAssessmentKey
      ),
      payload,
      { responseType: "json" },
      { observe: "body" },
      "funds",
    ).subscribe(
      (response)=>{
        this.reloadNewInfoToPreview(response);
      },
      error => {}
    );
  }

  finalizeRiskReview(riskAssessmentKey, payload) {
     this.userService.postEndPoint(
      this.idVerificationService.buildEndPoint(
        "finalizeRiskReview",
        riskAssessmentKey
      ),
      payload,
      { responseType: "json" },
      { observe: "body" },
      "funds",
    ).subscribe(
      (response)=>{
        this.reloadNewInfoToPreview(response);
      },
      error => {}
    );
  }

  reloadNewInfoToPreview(response)
  {
    let arg =[{
      disableScroll: false,
      requestKey: this.idVerificationService.getActiveInvestor(),
      hideActions: true,
      dontReloadAfterClosing : false,
    }, 
    response];
    this.getRiskReviewHistory(response.key).subscribe(
      (historyResponse)=>{
        arg[2]=historyResponse;
        this.idVerificationService.skipToStep(25, arg);
      },
      error => {}
    )
  }

  getRiskAssessment(riskEventKey) {
    return this.userService.getEndPoint(
      this.idVerificationService.buildEndPoint(
        "riskReviewEvent",
        riskEventKey,
      ),
      { responseType: "json" },
      { observe: "body" },
      "funds",
    );
  }
}
