import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { UserService } from 'src/app/user.service';
import {
  takeUntil,
} from "rxjs/operators";
import { HelperServiceService } from 'src/app/helper-service.service';
@Component({
  selector: 'app-verify-yourself-or-share-dialog',
  templateUrl: './verify-yourself-or-share-dialog.component.html',
  styleUrls: ['./verify-yourself-or-share-dialog.component.scss']
})
export class VerifyYourselfOrShareDialogComponent implements OnInit {

  @Input() entityName: string;
  @Input() verificationLink: any;
  @Input() typeOfInvestor: string;
  @Input() requestKey;
  @Output() markCurrentStepAsCompletedTwo = new EventEmitter<any>();

  confirmPersonage : boolean; // kje bidime shto 
  constructor(
    public translate: TranslateService,
    private idVerificationService: IdVerificationService,
    private userService: UserService,
    private helper : HelperServiceService
  ) { }

  ngOnInit(): void {

    let _this = this;
    window.onmessage = function (e) {
     
      if (e.data === "iframe is closed back") {
        _this.onIframeCloseBack();
      }
      if (e.data === "iframe is closed completed") {
        _this.onIframeCloseBack(true);
      }
      if (e.data === "iframe is closed GO") {
        _this.onIframeCloseBack(true);
      }
    };
  }
  
  onIframeCloseBack(completed?) {

    if(completed) {
      this.markCurrentStepAsCompletedTwo.emit("");
    }
    $('app-id-verification-dashboard').removeClass('d-none');
    $("#dvIframe").remove();
    setTimeout(function () {
      $("#dvIframe").remove();
    }, 1500);

    this.userService.postEndPoint(
      this.idVerificationService.buildEndPoint(
        "lazyResolveDigitalVerificationsFunds",
        this.idVerificationService.getActiveProject(),
        this.requestKey
      ),
      {},
      { responseType: "json" },
      { observe: "body" },
      "funds",
    ).subscribe( response2 => {

      if(completed) {
      }
    },error => {
      if(completed) {
      }
    });
  }

  onShareDVNextButton(event) {
    event.preventDefault();
    if (this.verificationLink) {
      event.preventDefault();
      $(
        `<iframe src="${this.verificationLink}" frameborder="0" id="dvIframe" style="position: absolute; top: 0; left: 0; width: 100vw; height: 100vh; z-index: 999999;"></iframe>`,
      ).appendTo("body");
      $('app-id-verification-dashboard').addClass('d-none');
      return; 
    }
  }
  switchScreen(newValue) {
    this.confirmPersonage = newValue;
  }
  onShareDVSkip() {
    this.idVerificationService.skipToStep(0);
  }

  
  sendVerificationLink(event) {
    let payload;
    payload = $(event.target)
      .closest(".input-text-button-wrapper")
      .find("input")
      .val();
    let requestKey = $(event.target).attr("requestKey");
    // if (!requestKey || requestKey === "") {
    //   requestKey = this.idVerificationService.getActiveInvestor();
    // }
    $(event.target)
      .closest(".element-wrapper")
      .find(".button-error-field")
      .hide();
    if (
      payload !== "" &&
      payload.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
    ) {
      $(event.target)
        .closest(".element-wrapper")
        .find(".input-action-status")
        .text("Link sent!")
        .fadeIn();
      setTimeout(function () {
        $(event.target)
          .closest(".element-wrapper")
          .find(".input-action-status")
          .text("Link sent!")
          .fadeOut();
      }, 2500);
      // $('#sendAddressVerificationLink').val('');
      const path =
        "/api/projects/" +
        this.idVerificationService.getActiveProject() +
        "/information/requests/" +
        requestKey +
        (this.idVerificationService.isInvestorType()
          ? "/verifications/email"
          : "/email");
      this.userService
        .postEndPoint(
          path,
          { email: payload },
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .subscribe(
          (response) => {
            if (typeof response === undefined) {
              return;
            }
          },
          (error) => {
            console.log(
              "The following error occured when generating id verification link",
              error,
            );
          },
        );
    } else {
      $(event.target)
        .closest(".element-wrapper")
        .find(".button-error-field")
        .show();
    }
  }

  CopyLinkToClipboard2(event) {
    const inputEl = $(event.target).siblings("input");
    const statusEl = inputEl.siblings(".input-action-status");
    this.helper.copyToClipboard(inputEl.val().toString());
    statusEl.text("Successfully copied!").fadeIn();
    setTimeout(function () {
      statusEl.fadeOut();
    }, 2500);
  }

}
