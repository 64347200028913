<div class="activity-log">
  <div *ngIf="label" class="preview-label">
    {{ label }}
  </div>
  <div *ngIf="activityLogText">
    <div
      class="activity"
      *ngFor="let log of activityLogText.parsedHistoryEntries"
    >
      <span [tooltip]="log.tooltip" [position]="tooltipPosition">{{
        log.text
      }}</span>
      <div class="activity-dot activity-dot-slim">
        <div></div>
      </div>
    </div>
  </div>
</div>
