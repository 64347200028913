<div class="collapisble-evidence">
  <form *ngIf="element.isActive" class="form-container">
    <div
      (click)="onEmit(elementService.collapseDigitalVerification($event))"
      class="collapsible-title input-label-large d-flex justify-content-between align-items-center"
      [attr.elementToCollapse]="
        'id-verification-collapsible' + digitalVerification.originalEvidenceKey
      "
      [ngClass]="
        element.params.activeDVKey === digitalVerification.originalEvidenceKey
          ? 'collapsed'
          : ''
      "
    >
      <!--ALTERNATE  SUBTITLE USED IN NEW FORMS-->
      <h4>Contract signing</h4>
      <div>
        <img
          *ngIf="
            (!element.params.reviewSide && !digitalVerification.notSharable) ||
            (element.params.reviewSide && !digitalVerification.reviewState)
          "
          class="mr-3"
          src="assets/images/check.svg"
        />
        <img class="collapse-icon" src="assets/images/result_arrow_down.png" />
      </div>
    </div>
    <div
      class="element-wrapper mt-0 collapsible-verification id-verification-collapsible"
      id="{{
        'id-verification-collapsible' + digitalVerification.originalEvidenceKey
      }}"
      [attr.collapseKey]="
        'id-verification-collapsible' + digitalVerification.originalEvidenceKey
      "
      [ngClass]="
        element.params.activeDVKey === digitalVerification.originalEvidenceKey
          ? 'collapsed'
          : ''
      "
    >
      <div class="input-fields-wrapper">
        <!-- INPUT FIELDS -->
        <div class="evidence-fields">
          <div class="element-wrapper input-transparent">
            <span class="input-label font-weight-bold">Contract templates</span>
            <span class="select-wrapper">
              <select
                class="select-input"
                [disabled]="element.investorSide"
                id="ContractTemplate"
                name="ContractTemplate"
                [attr.isRequired]="'false'"
                [value]="element.params.template"
              >
                <option
                  [selected]="
                    verificationPresets &&
                    verificationPresets.template == option.key
                      ? true
                      : false
                  "
                  value="{{ option.key }}"
                  *ngFor="let option of element.params.contractTemplates"
                >
                  {{ option.name }}
                </option>
              </select>
            </span>
            <div
              class="error-field"
              [attr.validatorMessage]="element.params.validatorMessage"
              [attr.validatorMessageField]="element.params.validatorMessage"
            ></div>
          </div>
        </div>
        <!-- <div class="evidence-fields">
          <div class="input-label font-weight-bold mt-4">
            Document used for:
          </div>
          <div class="purpose-tag-wrapper d-flex">
            <div class="purpose-tag px-3 mt-1">
              Proof of identity
            </div>
          </div>
        </div> -->
      </div>
      <!-- BUTTONS -->
      <div class="d-flex justify-content-between align-items-center">
        <button
          *ngIf="
            !element.params.reviewSide ||
            (element.params.reviewSide &&
              !digitalVerification.reviewState === false)
          "
          [attr.clickmethod]="'htpmSubmitEvidenceFields'"
          [attr.data-evidenceKey]="element.params.evidenceKey"
          [attr.data-dvType]="digitalVerification.originalEvidenceKey"
          [attr.data-verificationType]="'Contract'"
          [attr.data-originalKey]="digitalVerification.originalEvidenceKey"
          [attr.data-requestKey]="element.params.requestKey"
          (click)="
            onEmit(
              elementService.OnButtonClick($event, element.params.evidenceTypes)
            )
          "
          type="submit"
          class="form-button form-button-1 mt-4 evidence-submit-button"
        >
          {{
            isLastElement && element.params.reviewSide
              ? "Save and next"
              : isLastElement && !element.params.reviewSide
              ? "Save and request"
              : "Save"
          }}
        </button>
        <button
          (click)="
            onEmit(
              elementService.onDeleteEvidence(
                $event,
                element.params.requestKey,
                element.params.evidenceTypes,
                element.params.evidenceKey,
                digitalVerification.originalEvidenceKey
              )
            )
          "
          *ngIf="
            !element.investorSide &&
            !element.params.replaceFlow &&
            !element.params.addressDocumentUpload
          "
          class="evidence-delete-button mt-auto"
          [attr.data-requestKey]="element.params.requestKey"
          [attr.data-evidenceKey]="element.params.evidenceKey"
        ></button>
      </div>
    </div>
  </form>
</div>
