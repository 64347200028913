import { Component, OnInit } from "@angular/core";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";
import { UserService } from "src/app/user.service";
import { QuestionnaireBuilderService } from "../questionnaire-builder.service";

@Component({
  selector: "app-q-builder-list",
  templateUrl: "./q-builder-list.component.html",
  styleUrls: ["./q-builder-list.component.scss"],
})
export class QBuilderListComponent implements OnInit {
  myOrganisationTemplates = null;
  copyFrom = null;
  displayLoader = false;
  displayModal = false;
  formType: string;
  formTypeAlignment: string;
  formTitle: string;
  formSubTitle: string;
  formClass: string;
  lightboxClass: any;
  lightboxContent: any[];
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;
  btnOkEnabled: boolean;
  btnCancelEnabled: boolean;
  btnContentElements: string;
  refreshType: string;
  deleteForm = null;

  constructor(
    private userService: UserService,
    private idVerification: IdVerificationService,
    public qBuilderService: QuestionnaireBuilderService,
  ) {}

  async ngOnInit(): Promise<void> {
    // for testing purposes hardcode orgId

    this.userService
      // .getEndPoint(`api/pdf/templates/templates-and-drafts/${this.userService.getUserOrganizationId()}`
      .getEndPoint(
        `/api/organizations/questionnaires`,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .toPromise()
      .then((response) => {
        this.qBuilderService.parseQuestionareTemplates(response);
        this.setMyOrganisationTemplates(
          this.qBuilderService.questionareTemplates,
        );
      })
      .catch((error) => console.error(error));
  }

  setMyOrganisationTemplates(templates) {
    this.myOrganisationTemplates = templates;
    this.qBuilderService.questionareTemplates = templates;
  }

  AddNewForm() {
    $(".button-add").addClass("d-none");
    $(".new-form-choice").removeClass("d-none");
  }

  CreateNewQ() {
    if (!!this.qBuilderService.currentTemplate) {
      this.DisplayConfirmNewForm();
    } else {
      this.qBuilderService.togglePreviewList();
    }
  }

  DisplayConfirmNewForm() {
    // build the content for the Create new project lightbox
    this.formType = `Confirm starting a new template`;
    this.formClass = "";
    this.formTitle = ""; // 'Financial Holdings B.V';
    this.btnOkText = ""; // 'Yes, I am sure';
    this.formValidator = "";
    this.btnCancelText = ""; // 'No, cancel';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;
    this.lightboxClass = "";
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "delete_form",
      result_details: [
        {
          group_result_title: "enter_name_for_the_new_form", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "paragraph",
              class: "text-center mb-3",
              params: {
                content: `You are currently editing template <strong>
                ${this.qBuilderService.getNameBySlug(
                  this.qBuilderService.currentTemplate,
                )}</strong>.
                Are you sure you want to start a new form? Any changes made to the current form will not be saved!`,
              },
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Ok",
                  // 'id': 'accept',
                  type: "button",
                  action: "onCreateNewFormAccept",
                  class: "form-button form-button-1",
                  display: true,
                },
                {
                  content: "Cancel",
                  // 'id': 'rescan',
                  type: "button",
                  action: "onDeleteTemplateReject",
                  class: "form-button-2",
                  display: true,
                },
              ],
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  onCreateNewFormAccept() {
    this.qBuilderService.clearCurrentTemplate();
    this.qBuilderService.togglePreviewList();
  }

  CloseNewForm() {
    $(".button-add").removeClass("d-none");
    $(".new-form-choice").addClass("d-none");
  }

  OnFormEdit(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest(".card");
    this.qBuilderService.loadMyForm(
      closestCard.find(".body").attr("data-template-key"),
      closestCard.find(".body").attr("data-template-mode"),
    );
  }

  OnCopyFrom(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest(".card");
    this.copyFrom = closestCard.find(".body").attr("data-template-key");
    this.DisplayEnterNameCopyForm(
      closestCard.find(".body").attr("data-template-name"),
    );
  }

  OnFormDelete(event) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest(".card");
    const closestCardBody = $(event.target).closest(".card").find(".body");
    console.log(
      $(event.target),
      $(event.target).closest(".card"),
      closestCardBody.attr("data-template-key"),
      closestCardBody.attr("data-template-mode"),
    );
    this.deleteForm = {
      templateKey: closestCardBody.attr("data-template-key"),
      templateMode: closestCardBody.attr("data-template-mode"),
    };

    this.DisplayConfirmDeleteForm(closestCardBody.attr("data-template-name"));
  }

  async copyMyForm(newName, key?) {
    // create a copy of the form
    if (this.userService.getUserOrganizationId()) {
      await this.userService
        .getEndPoint(
          "api/organizations/questionnaires/" + this.copyFrom + "/",
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .toPromise()
        .then((schema) => {
          schema["record"].name = newName;
          const payload = schema["record"];
          this.userService
            .postEndPoint(
              "api/organizations/questionnaires/",
              payload,
              { responseType: "json" },
              { observe: "body" },
              "funds",
            )
            .toPromise()
            .then((schema2) => {
              this.qBuilderService.formattedJson = JSON.parse(
                schema["record"].formattedJson,
              );
              this.qBuilderService.setFormJson(
                this.qBuilderService.formattedJson,
                true,
              );
              this.qBuilderService.togglePreviewList();
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
    }
  }

  publishToggle(event, published, key) {
    event.preventDefault();
    event.stopPropagation();
    const closestCard = $(event.target).closest(".card");
    const selectedTemplate = closestCard
      .find(".body")
      .attr("data-template-key");
    const imgElem = $(event.target).find("img");
    imgElem.attr("src", "/assets/images/spinner-green.svg");
    // retreive selected template data
    let payload = { published: true };
    let callbackImage = "/assets/images/ico-unpublish-template.svg";
    if (published) {
      payload = { published: false };
      callbackImage = "/assets/images/ico-publish-template.svg";
    }
    this.userService
      .postEndPoint(
        "/api/organizations/questionnaires/" + selectedTemplate + "/publish",
        payload,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe((response) => {
        if (response === undefined) {
          console.log("Error while fetching record for additional data!");
          return;
        }
        this.qBuilderService.togglePublishMarker(selectedTemplate);
        this.setMyOrganisationTemplates(
          this.qBuilderService.questionareTemplates,
        );
        imgElem.attr("src", callbackImage);
      });
  }

  async deleteMyForm() {
    if (this.deleteForm) {
      // const payload = {'templateKey': this.deleteForm.templateKey, 'templateMode': this.deleteForm.templateMode};
      // console.log('payload', payload);
      await this.userService
        // .postEndPoint('api/pdf/templates/'
        //   + this.copyFrom
        //   + '/copy/'
        //   + this.userService.getUserOrganizationId()
        //   , payload
        //   , {responseType: 'json'}, {observe: 'body'}, 'verification')
        .deleteEndpoint(
          "api/organizations/questionnaires/" + this.deleteForm.templateKey,
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .toPromise()
        .then((response) => {
          this.qBuilderService.removeQuestionareFromList(
            this.deleteForm.templateKey,
          );
          this.setMyOrganisationTemplates(
            this.qBuilderService.questionareTemplates,
          );
          this.displayLoader = false;
          this.displayModal = false;
        })
        .catch((error) => console.error(error));
    }
  }

  DisplayEnterNameCopyForm(suggestedName) {
    this.formType = `Clone a template`;
    // this.btnOkText = 'Ok';
    // this.btnCancelText = 'Cancel';
    // build the content for the Create new project lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "copy_form",
      result_details: [
        {
          group_result_title: "enter_name_for_the_new_form", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "subtitle-alternate",
              class: "increased-margin-top text-center px-4 mt-5 location-text",
              params: {
                content: "Enter the name of the new form",
              },
            },
            {
              type: "paragraph",
              class: "text-center mb-3",
              params: {
                content: `Please enter the name for the new form that will be based on ${suggestedName}`,
              },
            },
            {
              type: "inputText",
              params: {
                label: "New form name ",
                id: "newFormName",
                name: "newFormName",
                content: `Copy of ${suggestedName}`,
                required: "true",
                labelClass: "font-weight-bold",
                maxLength: 60,
              },
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Ok",
                  // 'id': 'accept',
                  action: "onCopyForm",
                  class: "form-button form-button-1",
                  display: true,
                },
                {
                  content: "Cancel",
                  // 'id': 'rescan',
                  action: "onCancelCopy",
                  class: "form-button-2",
                  display: true, // TODO: check if user may edit the information
                },
              ],
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  toggleActionsMenu(event) {
    event.preventDefault();
    $(event.target)
      .closest(".actionsMenuWrapper")
      .find("ul")
      .toggleClass("d-none");
    $(event.target)
      .closest(".actionsMenuWrapper")
      .find(".action-icon")
      .toggleClass("icon-minus");
  }

  DisplayConfirmDeleteForm(suggestedName) {
    // build the content for the Create new project lightbox
    this.formType = `Confirm deleting a template`;
    this.formClass = "";
    this.formTitle = ""; // 'Financial Holdings B.V';
    this.btnOkText = ""; // 'Yes, I am sure';
    this.formValidator = "";
    this.btnCancelText = ""; // 'No, cancel';
    this.btnOkEnabled = true;
    this.btnCancelEnabled = true;
    this.lightboxClass = "";
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "delete_form",
      result_details: [
        {
          group_result_title: "enter_name_for_the_new_form", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "paragraph",
              class: "text-center mb-3",
              params: {
                content: `Please confirm that you are sure you want to delete the template <strong>${suggestedName}</strong>`,
              },
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Delete",
                  // 'id': 'accept',
                  type: "button",
                  action: "onDeleteTemplateAccept",
                  class: "form-button form-button-1",
                  display: true,
                },
                {
                  content: "Cancel",
                  // 'id': 'rescan',
                  type: "button",
                  action: "onDeleteTemplateReject",
                  class: "form-button-2",
                  display: true,
                },
              ],
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  onLightboxConfirm(returnedAction) {
    if (
      typeof returnedAction.method === "string" &&
      returnedAction.method !== ""
    ) {
      // allow only strings as acceptable method name
      let params = "";
      // if (Array.isArray(returnedAction.params)) {
      params = returnedAction.params;
      // }
      try {
        this[returnedAction.method](params); // call it
      } catch (error) {
        console.log(returnedAction.method, "is not declared as method!", error);
      }
    } else {
      console.log("**method name not string or empty string");
    }
    this.displayLoader = true;
  }

  onLightboxClose($event) {
    this.copyFrom = null;
    this.displayModal = false;
  }

  onCopyForm() {
    if ($("#newFormName").val() !== "") {
      this.copyMyForm(this.qBuilderService.slugify($("#newFormName").val()));
    } else {
      this.displayModal = false;
      alert("You must provide name for the new template");
    }
  }

  onDeleteTemplateAccept(event) {
    this.displayLoader = true;
    this.deleteMyForm();
  }

  onCancelCopy() {
    this.onCancelForm();
  }

  onDeleteTemplateReject() {
    this.deleteForm = null;
    this.onCancelForm();
  }

  onCancelForm() {
    this.onLightboxClose(null);
  }

  isBroken(template) {
    return template.type === "empty";
  }

  /**
   * call this method on click of a Content Builder button (not a submit)
   * @param returnedAction event
   */
  parseContentReturn(returnedAction) {
    // console.log('returned action', returnedAction);
    if (
      typeof returnedAction.method === "string" &&
      returnedAction.method !== ""
    ) {
      // allow only strings as acceptable method name
      let params = "";
      // if (Array.isArray(returnedAction.params)) {
      params = returnedAction.params;
      // }
      try {
        this[returnedAction.method](params); // call it
      } catch (error) {
        console.log(returnedAction.method, "is not declared as method!", error);
      }
    } else {
      console.log("**method name not string or empty string");
    }
  }
}
