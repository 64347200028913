import { Component, OnInit } from "@angular/core";
import { QuestionnaireBuilderService } from "../questionnaire-builder.service";

@Component({
  selector: "app-q-builder-header",
  templateUrl: "./q-builder-header.component.html",
  styleUrls: ["./q-builder-header.component.scss"],
})
export class QBuilderHeaderComponent implements OnInit {
  constructor(public qBuilderService: QuestionnaireBuilderService) {}

  ngOnInit(): void {}
}
