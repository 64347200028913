import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { HelperServiceService } from "src/app/helper-service.service";
import { PdfServerService } from "src/app/pdf-server.service";
import { UserService } from "src/app/user.service";

@Injectable({
  providedIn: "root",
})
export class FormBuilderServiceService {
  public form: Object = {
    components: [],
  };
  preview = false;
  previewList = true;
  previewJson = false;
  previewPdf = false;
  editOutput = false;
  editMode = true;

  myOrganisationTemplates = null;
  filterTemplatesBy: "all" | "published" | "drafts" | "broken" = "all";
  currentTemplate = null;
  currentMode = null;
  currentOutputs = [];
  currentOutputIndex = null;
  formattedJson = {};
  formattedHTML = "";

  firstName: any;
  lastName: any;
  dateOfBirth: any;
  contractBlob: any;
  public stringifiedJson = null;
  public downloadJson = null;
  private jsonElement = null;

  constructor(
    private sanitizer: DomSanitizer,
    private userService: UserService,
    private helper: HelperServiceService,
    private pdf: PdfServerService,
    private http: HttpClient,
  ) {}

  // this.loadMyForm(closestCard.find('.body').attr('data-template-key'));

  getUserOrgId() {
    return this.userService.getUserOrganizationId();
  }

  async loadMyForm(template, mode, preferedMode = "") {
    // GET THE TEMPLATE
    // this.http.get(this.baseUrl
    //   + 'api/pdf/templates/'
    //   + this.verificationRecord.template
    //   + '/template/'
    //   + this.verificationRecord.organizationKey.replace('ORG:', '')
    //   , {responseType: 'text'}
    // )
    // .pipe(takeUntil(this.unsubscribe$)).subscribe(async template => {
    //   try {
    //     template = JSON.parse(template);
    //     if (Array.isArray(template)) {
    //       template = template[0].data;
    //     }
    //   } catch (e) { }
    //   this.contractTemplate = template;
    //   this.previewPdf = 'data:application/pdf;base64,' + template;
    // }, error => {
    //   alert('Error while fetching the template.');
    //   this.previewFormio = false;
    //   this.errorState = true;
    // });

    this.currentTemplate = template;
    this.currentMode = mode;
    if (preferedMode === "") {
      preferedMode = mode;
    }
    // GET THE SCHEMA
    if (this.userService.getUserOrganizationId()) {
      await this.userService
        .getEndPoint(
          "api/pdf/templates/" +
            template +
            "/schema/" +
            this.userService.getUserOrganizationId() +
            `?templateMode=${preferedMode}`,
          { responseType: "json" },
          { observe: "body" },
          "verification",
        )
        .toPromise()
        .then((schema) => {
          this.formattedJson = JSON.parse(schema[0].data);
          this.setFormJson(this.formattedJson, true);
          this.togglePreviewList();
        })
        .catch((error) => console.error(error));

      // load the formattedHTML
      this.userService
        .getEndPoint(
          "api/pdf/templates/" +
            template +
            "/template/" +
            this.userService.getUserOrganizationId() +
            `?templateMode=${preferedMode}`,
          { responseType: "json" },
          { observe: "body" },
          "verification",
        )
        .toPromise()
        .then((html) => {
          if (Array.isArray(html)) {
            this.currentOutputs = html;
            if (html[0]) {
              // this can only be the case for broken templates
              this.formattedHTML = html[0].data;
            }
          }
        })
        .catch((error) => console.error(error));
    }
    // this.http.get(this.baseUrl
    //   + 'api/pdf/templates/'
    //   + this.verificationRecord.template
    //   + '/schema/'
    //   + this.verificationRecord.organizationKey.replace('ORG:', '')
    // )
    // .pipe(takeUntil(this.unsubscribe$)).subscribe(async schema => {
    //   // We will alter the schema to prefill any additional data the BE has sent us under personData
    //   // console.log('this is the new schema:', schema);
    //   // console.log('this is the new schema:', schema, atob(schema[0].data as any));
    //   // schema = atob(schema[0].data as any);
    //   if (this.verificationRecord.personData) {
    //     // let newSchema = JSON.parse(atob(schema[0].data as any));
    //     let newSchema = JSON.parse(schema[0].data);
    //     newSchema = this.prefillBEData(this.verificationRecord.personData, newSchema);
    //     this.formattedJson = newSchema;
    //   } else {
    //     // const convertedSchema = atob(schema[0].data as any);
    //     this.formattedJson =  JSON.parse(schema[0].data);
    //   }
    //   this.previewFormio = true;
    // }, error => {
    //   alert('Error while fetching the schema.');
    //   this.previewFormio = false;
    //   this.errorState = true;
    // });
  }

  filterTemplates(mode) {
    this.filterTemplatesBy = mode;
    console.log(this.filterTemplatesBy);
    switch (mode) {
      case "published":
        $(".templates-wrapper .card").removeClass("display-none");
        $(".templates-wrapper .card:not(.published:not(.empty))").addClass(
          "display-none",
        );
        break;
      case "drafts":
        $(".templates-wrapper .card").removeClass("display-none");
        $(".templates-wrapper .card:not(.draft:not(.empty))").addClass(
          "display-none",
        );
        break;
      case "broken":
        $(".templates-wrapper .card").removeClass("display-none");
        $(".templates-wrapper .card:not(.empty)").addClass("display-none");
        break;

      default:
        $(".templates-wrapper .card").removeClass("display-none");
        break;
    }
  }

  addNewOutput(name) {
    this.currentOutputs.push({
      fileName: name,
      data: "",
    });
    // console.log(this.currentOutputs);
    this.setCurrentOutput(this.currentOutputs.length - 1);
    if (this.currentOutputs.length === 1) {
      this.displayEditOutput();
    }
  }

  setCurrentOutput(index, displayEditOutput = false) {
    this.currentOutputIndex = index;
    if (this.currentOutputs[index] && this.currentOutputs[index].data) {
      this.formattedHTML = this.currentOutputs[index].data;
    }
    if (displayEditOutput) {
      this.displayEditOutput();
    }
  }

  getOutputNameByIndex(index = null) {
    let i = index;
    if (i !== null) {
      i = this.currentOutputIndex;
    }
    return this.helper.parseBackendName2(this.currentOutputs[i].fileName);
  }

  getOutputName(str: string): string {
    return this.helper.parseBackendName2(this.helper.parseCountryName(str));
  }

  setFormJson(formJson, setForm = false, stringified = false) {
    if (!formJson) {
      return;
    }
    if (stringified) {
      this.formattedJson = JSON.parse(formJson);
    }
    this.stringifiedJson = JSON.stringify(this.formattedJson, null, 4);
    this.downloadJson = this.sanitizer.bypassSecurityTrustUrl(
      "data:text/json;charset=UTF-8," +
        encodeURIComponent(this.stringifiedJson),
    );
    if (setForm) {
      this.form = this.formattedJson;
      if (this.jsonElement) {
        this.jsonElement.nativeElement.innerHTML = "";
        this.jsonElement.nativeElement.appendChild(
          document.createTextNode(this.stringifiedJson),
        );
      }
    }
  }

  public clearJsonDefaultValues(components) {
    components.map((component) => {
      if (component.defaultValue) {
        component.defaultValue = null;
      }
      if (component.components) {
        this.clearJsonDefaultValues(component.components);
      }
    });
    return components;
  }

  displayEditForm() {
    this.previewJson = false;
    this.previewList = false;
    this.preview = false;
    this.editOutput = false;
  }

  displayEditOutput() {
    if (this.currentOutputs.length > 0) {
      if (this.currentOutputIndex === null) {
        this.currentOutputIndex = 0;
      }
      this.setCurrentOutput(this.currentOutputIndex);
      this.previewJson = false;
      this.previewList = false;
      this.preview = false;
      this.editOutput = true;
    }
  }

  togglePreviewJson() {
    this.previewJson = !this.previewJson;
    if (this.previewJson) {
      this.previewList = !this.previewJson;
      // if (this.jsonElement) {
      this.preview = !this.previewJson;
      // }
    }
  }

  displayPreviewJson() {
    this.previewJson = true;
    this.previewList = false;
    this.preview = false;
    this.editOutput = false;
  }

  togglePreviewList() {
    this.previewList = !this.previewList;
    if (this.previewList) {
      this.preview = !this.previewList;
      this.previewJson = !this.previewList;
    }
  }

  displayPreviewList() {
    this.previewList = true;
    this.preview = false;
    this.previewJson = false;
    this.editOutput = false;
  }

  togglePreview() {
    this.preview = !this.preview;
    if (this.preview) {
      this.previewList = !this.preview;
      this.previewJson = !this.preview;
    }
  }

  displayPreview() {
    this.preview = true;
    this.previewList = false;
    this.previewJson = false;
    this.editOutput = false;
  }

  refreshPreviewForm() {
    // this.previewJson = false;
    // this.previewList = false;
    // this.preview = false;
    console.log("displaying edit");
    this.displayEditForm();
    console.log("displaying preview");
    this.displayPreview();
  }

  // Helper methods

  /**
   * check if all formio object has at least one element in it
   * @returns true if all formattedJson object has at least one property, otherwise returns false
   */
  isFormioReady() {
    if (
      this.formattedJson &&
      Object.keys(this.formattedJson).length > 0 &&
      Object.getPrototypeOf(this.formattedJson) === Object.prototype
    ) {
      return true;
    }
    return false;
  }

  /**
   * check if all outputs for the current template have content.
   * @returns true if all outputs have content, otherwise returns false
   */
  areOutputsReady() {
    if (
      Array.isArray(this.currentOutputs) &&
      this.currentOutputs.length === 0
    ) {
      return false;
    }
    const isEmpty = this.currentOutputs.filter((output) => output.data === "");
    if (isEmpty.length === 0) {
      return true;
    }
    return false;
  }

  /**
   * Turn str into slug
   * @param str Name of the form that needs to be slugified
   * @returns Slugified version of the form name
   */
  slugify(str) {
    return (
      str
        // .toLowerCase()
        .trim()
        .replace(/[^\w\s-]/g, "")
        .replace(/[\s_-]+/g, "-")
        .replace(/^-+|-+$/g, "")
    );
  }

  prepareOutputsForPayload() {
    this.currentOutputs.map(
      (output) => (output.slug = this.slugify(output.fileName)),
    );
    return this.currentOutputs;
  }

  /**
   * checks if the slugified name we are passing
   * is already found in myOrganisationTemplates list
   * @param str Slugified name of the form that needs to be checked
   * @returns boolean: true if name is unique, false otherwise
   */
  checkIfNameIsUniqueBySlug(str) {
    const filtered = this.myOrganisationTemplates?.filter(
      (template) => template.key === str,
    );
    // found slug in my organisation templates that equals the name we are checking
    // the name is not unique
    if (filtered && filtered.length > 0) {
      return false;
    }
    return true;
  }

  /**
   * returns the name of the template extracted from myOrgTemplates by slug
   * @returns if found name of the template, otherwise empty string
   */
  getNameBySlug(str) {
    const filtered = this.myOrganisationTemplates?.filter(
      (template) => template.key === str,
    );
    // found slug in my organisation templates that equals the name we are checking
    // the name is not unique
    if (filtered && filtered.length > 0) {
      return filtered[0].name;
    }
    return "";
  }

  /**
   * clears any changes made to the current template
   * and allows for new form to be created
   */
  clearCurrentTemplate() {
    this.currentMode = null;
    this.currentTemplate = null;
    this.currentOutputs = [];
    this.formattedHTML = "";
    this.formattedJson = {};
    this.form = {};
    this.stringifiedJson = null;
    this.downloadJson = null;
  }

  // form/pdf generate methods

  checkIfFormioDate(field, components) {
    let ret = false;
    components.map((component) => {
      if (
        field[0] === component.key &&
        component.widget &&
        component.type === "datetime"
      ) {
        ret = true;
      } else if (component.components) {
        if (this.checkIfFormioDate(field, component.components)) {
          ret = true;
        }
      }
    });
    return ret;
  }

  /**
   * recursive helper method to iterate through all components of a schema
   * @param components components of the current property of the schema object
   * @param entry key, value pair that will match the key of the component and populate it's value
   */
  public parseComponents(components, entry) {
    components.map((component) => {
      if (component.key === entry[0]) {
        component.defaultValue = entry[1];
      }
      if (component.components) {
        this.parseComponents(component.components, entry);
      }
    });
  }

  checkIfMultipleModes() {
    const currentTemplate = this.myOrganisationTemplates.filter(
      (item) => item.key === this.currentTemplate,
    );
    if (currentTemplate.length > 0 && currentTemplate[0].modes.length > 1) {
      console.log("returning true");
      return true;
    }
    console.log("returning false");
    return false;
  }

  /**
   * export json schema file
   * along with all the outputs as html files
   */
  async exportFiles() {
    const schemaFile = {
      fileName: this.currentTemplate,
      data: this.stringifiedJson,
    };
    this.helper.generateDownloadLink(
      schemaFile,
      "application/json",
      "schema.json",
    );
    const files = [...this.currentOutputs];

    files.map((file) => {
      this.helper.generateDownloadLink(file, "text/html", "html");
    });
  }
}
