<div
  class="form-menu-wrapper d-flex flex-row align-items-center justify-content-between"
>
  <a
    href="#"
    class="d-flex align-items-center"
    (click)="$event.preventDefault(); qBuilderService.displayPreviewList()"
  >
    <img src="assets/images/back-to-templates.svg" class="mr-2" /> My
    Questionnaires
  </a>
  <ul class="d-flex m-0">
    <li
      class="mr-3"
      title="create a form according to your needs"
      [ngClass]="{
        active:
          !qBuilderService.previewList &&
          !qBuilderService.preview &&
          !qBuilderService.previewJson &&
          !qBuilderService.editOutput
      }"
      (click)="qBuilderService.displayEditForm()"
    >
      Edit questionnaire
    </li>
    <!-- <li
      class="mr-3"
      [ngClass]="{'active': qBuilderService.editOutput}"
      (click)="qBuilderService.displayEditOutput()"
    >
      Edit Output
      <ul *ngIf="qBuilderService.currentOutputs && qBuilderService.currentOutputs.length > 1">
        <li *ngFor="let output of qBuilderService.currentOutputs; let i = index" (click)="$event.stopPropagation(); qBuilderService.setCurrentOutput(i, true)">{{qBuilderService.getOutputName(output.fileName)}}</li>
        <li (click)="DisplayEnterNameNewOutput()">+ Add New Output</li>
      </ul>
      <ul *ngIf="!qBuilderService.currentOutputs || qBuilderService.currentOutputs.length == 0 || qBuilderService.currentOutputs.length == 1">
        <li (click)="DisplayEnterNameNewOutput()">+ Add New Output</li>
      </ul>
    </li> -->
    <li
      class="mr-3"
      title="Preview how the questionnaire will look like for your clients"
      [ngClass]="{ active: qBuilderService.preview }"
      (click)="qBuilderService.displayPreview()"
    >
      Preview
    </li>
    <!-- <li
      class="mr-3"
      [ngClass]="{'active': qBuilderService.previewJson}"
      (click)="qBuilderService.displayPreviewJson()"
    >Preview Questionnaire</li> -->
    <li
      class="mr-3"
      [ngClass]="{ active: qBuilderService.previewJson }"
      (click)="qBuilderService.displayPreviewJson()"
    >
      View code
    </li>
  </ul>
  <!-- href="#" -->
  <div *ngIf="!qBuilderService.previewingOlderVersion" class="d-flex">
    <a
      class="d-flex form-button form-button-1"
      (click)="OnAttemptPublishForm($event)"
    >
      Save
    </a>
    <a
      *ngIf="
        qBuilderService.versionsData && qBuilderService.versionsData.previous
      "
      class="d-flex form-button form-button-1"
      (click)="onViewVersions($event)"
    >
      Versions
    </a>
  </div>
  <div
    class="d-flex align-items-center"
    *ngIf="qBuilderService.previewingOlderVersion"
  >
    <a
      class="d-flex form-button form-button-1"
      (click)="revertToCurrentVersion($event)"
    >
      Revert to current version
    </a>
    <img
      src="/assets/images/spinner-green.svg"
      width="35px"
      height="35px"
      class="d-none current-version-loader mx-auto"
    />
  </div>
</div>

<app-lightbox
  *ngIf="displayModal"
  [formType]="formType"
  [formTitle]="formTitle"
  [formClass]="formClass"
  [formSubTitle]="formSubTitle"
  [lightboxClass]="lightboxClass"
  [btnOkText]="btnOkText"
  [btnCancelText]="btnCancelText"
  [displayModal]="displayModal"
  [displayLoader]="displayLoader"
  [contentDOM]="lightboxContent"
  (confirmLightbox)="onLightboxConfirm($event)"
  (closeLightbox)="onLightboxClose($event)"
></app-lightbox>
