import _ from "lodash";

export default [
  // {
  //   key: 'multiple',
  //   ignore: true,
  // },
  {
    type: "textfield",
    input: true,
    label: "Label",
    key: "label",
    tooltip: "The description of the question",
    weight: 10,
    reorder: true,
  },
  {
    type: "select",
    input: true,
    label: "Options alignment",
    key: "optionsLabelPosition",
    tooltip: "Position for the alignment  for options for this field.",
    dataSrc: "values",
    weight: 32,
    defaultValue: "Vertical",
    data: {
      values: [
        { label: "Horizontal", value: "Horizontal" },
        { label: "Vertical", value: "Vertical" },
      ],
    },
  },
  {
    type: "datagrid",
    input: true,
    label: "Values",
    key: "values",
    tooltip:
      "The radio button values that can be picked for this field. Values are text submitted with the form data. Labels are text that appears next to the radio buttons on the form.",
    weight: 10,
    reorder: true,
    defaultValue: [{ label: "", value: "", answerWeight: "" }],
    components: [
      {
        label: "Label",
        key: "label",
        input: true,
        type: "textfield",
      },
      {
        label: "Weight",
        input: true,
        type: "number",
        key: "answerWeight",
        tooltip:
          "Enter the weight value of this answer that will be counted toward questionnaire total value",
        validate: {
          required: true,
        },
      },
    ],
  },
  // example of dropdown:
  // {
  //   type: 'select',
  //   input: true,
  //   label: 'Storage Type',
  //   key: 'dataType',
  //   clearOnHide: true,
  //   tooltip: 'The type to store the data. If you select something other than autotype, it will force it to that type.',
  //   weight: 12,
  //   template: '<span>{{ item.label }}</span>',
  //   dataSrc: 'values',
  //   data: {
  //     values: [
  //       { label: 'Autotype', value: 'auto' },
  //       { label: 'String', value: 'string' },
  //       { label: 'Number', value: 'number' },
  //       { label: 'Boolean', value: 'boolean' },
  //       { label: 'Object', value: 'object' },
  //     ],
  //   },
  // },
];
