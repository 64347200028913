import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ElementsService } from 'src/app/ui/content-builder/content-elements/components/elements.service';
import { RiskAssessmentService } from '../risk-assessment.service';


@Component({
  selector: 'app-risk-assessment-preview',
  templateUrl: './risk-assessment-preview.component.html',
  styleUrls: ['./risk-assessment-preview.component.scss']
})
export class RiskAssessmentPreviewComponent implements OnInit {

  @Input() element;
  userIsApprover : boolean = false;

  constructor(
    public elementService: ElementsService, 
    public translate: TranslateService, 
    private riskAssessmentService: RiskAssessmentService) { }

  ngOnInit(): void {
    this.userIsApprover = this.checkIfUserIsApprover();
  }

    completeAssessment(event)
    {
      if((this.element?.params?.riskAssessmentData?.record?.status && this.element.params.riskAssessmentData.record.status == 'IN_REVIEW') && this.userIsApprover)
      {
        if(this.element?.params?.riskAssessmentData)
          {
            this.riskAssessmentService.oppenRRELightbox(
              {
                content : this.element.params.riskAssessmentData,
                type : "risk-rating-approval"
              });
          }
      }
      event.preventDefault();
      this.riskAssessmentService.riskEventPreviewComplete.next(
        this.element.params.riskAssessmentData.key
      );
    }

    assignContributor(event) {
     // show assign contributor screen
     // get project key 


    const riskAssessmentKey = 
    this.element.params.riskAssessmentData.key;
    this.riskAssessmentService.getApprovers(riskAssessmentKey).subscribe(
      (response) => {

        this.riskAssessmentService.oppenRRELightbox(
          {
            content : {
              contributors: response,
              riskAssessmentData: this.element.params.riskAssessmentData
            },
            type : "assign-risk-contributor"
          });
      },
      (error) => {

      },
    );

    }

    overrideRiskIndicator(event, indicator)
    {
      indicator.RRkey = this.element.params.riskAssessmentData.key;
      this.riskAssessmentService.oppenRRELightbox(
        {
          content : indicator,
          type : "override-risk-indicator"
        });
    }

    getCompleteAssessmentTooltip() :string
    {
      if(this.element?.params?.riskAssessmentData?.record?.status && this.element.params.riskAssessmentData.record.status != 'IN_REVIEW')
      {
        return "Risk assessment can only be completed when all risk indicators are known.";
      }
      else if(!this.userIsApprover)
      {
        return "Risk assessment can only be completed by approver."
      }
    }

    checkIfUserIsApprover() :boolean
    {
      if(this.element?.params?.riskAssessmentData?.record?.approvers)
      {
        return this.riskAssessmentService.checkIfUserIsApprover(this.element.params.riskAssessmentData.record.approvers);
      }
      return false;
    }


    recalculateAssessments() {

      this.riskAssessmentService.loadRiskAssessmentLightbox();

      this.riskAssessmentService.recalculateRiskAssessment
      (this.element.params.riskAssessmentData.key).subscribe(
        (response) => {
          console.log(response);
          this.riskAssessmentService.buildRiskAssessmentLightbox(
            this.element.params.riskAssessmentData.key, false, true, false,
          );
        },
        (error) => {
  
        },
      );
    }
}
