<!-- <header class="d-flex flex-column p-2 p-sm-3 {{ headerClasses['backgroundClass'] }} {{ headerClasses['sectionClass'] }}" [ngStyle]="{'background-color': projectColor}" *ngIf="!initiated">
  <div class="header-top d-flex justify-content-between mx-auto py-3">
      <div class="navbar p-0">
        <a [routerLink]="['/']" class="navbar-brand"><img class="finos-logo-img" src ="{{logo}}" *ngIf="logo !== null"></a>
      </div>
  </div>
  <div class="d-flex flex-column flex-fill justify-content-center">
    <div class="unauth-message text-center mx-auto" [ngClass]="projectTextColorClass" *ngIf="message !== ''" innerHtml="{{message}}">
    </div>
    <div class="d-flex mx-auto text-center" *ngIf="displayLoader">
      <img src="/assets/images/spinner-gray.svg" width="32px" height="32px"></div>
    <!-- <div class="unauth-message text-center mx-auto" [ngClass]="projectTextColorClass" *ngIf="message === ''">
      {{message}}
    </div> --
  </div>
</header> -->
<app-header
  [headerInfo]="headerInfo"
  [headerClasses]="headerClasses"
  [kvkResults]="kvkResults"
  [message]="message"
  [initiated]="initiated"
></app-header>

<!-- <header class="d-flex flex-column p-2 p-sm-3 {{ headerClasses['backgroundClass'] }} {{ headerClasses['sectionClass'] }}" [ngStyle]="{'background-color': projectColor}" *ngIf="initiated">
  <div class="header-top d-flex justify-content-between mx-auto py-3">
      <div class="navbar p-0">
        <a [routerLink]="['/']" class="navbar-brand"><img class="finos-logo-img" src ="{{logo}}" *ngIf="logo !== null"></a>
      </div>
  </div>
  <div class="d-flex flex-column flex-fill justify-content-center">
    <div class="unauth-message text-center mx-auto" [ngClass]="projectTextColorClass">
      Please confirm that you understand that by accepting the invitation you will become project {{contributorType}} of "{{project}}"
      <div class="d-flex mt-3"><button class="form-button form-button-1 mx-auto" (click)="OnExplicitConsent()">I consent</button></div>
    </div>
  </div>
</header> -->
