import { Injectable } from "@angular/core";
import createAuth0Client from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import {
  from,
  of,
  Observable,
  BehaviorSubject,
  combineLatest,
  throwError,
  observable,
} from "rxjs";
import { tap, catchError, concatMap, shareReplay, share } from "rxjs/operators";
import { Router } from "@angular/router";
import { HelperServiceService } from "../helper-service.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private environment = this.helper.getEnvironment();
  private env_: any = this.helper.getAuth0Params();
  // Create an observable of Auth0 instance of client
  auth0Client$: Observable<Auth0Client> = null;
  public auth0InitedSubject$ = new BehaviorSubject<any>(null);
  private sAuthentication;
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  public isAuthenticated$ = null;
  public noLoginTokenAuth = false;
  private handleRedirectCallback$: Observable<RedirectLoginResult> = null;
  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  private userProfile$ = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  private loggedIn: boolean = null;

  constructor(
    private router: Router,
    private helper: HelperServiceService,
  ) {
    this.initAuth0();
  }

  initAuth0(login = false) {
    console.log("is it skipped? *****");
    if (this.env_) {
      // create aut0 client instance
      this.auth0Client$ = (
        from(createAuth0Client(this.env_)) as Observable<Auth0Client>
      ).pipe(
        tap((auth0val) => {
          this.auth0InitedSubject$.next(auth0val);
        }),
        shareReplay(1), // Every subscription receives the same shared value
        catchError((err) => throwError(err)),
      );

      // create authenticate observable
      this.isAuthenticated$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) => from(client.isAuthenticated())),
        tap((res) => (this.loggedIn = res)),
      );

      // Handle redirect from Auth0 login
      this.handleRedirectCallback$ = this.auth0Client$.pipe(
        concatMap((client: Auth0Client) =>
          from(client.handleRedirectCallback()),
        ),
      );

      // On initial load, check authentication state with authorization server
      // Set up local auth streams if user is already authenticated
      this.localAuthSetup();
      if (login) {
        this.login();
      }
    } else {
      console.log(this.env_);
    }
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap((user) => this.userProfileSubject$.next(user)),
    );
  }

  private localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      }),
    );
    checkAuth$.subscribe();
  }

  login(redirectPath: string = "/") {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    if (this.auth0Client$) {
      this.auth0Client$.subscribe((client: Auth0Client) => {
        // Call method to log in
        client.loginWithRedirect({
          redirect_uri: `${window.location.origin}/callback`,
          appState: { target: redirectPath },
        });
      });
    } else {
      console.error(
        "cannot handle login. auth0client unavailable",
        this.environment,
        this.env_,
      );
      console.log("retrying ...");
      this.initAuth0(true);
    }
  }

  sAuthenticateUrl(urlparam) {
    this.sAuthentication = urlparam;
  }

  returnSAuthentication() {
    return this.sAuthentication;
  }

  public handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    // const params = window.location.search;
    if (this.auth0Client$) {
      this.auth0Client$.subscribe((client: Auth0Client) => {
        let targetRoute: string; // Path to redirect to after login processsed
        const authComplete$ = this.handleRedirectCallback$.pipe(
          // Have client, now call method to handle auth callback redirect
          tap((cbRes) => {
            // Get and set target redirect route from callback results
            targetRoute =
              cbRes.appState && cbRes.appState.target
                ? cbRes.appState.target
                : "/";
          }),
          concatMap(() => {
            // Redirect callback complete; get user and login status
            return combineLatest([this.getUser$(), this.isAuthenticated$]);
          }),
        );
        // Subscribe to authentication completion observable
        // Response will be an array of user and login status
        authComplete$.subscribe(([user, loggedIn]) => {
          // according to https://auth0.com/docs/libraries/auth0-spa-js/migrate-from-auth0js
          // we cannot call neither userInfo nor use ManagementApi we are going
          // to proceed with fetching user details from BE
          // console.log('user', user, 'loggedIn', loggedIn);
          // Redirect to target route after callback processing
          this.router.navigateByUrl(targetRoute);
        });
      });
    } else {
      console.error(
        "cannot handle login. auth0client unavailable",
        this.environment,
        this.env_,
      );
      console.log("retrying ...");
      this.initAuth0(true);
    }
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: this.env_.client_id,
        returnTo: `${window.location.origin}`,
      });
    });
  }

  getTokenSilently$(options?): Observable<string> {
    if (this.sAuthentication) {
      return of(this.sAuthentication);
    } else {
      return this.auth0Client$.pipe(
        concatMap((client: Auth0Client) =>
          from(client.getTokenSilently(options)),
        ),
      );
    }
  }

  async getTokenSilentlyAsync$(options?): Promise<Observable<string>> {
    if (this.sAuthentication) {
      return of(this.sAuthentication);
    } else {
      if (!this.auth0Client$) {
        console.log("trying to auth0");
        if (this.env_) {
          console.log("trying to env");
          // create aut0 client instance
          this.auth0Client$ = (
            from(createAuth0Client(this.env_)) as Observable<Auth0Client>
          ).pipe(
            shareReplay(1), // Every subscription receives the same shared value
            catchError((err) => throwError(err)),
          );
          console.log("auth0 client", this.auth0Client$);
        }
      }
      return this.auth0Client$.pipe(
        concatMap((client: Auth0Client) =>
          from(client.getTokenSilently(options)),
        ),
      );
    }
  }
}
