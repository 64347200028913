import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-multiple-select",
  templateUrl: "./multiple-select.component.html",
  styleUrls: ["./multiple-select.component.scss"],
})
export class MultipleSelectComponent {
  @Input() textForSelect?: string;
  @Input() selectedOptions: any[] = [];
  @Input() availableOptions;
  @Output() onChange = new EventEmitter<any[]>();

  constructor() {}

  getObjectByKey(array: any[], key: string) {
    return array?.find((element) => element.key == key);
  }
  selectOption(option: string) {
    if (!this.getObjectByKey(this.selectedOptions, option)) {
      const object = this.getObjectByKey(this.availableOptions, option);
      object && this.selectedOptions.push(object);
      this.onChange.emit(this.selectedOptions);
    }
  }

  removeOption(option) {
    this.selectedOptions = this.selectedOptions.filter(
      (selected) => selected.key !== option.key,
    );
    this.onChange.emit(this.selectedOptions);
  }
}
