import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-lighbox-content",
  templateUrl: "./lighbox-content.component.html",
  styleUrls: ["./lighbox-content.component.scss"],
})
export class LighboxContentComponent implements OnInit {
  @Input() displayModal: boolean;
  @Input() formType: string;
  @Input() formTitle: string;
  @Input() formSubTitle: string;
  @Input() btnOkText: string;
  @Input() btnCancelText: string;
  @Input() btnContentElements: string;
  @Input() formValidator: string;
  @Input() content: string;

  constructor() {}

  ngOnInit() {}
}
