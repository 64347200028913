import { Injectable } from "@angular/core";
import { Observable, Subject, throwError, of, ReplaySubject } from "rxjs";
import { environment } from "./../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { HelperServiceService } from "../helper-service.service";

@Injectable({
  providedIn: "root",
})
export class TrustsService {
  private requestData: {};
  private baseUrl = environment.APIEndpoint; // 'https://virtserver.swaggerhub.com/SingleTruth/singletruth/1.0.4/';
  private requestedDataDetails = new Subject<any>();
  private noIntegrityRisk = new Subject<any>();
  public noIntegrityRiskStatus = this.noIntegrityRisk.asObservable();
  private submitAnswers = new Subject<any>();
  public submitAnswersStatus = this.submitAnswers.asObservable();
  private denyRequest = new Subject<any>();
  public denyRequestStatus = this.denyRequest.asObservable();

  // public detectmyVerificationTokenStatus = this.myVerificationToken.asObservable();

  constructor(
    private http: HttpClient,
    private helper: HelperServiceService,
  ) {}

  /**
   * call BE to check if the user should be granted access to the page
   */
  getRequestData(cryptoKey: string) {
    console.log("cryptoKey id:", cryptoKey);
    if (cryptoKey === "") {
      this.requestedDataDetails.next(null);
      return this.requestedDataDetails.asObservable();
    }
    this.http
      .get(this.baseUrl + "api/trusts/requests/" + cryptoKey + "/data")
      .subscribe(
        (response) => {
          this.requestedDataDetails.next(response);
        },
        (error) => {
          console.log("Error occured while fetching the access token", error);
          // this.requestedDataDetails.next(null);
          // return this.requestedDataDetails.asObservable();
          // for testing purposes only
          this.requestedDataDetails.next({
            key: "string",
            record: {
              status: "open",
              createdOn: "2019-05-16T06:07:52.412Z",
              finishedOn: "2019-05-16T06:07:52.412Z",
              type: "internal",
              questions: [
                {
                  questionOrder: 2,
                  questionText: "What was the nature of the integrity risk?",
                  questionType: "text",
                  key: "QST12312312",
                  answer: {
                    key: "ANS123891739812",
                    record: "This is the answer of a particular question",
                  },
                },
              ],
            },
            companyName: "External company",
          });
          return this.requestedDataDetails.asObservable();
        },
      );
    return this.requestedDataDetails.asObservable();
  }

  /**
   * Sets a request as no integrity risk
   */
  setNoIntegrityRisk(cryptoKey: string) {
    console.log("cryptoKey id:", cryptoKey);
    if (cryptoKey === "") {
      this.noIntegrityRisk.next(null);
      return;
    }
    // changed this to post and closed the lightbox Alex
    this.http
      .post(
        this.baseUrl + "api/trusts/requests/" + cryptoKey + "/norisk",
        {},
        { responseType: "text" },
      )
      .subscribe(
        (response) => {
          // this.requestedDataDetails.next(response);
          this.noIntegrityRisk.next(response);
        },
        (error) => {
          console.log("Error occured while setting no integrity risk", error);
          this.noIntegrityRisk.next(null);
          return;
        },
      );
  }

  /**
   * Provides answers to requested information
   */
  submitAnswersToRequestedInfo(answers, cryptoKey: string) {
    if (typeof answers !== "object") {
      this.submitAnswers.next(null);
      return;
    }
    if (cryptoKey === "") {
      this.submitAnswers.next(null);
      return;
    }

    this.http
      .post(
        this.baseUrl + "api/trusts/requests/" + cryptoKey + "/answer",
        this.helper.sanitize(answers),
        { responseType: "text" },
      )
      .subscribe(
        (status) => {
          console.log("returned from posting answers to questions: ", status);
          this.submitAnswers.next(status);
        },
        (error) => {
          console.log("Error occured while submitting answers", error);
          this.submitAnswers.next(null);
          return;
        },
      );
  }

  /**
   * Denies answers to requested information
   */
  rejectRequestedInfo(cryptoKey: string) {
    if (cryptoKey === "") {
      this.denyRequest.next(null);
      return;
    }
    this.http
      .post(
        this.baseUrl + "api/trusts/requests/" + cryptoKey + "/reject",
        {},
        { responseType: "text" },
      )
      .subscribe(
        (status) => {
          console.log("returned from denying a request: ", status);
          this.denyRequest.next(status);
        },
        (error) => {
          console.log("Error occured while denying answers", error);
          this.denyRequest.next(null);
          return;
        },
      );
  }
}
