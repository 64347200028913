<div class="collapisble-evidence">
  <form *ngIf="element.isActive" class="form-container">
    <div
      (click)="onEmit(elementService.collapseDigitalVerification($event))"
      class="collapsible-title input-label-large d-flex justify-content-between align-items-center"
      [attr.elementToCollapse]="
        'id-verification-collapsible' + digitalVerification.originalEvidenceKey
      "
      [ngClass]="
        element.params.activeDVKey === digitalVerification.originalEvidenceKey
          ? 'collapsed'
          : ''
      "
    >
      <!--ALTERNATE  SUBTITLE USED IN NEW FORMS-->
      <h4>Compliance check</h4>
      <div>
        <img
          *ngIf="
            (!element.params.reviewSide && !digitalVerification.notSharable) ||
            (element.params.reviewSide && !digitalVerification.reviewState)
          "
          class="mr-3"
          src="assets/images/check.svg"
        />
        <img class="collapse-icon" src="assets/images/result_arrow_down.png" />
      </div>
    </div>
    <div
      class="element-wrapper mt-0 collapsible-verification id-verification-collapsible"
      id="{{
        'id-verification-collapsible' + digitalVerification.originalEvidenceKey
      }}"
      [attr.collapseKey]="
        'id-verification-collapsible' + digitalVerification.originalEvidenceKey
      "
      [ngClass]="
        element.params.activeDVKey === digitalVerification.originalEvidenceKey
          ? 'collapsed'
          : ''
      "
    >
      <div class="input-fields-wrapper">
        <!-- INPUT FIELDS -->
        <div class="evidence-fields">
          <div class="element-wrapper input-transparent">
            <span class="input-label font-weight-bold">Screening scope</span>
            <div class="range-wrapper">
              <input
                [ngClass]="isReview ? 'non-interactive' : ''"
                type="range"
                [attr.min]="1"
                [attr.max]="10"
                class="range-input w-100"
                id="FuzinessLevel"
                (change)="onChangeScreeningRange($event)"
                [attr.changemethod]="'onChangeScreeningRange'"
                (input)="onChangeScreeningRange($event)"
                [attr.disabled]="isReview ? true : undefined"
                [attr.value]="
                  verificationPresets && verificationPresets.fuzziness
                    ? verificationPresets.fuzziness * 10
                    : 3
                "
              />
              <div class="range-labels unselectable">
                <div class="range-label">Narrow</div>
                <div class="range-label">Broad</div>
              </div>
              <div class="range-input-indicator">30%</div>
            </div>
          </div>
        </div>
        <div class="evidence-fields">
          <div class="element-wrapper mt-3 input-transparent">
            <input
              class="custom-checkbox"
              [ngClass]="isReview ? 'non-interactive' : ''"
              type="checkbox"
              id="exactMatch"
              name="exactMatch"
              value="exactMatch"
              (change)="exactMatchClicked()"
              [checked]="
                verificationPresets && verificationPresets.exactMatch
                  ? true
                  : false
              "
              [attr.disabled]="isReview ? true : undefined"
              *ngIf="
                element.params.display === undefined ||
                element.params.display === true
              "
            />
            <label class="custom-checkbox-label unselectable" for="exactMatch"
              >Exact match</label
            >
          </div>
        </div>
      </div>
      <!-- BUTTONS -->
      <div class="d-flex justify-content-between align-items-center">
        <button
          *ngIf="
            !element.params.reviewSide ||
            (element.params.reviewSide &&
              !digitalVerification.reviewState === false)
          "
          [attr.clickmethod]="'htpmSubmitEvidenceFields'"
          [attr.data-evidenceKey]="element.params.evidenceKey"
          [attr.data-dvType]="digitalVerification.originalEvidenceKey"
          [attr.data-verificationType]="'APS'"
          [attr.data-originalKey]="digitalVerification.originalEvidenceKey"
          [attr.data-requestKey]="element.params.requestKey"
          (click)="
            onEmit(
              elementService.OnButtonClick($event, element.params.evidenceTypes)
            )
          "
          type="submit"
          class="form-button form-button-1 mt-4 evidence-submit-button"
        >
          {{
            isLastElement && element.params.reviewSide
              ? "Save and next"
              : isLastElement && !element.params.reviewSide
              ? "Save and request"
              : "Save"
          }}
        </button>
        <button
          (click)="
            onEmit(
              elementService.onDeleteEvidence(
                $event,
                element.params.requestKey,
                element.params.evidenceTypes,
                element.params.evidenceKey,
                digitalVerification.originalEvidenceKey
              )
            )
          "
          *ngIf="
            !element.investorSide &&
            !element.params.replaceFlow &&
            !element.params.addressDocumentUpload
          "
          class="evidence-delete-button mt-auto"
          [attr.data-requestKey]="element.params.requestKey"
          [attr.data-evidenceKey]="element.params.evidenceKey"
        ></button>
      </div>
    </div>
  </form>
</div>
