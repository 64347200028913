import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/user.service";
import { QuestionnaireBuilderService } from "../questionnaire-builder.service";

@Component({
  selector: "app-q-header",
  templateUrl: "./q-header.component.html",
  styleUrls: ["./q-header.component.scss"],
})
export class QHeaderComponent implements OnInit {
  displayLoader = false;
  displayModal = false;
  formType: string;
  formTypeAlignment: string;
  formTitle: string;
  formSubTitle: string;
  formClass: string;
  lightboxClass: any;
  lightboxContent: any[];
  btnOkText: string;
  formValidator: string;
  btnCancelText: string;
  btnOkEnabled: boolean;
  btnCancelEnabled: boolean;
  btnContentElements: string;

  constructor(
    public qBuilderService: QuestionnaireBuilderService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {}

  onViewVersions(event) {
    this.formType = `Questionare versions`;
    this.displayLoader = false;
    // this.btnOkText = 'Ok';
    // this.btnCancelText = 'Cancel';
    // build the content for the Create new project lightbox
    let group;
    group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "copy_form",
      result_details: [
        {
          group_result_title: "enter_name_for_the_new_form", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            // {
            //   'type': 'button',
            //   'params': {
            //     'content': 'Create new version',
            //     'type': 'submit',
            //     'display': true,
            //     'action': 'onCreateNewVersion',
            //   }
            // },
          ],
        },
      ],
    };
    if (
      this.qBuilderService.versionsData &&
      this.qBuilderService.versionsData.previous
    ) {
      group.result_details[0].elements.unshift(
        {
          type: "paragraph",
          class: " text-left",
          params: {
            content:
              "Below is a list of all the versions of this questionnaire. Click on a previous version to preview it",
          },
        },
        {
          type: "questionareVersions",
          class: "",
          params: {
            versions: this.qBuilderService.versionsData.previous,
          },
        },
      );
    } else {
      group.result_details[0].elements.unshift({
        type: "paragraph",
        class: " text-left",
        params: {
          content:
            "There are currently no other versions of this questionare. Click the button below to create a new version",
        },
      });
    }
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  fetchOlderVersion(event) {
    const key = $(event.target).attr("id");
    this.qBuilderService.currentVersionKey = `${this.qBuilderService.currentTemplate}`;
    this.qBuilderService.loadMyForm(key, "PUBLISHED", false);
    this.qBuilderService.previewingOlderVersion = true;
    this.displayModal = false;
    this.displayLoader = false;
  }

  revertToCurrentVersion(event) {
    $(".current-version-loader").removeClass("d-none");
    this.qBuilderService.loadMyForm(
      this.qBuilderService.currentVersionKey,
      "PUBLISHED",
      false,
    );
    this.qBuilderService.previewingOlderVersion = false;
  }

  OnAttemptPublishForm(event) {
    event.preventDefault();
    // TODO: add logic that will determine if this is a:
    // * new form
    // * existing published form
    // * existing draft form
    if (
      this.qBuilderService.currentTemplate &&
      this.qBuilderService.currentMode
    ) {
      this.ConfirmPublishExitingForm();
    } else {
      this.DisplayEnterNameNewForm();
    }
  }

  DisplayEnterNameNewForm() {
    this.formType = `Save the questionnaire`;
    this.displayLoader = false;
    // this.btnOkText = 'Ok';
    // this.btnCancelText = 'Cancel';
    // build the content for the Create new project lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "copy_form",
      result_details: [
        {
          group_result_title: "enter_name_for_the_new_form", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "subtitle-alternate",
              class: "increased-margin-top text-center px-4 mt-5 location-text",
              params: {
                content: "Give the questionnaire a name",
              },
            },
            // {
            //   'type': 'paragraph',
            //   'class' : 'text-center mb-3',
            //   'params': {
            //     'content':
            //       `if you change the name `
            //   }
            // },
            {
              type: "inputText",
              params: {
                label: "Questionnaire name ",
                id: "newFormName",
                name: "newFormName",
                content:
                  this.qBuilderService.currentOutputs.length === 1
                    ? this.qBuilderService.currentOutputs[0].fileName
                    : "",
                required: "true",
                labelClass: "font-weight-bold",
                maxLength: 60,
              },
            },
            {
              type: "select",
              // 'subType' : 'addressManualInput',
              class: "reduced-margin-top input-transparent manualInput",
              params: {
                label: "Save as:",
                id: "saveAs",
                name: "saveAs",
                options: [
                  { key: "Draft", name: "Draft" },
                  { key: "Published", name: "Published" },
                ],
                labelClass: "font-weight-bold",
              },
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Save",
                  // 'id': 'accept',
                  action: "onPublishQ",
                  class: "form-button form-button-1",
                  display: true,
                },
                {
                  content: "Cancel",
                  // 'id': 'rescan',
                  action: "onCancelPublishQ",
                  class: "form-button-2",
                  display: true, // TODO: check if user may edit the information
                },
              ],
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  ConfirmPublishExitingForm() {
    this.formType = `Confirm updating a template`;
    this.displayLoader = false;
    // this.btnOkText = 'Ok';
    // this.btnCancelText = 'Cancel';
    // build the content for the Create new project lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "copy_form",
      result_details: [
        {
          group_result_title: "enter_name_for_the_new_form", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "subtitle-alternate",
              class: "increased-margin-top text-center px-4 mt-5 location-text",
              params: {
                content: "Please confirm your action",
              },
            },
            {
              type: "paragraph",
              class: "text-center mb-3",
              params: {
                content: `You are about to update the content and/or status of the "<strong>
                ${this.qBuilderService.currentTemplateName}</strong>" template. Please confirm that this is intentional as the action is non-reversable!`,
              },
            },
            {
              type: "inputText",
              params: {
                label: "Form name ",
                id: "newFormName",
                name: "newFormName",
                content: this.qBuilderService.currentTemplateName
                  ? this.qBuilderService.currentTemplateName
                  : "",
                required: "true",
                labelClass: "font-weight-bold",
                maxLength: 60,
              },
            },
            {
              type: "select",
              class: "reduced-margin-top input-transparent manualInput",
              params: {
                label: "Save as:",
                id: "saveAs",
                name: "saveAs",
                options: [
                  { key: "Draft", name: "Draft" },
                  { key: "Published", name: "Published" },
                ],
                optionselected: this.qBuilderService.currentMode,
                labelClass: "font-weight-bold",
              },
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Save",
                  // 'id': 'accept',
                  action: "onConfirmPublishExistingForm",
                  class: "form-button form-button-1",
                  display: true,
                },
                {
                  content: "Create new version",
                  // 'id': 'accept',
                  action: "onCreateNewVersion",
                  class: "form-button form-button-1",
                  display: true,
                },
                {
                  content: "Cancel",
                  // 'id': 'rescan',
                  action: "onCancelPublishQ",
                  class: "form-button-2",
                  display: true, // TODO: check if user may edit the information
                },
              ],
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  DisplayEnterNameNewOutput() {
    this.formType = `Create new output`;
    this.displayLoader = false;
    // this.btnOkText = 'Ok';
    // this.btnCancelText = 'Cancel';
    // build the content for the Create new project lightbox
    const group = {
      section_class: "results d-flex flex-column justify-items-center p-0",
      result_container_class: "",
      is_collapsable: false,
      form_id: "copy_form",
      result_details: [
        {
          group_result_title: "enter_name_for_the_new_form", // UNIQUE ID FOR THIS GROUP, IT SHOULD BE KVK Number
          elements: [
            {
              type: "subtitle-alternate",
              class: "increased-margin-top text-center px-4 mt-5 location-text",
              params: {
                content: "Enter the name of the new output template",
              },
            },
            // {
            //   'type': 'paragraph',
            //   'class' : 'text-center mb-3',
            //   'params': {
            //     'content':
            //       `Please enter the name for the new form`
            //   }
            // },
            {
              type: "inputText",
              params: {
                label: "New output name ",
                id: "newOutputName",
                name: "newOutputName",
                content: "",
                required: "true",
                labelClass: "font-weight-bold",
                maxLength: 60,
              },
            },
            {
              type: "button_group",
              params: [
                {
                  content: "Ok",
                  // 'id': 'accept',
                  action: "onCreateNewOutput",
                  class: "form-button form-button-1",
                  display: true,
                },
                {
                  content: "Cancel",
                  // 'id': 'rescan',
                  action: "onCancelPublishQ",
                  class: "form-button-2",
                  display: true, // TODO: check if user may edit the information
                },
              ],
            },
          ],
        },
      ],
    };
    // set the content to the lightbox
    this.lightboxContent = [];
    this.lightboxContent.push(group);
    this.displayModal = true;
  }

  /**
   * used to publish newly created forms
   * @returns void
   */
  onPublishQ() {
    // check if the form name is already taken
    // take the qBuilderService.formattedJson
    // take the qBuilderService.formattedHTML
    // and submit them to PDF Server for writing to files
    if ($("#newFormName").val() !== "") {
      if (this.qBuilderService.formattedJson === "") {
        alert("Cannot save, your form is empty!");
        this.displayLoader = false;
        this.displayModal = false;
        return;
      }
      if (!this.qBuilderService.areWeightsReady()) {
        alert(
          "Cannot save, your still have questions without set weight! Please check the 'Edit weights' tab.",
        );
        this.displayLoader = false;
        this.displayModal = false;
        return;
      }
      if (this.qBuilderService.parseQuestionsResponse().length === 0) {
        alert("There must be at least one question in the questionnare");
        this.displayLoader = false;
        this.displayModal = false;
        return;
      }
      const formSlug = this.qBuilderService.slugify($("#newFormName").val());

      if (this.qBuilderService.currentOutputs.length === 1) {
        // if there is only one output make sure it's name is same as the form name
        this.qBuilderService.currentOutputs[0].fileName =
          $("#newFormName").val();
      }
      this.publishMyForm($("#newFormName").val(), $("#saveAs").val());
    } else {
      this.displayLoader = false;
      alert("You must provide name for the new template");
    }
  }

  onConfirmPublishExistingForm() {
    // take the qBuilderService.formattedJson
    // take the qBuilderService.formattedHTML
    // and submit them to PDF Server for writing to files
    if ($("#newFormName").val() !== "") {
      if (this.qBuilderService.formattedJson === "") {
        alert("Cannot save, your form is empty!");
        this.displayLoader = false;
        this.displayModal = false;
        return;
      }
      if (!this.qBuilderService.areWeightsReady()) {
        alert(
          "Cannot save, your still have questions without set weight! Please check the 'Edit weights' tab.",
        );
        this.displayLoader = false;
        this.displayModal = false;
        return;
      }
      if (this.qBuilderService.parseQuestionsResponse().length === 0) {
        alert("There must be at least one question in the questionnare");
        this.displayLoader = false;
        this.displayModal = false;
        return;
      }
      this.publishMyForm($("#newFormName").val(), $("#saveAs").val(), true);
      // this.displayModal = false;
    }
  }

  // this should not be needed
  // onCreateNewOutput() {
  //   if ($('#newOutputName').val() !== '') {
  //     this.qBuilderService.addNewOutput($('#newOutputName').val());
  //     this.displayModal = false;
  //   }
  // }

  onCreateNewVersion() {
    if (this.qBuilderService.parseQuestionsResponse().length === 0) {
      alert("There must be at least one question in the questionnare");
      this.displayLoader = false;
      this.displayModal = false;
      return;
    }
    this.publishMyForm($("#newFormName").val(), $("#saveAs").val(), true, true);
  }

  onCancelPublishQ() {
    this.onLightboxClose(null);
  }

  async publishMyForm(newName, saveAs, editMode?, newVersion?) {
    // create a copy of the form

    // build the response from the json

    // return
    if (this.userService.getUserOrganizationId()) {
      const payload = {
        name: newName,
        published: saveAs.toLowerCase() === "draft" ? false : true,
        description: "description",
        formattedJson: JSON.stringify(this.qBuilderService.formattedJson),
        formattedHTML: this.qBuilderService.formattedHTML,
        questions: this.qBuilderService.parseQuestionsResponse(),
        outputs: JSON.stringify(
          this.qBuilderService.prepareOutputsForPayload(),
        ),
      };
      let saveUrl = "api/organizations/questionnaires/";
      if (editMode) {
        saveUrl =
          "api/organizations/questionnaires/" +
          this.qBuilderService.currentTemplate;
      }
      if (newVersion) {
        saveUrl =
          "api/organizations/questionnaires/" +
          this.qBuilderService.currentTemplate +
          "/versions";
      }
      await this.userService
        .postEndPoint(
          saveUrl,
          payload,
          { responseType: "json" },
          { observe: "body" },
          "funds",
        )
        .toPromise()
        .then((schema) => {
          // this.qBuilderService.formattedJson =  JSON.parse(schema[0].data);
          // this.qBuilderService.setFormJson(this.qBuilderService.formattedJson, true);
          this.displayModal = false;
          this.displayLoader = false;
          this.qBuilderService.displayPreviewList();
        })
        .catch((error) => console.error(error));
    }
  }

  // lightbox handler methods
  onLightboxConfirm(returnedAction) {
    if (
      typeof returnedAction.method === "string" &&
      returnedAction.method !== ""
    ) {
      // allow only strings as acceptable method name
      let params = "";
      // if (Array.isArray(returnedAction.params)) {
      params = returnedAction.params;
      // }
      try {
        this[returnedAction.method](params); // call it
      } catch (error) {
        console.log(returnedAction.method, "is not declared as method!", error);
      }
    } else {
      console.log("**method name not string or empty string");
    }
    this.displayLoader = true;
  }

  onLightboxClose($event) {
    // this.copyFrom = null;
    this.displayModal = false;
  }
}
