<div class="review-loading-screen">
  <div class="d-flex justify-content-center align-items-center flex-column">
    <img
      src="/assets/images/MeshID-Loading-Animation-WhiteBG.gif"
      width="100px"
      height="100px"
      class="my-auto mx-auto"
    />
    <div
      class="px-3"
      style="
        font-size: 22px;
        font-weight: 700;
        margin-top: 30px;
        text-align: center;
      "
    >
      {{ "review.loadingScreen" | translate }}
    </div>
  </div>
</div>
