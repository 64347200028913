<div class="tooltip-container {{ classWrapper }}" #tooltipElement>
  <div class="icon-tooltip-wrapper">
    <img
      alt="Information icon"
      src="{{ icon }}"
      width="{{ iconWidth }}"
      height="{{ iconHeight }}"
      class="icon-tooltip"
      [tooltip]="tooltipText"
      (click)="onClick.emit()"
    />
  </div>
</div>
