import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  AfterContentInit,
  AfterViewInit,
} from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { HelperServiceService } from "src/app/helper-service.service";
import { ElementsService } from "../elements.service";

@Component({
  selector: "app-digital-verification-evidence-id-verification-go",
  templateUrl:
    "./digital-verification-evidence-id-verification-go.component.html",
  styleUrls: [
    "./digital-verification-evidence-id-verification-go.component.scss",
  ],
})
export class DigitalVerificationEvidenceIdVerificationComponentGo
  implements OnInit, AfterContentInit, AfterViewInit
{
  @Input() element;
  @Input() digitalVerification;
  @Output() contentReturn = new EventEmitter<any>();

  verificationPresets;
  isLastElement;

  constructor(
    private elementService: ElementsService,
    private helperService: HelperServiceService,
  ) {}

  ngAfterContentInit(): void {
    this.verificationPresets = this.digitalVerification.verificationData
      ? this.digitalVerification.verificationData.verificationPresets
      : undefined;
    this.isLastElement = this.elementService.isLastDVEvidenceToReview(
      this.element.params.evidenceTypes,
      this.digitalVerification,
      this.element.params.reviewSide,
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      $("#IDVerificationCountry").trigger("click");
    }, 1000);
  }

  ngOnInit(): void {}

  /**
   * emit event back to parent component
   * @param emitPayload params that should be emitted by parent component
   */
  onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }
}
