import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  AfterContentInit,
  AfterViewInit,
} from "@angular/core";
import { ElementsService } from "../elements.service";

@Component({
  selector: "app-digital-verification-evidence-aps",
  templateUrl: "./digital-verification-evidence-aps.component.html",
  styleUrls: ["./digital-verification-evidence-aps.component.scss"],
})
export class DigitalVerificationEvidenceAPSComponent
  implements OnInit, AfterContentInit, AfterViewInit
{
  @Input() element;
  @Input() digitalVerification;
  @Output() contentReturn = new EventEmitter<any>();
  isReview: boolean;
  verificationPresets;

  constructor(private elementService: ElementsService) {}

  ngOnInit(): void {}

  ngAfterContentInit() {
    this.isReview = this.element.params.reviewSide;
    this.verificationPresets = this.digitalVerification.verificationData
      ? this.digitalVerification.verificationData.verificationPresets
      : undefined;
  }

  ngAfterViewInit() {
    if (this.verificationPresets && this.verificationPresets.fuzziness) {
      this.onChangeScreeningRange(
        undefined,
        this.verificationPresets.fuzziness * 10,
      );
    }
  }

  onEmit(emitPayload) {
    if (emitPayload) {
      this.contentReturn.emit(emitPayload);
    }
  }

  exactMatchClicked() {
    if ($(".range-wrapper input").prop("disabled")) {
      $(".range-wrapper input").prop("disabled", false);
    } else {
      $(".range-wrapper input").prop("disabled", "disabled");
    }
  }

  onChangeScreeningRange(event, setValue?) {
    const value = setValue ? setValue : $(event.target).val().toString();
    let parsedValue;
    let cssValue;
    parsedValue = 10 * parseInt(value, 10);
    parsedValue = parsedValue + "%";
    cssValue = 10 * (parseInt(value, 10) - 1);
    cssValue = cssValue + "%";
    $(".range-input-indicator").text(parsedValue);
    $(".range-input-indicator").css("left", cssValue);
  }
}
