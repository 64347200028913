import _ from "lodash";

export default [
  // {
  //   key: 'multiple',
  //   ignore: true,
  // },
  {
    type: "select",
    input: true,
    label: "Text alignment",
    key: "HeaderPosition",
    tooltip: "Alignment of the header",
    dataSrc: "values",
    weight: 32,
    defaultValue: "text-left",
    data: {
      values: [
        { label: "Left", value: "text-left" },
        { label: "Center", value: "text-center" },
        { label: "Right", value: "text-right" },
      ],
    },
  },
  {
    type: "select",
    input: true,
    label: "Text size",
    key: "HeaderSize",
    tooltip: "Font size of the header",
    dataSrc: "values",
    weight: 32,
    defaultValue: "title-medium",
    data: {
      values: [
        { label: "Small", value: "title-small" },
        { label: "Medium", value: "title-medium" },
        { label: "Large", value: "title-large" },
      ],
    },
  },
  {
    type: "textfield",
    input: true,
    label: "Label",
    key: "label",
    tooltip: "The description of the question",
    weight: 10,
    reorder: true,
  },
  // example of dropdown:
  // {
  //   type: 'select',
  //   input: true,
  //   label: 'Storage Type',
  //   key: 'dataType',
  //   clearOnHide: true,
  //   tooltip: 'The type to store the data. If you select something other than autotype, it will force it to that type.',
  //   weight: 12,
  //   template: '<span>{{ item.label }}</span>',
  //   dataSrc: 'values',
  //   data: {
  //     values: [
  //       { label: 'Autotype', value: 'auto' },
  //       { label: 'String', value: 'string' },
  //       { label: 'Number', value: 'number' },
  //       { label: 'Boolean', value: 'boolean' },
  //       { label: 'Object', value: 'object' },
  //     ],
  //   },
  // },
];
