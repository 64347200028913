import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { IdVerificationService } from "src/app/id-verification/id-verification.service";

@Component({
  selector: "app-id-verification-result",
  templateUrl: "./id-verification-result.component.html",
  styleUrls: ["./id-verification-result.component.scss"],
})
export class IdVerificationResultComponent implements OnInit {
  @Input() contentDOM: any[];
  @Output() closeAlert = new EventEmitter<any>();

  constructor(private idVerificationService: IdVerificationService) {}

  ngOnInit() {}

  parseContentReturn(event) {}
  OnDisplayLoader(event) {}

  closeAlertEmit(event) {
    event.preventDefault();
    this.closeAlert.emit(true); // usually on mobile
  }
}
