<div class="mt-5 language-dropup">
  <div class="dropup-wrapper">
    <img
      class="profile-icon"
      src="../../../../assets/images/globe_placeholder_teal.svg"
    />
    <span class="translation">{{ "translation.Language" | translate }}: </span>
    <ul>
      <li (click)="showLanguageList()" #languageSwitcher>
        <span class="translation"
          >{{ "translation.language" | translate }}
        </span>
        <i class="fa fa-angle-up" aria-hidden="true"></i>
        <ul id="dropup-content" *ngIf="showLanguages" @fadeIn @fadeOut>
          <div class="triangle"></div>
          <li
            *ngIf="currentLang != 'en'"
            id="en"
            class="languages"
            (click)="changeLanguage('en')"
          >
            <i class="lg-flag flag-en"> </i>
            <span class="post-title">{{ "translation.en" | translate }}</span>
          </li>
          <li
            *ngIf="currentLang != 'nl'"
            id="nl"
            class="languages"
            (click)="changeLanguage('nl')"
          >
            <i class="lg-flag flag-nl"> </i>
            <span class="post-title">{{ "translation.nl" | translate }}</span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
